import React, { useCallback, useContext, useState } from "react";
import { TurnoSelectorContext } from "./TurnoSelectorContext";
import { Button, Switch, Typography, message } from "antd";
import AppointmentService from "../../../services/appointment.service";
import moment from "moment";

export default function TurnoSave({ onSaved, onUpdated }) {

  const [saving, setSaving] = useState(false)
  const { appointmentId, client, date, duration, hour, observations, user, isOverturn, customTitle, setIsOverturn } = useContext(TurnoSelectorContext);

  const handleOverturn = (checked) => {
    setIsOverturn(checked)
  }

  const isDisabled = useCallback(() => {
    if (!client || !date || !duration || !hour || !user) {
      return true
    }
    return false
  }, [
    client, date, duration, hour, observations, user
  ])

  /* console.log({ client, date, duration, hour, observations, user }) */

  const handleSave = async () => {
    try {
      setSaving(true)
      const starting = moment(date).set({ hour: hour.split(":")[0], minute: hour.split(":")[1] })
      const ending = starting.clone().add(duration, 'minutes')
      const set_custom_title = customTitle && customTitle.trim() !== '';

      const clientExists = client && !client.raw;

      const payload = {
        customTitle: set_custom_title ? customTitle : null,
        clientExists,
        client_id: clientExists ? client.id : client.firstname,
        date: date.clone().format("YYYY-MM-DD"),
        out_of_shift: isOverturn,
        starting_time: starting.format("HH:mm"),
        ending_time: ending.format("HH:mm"),
        duration,
        hour,
        observation: observations,
        user_id: user
      }

      console.log(payload)

      if (appointmentId) {
        const { data } = await AppointmentService.updateAppointment(payload, appointmentId)
        onUpdated && onUpdated(data)
        message.success({ type: 'success', content: 'Turno actualizado' })
      } else {
        const { data } = await AppointmentService.storeAppointment(payload)
        onSaved && onSaved(data)
        message.success({ type: 'success', content: 'Turno agendado' })
      }

    } catch (error) {
      message.error({ type: 'error', content: 'No se pudo agendar el turno' })
    } finally {
      setSaving(false)
    }
  }

  return <div className="t-resume" style={{ display: 'flex', columnGap: '10px', alignItems: 'center' }}>
    <Button
      disabled={isDisabled()}
      type={isDisabled() ? 'default' : 'primary'}
      loading={saving} onClick={() => handleSave()}>
      Guardar turno
    </Button>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography.Text style={{ fontSize: '0.65rem' }}>Sobreturno</Typography.Text>
      <Switch onChange={handleOverturn} defaultChecked={isOverturn} title="Sobreturno" />
    </div>
  </div>;
}