import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from "antd";
import UserService from "../../services/user.service";
import React, { useState, useEffect } from "react";

const { Option } = Select;

const UserSelect = ({ getFieldDecorator, users, mode, formKey, label, required, hideOnSelect, withTrashed, onUserSelect }) => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    UserService.getUsers(1, true, false, users[0])
      .then(data => {

        if (withTrashed) {
          setData(data.data);
        } else {
          //Ocultar usuarios borrados a menos que se esté editando y/o esos usuarios borrados esten pre-seleccionados
          const users_list = [...data.data].filter(user => (user.deleted_at === null || users && users.includes(user.id)));
          setData(users_list);
        }
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Form.Item key={formKey} label={label}>
      {getFieldDecorator(formKey, {
        rules: [
          {
            required: required,
            message: "Debe indicar el/los responsable/s."
          }
        ],
        initialValue: !loading ? users : [] || []
      })(
        <Select
          className="responsive-input"
          onChange={(v) => {
            onUserSelect && onUserSelect(v);
          }}
          onSelect={() => {
            hideOnSelect && setOpen(false);
          }}
          onDeselect={() => {
            hideOnSelect && setOpen(false);
          }}
          onDropdownVisibleChange={setOpen}
          open={open}
          optionFilterProp="children"
          showSearch
          loading={loading} mode={mode}>
          {data.map(user => (
            <Option key={user.id} value={user.id} style={{ color: user.deleted_at ? 'red' : 'inherit' }}>
              {user.firstname} {user.lastname}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

export default UserSelect;

UserSelect.defaultProps = {
  mode: "default",
  users: [] || null,
  label: "Usuarios",
  hideOnSelect: false
};
