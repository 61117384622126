import { Card } from "antd";
import React, { useState } from "react";
import GoogleOAuth from "../../../components/GoogleOAauth";
import SyncContacts from "../../../components/GoogleOAauth/SyncContacts";
import GoogleStateContainer from "../../../unstatedStores/GoogleStateStore";
import GoogleApisImage from '../../../assets/images/google_apis.png';

const GoogleIntegration = ({ withoutCard }) => {
  const [askForcontactIntegration, setAskContactIntegration] = useState(false);
  const [showSync, setShowSync] = useState(false);
  const googleState = GoogleStateContainer.useContainer();
  return !withoutCard ? (
    <Card className="gx-card" title="Google (Calendar, Contacts) " >
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          justifyContent: "space-between"
        }}
      >
        <img src={GoogleApisImage} alt="Google" style={{ width: '100%', height: 'auto' }} />
        <p>Integración de servicios de calendario y contactos de Google</p>
        <GoogleOAuth
          canSync={result => setShowSync(result)}
          askForContactSync={() => setAskContactIntegration(true)}
        />
        {showSync && googleState.googleState.authorized && (
          <SyncContacts
            canSync={result => setShowSync(result)}
            askForContactSync={() => setAskContactIntegration(false)}
            defaultVisible={askForcontactIntegration}
          />
        )}
      </div>
    </Card>
  ) : (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        justifyContent: "space-between"
      }}
    >
      <p>Integración de servicios de calendario y contactos de Google</p>
      <GoogleOAuth
        canSync={result => setShowSync(result)}
        askForContactSync={() => setAskContactIntegration(true)}
      />
      {showSync && googleState.googleState.authorized && (
        <SyncContacts
          canSync={result => setShowSync(result)}
          askForContactSync={() => setAskContactIntegration(false)}
          defaultVisible={askForcontactIntegration}
        />
      )}
    </div>
  );
};

export default GoogleIntegration;
