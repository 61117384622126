import { LoadingOutlined, WarningFilled, CheckCircleFilled } from '@ant-design/icons';
import { Button, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import SubscriptionService from '../../services/subscription.service';

export default function PlanUpgrade() {

  const queryParams = new URLSearchParams(window.location.search);
  const preapprovalId = queryParams.get('preapproval_id');
  const [isSuccess, setIsSuccess] = useState(0); // 0: loading, 1: success, 2: error
  const [result, setResult] = React.useState();

  const success = async () => {
    setResult({
      message: "Tu subscripción ha sido procesada con éxito",
      icon: <CheckCircleFilled style={{ color: '#3ec94d' }} />,
      extra: 'Serás redirigido a tu estudio en unos segundos'
    });
    setIsSuccess(1);
    return setTimeout(() => {
      window.location.href = '/';
    }, 3000);
  }

  const processSubscription = async () => {
    try {
      setResult({
        message: "Estamos verificando tu subscripción...",
        extra: 'Por favor espera un momento',
        icon: <LoadingOutlined />
      });
      setIsSuccess(0);
      await SubscriptionService.processSubscription(preapprovalId)
      success();
    } catch (error) {
      setIsSuccess(2);
      setResult({
        message: "Hubo un error al procesar tu subscripción",
        icon: <WarningFilled style={{ color: '#ffcd4f' }} />,
        extra: error.response ?
          error.response.data ?
            error.response.data.message
            : 'Ponte en contacto con nosotros para resolver el problema'
          : 'Ponte en contacto con nosotros para resolver el problema'
      });
    }
  }

  useEffect(() => {
    processSubscription();
  }, [preapprovalId]);

  return <div style={{ display: 'grid', placeContent: 'center', gap: 20 }}>
    {result && <Result
      icon={result.icon}
      title={result.message}
      extra={<p style={{ fontStyle: 'italic' }}>{result.extra}</p>}
    />}
    {isSuccess === 2 && <Button onClick={() => processSubscription()}>Reintentar</Button>}
    {(isSuccess === 2 || isSuccess === 1) && <Button onClick={() => {
      window.location.href = '/'
    }}>Regresar a Metagestion</Button>}
  </div>
}