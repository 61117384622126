import { CLIENTS_FETCHED, CLIENT_CREATED, CLIENT_DELETED, CLIENT_UPDATED } from "../../constants/ActionTypes";

const INIT_STATE = {
    clients: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLIENTS_FETCHED: {
            return { ...state, clients: action.payload };
        }
        case CLIENT_CREATED: {
            return { ...state, clients: [...state.clients, action.payload] }
        }
        case CLIENT_DELETED: {
            return { ...state, clients: state.clients.filter(c => c.id !== action.payload) }
        }
        case CLIENT_UPDATED: {
            return { ...state, clients: state.clients.map(c => c.id !== action.payload.id ? action.payload : c) }
        }
        default:
            return state;
    }
}
