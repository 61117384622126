import { Tag } from 'antd';
import React from 'react';

export const Exjudicial = ({ movement }) => {
    if (
        movement.movement_type_id !==
        Number.parseInt(process.env.REACT_APP_MOVEMENT_TYPE_ID_LEX_ESCRITO)
    ) {
        return (
            <span>
                <Tag
                    style={{ fontSize: "10px" }}
                    color={movement.is_judicial ? "#8987C0" : "#FBBB00"}
                >
                    {movement.is_judicial ? "Judicial" : "Extrajudicial"}
                </Tag>
                {movement.important ? (
                    <Tag style={{ fontSize: "9px" }} color="#8CB3FF">
                        IMPORTANTE
                    </Tag>
                ) : null}
                {movement.public_access ? (
                    <Tag style={{ fontSize: "9px" }} color="#8CB3FF">
                        EXTERNO
                    </Tag>
                ) : null}
            </span>
        );
    } else {
        return "-";
    }
}