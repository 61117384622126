import { SaveOutlined } from '@ant-design/icons';
import { Button, message, Steps, Form, Row, Col, Progress } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useExpedientMovementForm } from '../../../hooks/useExpedientMovementFormState';
import ExpedientMovementService from '../../../services/expedient_movement.service';
import { parseJwt } from '../../../util/jwt.helper';
import CenteredLoading from '../../CenteredLoading';
import checkStorage from '../../ExpedientFiles/StorageWarning';
import FirstStepForm from './components/FirstStepForm';
import SecondtStepForm from './components/SecondsStepForm';
import { purifyStyledText } from '../../../util/purifyText';


const { Step } = Steps;


const ExpedientMovementFormWithSteps = ({
    expedient_movement,
    expedient,
    onCancel,
    onUpdated,
    onSaved,
}) => {


    const { fields, setFields } = useExpedientMovementForm();
    const [uploadPercentage, setUploadPercentage] = useState(0.0);
    const [loading, setLoading] = useState(false);
    const [loadingAttach, setLoadingAttach] = useState(false);
    const user = parseJwt();
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' })


    const [form1] = Form.useForm();
    const [form2] = Form.useForm();

    const steps = [
        {
            title: 'Parte 1/2',
            content: Object.keys(fields).length > 0 ? <FirstStepForm expedient_movement={expedient_movement} expedient={expedient} form={form1} /> : <CenteredLoading />,
        },
        {
            title: 'Parte 2/2',
            content: Object.keys(fields).length > 0 ? <SecondtStepForm expedient_movement={expedient_movement} expedient={expedient} form={form2} uploadPercentage={uploadPercentage} /> : <CenteredLoading />,
        }
    ];

    const [current, setCurrent] = useState(0);

    const next = () => {
        setCurrent(current + 1);
        form1.submit()
    };

    const prev = () => {
        setCurrent(current - 1);
        form2.submit()
    };

    const handleDone = () => {
        if (current === 0) {
            form1.submit()
        } else {
            form2.submit()
        }

        setTimeout(() => {
            if (fields.observations === null) {
                return message.error("El campo 'Observaciones' no puede ser nulo.");
            } else if (fields.movement_type_id === null) {
                return message.error("Debe seleccionar el tipo de movimiento.");
            }
            handleSubmit(fields);
        }, 250);
    };

    useEffect(() => {
        setFields({
            movement_type_id: expedient_movement ? expedient_movement.movement_type_id : null,
            is_judicial: expedient_movement ? expedient_movement.is_judicial : true,
            taskable: expedient_movement ? Boolean(expedient_movement.taskable) : true,
            realizado: expedient_movement ? expedient_movement.realizado : false,
            insta_la_accion: expedient_movement ? Boolean(expedient_movement.insta_la_accion) : true,
            public_access: expedient_movement ? expedient_movement.public_access : false,
            is_public: expedient_movement ? expedient_movement.is_public : true,
            important: expedient_movement ? expedient_movement.important : true,
            days_before_popup_notification: expedient_movement ? expedient_movement.days_before_popup_notification : "1",
            days_before_mail_notification: expedient_movement ? expedient_movement.days_before_mail_notification : "1",
            days_before_client_mail_notification: expedient_movement ? expedient_movement.days_before_client_mail_notification : null,
            observations: expedient_movement ? expedient_movement.observations : null,
            foja_number: expedient_movement ? expedient_movement.foja_number : null,
            date: expedient_movement ? moment(expedient_movement.date) : moment(),
            public_access_observation: expedient_movement ? expedient_movement.public_access_observation : null,
            files: [],
            data: []
        });
    }, []);



    const handleSubmit = async values => {

        const attach = document.activeElement.id === "attach";

        setLoading(true);

        let filesSize = 0;

        //Si se adjuntan archivos, FilesSize será mayor que cero
        if (fields.files.length > 0) {
            filesSize = fields.files
                .map(file_data => file_data.size)
                .reduce((a, b) => a + Number.parseInt(b));
        }

        /**
         * La siguiente funcion verifica si el usuario es super administrador y
         * si hay suficiente almacenamiento disponible para los archivos, en cuyo caso, ejecuta la carga de archivos, de lo contrario,
         * se muestra un warning y no se permite modificar el movimiento de expediente.
         *
         * El Administrador general puede cargar archivos independiente de la capacidad de almacenamiento del estudio.
         */

        checkStorage({
            user_role_id: user.user.role_id,
            studio_id: user.user.studio_id,
            fileSize: filesSize,
            onChecked: () =>
                createOrUpdateMovement(
                    { ...values, realizado: fields.realizado, data: fields.data, files: fields.files },
                    expedient,
                    expedient_movement,
                    setUploadPercentage
                )
                    .then(mov => {
                        message.success(
                            `Movimiento ${expedient_movement ? "editado" : "creado"}.`,
                            2
                        );
                        expedient_movement ? onUpdated(mov.data, attach) : onSaved(mov.data, attach);
                        attach ? setLoadingAttach(false) : setLoading(false);
                    })
                    .catch(() => {
                        message.error("Ha ocurrido un error!");
                        attach ? setLoadingAttach(false) : setLoading(false);
                    }),
            onCantStorage: () =>
                attach ? setLoadingAttach(false) : setLoading(false)
        });
    };


    return (
        <>
            <div className="movement-form-header"> Parte {current + 1}/2 &nbsp;<Progress strokeColor='#016ece' style={isTabletOrMobile ? { width: "30%" } : { width: "15%" }} percent={(50 * (current + 1))} showInfo={false} /> </div>
            <div className="steps-content">{steps[current].content}</div>
            <Row gutter={10} style={{ flexDirection: "inherit" }}>
                <Col span={24} style={{ display: "inline-flex", justifyContent: "center" }}>
                    {current > 0 && (
                        <Button loading={loading} htmlType="button" className={"responsive-input responsive-button"} type="primary" style={{ margin: '0 8px' }} onClick={() => prev()}>
                            Anterior
                        </Button>
                    )}
                    <Button loading={loading} className={"responsive-input responsive-button"} ghost type="primary" style={{ fontSize: "16px" }} onClick={() => handleDone()}>
                        <SaveOutlined /> Guardar
                    </Button>
                    {current < steps.length - 1 && (
                        <Button loading={loading} htmlType="button" className={"responsive-input responsive-button"} style={{ margin: '0 8px' }} type="primary" onClick={() => next()}>
                            Siguiente
                        </Button>
                    )}
                </Col>
            </Row>

        </>
    );
};

async function createOrUpdateMovement(
    values,
    expedient,
    movement,
    setUploadPercentage
) {
    const { user } = parseJwt();

    Object.assign(values, {
        observations: purifyStyledText(values.observations),
        expedient_id: expedient.id,
        date: moment(values.date).format("YYYY-MM-DD HH:mm:ss"),
        responsibles: values.internal_responsibles
            .map(resp => ({
                responsible_id: resp,
                is_corresponsible: false
            }))
            .concat(
                values.co_responsibles.map(resp => ({
                    responsible_id: resp,
                    is_corresponsible: true
                }))
            )
    });

    const { files } = values;

    delete values["files"];
    delete values["current_files"];



    const form_data = new FormData();
    form_data.set("body", JSON.stringify({ ...values }));
    files &&
        files.forEach(file => {
            form_data.append("files[]", file.originFileObj);
        });

    if (!Boolean(movement)) {
        return await ExpedientMovementService.createExpedientMovement(
            form_data,
            progress => setUploadPercentage(progress)
        );
    } else {
        form_data.append("id", movement.id);
        return await ExpedientMovementService.updateExpedientMovement(
            form_data,
            progress => setUploadPercentage(progress)
        );
    }
}

export default ExpedientMovementFormWithSteps;