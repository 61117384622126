import { Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import CreateExpedientForm from "../law-components/Forms/CreateExpedientForm";
import { HStack } from "./ExpedientFilterModal";
import { VideoTutorialButton } from "./VideoTutorialModal";

const CreateExpedientModal = ({ showModal, onCancel }) => {
  const [_step, _setStep] = useState(0);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <Modal
      title={<HStack>
        <Typography.Text style={{ fontWeight: 800 }} >Crear expediente</Typography.Text>
        <VideoTutorialButton tutorial={"crear_expediente"} />
      </HStack>}
      visible={showModal}
      onCancel={() => onCancel()}
      closable
      destroyOnClose
      footer={null}
      width={isTabletOrMobile ? "95vw" : "80vw"}
      centered
    >
      <CreateExpedientForm
        onCancel={() => onCancel()}
        onStored={() => onCancel()}
      />
    </Modal>
  );
};

export default CreateExpedientModal;
