import React from 'react';
import { Typography } from "antd";
import { moneyFormatter } from "../../../util/moneyFormatter";
import { onCopy } from '../../../routes/RtfPage/RtfInfo';

const AccountBalance = ({ amount, size }) => {
  return (
    <Typography.Text
      className="copyable-text"
      onClick={(event) => onCopy(event.currentTarget.innerHTML)}
      style={{
        fontWeight: "500",
        fontSize: size,
        color: amount >= 0 ? "#3D529E" : "#FF5879",
        whiteSpace: "nowrap"
      }}
    >
      {moneyFormatter(amount)}
    </Typography.Text>
  );
};

AccountBalance.defaultProps = {
  size: "1.5rem"
};

export default AccountBalance;