import React, { useEffect, useState } from "react";
import { message, Row, Col, Button, Input, Collapse } from "antd";
import RtfService from "../../services/rtf.service";
import { useMediaQuery } from "react-responsive";
import CenteredLoading from "../../law-components/CenteredLoading";

const { Panel } = Collapse;

const RtfInfo = ({ onCopyFromEditor, type = "RTF" }) => {
  const [loading, setLoading] = useState(false);
  const [vars, setVars] = useState([]);
  const [filteredVars, setFilteredVars] = useState([]);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    setLoading(true);
    getVars(type)
      .then((variables) => {

        let varsTitle = Array.from(new Set(variables.map(v => {
          let index = v.indexOf('.');
          return v.substring(0, index);
        }))).sort();

        const sorted_v = varsTitle.map(title => {
          return {
            label: title,
            children: variables
              .sort()
              .filter((v) => {
                let index = v.indexOf('.');
                return v.substring(0, index) === title; 
                // con esto evitamos que por ejemplo la variable "cliente.fecha_de_nacimiento"
                // se repita en el grupo de "fecha"
              })//Verificamos que la variable hace match con el titulo de parent
              .map((v) => {

                //Retornamos la variable que ahora esta guardada en el agrupada
                let index = v.indexOf('.') + 1;
                return {
                  label: v.substring(index, v.length),
                  value: v
                }

              })
          }
        });

        setVars(sorted_v);
        setFilteredVars(sorted_v);
      })
      .catch(e => message.error("No se han podido cargar las variables"))
      .finally(() => setLoading(false));
  }, []);

  const filter = (w) => {

    let main_vars = [];

    if (w.replace(/ /g, "") === "") {
      for (let i = 0; i < vars.length; i++) {
        main_vars[i] = vars[i];
      }
    } else {
      for (let i = 0; i < vars.length; i++) {
        if (vars[i].children.filter(variable => variable.value.toLowerCase().includes(w.toLowerCase())).length > 0) {
          main_vars[i] = Object.assign({}, {
            label: vars[i].label,
            children: vars[i].children.filter(variable => variable.value.toLowerCase().includes(w.toLowerCase()))
          });
        }
      }
    }

    console.log(main_vars, vars);

    setFilteredVars(main_vars);

  }

  return !loading ? (
    <Row gutter={[5, 5]}>
      <Col span={24}>
        <Input.Search placeholder="filtrar" enterButton onSearch={filter} />
      </Col>
      <Collapse style={{ width: "100%" }} >
        {filteredVars.map((category, i) => (
          <Panel
            header={`${category.label} (${category.children.length})`} key={i}
          /*             size={isTabletOrMobile ? "small" : "middle"}
                      onClick={() => onCopy(v, onCopyFromEditor)} */
          >
            {category.children.map((v, i) => (
              <Button
                style={{ marginBottom: '2px' }}
                size={isTabletOrMobile ? "small" : "middle"}
                onClick={() => onCopy(v.value, onCopyFromEditor)}
              >
                {v.label}
              </Button>
            ))}
          </Panel>
        ))}
      </Collapse>
    </Row>
  ) : (
    <CenteredLoading tip="Cargando variables" />
  );
};

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    if (successful) message.success("Copiado!");
    else message.error("No se pudo copiar");
  } catch (err) {
    message.error("No se pudo copiar");
  }

  document.body.removeChild(textArea);
}

export function onCopy(text, onCopyFromEditor = null) {
  if (onCopyFromEditor) {
    onCopyFromEditor(text);
  }
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      message.success("Copiado!");
    },
    function (err) {
      message.error("No se pudo copiar");
    }
  );
}

async function getVars(type = "RTF") {
  if (type === "LETTER") {
    const resp = await RtfService.getLetterVars();
    return resp.data;
  } else {
    const resp = await RtfService.getVars();
    return resp.data;
  }
}

export default RtfInfo;
