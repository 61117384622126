import { useEffect, useState } from "react";
import { parseJwt } from "../util/jwt.helper";

export function useScope(scope) {
  const [permit, setPermit] = useState(false);
  const { claims } = parseJwt();
  useEffect(() => {
    const _permit = claims.includes(scope);
    setPermit(_permit);
  }, [scope, claims]);

  return permit;
}
