import { UPDATE_MOVEMENTS_LOCAL_FILTER, UPDATE_MOVEMENTS_LOCAL_SEARCH, TOGGLE_SHOW_EXPORT_EXPEDIENT } from "../../constants/ActionTypes";

const INIT_STATE = {
    movementsLocalFiltersState: {
        status: "todo",
        judicial: "todo",
        important: "todo",
        movement_type: null,
        sortOrder: false
    },
    movementsSearch: null,
    showExportExpedient: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_MOVEMENTS_LOCAL_FILTER: {
            return { ...state, movementsLocalFiltersState: action.payload }
        }

        case UPDATE_MOVEMENTS_LOCAL_SEARCH: {
            return { ...state, movementsSearch: action.payload }
        }

        case TOGGLE_SHOW_EXPORT_EXPEDIENT: {
            return { ...state, showExportExpedient: action.payload }
        }

        default:
            return state;
    }
}