import { DEFENSORIA_DESIGN } from "../../constants/ThemeSetting";

export const handleDesignModeChange = (e) => async (dispatch) => {
    
    localStorage.setItem("defensoriamode", e);
  
    dispatch({
      type: DEFENSORIA_DESIGN,
      payload: e,
    });
  };