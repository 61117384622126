import axios from "../util/Api";

const ExpedientService = {
  updateObservation: (observation, expedient_id) => {
    return axios.patch(
      `expedient/update-observation/${expedient_id}`,
      { observation },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
  },
  getAccount: expedient_id => {
    return axios.get(`expedient/account/${expedient_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  generatePrintableFront: expedient_id => {
    return axios.get(`expedient/generate-printable-front/${expedient_id}`, {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  searchExpedient: params => {
    return axios.get(`expedient-search`, {
      params: {
        search: params
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  expedientExists: (params = {}) => {
    return axios.get("expedient-check", {
      params: params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteResponsible: data => {
    return axios.post("expedient/delete-responsible", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createExpedient: expedient => {
    return axios.post("/expedient", expedient, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  vinculateExpedients: data => {
    return axios.post("/vinculate-expedients", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }, editVinculation: data => {
    return axios.patch("/edit-expedient-vinculation", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getExpedientTypes: () => {
    return axios.get("/expedient-type", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getExpedientPg: (page = 1, filterExpedient, showTrashed = false, perPage) => {
    return axios.get(`/paginate/expedient?page=${page}`, {
      params: { ...filterExpedient, showTrashed, perPage },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  searchExpedientsToVinculate: (page = 1, filterExpedient, showTrashed = false, perPage) => {
    return axios.get(`/search-expedients-to-vinculate?page=${page}`, {
      params: { ...filterExpedient, showTrashed, perPage },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  printListsCsv: (filterExpedient, showTrashed = false, mode) => {
    return axios
      .get("/paginate/expedient?page=1", {
        params: {
          ...filterExpedient,
          showTrashed,
          mode,
          page: 1,
          showall: true
        },
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then(
        resp =>
          new Blob([resp.data], {
            type:
              mode === "csv"
                ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                : "application/pdf"
          })
      );
  },
  getExpedientStatus: () => {
    return axios.get("expedient-status", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getExpedientStatusPg: params => {
    return axios.get(`/paginate/expedient-status`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  changeExpedientStatus: data => {
    return axios.post("expedient/update-status", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createExpedientStatus: expedient_status => {
    return axios.post("/expedient-status", expedient_status, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateExpedientStatus: expedient_status => {
    return axios.patch(
      `/expedient-status/${expedient_status.id}`,
      expedient_status,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
  },
  softDeleteExpedient: expedient => {
    return axios.delete(`/expedient/${expedient}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  restoreExpedient: expedient => {
    return axios.patch(
      `/expedient-restore/${expedient}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
  },
  deleteExpedientStatus: expedient_status => {
    return axios.delete(`/expedient-status/${expedient_status}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteVinculation: vinculated_exp_id => {
    return axios.delete(`/delete-vinculation/${vinculated_exp_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getFiles: (expedient, params = {}) => {
    return axios.get(`/expedient/${expedient}/files`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  countFiles: expedient => {
    return axios.get(`file/expedient/count/${expedient}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getExpedient: expedient => {
    return axios.get(`/expedient/${expedient}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getVinculatedExpedients: expedient => {
    return axios.get(`/expedient/vinculated-expedients/${expedient}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getVinculationTypes: () => {
    return axios.get(`/expedient-vinculation-type`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  importFromMev: data => {
    return axios.post("/expedient/import-from-mev", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getAllMevCausas: (credentials) => {
    return axios.get("/expedient/all-mev-causas", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      params: {
        mevCredentialId: credentials
      }
    });
  },
  updateExpedient: expedient => {
    return axios.patch(`/expedient/${expedient.id}`, expedient, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  renameFile: (file, name) => {
    return axios.patch(
      `/file/rename/${file}`,
      { name },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
  },
  deleteFile: file => {
    return axios.delete(`/file/${file}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  processFiles: (data) => {
    return axios.post(
      "/file/process-files",
      data,
      {
        headers: {
          ContentType: 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        responseType: "blob",
      }
    );
  },
  deleteFiles: (ids) => {
    return axios.post(
      "/file/delete-files",
      { ids },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
  },
  getFilertsExpedient: (data = {}) => {
    return axios.post("/get/filters/expedient", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  massActionsOfExpedients: data => {
    return axios.post("mass/actions/expedients", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  donwloadExpedientAsPdf: async (params) => {
    return axios
      .get(`/expedient-movements/export-by-expedient`, {
        params,
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then(
        resp =>
          new Blob([resp.data], {
            type: "application/pdf"
          })
      );
  },
};

export default ExpedientService;
