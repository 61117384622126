import { useState } from 'react';
import { createContainer } from 'unstated-next';

const  useMailCounter = () => {
 const [count, setCount] = useState(0)

  let set = (c) => {
    setCount(c);
  }

  let add = () => {
    setCount(c => c + 1);
  }

  let subtract = () => {
    setCount(c => c - 1);
  }

 return {count, setCount, add, set, subtract }
}

const MailCounterContainer=createContainer(useMailCounter);
export default MailCounterContainer;

