import axios from "../util/Api";

const GoogleOAuthService = {
    syncContacts: () => {
        return axios.get(`/google-contacts/sync`, {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    callback: (data) => {
        return axios.post(`/oauth2/callback`, data, {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    getScopes: () => {
        return axios.get(`/oauth2/get-scopes`, {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    validateAuthorization: () => {
        return axios.get(`/oauth2/validate-authorization`, {
            headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    logout: () => {
      return axios.get(`/oauth2/logout`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
    }
};

export default GoogleOAuthService;
