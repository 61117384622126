import axios from "../util/Api";

const ConfigService = {
  updateAccountMovement: (movement, id) => {
    return axios.post(`expedient/account/${id}`, movement, {
      headers: {
        ContentType: "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteAccountMovement: (id) => {
    return axios.delete(`expedient/account/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createAccountMovement: (movement) => {
    return axios.post('expedient/account', movement, {
      headers: {
        ContentType: 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getAccountingConcept: (params) => {
    return axios.get('/paginate/accounting-concept', {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getAccountingConceptNoPg: (url = "/accounting-concept") => {
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateAccountingConcept: (ac) => {
    return axios.patch(`accounting-concept/${ac.id}`, ac, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createAccountingConcept: accountingConcept => {
    return axios.post("accounting-concept", accountingConcept, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteAccountinConcept: ac => {
    return axios.delete(`accounting-concept/${ac}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getIndentificationTypesPg: (page = 1) => {
    return axios.get(`/paginate/identification-type?page=${page}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getIndentificationTypes: (url = "/trial/identification-type") => {
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createIndentificationType: identificationType => {
    return axios.post("identification-type", identificationType, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },

  createContact: contact => {
    return axios.post("contact", contact, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getContact: () => {
    return axios.get("/contact", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getAddresses: () => {
    return axios.get("/contact", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getContactTypePg: (page = 1) => {
    return axios.get(`/paginate/contact-type?page=${page}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getContactType: () => {
    return axios.get("/contact-type", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createContactType: contactType => {
    return axios.post("/contact-type", contactType, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getExpedientTypePg: (params) => {
    return axios.get(`/paginate/expedient-type`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getExpedientVinculationTypePg: (params) => {
    return axios.get(`/paginate/expedient-vinculation-type`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getExpedientType: () => {
    return axios.get("/expedient-type", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createExpedientType: expedienttype => {
    return axios.post("/expedient-type", expedienttype, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createExpedientVinculationType: expedientVinculationType => {
    return axios.post("/expedient-vinculation-type", expedientVinculationType, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateExpedientType: (expedient_type) => {
    return axios.patch(`/expedient-type/${expedient_type.id}`, expedient_type, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateExpedientVinculationType: (expedientVinculationType) => {
    return axios.patch(`/expedient-vinculation-type/${expedientVinculationType.id}`, expedientVinculationType, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteExpedientType: (expedient_type) => {
    return axios.delete(`/expedient-type/${expedient_type}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteExpedientVinculationType: (expedient_vinculation_type) => {
    return axios.delete(`/expedient-vinculation-type/${expedient_vinculation_type}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getCallRegistryPg: (url = "/paginate/call-registry") => {
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getCallRegistry: () => {
    return axios.get("/call-registry", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  CreateCallRegistry: callregistry => {
    return axios.post("/call-registry", callregistry, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },

  getStudioPg: (params = {}) => {
    return axios.get(`/paginate/studio`, {
      params: params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getStudio: (url = "/studio") => {
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createStudio: (studio, url = "/studio") => {
    return axios.post(url, studio, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateStudio: (studio_id, values, url = '/studio') => {

    url += `/${studio_id}`;

    values._method = 'PUT';

    return axios.post(url, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  restoreOrDelete: (id) => {

    return axios.post(`/studio/${id}/restore-or-delete`, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });

  },
  createSettings: (payload, url = "/setting") => {
    return axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateSettings: (payload, id, url = "/setting") => {
    return axios.put(`${url}/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  attachSpecialty: (payload, url = "/specialty/attach") => {
    return axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  detachSpecialty: (payload, url = "/specialty/detach") => {
    return axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getSpecialties: (url = "/specialty") => {
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteShift: (id, url = "/shift") => {
    return axios.delete(`${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getShiftsBySpecialtyUser: (specialty, user, url = "/shift") => {
    return axios.get(`${url}/user/${user}/specialty/${specialty}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createShift: (payload, url = "/shift") => {
    return axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  checkShiftAvailability: (payload, url = "/shift/check-availability") => {
    return axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateShift: (id, payload, url = "/shift") => {
    return axios.put(`${url}/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },

};

export default ConfigService;
