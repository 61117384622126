import { Subject } from "rxjs";

const subject = new Subject();

export const movementCounterUpdater = {
  updater: subject.asObservable(),
  updateMovemetCounter: movement => {
    subject.next(movement);
  }
};
