import { ContactsOutlined, FolderAddOutlined, QuestionOutlined, PlusOutlined, ScheduleOutlined, DollarOutlined, CalendarOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import React, { useState } from 'react';
import { BiPlus } from "react-icons/bi";
import { IconContext } from 'react-icons'
import "./index.css";
import CreateExpedientModal from '../../modals/CreateExpedientModal';
import CreateClientModal from '../../modals/CreateClientModal';
import { withRouter } from 'react-router-dom';
import { AppointmentModal } from '../../modals/CreateAppointmentModal';
import { useTrialDays } from '../../hooks/useTrialDays';
import { useMediaQuery } from 'react-responsive';
import CreateStudioTaskModal from '../../modals/CreateStudioTaskModal';
import { CreateFeeModal } from '../../modals/CreateFeeModal';
import { history } from '../../appRedux/store';


const QuickActionsMenu = ({ }) => {

  const [showCreateExpedient, setShowCreateExpedient] = useState(false);
  const [showAddContact, setShowAddContact] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [showCreateCheckModal, setShowCreateCheckModal] = useState(false);
  const [showCreateFeeModal, setShowCreateFeeModal] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 980px)" });

  const trialData = useTrialDays();
  const isTrial = trialData ? trialData.is_trial : false;
  const trialEnded = isTrial ? trialData.trial_ended : null;
  const disabledMenu = !(trialData && !trialEnded);

  const items = [
    {
      type: "divider",
    }, {

      title: "Expediente",
      label: "Expediente",
      key: "create-expedient",
      icon: <FolderAddOutlined className="quick-act-icon" />,
    },
    {
      title: "Contacto",
      label: "Contacto",
      key: "add-contact",
      icon: <ContactsOutlined className="quick-act-icon" />,
    },
    /* {
      type: "divider",
    }, */
    {
      title: "Honorario",
      label: "Honorario",
      key: "fee",
      icon: <DollarOutlined className="quick-act-icon" />
    },/*  {
      type: "divider",
    }, */ {
      title: "Turno",
      label: "Turno",
      key: "create-appointment",
      icon: <ScheduleOutlined className="quick-act-icon" />,
    }, {
      title: "Tarea",
      label: "Tarea",
      key: "check",
      icon: <CalendarOutlined className="quick-act-icon" />
    }, {
      type: "divider",
    },
    {
      title: "Preguntas frecuentes",
      label: "Preguntas frecuentes",
      key: "faq",
      icon: <QuestionOutlined className="quick-act-icon" />,
    }
  ];

  const handleMenuClick = (e) => {
    if (e.key === 'create-expedient') {
      setShowCreateExpedient(true)
      setShowAddContact(false)
      setShowAppointmentModal(false);
      setShowCreateCheckModal(false);
      setShowCreateFeeModal(false)
    } else if (e.key === 'add-contact') {
      setShowCreateExpedient(false)
      setShowAddContact(true)
      setShowAppointmentModal(false);
      setShowCreateCheckModal(false);
      setShowCreateFeeModal(false)

    } else if (e.key === 'create-appointment') {
      setShowCreateExpedient(false)
      setShowAddContact(false)
      setShowAppointmentModal(true);
      setShowCreateCheckModal(false);
      setShowCreateFeeModal(false)
    } else if (e.key === 'faq') {
      window.open('https://metajuridico.tawk.help/', '_blank');
    } else if (e.key === 'check') {
      setShowCreateExpedient(false)
      setShowAddContact(false)
      setShowAppointmentModal(false);
      setShowCreateCheckModal(true);
      setShowCreateFeeModal(false)
    } else if (e.key === 'fee') {
      setShowCreateExpedient(false)
      setShowAddContact(false)
      setShowAppointmentModal(false);
      setShowCreateCheckModal(false);
      setShowCreateFeeModal(true)
    }
  };

  return (
    <>
      <CreateStudioTaskModal
        show_custom_title
        schedule={false}
        onCreated={() => {
          /* onCreatedTask(); */
          setShowCreateCheckModal(false);
        }}
        task={undefined}
        visible={showCreateCheckModal}
        onCancel={() => setShowCreateCheckModal(false)}
      />
      <CreateFeeModal
        withoutExpedient={true}
        visible={showCreateFeeModal}
        onCancel={() => {
          setShowCreateFeeModal(false)
        }}
        onNewFee={(fee) => {
          setShowCreateFeeModal(false);
          history.push(`/expedient/details?account=${fee.id * -1}#${fee.expedient_uuid}`)
        }}
        onUpdated={(fee) => {
          setShowCreateFeeModal(false);
        }}
        expedient={null}
        fee={null}
      />
      <CreateExpedientModal
        showModal={showCreateExpedient}
        onCancel={() => setShowCreateExpedient(false)}
      />
      <CreateClientModal
        title={"cliente"}
        visible={showAddContact}
        onNewClient={c => {
          setShowAddContact(false);
        }}
        onCancel={() => {
          setShowAddContact(false);
        }}
        showLinkButton={true}
      />
      <AppointmentModal
        visible={showAppointmentModal}
        onCancel={() => setShowAppointmentModal(false)}
        isOutOfShift={false}
        onDone={() => setShowAppointmentModal(false)}
      />
      <Dropdown
        dropdownAlign
        trigger={["click"]}
        placement="bottomRight"
        menu={{
          items,
          onClick: handleMenuClick
        }}
        disabled={disabledMenu}
        dropdownRender={dropdownRender}
      >
        {isTabletOrMobile ?
          <button style={{ border: "none", background: "transparent", lineHeight: "10px", width: "5px" }}><IconContext.Provider value={{ size: "20px", color: "#fff" }}>
            <BiPlus />
          </IconContext.Provider></button>

          : <Button className='quick-act-button' >
            <IconContext.Provider value={{ size: "20px" }}>
              <BiPlus />
            </IconContext.Provider>
          </Button>}
      </Dropdown>
    </>
  )
}
export default withRouter(QuickActionsMenu);

const dropdownRender = menu => {
  return (
    <div className='quick-act-dropdown-edited' >
      <div className='quick-actions-popover-arrow'><span className="quick-actions-popover-arrow-content"></span> </div>
      <span style={{ fontWeight: "bold" }}>&nbsp;&nbsp; Añadir nuevo</span>
      {menu}

    </div>
  )
}