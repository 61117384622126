import React, { useState, useEffect } from "react";
import { Button, Row, Col, Input, message, Checkbox, Skeleton } from "antd";
import NotificationsService from "../../../services/notifications.service";
import { isNumber } from "lodash-es";

const ConfigureSmsNotificationForm = props => {

  const { movement_type, onCancel, type, movement, mode, outPut } = props;
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [templatesToSave, setTemplatesToSave] = useState([]);
  const [lastFocus, setLastFocus] = useState(-1);
  const [lastType, setLastType] = useState();


  const setConfig = async templ => {
    const config = await getConfig(movement_type, type);
    return templ.map(t => {
      const idx = config.findIndex(v => v.message_template_id === t.id);
      return {
        ...t,
        days_before_notification:
          idx !== -1 ? config[idx].days_before_notification : ""
      };
    });
  };

  const setOverrideConfig = async templ => {
    const config = await getOverrideConfig(movement.id, type);
    return templ.map(t => {
      const idx = config.findIndex(v => v.message_template_id === t.id);
      return {
        ...t,
        days_before_notification:
          idx !== -1
            ? config[idx].days_before_notification
            : t.days_before_notification
      };
    });
  };

  useEffect(() => {
    if (templates.length === 0 || type !== lastType) {
      setLoading(true);
      NotificationsService.getNotificationsTemplates({ type })
        .then(t => {
          setConfig(t.data).then(t => {
            if (movement) {
              setLoading(true);
              setOverrideConfig(t).then(t => {
                setTemplates(t);
              }).finally(() => {
                setLoading(false);
              });
            } else {
              setTemplates(t);
            }
          }).finally(() => {
            setLoading(false);
          });
        })
        .catch(err => {
          message.error("No se pudieron obtener las plantillas");
          setLoading(false);
        }).finally(() => {
          setLastType(type);
        });
    }
  }, [type]);

  const updateMovementNotificationTemplate = data => {
    NotificationsService.updateMovementMessageTemplate(data)
      .then(response => message.success("Cambios guardados con exito."))
      .catch(err => message.error("Ha ocurrido un error."));
  };

  const updateNotificationTemplate = data => {
    NotificationsService.updateMessageTemplate(data)
      .then(response => message.success("Cambios guardados con exito."))
      .catch(err => message.error("Ha ocurrido un error."));
  };

  const Field = ({ template, onChange, value, focus }) => {
    return (
      <Row>
        <Col span={13}>{template.title}</Col>
        <Col span={movement ? 9 : 11}>
          <Input
            autoComplete="cc-csc"
            autoFocus={focus}
            placeholder="1,2,3,4 dias antes de notificar."
            value={value}
            onChange={e =>
              onChange({
                days_before_notification: e.target.value,
                template: template.id,
                message_type: type,
                expedient_movement_id: movement
              })
            }
          />
        </Col>
      </Row>
    );
  };

  return (
    loading ? <Skeleton active /> :
      <Row type="flex" justify="start">
        {templates.map((template, k) => {
          const tId = templatesToSave.findIndex(t => t.template === template.id);
          return (
            <Col span={24} key={k}>
              <Field
                onChange={v => {
                  const idx = templatesToSave.findIndex(
                    tts => tts.template === v.template
                  );
                  let temp = [...templatesToSave];
                  if (idx > -1) temp[idx] = v;
                  else temp = [...temp, v];
                  setTemplatesToSave(temp);
                  setLastFocus(v.template);
                }}
                key={k}
                template={template}
                value={
                  tId > -1
                    ? templatesToSave[tId].days_before_notification
                    : template.days_before_notification
                }
                focus={lastFocus === template.id}
              />
            </Col>
          );
        })}
        <Col span={24}>
          <Button
            type="primary"
            onClick={() => {
              const t = templatesToSave/* .filter(_t => _t.days_before_notification !== ""); */
              const valid = true/* checkForZeroes(t); */
              if (valid) {
                outPut(t);
              } else {
                message.error("No puede contener letras o mas de 1 cero (0).");
              }
            }}
            className="ant-btn warn"
            htmlType="button"
            disabled={loading}
          >
            Guardar
          </Button>
          <Button
            type="danger"
            onClick={() => onCancel()}
            className="ant-btn warn"
            htmlType="button"
            disabled={loading}
          >
            Cancelar
          </Button>
        </Col>
      </Row>
  );
};

function checkForZeroes(templates) {
  let zeroes = 0;
  let letters = 0;
  templates.forEach(v => {
    let days = v.days_before_notification.split(",");
    if (days.includes("0")) zeroes++;
    if (days.some(d => isNaN(Number.parseInt(d)))) letters++;
  });
  return zeroes <= 1 && letters === 0;
}

async function getConfig(movement_type, type) {
  if (!movement_type) {
    const templates = await NotificationsService.getNotificationsTemplates({ type });
    return templates.data;
  } else {
    const currentConfig = await NotificationsService.getCurrentTemplateConfigByMovementType(
      movement_type.id,
      type
    );
    return currentConfig.data;
  }
}

async function getOverrideConfig(movement, type) {
  const overrideConfig = await NotificationsService.getMovementTemplateConfig(
    movement,
    type
  );
  return overrideConfig.data;
}

export default ConfigureSmsNotificationForm;

ConfigureSmsNotificationForm.defaultProps = {};
