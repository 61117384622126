import React, { useContext, useState } from 'react';
import { Input, Select } from "antd";
import { TurnoSelectorContext } from './TurnoSelectorContext';

const { Option } = Select

export function TDurationInput() {

  const { duration, setDuration, isOverturn, maxDuration } = useContext(TurnoSelectorContext)
  const [showCustom, setShowCustom] = useState(false)

  const handleSelect = (value) => {
    const numDuration = Number(value)
    if (!isNaN(numDuration)) {
      setDuration(numDuration)
      setShowCustom(false)
    } else {
      setShowCustom(true)
    }
  }

  return <>
    <Select className='responsive-input' onSelect={handleSelect} value={duration}>
      {durationOptions.map((duration) => {
        const cond = isOverturn || duration.value <= maxDuration;
        if (!cond) return null
        return (
          <Option key={duration.value} value={duration.value}>{duration.label}</Option>
        )
      })}
      <Option value={'custom'}>Personalizado</Option>
    </Select>
    {showCustom &&
      <Input
        type='number'
        min={15}
        max={maxDuration}
        onChange={e => {
          let v = e.target.value;
          if (v < 15) v = 15
          v && setDuration(Number(v))
        }}
      />}
  </>
}

const durationOptions = [
  {
    label: '15',
    value: 15
  },
  {
    label: '30',
    value: 30
  },
  {
    label: '60',
    value: 60
  }
] 