import { Typography } from 'antd';
import React from 'react';
import styled from "styled-components";

const MobileTableEntry = ({ item, isActive, columns }) => {
  const dataCheckedStyle = { color: isActive ? "#038fde" : "#707070" };
  const tableCheckedStyle = { backgroundColor: isActive ? "#f3f3f3" : "#fff", borderRadius: isActive ? "5px 14px 14px 5px" : "14px" };

  return (
    <TableContainer
      style={{
        width: "100%",
        border: "1.5px solid #CCCCCC",
        background: "#fff",
        position: "relative",
        margin: "1ch 0px 0.5ch 0px",
        display: "grid",
        gap: "0px 0px",

        ...tableCheckedStyle
      }}>
      {isActive && <SelectedRowIndicator />}
      {columns.map((column, i) => {
        return (
          <TableRow style={{ borderBottom: (i + 1) === columns.length ? "none" : "1px solid #CCCCCC" }}>
            <Td>
              <Typography.Text style={{ fontWeight: 700 }}>{column.title}</Typography.Text>
            </Td>
            <Td style={{ ...dataCheckedStyle, }}><Typography.Text>{column.render ? column.render(item[column.dataIndex], item) : item[column.dataIndex]}</Typography.Text></Td>
          </TableRow>
        )
      })}
    </TableContainer>)
}

export default MobileTableEntry;

const TableContainer = styled.table`
  border: 1px solid #CCCCCC;
  border-radius: 14px;
  background: #fff;
  margin: 5px;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #CCCCCC;
  display: flex;
  justify-content: space-between;
  &:first-child {
    font-weight: 500;
  }
`;

const Td = styled.td`
  padding: 0.8em 1em;
  align-items: center;
`;

const SelectedRowIndicator = styled.div`
  height: fill-available;
  height: -webkit-fill-available;
  width: 0.75ch;
  background-color: #038FDE;
  position: absolute;
  left: -1px;
  top: 0;
  border-radius: 0px 8px 8px 0px;
`

/* const mapStateToProps = ({ auth, client }) => {
  const { authUser } = auth;
  const { clientsFetched, clients, clientDeleted, clientUpdated } = client;
  return { authUser, clientsFetched, clients, clientDeleted, clientUpdated };
}; */

/* export default connect(mapStateToProps, {
  clientsFetched,
  clientDeleted,
  clientUpdated
})(ClientListMobile); */