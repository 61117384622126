import axios from '../util/Api';

const CountryService = {
  getCountries: () => {
    return axios.get('/trial/country', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getProvinces: (country) => {
    return axios.get(`/trial/country/${country}/provinces`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getCountryByProvince: (province_id) => {
    return axios.get(`/country/by-province/${province_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }
}

export default CountryService;
