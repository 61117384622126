import { Checkbox, Typography } from 'antd';
import React from 'react';
import styled from "styled-components";

const LawTableMobileListItem = ({ columns, record, }) => {

  const isChecked = false;

  const dataCheckedStyle = { color: isChecked ? "#038fde" : "#707070" };
  const tableCheckedStyle = { backgroundColor: isChecked ? "#f3f3f3" : "#fff", borderRadius: isChecked ? "5px 14px 14px 5px" : "14px" };
  /* console.log({ record, columns }) */
  return (
    <TableContainer
      style={{
        width: "100%",
        border: "1.5px solid #CCCCCC",
        background: "#fff",
        position: "relative",
        margin: "1ch 0px 0.5ch 0px",
        display: "grid",
        gap: "0px 0px",
        ...tableCheckedStyle
      }}>
      {columns.map((column, index) => {


        const title_is_function = typeof column.title === "function";
        if (column.render) {
          return (
            <>
              <TableRow >
                <Td style={column.title === "Acciones" ? { width: "30%" } : {}} ><b> <Typography.Text style={{ fontWeight: 700 }}>{title_is_function ? column.title(record[column.dataIndex]) : column.title}</Typography.Text></b></Td>
                <Td style={column.title === "Acciones" ? { width: "70%", display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '1.55em' } : {}}>
                  {column.render(record[column.dataIndex], record)}
                </Td>
              </TableRow>
            </>
          )
        }

        if (index === 0) {
          return (
            <>
              <TableRow style={{ flexFlow: 'column' }}>
                <Td><b>
                  {title_is_function ? column.title(record[column.dataIndex]) : column.title}
                </b></Td>
                <Td>
                  {record[column.dataIndex]}
                </Td>
              </TableRow>
            </>
          );
        } else {
          return (
            <>
              <TableRow >
                <Td><b>
                  {title_is_function ? column.title(record[column.dataIndex]) : column.title}
                </b></Td>
                <Td>
                  {record[column.dataIndex]}
                </Td>
              </TableRow>
            </>
          )
        }
      })}
    </TableContainer>)
}

export default LawTableMobileListItem;

export const TableContainer = styled.table`
  border: 1px solid #CCCCCC;
  border-radius: 14px;
  background: #fff;
  margin: 5px;
`;

export const TableRow = styled.tr`
  border-bottom: 1px solid #CCCCCC;
  display: flex;
  justify-content: left;
  &:first-child {
    font-weight: 500;
  }
`;

export const Td = styled.td`
  padding: 0.8em 1em;
  align-items: center;
`;

export const SelectedRowIndicator = styled.div`
  height: fill-available;
  height: -webkit-fill-available;
  width: 0.75ch;
  background-color: #038FDE;
  position: absolute;
  left: -1px;
  top: 0;
  border-radius: 0px 8px 8px 0px;
`

/* const mapStateToProps = ({ auth, client }) => {
  const { authUser } = auth;
  const { clientsFetched, clients, clientDeleted, clientUpdated } = client;
  return { authUser, clientsFetched, clients, clientDeleted, clientUpdated };
}; */

/* export default connect(mapStateToProps, {
  clientsFetched,
  clientDeleted,
  clientUpdated
})(ClientListMobile); */