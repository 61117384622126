import React, { useState, useContext, useEffect } from "react";
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import "@ant-design/compatible/assets/index.css";
import { Button, Select, Row, Col, Input, Form } from "antd";
import { FilterExpedientContext } from "../../../containers/App/MainApp";
import { DatePicker } from "antd";
import ExpedientService from "../../../services/expedient.service";
import AdditionalElementControl from "./AdditionalElementControl";
import CenteredLoading from "../../CenteredLoading";
import { FueroSelect } from "../../PrebuiltFormComponents/FueroSelect";
import { CourtSelect } from "../../PrebuiltFormComponents/CourtSelect";
import Antd4TagAutocomplete from "../../PrebuiltFormComponents/Antd4TagAutocomplete";
import { SecretarySelect } from "../../PrebuiltFormComponents/SecretarySelect";
import { SelectColumns } from "./SelectColumns";
import Antd4ClientSelect from "../../../routes/LettersPage/components/antd4ClientSelect";
import { useForm } from "antd/lib/form/Form";
import { history } from "../../../appRedux/store";
import { ReactComponent as CalendarIcon } from "../../../assets/assets2_0/calendar-2.svg";
import CustomCheckbox from "../../../util/CustomCheckbox";
import { useMediaQuery } from "react-responsive";
import { connect } from "react-redux";
import JurisdictionSelect from "../../PrebuiltFormComponents/JurisdictionSelect";

const Antd4FilterExpedientForm = ({ currentLang, onDone, onCancel }) => {
    const { Option } = Select;
    const { setState } = useContext(FilterExpedientContext);
    const [loading, setLoading] = useState(true);
    const [additionalElementsControl, setAdditionalElementsControl] = useState(
        false
    );

    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

    const [form] = useForm()
    const [data, setData] = useState({});
    const [customFields, setCustomFields] = useState([]);
    const [showSelectColumn, setShowSelectColumn] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([
        "front",
        "folder",
        "expedient_number",
        "responsible",
        "court",
        "init_date",
        "demand_begin_date",
        "claimed_amount",
        "status",
        "actions",
        "last_action_date"
    ]);

    const [province, setProvince] = useState(null);
    const [jurisdiction, setJurisdiction] = useState(null);
    const [fuero, setFuero] = useState(null);
    const [court, setCourt] = useState(null);
    const [secretary, setSecretary] = useState(null);

    const [initialValues, setInitialValues] = useState({
        "tags-expedients-switch": false,
        "expedient-status-switch": false,
        "jurisdiction-switch": false,
        "province-switch": false,
        "fuero-switch": false,
        "court-switch": false,
        "movement-type-switch": false,
        "taskable-switch": false,
        "responsible-switch": false,
        "movement-responsible-switch": false,
        "movement-co-responsible-switch": false,
        "realizado-switch": false,
        "secretary-switch": false,
        "accounting-concepts-switch": false,
        "process-type-switch": false,
        "tags-expedients-additional-switch": false,
        "tags-clients-switch": false,
        "extra_judicial": null,
        "extra_judicial-switch": false,
        "front-switch": false,
        "last-activity": null,
        "last-movement": null,
        "last-activity-switch": false,
        "last-movement-switch": false,
        "parts-switch": false,
        "client-switch": false,
        "columns": [
            "front",
            "folder",
            "expedient_number",
            "responsible",
            "court",
            "init_date",
            "demand_begin_date",
            "claimed_amount",
            "status",
            "actions",
            "last_action_date"
        ],
        "expedient-status": "",
        "province": null,
        "jurisdiction": null,
        "fuero": null,
        "court": null,
        "taskable": null,
        "realizado": null,
        "filterActive": true,
        "showall": false,
        "search": "",
        "sort_order": "ascend",
        "sort_columnkey": null
    });

    const handleCheckbox = (e, key) => {
        setInitialValues({ ...initialValues, [key]: !e.target.checked })
    };

    const fields = [
        {
            key: "dateRange",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="dateRange"
                    label="Fecha de expediente"
                    name="dateRange"
                    rules={[
                        { required: false, message: "Debe introducir una fecha" }
                    ]}
                >
                    <DatePicker.RangePicker className="responsive-input" suffixIcon={<CalendarIcon />} style={{ width: "100%" }} />
                </Form.Item>
            )
        },
        {
            key: "tags-expedients",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Antd4TagAutocomplete
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "tags-expedients-switch")} /> Tags Expedientes</div>}
                    name="tags-expedients"
                />
            )
        },
        {
            key: "expedient-status",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="expedient-status"
                    name="expedient-status"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "expedient-status-switch")} /> Estado del Expediente</div>}
                    rules={[
                        {
                            required: false,
                            message: "Debe indicar un estado."
                        }
                    ]}
                    hasFeedback
                >
                    <Select className="responsive-input" style={{ width: "100%" }}>
                        <Option value="" key="default-value">Seleccionar Estado</Option>
                        {data.expedientStatus &&
                            data.expedientStatus.map(status => (
                                <Option key={status.id} value={status.id}>
                                    {status.name}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
            )
        },
        {
            key: "responsible",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="responsible"
                    name="responsible"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "responsible-switch")} /> Responsable (Expedientes)</div>}
                    rules={[
                        {
                            required: false,
                            message: "Debe seleccionar un responsable."
                        }
                    ]}
                >
                    <Select
                        className="responsive-input"
                        style={{ width: "100%" }}
                        mode="multiple"
                        optionFilterProp="children"
                        showSearch
                    >
                        {data.users &&
                            data.users.map(user => (
                                <Option
                                    key={user.id}
                                    value={user.id}
                                >{`${user.firstname} ${user.lastname}`}</Option>
                            ))}
                    </Select>
                </Form.Item>
            )
        },
        {
            key: "province",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="province"
                    name="province"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "province-switch")} /> Provincia</div>}
                    rules={[
                        {
                            required: false,
                            message: `Debe seleccionar una Provincia.`
                        }
                    ]}>
                    <Select
                        className="responsive-input"
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        showSearch
                        onSelect={j => {
                            setProvince(j || null);
                            setJurisdiction(null);
                            setFuero(null);
                            setCourt(null);
                            setSecretary(null);
                            form.setFieldsValue({
                                jurisdiction: null,
                                fuero: null,
                                court: null,
                                secretary: null
                            });
                        }}
                    >
                        <Option value="" key="default-province">Seleccionar Provincia</Option>
                        {data.provinces &&
                            data.provinces.map(province => (
                                <Option key={province.id} value={province.id}>
                                    {province.name}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
            )
        },
        {
            key: "jurisdiction",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="jurisdiction"
                    name="jurisdiction"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "jurisdiction-switch")} /> {currentLang.text.jurisdiction}</div>}
                    rules={[
                        {
                            required: false,
                            message: `Debe seleccionar una ${currentLang.text.jurisdiction}.`
                        }
                    ]}>
                    <JurisdictionSelect
                        mode="no-form"
                        disabled={!province}
                        province={province}
                        onSelect={j => {
                            setJurisdiction(j || null);
                            setFuero(null);
                            setCourt(null);
                            setSecretary(null);
                            form.setFieldsValue({
                                fuero: null,
                                court: null,
                                secretary: null
                            });
                        }}
                    />
                </Form.Item>
            )
        },
        {
            key: "fuero",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "fuero-switch")} /> Fuero</div>}
                    name="fuero"
                    key="fuero"
                >
                    <FueroSelect
                        mode="no-form"
                        jurisdiction={jurisdiction}
                        disabled={!jurisdiction}
                        fuero={fuero}
                        onSelect={f => {
                            setFuero(f);
                            setCourt(null);
                            setSecretary(null);
                            form.setFieldsValue({ court: null, secretary: null });
                        }}
                    />
                </Form.Item>
            )
        },
        {
            key: "court",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "court-switch")} /> Corte</div>}
                    name="court"
                    key="court"
                >
                    <CourtSelect
                        mode="no-form"
                        fuero={fuero}
                        disabled={!fuero || !jurisdiction}
                        court={court}
                        onSelect={court => {
                            setCourt(court);
                            setSecretary(null);
                            form.setFieldsValue({ secretary: null });
                        }}
                    />
                </Form.Item>
            )
        }, {
            key: "movementDateRange",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="movementDateRange"
                    label="Fecha de movimiento"
                    name="movementDateRange"
                    rules={[
                        { required: false, message: "Debe introducir una fecha" }
                    ]}
                >
                    <DatePicker.RangePicker className="responsive-input" suffixIcon={<CalendarIcon />} style={{ width: "100%" }} />
                </Form.Item>
            )
        },
        {
            key: "movement-type",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="movement-type"
                    name="movement-type"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "movement-type-switch")} /> Tipos de Movimientos</div>}
                    rules={[
                        {
                            required: false,
                            message: "Debe una fecha."
                        }
                    ]}
                >
                    <Select
                        className="responsive-input"
                        mode="multiple"
                        optionFilterProp="children"
                        showSearch
                        style={{ width: "100%" }}
                    >
                        {data.movements &&
                            data.movements.map(movement => (
                                <Option key={movement.id} value={movement.id}>
                                    {movement.name}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
            )
        }, {
            key: "realizado",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="realizado"
                    name="realizado"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "realizado-switch")} /> Movimiento Realizado</div>}
                >
                    <Select className="responsive-input" style={{ width: "100%" }}>
                        <Option value={null}></Option>
                        <Option value="">Todos</Option>
                        <Option value="1">Hechos</Option>
                        <Option value="0">Pendientes</Option>
                    </Select>
                </Form.Item>
            )
        }, {
            key: "movement-responsible",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="movement-responsible"
                    name="movement-responsible"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "movement-responsible-switch")} />Responsable (Movimientos)</div>}
                    rules={[
                        {
                            required: false,
                            message: "Debe seleccionar un responsable."
                        }
                    ]}
                >
                    <Select
                        className="responsive-input"
                        style={{ width: "100%" }}
                        mode="multiple"
                        optionFilterProp="children"
                        showSearch
                    >
                        {data.users &&
                            data.users.map(user => (
                                <Option
                                    key={user.id}
                                    value={user.id}
                                >{`${user.firstname} ${user.lastname}`}</Option>
                            ))}
                    </Select>
                </Form.Item>
            )
        },
        {
            key: "taskable",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="taskable"
                    name="taskable"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "taskable-switch")} /> Movimiento Agendado</div>}
                >
                    <Select className="responsive-input" style={{ width: "100%" }}>
                        <Option key={1} value={null}></Option>
                        <Option key={2} value="">
                            Todos
                        </Option>
                        <Option key={3} value="1">
                            Agendados
                        </Option>
                        <Option key={4} value="0">
                            No Agendados
                        </Option>
                    </Select>
                </Form.Item>
            )
        },
        {
            key: "movements_limit",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item key="movements_limit" name="movements_limit" label="Limite de movimientos">
                    <Input className="responsive-input" autoComplete="cc-csc" />
                </Form.Item>
            )
        },
    ];

    const additionalFields = [
        {
            key: "secretary",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="secretary"
                    name="secretary"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "secretary-switch")} /> Secretaria</div>}
                >
                    <SecretarySelect
                        mode="no-form"
                        court={court}
                        disabled={!fuero || !jurisdiction || !court}
                        secretary={null}
                        onSelect={f => setSecretary(f)}
                    />
                </Form.Item>
            )
        },
        {
            key: "accounting-concepts",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="accounting-concepts"
                    name="accounting-concepts"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "accounting-concepts-switch")} /> Conceptos Contables</div>}
                    rules={[
                        {
                            required: false,
                            message: "Debe una fecha."
                        }
                    ]}
                >
                    <Select className="responsive-input" mode="multiple" style={{ width: "100%" }}>
                        {data.accountings &&
                            data.accountings.map(accounting => (
                                <Option key={accounting.id} value={accounting.id}>
                                    {accounting.name}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
            )
        },
        {
            key: "process-type",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="process-type"
                    name="process-type"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "process-type-switch")} /> Tipo de Proceso</div>}
                    rules={[
                        {
                            required: false,
                            message: "Debe una fecha."
                        }
                    ]}
                >
                    <Select className="responsive-input"
                        mode="multiple"
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        showSearch
                    >
                        {data.expedientsType &&
                            data.expedientsType.map(type => (
                                <Option key={type.id} value={type.id}>
                                    {type.name}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
            )
        },
        {
            key: "tags-expedients-additional",
            lg: 6,
            md: 6,
            xs: 24,
            item: (

                <Antd4TagAutocomplete
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "tags-expedients-additional-switch")} /> Tags Expedientes</div>}
                    name="tags-expedients-additional"
                />
            )
        },
        {
            key: "tags-clients",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Antd4TagAutocomplete
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "tags-clients-switch")} /> Tags {currentLang.text.client}</div>}
                    name="tags-clients"
                    filter="clients"
                />
            )
        },
        {
            key: "extra_judicial",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="extra_judicial"
                    name="extra_judicial"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "extra_judicial-switch")} /> Judicial/Extrajudicial</div>}
                >
                    <Select className="responsive-input" style={{ width: "100%" }}>
                        <Option value={null}></Option>
                        <Option value="">Todos</Option>
                        <Option value="1">Judicial</Option>
                        <Option value="0">Extrajudicial</Option>
                    </Select>
                </Form.Item>
            )
        }, {
            key: "movement-co-responsible",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="movement-co-responsible"
                    name="movement-co-responsible"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "movement-co-responsible-switch")} /> Co Responsable (Movmientos)</div>}
                    rules={[
                        {
                            required: false,
                            message: "Debe selecciona un co responsable."
                        }
                    ]}
                >
                    <Select className="responsive-input"
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        showSearch
                        mode="multiple"
                    >
                        {data.users &&
                            data.users.map(user => (
                                <Option
                                    key={user.id}
                                    value={user.id}
                                >{`${user.firstname} ${user.lastname}`}</Option>
                            ))}
                    </Select>

                </Form.Item>
            )
        },
        {
            key: "front",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    initialValue={null}
                    key="front"
                    name="front"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "front-switch")} /> Caratula</div>}
                >
                    <Input className="responsive-input" autoComplete="cc-csc" />
                </Form.Item>
            )
        },
        {
            key: "last-movement",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="last-movement"
                    name="last-movement"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "last-movement-switch")} /> Dias sin movimientos</div>}
                    initialValue={null}>
                    <Input className="responsive-input" autoComplete="cc-csc" type="number" />
                </Form.Item>
            )
        },
        {
            key: "last-activity",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="last-activity"
                    name="last-activity"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "last-activity-switch")} /> Dias sin instar a la acción</div>}
                    initialValue={null}>
                    <Input className="responsive-input" autoComplete="cc-csc" type="number" />
                </Form.Item>
            )
        },
        {
            key: "parts",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="parts"
                    name="parts"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "parts-switch")} /> Partes</div>}
                >
                    <Select className="responsive-input"
                        mode="multiple"
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        showSearch
                    >
                        {data.parts &&
                            data.parts.map(part => (
                                <Option key={part.id} value={part.id}>
                                    {part.name}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
            )
        },
        {
            key: "client",
            lg: 6,
            md: 6,
            xs: 24,
            item: (
                <Form.Item
                    key="client"
                    name="client"
                    label={<div><CustomCheckbox defaultChecked={true} handleChange={(e) => handleCheckbox(e, "client-switch")} /> Contacto</div>}
                >
                    <Antd4ClientSelect showNewClientButton={false} />
                </Form.Item>
            )
        }
    ];

    const handleSubmit = (values) => {

        let valuesToMerge = initialValues;

        if (values.dateRange) {
            values.dateRange = [
                values.dateRange[0].format("YYYY-MM-DD"),
                values.dateRange[1].format("YYYY-MM-DD")
            ];
        }
        if (values.movementDateRange) {
            values.movementDateRange = [
                values.movementDateRange[0].format("YYYY-MM-DD"),
                values.movementDateRange[1].format("YYYY-MM-DD")
            ];
        }
        values.filterActive = true;
        values = Object.assign(valuesToMerge,
            {
                ...values,
                columns: selectedColumns,
                jurisdiction: jurisdiction,
                fuero: fuero,
                court: court,
                secretary: secretary
            });

        setState(values, () => {
            onDone();
            return history.push("/expedient/filter");
        });

    };

    const getFilters = () => {
        setLoading(true);

        ExpedientService.getFilertsExpedient()
            .then(({ data }) => {
                setData(data);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getFilters();
    }, []);

    const addElement = option => {
        setCustomFields(prevState => {
            return [...prevState, option];
        });
    };

    return !loading ? (
        <>
            <Form
                preserve
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
            >
                <Row type="flex" justify="start" gutter={20} style={{ flexDirection: "inherit" }}>

                    <Col lg={12} xs={24}>
                        {!isTabletOrMobile ? <Button
                            style={!showSelectColumn ? { backgroundColor: "#35B6BC", width: 'auto' } : { backgroundColor: "#ff5879", width: 'auto' }}
                            type={showSelectColumn ? "danger" : ""}
                            onClick={() => setShowSelectColumn(!showSelectColumn)}
                            className={`responsive-input responsibe-button ${showSelectColumn ? "warn" : ""}`}
                            htmlType="button"
                            disabled={loading}
                        >
                            {showSelectColumn ? "Ocultar Columnas" : <div style={{ color: "#fff" }} ><CheckCircleOutlined style={{ fontSize: "14.5px" }} />  Seleccionar Columnas</ div>}
                        </Button> : <AdditionalElementControl
                            fieldsSelected={customFields}
                            handleCancel={() => {
                                setAdditionalElementsControl(!additionalElementsControl);
                                setCustomFields([]);
                            }}
                            handleChange={setCustomFields}
                        />}
                    </Col>
                    <Col lg={12} xs={24}>
                        {isTabletOrMobile ? <Button
                            style={!showSelectColumn ? { backgroundColor: "#35B6BC", width: 'auto' } : { backgroundColor: "#ff5879", width: 'auto' }}
                            type={showSelectColumn ? "danger" : ""}
                            onClick={() => setShowSelectColumn(!showSelectColumn)}
                            className={`responsive-input responsibe-button ${showSelectColumn ? "warn" : ""}`}
                            htmlType="button"
                            disabled={loading}
                        >
                            {showSelectColumn ? "Ocultar Columnas" : <div style={{ color: "#fff" }} ><CheckCircleOutlined style={{ fontSize: "14.5px" }} />  Seleccionar Columnas</ div>}
                        </Button> : <AdditionalElementControl
                            fieldsSelected={customFields}
                            handleCancel={() => {
                                setAdditionalElementsControl(!additionalElementsControl);
                                setCustomFields([]);
                            }}
                            handleChange={setCustomFields}
                        />}
                    </Col>
                    <Col lg={24} xs={24}>
                        <SelectColumns optionsDefault={selectedColumns} handleChange={(columns) => setSelectedColumns(columns)} mode="form" show={showSelectColumn} />
                    </Col>

                    {fields.map(field => (
                        <Col lg={field.lg} xs={field.xs}>
                            {field.item}
                        </Col>
                    ))}

                    {additionalFields.filter((field) => customFields.includes(field.key)).map(field => {
                        return (
                            <>
                                <Col lg={field.lg} xs={field.xs}>
                                    {field.item}
                                </Col>
                            </>
                        );
                    })}

                    <Col
                        lg={24}
                        xs={24}
                    >

                        <div
                            style={{
                                width: "100%",
                                display: "inline-flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="responsive-button responsive-input"
                                loading={loading}
                                style={{ margin: "0px 10px" }}
                            >
                                <SearchOutlined style={{ fontSize: "14.5px" }} /> Buscar
                            </Button>

                            <Button
                                type="danger"
                                onClick={onCancel}
                                className="ant-btn warn responsive-button responsive-input"
                                htmlType="button"
                                disabled={loading}
                                style={{ margin: "0px 10px" }}
                            >
                                <CloseCircleOutlined style={{ fontSize: "14.5px" }} /> Cancelar
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    ) : (
        <CenteredLoading tip="Cargando filtro de expedientes" />
    );
};

const mapStateToProps = ({ lang }) => {
    const { currentLang } = lang
    return { currentLang }
}

export default connect(mapStateToProps)(Antd4FilterExpedientForm)
