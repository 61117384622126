import axios from '../util/Api';
import { getPercentage } from './expedient_movement.service';

const FeeService = {
  indexPg: async (page = 1, filters, signal) => {
    return axios.get(`/paginate/fees?page=${page}`, {
      params: filters,
      signal: signal,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createFee: (fee, get_progres) => {
    return axios.post(`/fee`, fee, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      onUploadProgress: progress => {
        get_progres(Math.trunc(getPercentage(progress.loaded, progress.total)));
      }
    });
  },
  updateStudioTask: (fee, get_progres) => {
    return axios.post(`fee/update`, fee, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      onUploadProgress: progress => {
        get_progres(Math.trunc(getPercentage(progress.loaded, progress.total)));
      }
    });
  }, deleteFee: (id) => {
    return axios.delete(`fee/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }, cancelRecurretion: (id) => {
    return axios.get(`cancel-fee-recurretion/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  exportFeesAs: async (filters, format) => {
    return axios.get(`export-fees-as`, {
      params: { ...filters, format: format },
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then(
        resp =>
          new Blob([resp.data], {
            type:
              format === "csv"
                ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                : "application/pdf"
          })
      );
  }
}

export default FeeService;
