import React, { cloneElement, useState } from "react";
import { DeleteOutlined, KeyOutlined, UndoOutlined } from "@ant-design/icons";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import {
  Table,
  Card,
  Input,
  Button,
  Popconfirm,
  Select,
  Row,
  Col,
  Typography,
  Tooltip,
  List
} from "antd";
import { ReactComponent as DeleteIcon } from "../../assets/assets2_0/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/assets2_0/edit.svg";
import { ReactComponent as EyeIcon } from "../../assets/assets2_0/eye.svg";
import { ReactComponent as ViewExpedientIcon } from "../../assets/assets2_0/view-expedients.svg";
import { ReactComponent as CopyIcon } from "../../assets/assets2_0/copy.svg";
import { ReactComponent as KeyIcon } from "../../assets/assets2_0/key.svg";
import LawTableMobileListItem from "./components/LawTableMobileListItem";

export const LawTable = ({
  columns,
  fetch,
  fetchParams,
  title,
  addButton,
  onAddButtonClick,
  adButtonSibling,
  expanded,
  remove,
  edit,
  show,
  reload,
  access,
  studio,
  options,
  restoreOrDelete,
  onSearch,
  extraActions,
  extraDeleteCond,
  extraUpdateCond,
  getSearchParam,
  fetchExpedients,
  createExpedient,
  searchPlaceholder = "Buscar contacto",
  fullWidthSearchbar = false,
  isTabletOrMobile = false
}) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [pagination, setPagination] = React.useState({});
  const [studioId, setStudioId] = React.useState(studio);
  const [search, setSearch] = React.useState("");
  const { Option } = Select;
  const [searchParam, setSearchParam] = React.useState(null);

  React.useEffect(() => {
    const params = {
      ...fetchParams,
      studio_id: studioId
    };
    setLoading(true);
    fetch(params).then(datasource => {
      setData(datasource.data);
      setPagination({
        current: datasource.data.current_page,
        pageSize: datasource.data.per_page,
        pageSizeOptions: [10, 20, 30],
        total: datasource.data.total,
        nextUrl: datasource.data.next_page_url,
        prevUrl: datasource.data.prev_page_url
      });
      setLoading(false);
    });
  }, [reload]);

  const handleTableChange = (
    _pagination,
    filters = "",
    studio_id = studioId
  ) => {
    setLoading(true);
    const pager = { ..._pagination };

    setPagination(pager);

    const params = {
      ...fetchParams,
      studio_id: studio_id,
      page: pager.current ? pager.current : 1,
      search: search,
      pageSize: _pagination.pageSize
    };

    fetch(params).then(datasource => {
      setLoading(false);

      setData(datasource.data);

      setPagination({
        current: datasource.data.current_page,
        pageSize: datasource.data.per_page,
        pageSizeOptions: [10, 20, 30],
        total: datasource.data.total,
        nextUrl: datasource.data.next_page_url,
        prevUrl: datasource.data.prev_page_url
      });
    });
  };

  const _onSearch = (w, studio = undefined) => {
    setLoading(true);
    let pg = pagination;
    if (w !== search) {
      pg = { ...pagination, current: 1 };
    }
    setSearch(w);

    const params = {
      ...fetchParams,
      studio_id: studio,
      page: pagination.current ? pg : 1,
      search: w
    };

    onSearch(params)
      .then(({ data }) => {
        setData(data);
        setPagination({
          current: data.current_page,
          pageSize: data.per_page,
          total: data.total,
          nextUrl: data.next_page_url,
          prevUrl: data.prev_page_url
        });
        setLoading(false);
      })
      .catch(err => setLoading(false));
  };

  return (
    <>
      <Row>
        <Col span={24} style={{ margin: "3ch 0", padding: isTabletOrMobile ? "0px 15px" : "auto" }}>
          <Row align="center" justify="space-between" gutter={[10, 10]}>
            {isTabletOrMobile &&
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography.Text
                  style={{ fontSize: "1.2em", fontWeight: "bold" }}
                >
                  {title}
                </Typography.Text>
              </Col>}
            <Col span={fullWidthSearchbar || isTabletOrMobile ? 24 : null}>
              <Input
                width={fullWidthSearchbar || isTabletOrMobile ? "100%" : "50%"}
                autoComplete="cc-csc"
                placeholder={searchPlaceholder}
                onKeyUp={e => {
                  if (e.keyCode === 13) {
                    _onSearch(searchParam, studioId);
                  } else {
                    getSearchParam && getSearchParam(e.target.value);
                    setSearchParam(e.target.value);
                  }
                }}
              />
            </Col>
            {!isTabletOrMobile && <Col >
              <Typography.Text
                style={{ fontSize: "1.2em", fontWeight: "bold" }}
              >
                {title}
              </Typography.Text>
            </Col>}
            <Col
              span={isTabletOrMobile ? 24 : null}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                gap: "1ch"
              }}
            >
              {addButton &&
                cloneElement(addButton, { onClick: () => onAddButtonClick() })}
              {adButtonSibling &&
                cloneElement(adButtonSibling, { onClick: () => { } })}
            </Col>
          </Row>
        </Col >
        {options && (
          <Col span={12}>
            <Select
              style={{ width: "100%" }}
              onChange={studio => {
                setStudioId(studio);
                _onSearch(search, studio);
              }}
            >
              {options}
            </Select>
          </Col>
        )}
      </Row>

      {isTabletOrMobile ?
        <List
          loading={loading}
          dataSource={data.data}
          pagination={{
            ...pagination,
            onChange: (pageNumber, pageSize) => { handleTableChange({ ...pagination, current: pageNumber, pageSize }) }
          }}

          renderItem={record => {
            return (
              <List.Item style={{ padding: "15px" }}>
                <LawTableMobileListItem
                  columns={addActionsButtons(
                    columns,
                    remove,
                    edit,
                    show,
                    access,
                    restoreOrDelete,
                    extraActions,
                    extraDeleteCond,
                    extraUpdateCond,
                    fetchExpedients,
                    createExpedient,
                  )}
                  record={record}
                  rowSelection={[]}
                  showSelection={false}
                />
              </List.Item>
            );
          }}
        />
        : <Table
          className="gx-table-responsive"
          columns={addActionsButtons(
            columns,
            remove,
            edit,
            show,
            access,
            restoreOrDelete,
            extraActions,
            extraDeleteCond,
            extraUpdateCond,
            fetchExpedients,
            createExpedient,
          )}
          rowKey="id"
          rowClassName={"antd-table-row"}
          dataSource={data.data}
          pagination={pagination}
          loading={loading}
          onChange={pagination => handleTableChange(pagination)}
          expandedRowRender={expanded}
        />}
    </>
  );
};

function addActionsButtons(
  columns,
  remove,
  edit,
  show,
  access,
  restoreOrDelete,
  extraActions,
  extraDeleteCond,
  extraUpdateCond,
  fetchExpedients,
  createExpedient
) {

  /* extraActions must be type:
    [
      {
        key: string,
        component: <ReactComponenent />
        action: function
      },
  ]
  */
  return remove ||
    edit ||
    show ||
    access ||
    restoreOrDelete ||
    fetchExpedients ||
    createExpedient ||
    extraActions
    ? [
      ...columns,
      {
        title: "Acciones",
        dataIndex: "id",
        render: (id, record) => {
          const DeleteButton = () => {
            const [isHovered, setIsHovered] = useState(false);

            if (extraDeleteCond && !extraDeleteCond(record)) return null;
            return (
              <Popconfirm
                title="¿Eliminar registro?"
                onConfirm={() => remove(id)}
              >
                <Tooltip title="Eliminar" trigger="hover">
                  <DeleteIcon
                    style={{ cursor: "pointer", "margin-left": "20px" }}
                    color={isHovered ? "#FF5879" : "#95001D"}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                  />
                </Tooltip>
              </Popconfirm>
            );
          };

          const UpdateButton = () => {
            const [isHovered, setIsHovered] = useState(false);

            if (extraUpdateCond && !extraUpdateCond(record)) return null;
            return (
              <Tooltip title="Editar" trigger="hover">
                <EditIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => edit(record)}
                  color={isHovered ? "#0185F8" : "#002A4E"}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
              </Tooltip>
            );
          };

          const UpdateProfileButton = () => {
            const [isHovered, setIsHovered] = useState(false);

            return (
              <Tooltip title="Editar Permisos" trigger="hover">
                <KeyIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => access(record)}
                  color={isHovered ? "#0185F8" : "#002A4E"}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
              </Tooltip>
            );
          };

          const ViewButton = () => {
            const [isHovered, setIsHovered] = useState(false);

            return (
              <Tooltip title="Ver" trigger="hover">
                <EyeIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => show(record)}
                  color={isHovered ? "#0185F8" : "#002A4E"}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
              </Tooltip>
            );
          };

          const ExpedientsButton = () => {
            const [isHovered, setIsHovered] = useState(false);

            return (
              <Tooltip title="Ver Causas Vinculadas" trigger="hover">
                <ViewExpedientIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => fetchExpedients(record)}
                  color={isHovered ? "#0185F8" : "#002A4E"}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
              </Tooltip>
            );
          };

          const CreateExpedientButton = () => {
            const [isHovered, setIsHovered] = useState(false);

            return (
              <Tooltip title="Crear Expediente Asignado" trigger="hover">
                <CopyIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => createExpedient(record)}
                  fill={isHovered ? "#0185F8" : "#002A4E"}
                  color={isHovered ? "#0185F8" : "#002A4E"}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
              </Tooltip>
            );
          };
          //

          return (
            <div
              style={{ display: "flex", alignItems: "center", gap: "1.5ch" }}
            >
              {edit ? <UpdateButton /> : null}
              {access ? <UpdateProfileButton /> : null}
              {show ? <ViewButton /> : null}
              {createExpedient ? <CreateExpedientButton /> : null}
              {fetchExpedients ? <ExpedientsButton /> : null}
              {remove ? <DeleteButton /> : null}
              {restoreOrDelete
                ? restoreOrDeleteButton(record, restoreOrDelete)
                : null}
              {(extraActions && extraActions.length > 0) ? extraActions.map(action => {
                return cloneElement(action.component, { action: action.action, cond: action.cond(record), record: record })
              }) : null}
            </div>
          );
        }
      }
    ]
    : columns;
}

const restoreOrDeleteButton = (record, restoreOrDelete) => {
  if (record.deleted_at) {
    return (
      <Popconfirm
        title="¿Restaurar registro?"
        onConfirm={() => restoreOrDelete(record.id)}
      >
        <Button icon={<UndoOutlined />} type="primary" />
      </Popconfirm>
    );
  } else {
    return (
      <Popconfirm
        title="¿Eliminar registro?"
        onConfirm={() => restoreOrDelete(record.id)}
      >
        <Button icon={<DeleteOutlined />} type="danger" />
      </Popconfirm>
    );
  }
};

LawTable.defaultProps = {
  remove: false,
  edit: false,
  onSearch: () => null,
  extraActions: []
};
