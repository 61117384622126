import { CLIENTS_FETCHED, CLIENT_CREATED, CLIENT_DELETED, CLIENT_UPDATED } from "../../constants/ActionTypes";

export const clientsFetched = (clients) => {
    return {
        type: CLIENTS_FETCHED,
        payload: clients
    }
};

export const clientDeleted = (id) => {
    return {
        type: CLIENT_DELETED,
        payload: id
    }
};

export const clientCreated = (client) => {
    return {
        type: CLIENT_CREATED,
        payload: client
    }
};

export const clientUpdated = (client) => {
    return {
        type: CLIENT_UPDATED,
        payload: client
    }
};