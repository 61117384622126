import { Button, Modal, Tooltip } from "antd";
import React, { cloneElement, useEffect, useState } from "react";
import create from "zustand";
import axios, { authHeaders } from "../util/Api";
import CenteredLoading from "../law-components/CenteredLoading";
import { QuestionOutlined } from "@ant-design/icons";
import { ReactComponent as QuestionCircleIcon } from "../assets/assets2_0/question_circle.svg";

export function VideoTutorialModal() {
  const {
    showVideoTutorialModal,
    currentTutorial,
    setShowVideoTutorialModal
  } = useVideoTutorialState();

  return (
    <Modal
      zIndex={9999}
      destroyOnClose
      onCancel={() => setShowVideoTutorialModal(false)}
      title={currentTutorial ? currentTutorial.titulo : ""}
      width={"60vw"}
      centered
      footer={null}
      visible={showVideoTutorialModal}
    >
      {currentTutorial ? (
        <div style={{ display: "grid", placeItems: "center" }}>
          <p>{currentTutorial.descripcion}</p>
          <EmbedVideo
            width={"95%"}
            height={"400px"}
            title={currentTutorial.title}
            src={currentTutorial.video_url}
          />{" "}
        </div>
      ) : (
        <CenteredLoading />
      )}
    </Modal>
  );
}

export function VideoTutorialButton({
  tutorial,
  tooltipPlacement,
  toolTipText,
  customButtonIcon
}) {
  const {
    setCurrentTutorial,
    tutorialAvailable,
    checkTutorialAvailability
  } = useVideoTutorialState();

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (tutorialAvailable.length < 1) {
      checkTutorialAvailability();
    }
  }, []);

  return (
    tutorialAvailable.includes(tutorial) && (
      <Tooltip
        placement={tooltipPlacement || "top"}
        arrowPointAtCenter
        autoAdjustOverflow
        align={"right"}
        color={"#8987C0"}
        overlayInnerStyle={{ textAlign: "center" }}
        title={
          toolTipText ||
          "Si tienes alguna duda has click aqui para ver el tutorial"
        }
      >
        {customButtonIcon ? (
          cloneElement(customButtonIcon, {
            style: { cursor: "pointer" },
            className: "fadein",
            title: "Video Tutorial",
            onClick: () => {
              setCurrentTutorial(tutorial);
            }
          })
        ) : (
          <QuestionCircleIcon
            style={{ cursor: "pointer" }}
            className="fadein"
            title="Video Tutorial"
            onClick={() => {
              setCurrentTutorial(tutorial);
            }}
            fill={isHovered ? "#59c8c9" : "#35B6BC"}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
        )}
      </Tooltip>
    )
  );
}

function EmbedVideo({ title, width, height, src }) {
  return (
    <iframe
      title={title}
      width={width}
      height={height}
      src={src}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
}

export const useVideoTutorialState = create(set => ({
  showVideoTutorialModal: false,
  currentTutorial: null,
  tutorialAvailable: [],
  tooltipSeen: false,
  setTooltipSeen: () => set(state => ({ tooltipSeen: true })),
  checkTutorialAvailability: async () => {
    const resp = await axios.get("/video-tutorial-availables", {
      headers: {
        ...authHeaders()
      }
    });
    set({ tutorialAvailable: resp.data });
  },
  setShowVideoTutorialModal: showVideoTutorialModal => {
    set({ showVideoTutorialModal });
  },
  setCurrentTutorial: async currentTutorial => {
    try {
      const resp = await axios.get(`/video-tutorial/${currentTutorial}`, {
        headers: {
          ...authHeaders()
        }
      });
      set({ currentTutorial: resp.data, showVideoTutorialModal: true });
    } catch (error) {}
  }
}));
