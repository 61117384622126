import { Col, Form, Input, Row } from 'antd';
import React, { useState } from 'react';
import { useExpedientMovementForm } from '../../../../hooks/useExpedientMovementFormState';
import ExpedientMovementService from '../../../../services/expedient_movement.service';
import DragAndDropper from '../../../ExpedientFiles/DragAndDrop';
import ExpedientFileList from '../../../ExpedientFiles/ExpedientFileList';
import { SmsNotificationsModal } from '../../CreateExpedientMovementTypeForm';

const SecondtStepForm = ({ expedient, expedient_movement, form, uploadPercentage }) => {

    const { fields, setFields } = useExpedientMovementForm();

    const [fileList, setFileList] = useState(fields.files);


    const [smsData, setSmsData] = useState([]);

    let lexMovCond = true;
    if (
        fields &&
        fields.movement_type_id ===
        Number.parseInt(process.env.REACT_APP_MOVEMENT_TYPE_ID_LEX_ESCRITO)
    ) {
        lexMovCond = false;
    }


    const secondtStepFields = [
        {
            key: "days_before_popup_notification",
            lg: 12,
            md: 12,
            xs: 24,
            _tutoStep: 14,
            cond: lexMovCond,
            item: (
                <Form.Item
                    name="days_before_popup_notification"
                    label="Notificación de popup (mostrar dias antes)"
                >
                    <Input className="responsive-input" autoComplete="cc-csc" disabled={fields && !Boolean(fields.taskable)} />
                </Form.Item>
            )
        },
        {
            key: "days_before_mail_notification",
            lg: 12,
            md: 12,
            xs: 24,
            _tutoStep: 15,
            cond: lexMovCond,
            item: (
                <Form.Item
                    name="days_before_mail_notification"
                    label="Notificación de email (mostrar días antes)"
                >
                    <Input className="responsive-input" autoComplete="cc-csc" disabled={fields && !Boolean(fields.taskable)} />
                </Form.Item>
            )
        },
        {
            key: "days_before_sms_notification",
            lg: 12,
            md: 6,
            xs: 24,
            _tutoStep: 16,
            cond: lexMovCond,
            item: (
                <Form.Item
                    name="days_before_sms_notification"
                    label="Notificación de sms y whatsapp (mostrar días antes)"
                >
                    <SmsNotificationsModal
                        movement={expedient_movement}
                        mode={expedient_movement ? "update_movement" : "new_movement"}
                        disabled={fields && !fields.movement_type_id}
                        outPut={data => {
                            setSmsData(data);
                        }}
                        m_type={{
                            id: fields
                                ? fields.movement_type_id
                                : form.getFieldValue("movement_type_id")
                        }}
                    />
                </Form.Item>
            )
        },
        {
            key: "days_before_client_mail_notification",
            lg: 12,
            md: 12,
            xs: 24,
            _tutoStep: 15,
            cond: lexMovCond,
            item: (
                <Form.Item
                    name="days_before_client_mail_notification"
                    label="Notificación de email al cliente (días antes)"
                >
                    <Input className="responsive-input" autoComplete="cc-csc" />
                </Form.Item>
            )
        },
        {
            key: "files",
            lg: 8,
            md: 13,
            xs: 24,
            _tutoStep: 17,
            cond: true,
            item: (
                <Form.Item label="Adjuntar archivos" name="files">
                    <DragAndDropper
                        files={fileList}
                        onFileSelect={fileList => setFileList(fileList)}
                        uploadPercentage={uploadPercentage}
                    />
                </Form.Item>
            )
        },
        {
            key: "current_files",
            lg: 16,
            md: 13,
            xs: 24,
            cond: true,
            item: (
                <Form.Item
                    label="Archivos adjuntos"
                    name="current_files"
                    style={{ width: "100%" }}
                >
                    <ExpedientFileList
                        size="small"
                        showUploader={false}
                        service={ExpedientMovementService}
                        expedient={expedient_movement}
                        showActions={false}
                    />
                </Form.Item>
            )
        }
    ];

    const updateMainForm = (values) => {
        Object.assign(values, { data: smsData, files: fileList });
        setFields(values);
    }

    return (
        <Form
            form={form}
            title='second-step-form'
            layout="vertical"
            onFinish={updateMainForm}
            initialValues={{
                days_before_popup_notification: fields.days_before_popup_notification,
                days_before_mail_notification: fields.days_before_mail_notification,
                days_before_client_mail_notification: fields.days_before_client_mail_notification
            }}
        >
            <Row gutter={10} style={{ flexDirection: "inherit" }}>
                {secondtStepFields
                    .map(f =>
                        f.cond ? f : { item: !lexMovCond ? null : <div>&nbsp;</div> }
                    )
                    .filter(field => {
                        if (!lexMovCond) {
                            return field.item !== null;
                        }
                        return true;
                    })
                    .map(field => (
                        <Col key={field.key} xs={field.xs} lg={field.lg} md={field.md}>
                            <div >
                                {field.item}
                            </div>
                        </Col>
                    ))}
            </Row>
        </Form>
    )
}

export default SecondtStepForm;