import React from 'react'
import { Button } from "antd";
import Logo from "../../assets/images/microsoft.png";
import { apiRoute } from '../../util/Api';

export default function MicrosoftLoginButton() {
  return <a href={`${apiRoute}/login/microsoft`}>
    <Button size='large' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: 2.5, width: '250px' }}>
      <img src={Logo} width={16} alt="Microsoft Logo" />
      Hotmail
    </Button>
  </a>
}