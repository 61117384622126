import React from 'react';

const KnowMoreMessage = () => {
    return (
        <div className="know-more-message">
            <span >
                Nuestro software utiliza un panel de acceso específico para los usuarios.
                Si deseas obtener más datos acerca de Meta Jurídico te invitamos a visitar nuestra web haciendo
                <a href="https://metajuridico.com" target="_blank" className="know-more-link"> click aquí.</a>

            </span>
        </div>
    )
}

export default KnowMoreMessage;
