import { DownloadOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react'

const DownloadPreviewImage = ({ file, imageSrc }) => {

    return (
        <Tooltip trigger="hover" title="Descargar archivo" placement={"bottom"}>
            <Button
                icon={<DownloadOutlined />}
                onClick={() => {
                    handleDownload(imageSrc, `${file.name}.${file.mimetype}`)
                }}
                type="primary"
                size="small"
                shape="circle"
            />
        </Tooltip>
    );
}

export default DownloadPreviewImage;

function handleDownload(imageSrc, name) {
    var a = document.createElement("a");
    a.href = imageSrc;
    if (name) {
        a.download = name;
    } else {
        a.download = "file.txt"
    }
    document.body.appendChild(a);
    a.click();
    a.remove();
};