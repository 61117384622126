import axios from "../util/Api";

const TicketService = {
    getTicketById: (id) => {
        return axios.get(`/ticket/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    }, deleteById: (id) => {
        return axios.delete(`/ticket/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    }, markAsRead: (ticket_id) => {
        return axios.get(`/ticket/mark-as-read/${ticket_id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    ticketToAdmin: (data) => {
        return axios.post(`/ticket/send-to-admin`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    }, answerTicket: (data) => {
        return axios.post(`/ticket/answer`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    getTicketTypes: () => {
        return axios.get('/ticket/ticket-types', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    getSentTickets: (current_page = 1, params) => {
        return axios.get(`/paginate/ticket/by-user?page=${current_page}`, {
            params: params,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
    }, getReceivedTickets: (current_page = 1, params) => {
        return axios.get(`/paginate/ticket/received?page=${current_page}`, {
            params: params,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
    }, getAllTickets: (current_page = 1, params) => {
        return axios.get(`/paginate/tickets?page=${current_page}`, {
            params: params,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    requestCalculadoras: (message) => {
        return axios.post(`/subscription/request`, {
            message
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    changeStatus: (id) => {
        return axios.patch(`/ticket/change-status/${id}`, {}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
    }
};

export default TicketService;