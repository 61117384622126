import { NotificationOutlined } from "@ant-design/icons";
import { Button, message, Tooltip } from "antd";
import React, { useState } from "react";
import axios, { baseURL, authHeaders } from "../../util/Api";

const NotifyCallButton = ({ call_log_id, onNotified, notify_to }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Tooltip title="Notificar al responsable del expediente">
        <Button
        style={{borderColor: '#038FDE', color: "#038FDE"}}
          type="ghost"
          loading={loading}
          icon={<NotificationOutlined  />}
          onClick={() => {
            setLoading(true);
            notify({call_log_id, notify_to})
              .then((n) => {
                onNotified && onNotified(n);
                message.success("Alerta enviada");
              })
              .catch(e => message.error("No se enviar la notificacion"))
              .finally(() => {
                setLoading(false);
              });
          }}
  
        />
    </Tooltip>
  );
};

async function notify(data) {
  const resp = await axios.post(`${baseURL}/call-log-notify`, data, {
    headers: {
      ...authHeaders()
    }
  });
  return resp.data;
}

export default NotifyCallButton;
