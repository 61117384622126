import { SET_RTF_VIEW } from "../../constants/ActionTypes";

const INIT_STATE = {
    view: null
};

export default ( state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_RTF_VIEW: {
            return { ...state, view: action.payload};
        }
        default:
            return state;
    }
}