import React, { useState } from "react";
import {
  Popconfirm,
  Form,
  Button,
  Select,
  DatePicker,
  message,
  Tooltip
} from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import ExpedientMovementService from "../../../services/expedient_movement.service";
import moment from "moment";
import { ReactComponent as Clock2Icon } from "../../../assets/assets2_0/clock2.svg";

const PostponeConfirm = ({
  item,
  onPostponed,
  isTabletOrMobile,
  itemType,
  postponeFunc
}) => {
  const [form] = useForm();

  const [postponing, setPostponing] = useState(false);
  const [visible, setVisible] = useState(false);
  const [range, setRange] = useState(null);
  const [newDate, setNewDate] = useState(null);
  const confirmEnabled =
    range && range !== "custom" ? true : newDate ? true : false;

  const { Option } = Select;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Tooltip title={`Posponer ${itemType}`} trigger="hover">
      <Popconfirm
        okButtonProps={{
          disabled: !confirmEnabled
        }}
        onConfirm={() => form.submit()}
        placement={isTabletOrMobile ? "top" : "topRight"}
        title={
          <div style={{ width: "300px" }}>
            <div>Postergar {itemType}</div>
            <Form
              form={form}
              style={{ padding: "10px 20px 0px 0px" }}
              onFinish={values => {
                values.days_range = range;
                values.new_date = moment(values.new_date).format(
                  "YYYY-MM-DD HH:mm:ss"
                );
                values.id = item.id;

                setPostponing(true);
                postponeFunc(values)
                  .then(postposed_item => {
                    message.success(
                      `${itemType} postergado satisfactoriamente`
                    );
                    onPostponed(postposed_item.data);
                  })
                  .catch(e => {
                    message.error("Ha ocurrido un error");
                    console.log(e);
                  })
                  .finally(() => {
                    setNewDate(null);
                    setRange(null);
                    form.resetFields();
                    setPostponing(false);
                  });
              }}
            >
              <Form.Item
                name="days_range"
                key="days_range"
                label="Días"
                rules={[
                  {
                    required: true,
                    message: "Seleccione la cantidad de días"
                  }
                ]}
              >
                <Select size="middle" onSelect={v => setRange(v)}>
                  <Option key="1" value="1">
                    1
                  </Option>
                  <Option key="2" value="2">
                    2
                  </Option>
                  <Option key="7" value="7">
                    7
                  </Option>
                  <Option key="custom" value="custom">
                    Nueva fecha
                  </Option>
                </Select>
              </Form.Item>
              {range === "custom" && (
                <Form.Item
                  name="new_date"
                  key="new_date"
                  label="Fecha"
                  rules={[
                    {
                      required: true,
                      message: "Selecciona la nueva fecha"
                    }
                  ]}
                >
                  <DatePicker
                    onSelect={v => setNewDate(v)}
                    size="middle"
                    showTime={{ format: "HH:mm A" }}
                    format="L LT"
                  />
                </Form.Item>
              )}
            </Form>
          </div>
        }
      >
        <Clock2Icon
          style={{ cursor: "pointer" }}
          width={"15.8"}
          color={isHovered ? "#0185F8" : "#002A4E"}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      </Popconfirm>
    </Tooltip>
  );
};

export default PostponeConfirm;

PostponeConfirm.defaultProps = {
  isTabletOrMobile: false
};
