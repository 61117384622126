import React, { useState } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Row, Col, Switch, Checkbox, Modal} from "antd";
import { withRouter } from "react-router-dom";
import ExpedientMovementService from "../../../services/expedient_movement.service";
import ConfigureSmsNotificationForm from "./ConfigureSmsNotificationsForm";
import { CirclePicker } from 'react-color';
import { COLORS } from "../../../util/colors";
import { ReactComponent as SettingsIcon } from '../../../assets/assets2_0/settings.svg';
import { useMediaQuery } from "react-responsive";

const CreateExpedientMovementType = props => {
  const { form, movement_type, onCancel, onDone, onUpdated } = props;
  const { getFieldDecorator } = form;
  const [isTaskable, setIsTaskable] = useState(
    movement_type ? movement_type.taskable : true
  );
  const [loading, setLoading] = useState(false);
  const [smsData, setSmsData] = useState([]);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        setLoading(true);
        if (movement_type) {
          editExpedientMovementType(
            { ...values, data: smsData, color: values.color.hex },
            movement_type.id
          )
            .then(mt => {
              setLoading(false);
              onUpdated(mt.data);
            })
            .catch(e => {
              setLoading(false);
            });
        } else {
          saveExpedientMovementType({ ...values, data: smsData, color: values.color.hex })
            .then(mt => {
              setLoading(false);
              onDone(mt.data);
            })
            .catch(e => {
              setLoading(false);
            });
        }
      }
    });
  };

  const fields = [
    {
      key: "name",
      lg: 24,
      md: 12,
      xs: 24,
      item: (
        <Form.Item key="name" label="Nombre">
          {getFieldDecorator("name", {
            rules: [
              {
                required: true,
                message: "Debe indicar el nombre del tipo de movimiento."
              }
            ],
            initialValue: movement_type ? movement_type.name : null
          })(<Input autoComplete="cc-csc" />)}
        </Form.Item>
      )
    },
    {
      key: "is_judicial",
      lg: 12,
      md: 6,
      xs: 24,
      item: (
        <Form.Item key="is_judicial" label="Tipo">
          {getFieldDecorator("is_judicial", {
            rules: [
              {
                required: true,
                message: "Debe indicar el 'tipo' del tipo de movimiento."
              }
            ],
            valuePropName: "checked",
            initialValue: movement_type
              ? Boolean(movement_type.is_judicial)
              : true
          })(
            <Switch
              size="default"
              unCheckedChildren={"Extrajudicial"}
              checkedChildren={"Judicial"}
            />
          )}
        </Form.Item>
      )
    },
    {
      key: "insta_la_accion",
      lg: 12,
      md: 6,
      xs: 24,
      item: (
        <Form.Item key="insta_la_accion" label="¿Insta la accion?">
          {getFieldDecorator("insta_la_accion", {
            rules: [
              {
                required: true,
                message: "Debe indicar si insta la accion."
              }
            ],
            valuePropName: "checked",
            initialValue: movement_type ? movement_type.insta_la_accion : true
          })(
            <Switch
              size="default"
              unCheckedChildren={"No"}
              checkedChildren={"Si"}
            />
          )}
        </Form.Item>
      )
    },
    {
      key: "taskable",
      lg: 12,
      md: 6,
      xs: 24,
      item: (
        <Form.Item key="taskable" label="¿Agendable?">
          {getFieldDecorator("taskable", {
            rules: [
              {
                required: true,
                message: "Debe indicar si es agendable."
              }
            ],
            valuePropName: "checked",
            initialValue: movement_type ? movement_type.taskable : true
          })(
            <Switch
              onChange={t => setIsTaskable(t)}
              size="default"
              unCheckedChildren={"No"}
              checkedChildren={"Si"}
            />
          )}
        </Form.Item>
      )
    },
    {
      key: "color",
      lg: 12,
      md: 6,
      xs: 24,
      item: (
        <Form.Item key="color" label="Color">
          {getFieldDecorator("color", {
            rules: [
              {
                required: true,
                message: "Debe indicar el color del tipo de movimiento."
              }
            ],
            initialValue: movement_type ? movement_type.color : "#039be5",
            valuePropName: "color"
          })(
            //<Input autoComplete="cc-csc" type="color"  />
            <CirclePicker
              circleSize={16}
              colors={COLORS} />
          )}
        </Form.Item>
      )
    },
    {
      key: "important",
      lg: 12,
      md: 12,
      xs: 24,
      cond: true,
      item: (
        <Form.Item label="Marcar como importante" key="important">
          {getFieldDecorator("important", {
            valuePropName: "checked",
            initialValue: movement_type ? movement_type.important : true
          })(<Checkbox />)}
        </Form.Item>
      )
    },
    {
      key: "days_before_popup_notification",
      lg: 12,
      md: 6,
      xs: 24,
      item: (
        <Form.Item
          key="days_before_popup_notification"
          label="Notificación de popup (mostrar dias antes)"
        >
          {getFieldDecorator("days_before_popup_notification", {
            initialValue: movement_type
              ? movement_type.days_before_popup_notification
              : 1
          })(<Input autoComplete="cc-csc" disabled={!isTaskable} />)}
        </Form.Item>
      )
    },
    {
      key: "days_before_mail_notification",
      lg: 12,
      md: 6,
      xs: 24,
      item: (
        <Form.Item
          key="days_before_mail_notification"
          label="Notificación de email (mostrar dias antes)"
        >
          {getFieldDecorator("days_before_mail_notification", {
            initialValue: movement_type
              ? movement_type.days_before_mail_notification
              : 1
          })(<Input autoComplete="cc-csc" disabled={!isTaskable} />)}
        </Form.Item>
      )
    },
    {
      key: "sms_whatsapp_notifications_config",
      lg: 12,
      md: 6,
      xs: 24,
      item: (
        <SmsNotificationsModal
          outPut={data => setSmsData(data)}
          mode="movement_type"
          m_type={movement_type}
        />
      )
    },
    {
      key: "submit",
      lg: 24,
      md: 24,
      xs: 24,
      item: (
        <Form.Item key="submit">
          <Button
            type="primary"
            htmlType="submit"
            className="full-input"
            loading={loading}
          >
            {movement_type ? "Editar" : "Completar"}
          </Button>
          <Button
            type="danger"
            onClick={() => onCancel()}
            className="ant-btn warn"
            htmlType="button"
            disabled={loading}
          >
            Cancelar
          </Button>
        </Form.Item>
      )
    }
  ];

  return (
    <Form onSubmit={handleSubmit} layout="vertical">
      <Row type="flex" justify="start" gutter={[10, 10]}>
        {fields.map(field => (
          <Col lg={field.lg} xs={field.xs}>
            {field.item}
          </Col>
        ))}
      </Row>
    </Form>
  );
};

const saveExpedientMovementType = async values => {
  return await ExpedientMovementService.createExpedientMovementType(values);
};

const editExpedientMovementType = async (values, id) => {
  return await ExpedientMovementService.editExpedientMovementType({
    ...values,
    id
  });
};

const CreateExpedientMovementTypeForm = Form.create({
  name: "create-expedient-type"
})(CreateExpedientMovementType);

export const SmsNotificationsModal = ({
  m_type,
  disabled,
  movement,
  mode,
  outPut
}) => {
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState("sms");
  const user = JSON.parse(localStorage.getItem("user"));
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const buttonsStyle = {
    width: isTabletOrMobile ? "95%" : "50%",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1ch",
    marginRight: isTabletOrMobile ? "0px" : "1ch",
  };

  return (
    <React.Fragment>
      <div
        className="sms-notification-config"
      >
        <Modal
          width="65vw"
          footer={false}
          visible={visible}
          onCancel={() => setVisible(false)}
          title={`Notificaciones ${type}`}
        >
          <ConfigureSmsNotificationForm
            outPut={v => {
              outPut(v);
              setVisible(false);
            }}
            mode={mode}
            movement={movement}
            movement_type={m_type}
            type={type}
            onCancel={() => setVisible(false)}
          />
        </Modal>
        <Button
          className="responsive-button responsive-input"
          disabled={disabled}
          type="primary"
          style={buttonsStyle}
          onClick={() => {
            setType("whatsapp");
            setVisible(true);
          }}
        >
          <SettingsIcon />&nbsp;Notificaciones whatsapp
        </Button>
        {user.studio.province && user.studio.province.country_id === 1 && <Button
          className="responsive-button responsive-input"
          style={buttonsStyle}
          disabled={disabled}
          type="primary"
          onClick={() => {
            setType("sms");
            setVisible(true);
          }}
        >
          <SettingsIcon />&nbsp;Notificaciones sms
        </Button>}
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateExpedientMovementTypeForm);

CreateExpedientMovementTypeForm.defaultProps = {
  onCancel: () => null
};

SmsNotificationsModal.defaultProps = {
  disabled: false,
  mode: "create"
};
