import React, { useState, useEffect } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Button, Divider } from "antd";
import ConfigService from "../../services/config.service";
import AuthService from "../../services/auth.service";
import ExpedientService from "../../services/expedient.service";
import ClientService from "../../services/client.service";
import StudioService from "../../services/studio.service";
import CountryService from "../../services/geographic.service";
import { useScope } from "../../hooks/useScope";
import { connect } from "react-redux";
const { Option } = Select;

export const IdentificationTypeSelect = ({
  onSelect,
  getFieldDecorator,
  identificationType,
  required,
  disabled = false
}) => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    ConfigService.getIndentificationTypes()
      .then(data => {
        setData(data.data);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Form.Item key="identification_type_id" label="Tipo de identificación">
      {getFieldDecorator("identification_type_id", {
        rules: [
          {
            required,
            message: "Debe indicar el tipo de indentificacion."
          }
        ],
        initialValue: identificationType || null
      })(
        <Select
          className="responsive-input"
          onSelect={onSelect ? (v) => onSelect(v) : undefined}
          optionFilterProp="children"
          showSearch
          disabled={disabled}
          loading={loading}>
          {data.map(id_type => (
            <Option key={id_type.id} value={id_type.id}>
              {id_type.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};


const ExpedientTypeS = ({ getFieldDecorator, expedientType, onChange, currentLang }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    ExpedientService.getExpedientTypes()
      .then(data => {
        setData(data.data);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Form.Item key="expedient_type_id" label={currentLang.text.tipo_expediente}>
      {getFieldDecorator("expedient_type_id", {
        rules: [
          {
            required: true,
            message: `Debe indicar el ${currentLang.text.tipo_expediente}.`
          }
        ],
        initialValue: !loading ? expedientType : null || null
      })(
        <Select
          className="responsive-input"
          onChange={v => {
            //What is mt?
            const mt = data.find(d => d.id === v);
            onChange(mt ? mt : null);
          }}
          optionFilterProp="children"
          showSearch
          loading={loading}>
          {data.map(id_type => (
            <Option key={id_type.id} value={id_type.id}>
              {id_type.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

export const ExpedientStatusSelect = ({
  getFieldDecorator,
  expedientStatus
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    ExpedientService.getExpedientStatus()
      .then(data => {
        setData(data.data);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Form.Item key="status" label="Status del expediente">
      {getFieldDecorator("status", {
        rules: [
          {
            required: true,
            message: "Debe indicar el status de expediente."
          }
        ],
        initialValue: !loading ? expedientStatus : null || null
      })(
        <Select
          optionFilterProp="children"
          showSearch
          loading={loading}>
          {data.map(status => (
            <Option key={status.id} value={status.id}>
              {status.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

export const RoleSelect = ({ getFieldDecorator, role }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const canCreateUser = useScope('user.create')
  const canUpdateUser = useScope('user.update')

  useEffect(() => {
    AuthService.getRoles()
      .then(data => {
        setData(data.data.data);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, []);
  if(!canCreateUser && !canUpdateUser) return <></>
  return (
    <Form.Item key="role_id" label="Rol" hasFeedback>
      {getFieldDecorator("role_id", {
        rules: [
          { required: true, message: "Debe indicar la rol del usuario." }
        ],
        initialValue: !loading ? role : null || null
      })(
        <Select
          optionFilterProp="children"
          showSearch
          loading={loading}>
          {data.map((role, i) => (
            <Option key={i} value={role.id}>
              {role.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

export const ModuleSelect = ({ decoratorKey, getFieldDecorator, required }) => {
  const [loading, setLoading] = useState(true);
  let data = [];

  useEffect(() => {
    (async function loadData() {
      await ConfigService.getModules()
        .then(data => {
          data = data;
          setLoading(false);
        })
        .catch(err => console.log(err));
    })();
  }, []);

  return (
    <Select
      optionFilterProp="children"
      showSearch
      loading={loading}>
      {data.map(mod => (
        <Option key={mod.id} value={mod.id}>
          {mod.name}
        </Option>
      ))}
    </Select>
  );
};

export const JurisdictionSelect = ({
  decoratorKey,
  getFieldDecorator,
  required
}) => {
  const [loading, setLoading] = useState(true);
  let data = [];

  useEffect(() => {
    (async function loadData() {
      await ConfigService.getJurisdictions()
        .then(data => {
          data = data;
          setLoading(false);
        })
        .catch(err => console.log(err));
    })();
  }, []);

  return (
    <Select loading={loading}>
      {data.map(jurisdiction => (
        <Option key={jurisdiction.id} value={jurisdiction.id}>
          {jurisdiction.name}
        </Option>
      ))}
    </Select>
  );
};

export const FueroSelect = ({
  decoratorKey,
  getFieldDecorator,
  required,
  jurisdiction
}) => {
  const [loading, setLoading] = useState(true);
  let data = [];

  useEffect(() => {
    (async function loadData() {
      await ConfigService.getFueros(jurisdiction)
        .then(data => {
          data = data;
          setLoading(false);
        })
        .catch(err => console.log(err));
    })();
  }, []);

  return (
    <Select loading={loading}>
      {data.map(fuero => (
        <Option key={fuero.id} value={fuero.id}>
          {fuero.name}
        </Option>
      ))}
    </Select>
  );
};

export const CourtSelect = ({
  decoratorKey,
  getFieldDecorator,
  required,
  fuero
}) => {
  const [loading, setLoading] = useState(true);
  let data = [];

  useEffect(() => {
    (async function loadData() {
      await ConfigService.getCourts(fuero)
        .then(data => {
          data = data;
          setLoading(false);
        })
        .catch(err => console.log(err));
    })();
  }, []);

  return (
    <Select loading={loading}>
      {data.map(court => (
        <Option key={court.id} value={court.id}>
          {court.name}
        </Option>
      ))}
    </Select>
  );
};

export const StudiosSelect = ({ getFieldDecorator, studio }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    StudioService.getStudios()
      .then(data => {
        setData(data.data);
        setLoading(false)
      })
      .catch(err => console.log(err));
  }, []);
  return (
    <Form.Item key="studio_id" label="Estudio" hasFeedback>
      {getFieldDecorator("studio_id", {
        rules: [{ required: true, message: "Debe indicar el estudio." }],
        initialValue: studio || user.studio_id
      })(
        <Select loading={loading}>
          {data.map(studio => (
            <Option key={studio.id} value={studio.id}>
              {studio.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

export const ProvinciasSelect = ({ getFieldDecorator, provincia }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    CountryService.getProvincias()
      .then(data => {
        setData(data.data);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Form.Item key="provincia" label="Provincia">
      {getFieldDecorator("provincia", {
        rules: [{ required: true, message: "Debe indicar la Provincia." }],
        initialValue: !loading ? provincia : null || null
      })(
        <Select loading={loading}>
          {data.map(provincia => (
            <Option key={provincia.id} value={provincia.id}>
              {provincia.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

export const ContactSelect = ({ getFieldDecorator, contact }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    ConfigService.getContact()
      .then(data => {
        setData(data.data);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Form.Item key="contact" label="Contacto">
      {getFieldDecorator("contact", {
        rules: [{ required: true, message: "Debe indicar el Contacto." }],
        initialValue: contact || null
      })(
        <Select loading={loading}>
          {data.map(contact => (
            <Option key={contact.id} value={contact.id}>
              {contact.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

export const AddresseeSelect = ({ getFieldDecorator, addressee }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    ConfigService.getAddressee()
      .then(data => {
        setData(data.data);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Form.Item key="addressee" label="Destinatario">
      {getFieldDecorator("addressee", {
        rules: [{ required: true, message: "Debe indicar el Destinatario." }],
        initialValue: addressee || null
      })(
        <Select loading={loading}>
          {data.map(addressee => (
            <Option key={addressee.id} value={addressee.id}>
              {addressee.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

export const GoBackButton = props => {
  return (
    <Button
      type="primary"
      onClick={() => props.history.goBack()}
      className="ant-btn warn"
      htmlType="button"
    >
      {props.text || "Cancelar"}
    </Button>
  );
};

const mapStateToProps = ({ auth, lang }) => {
  const {currentLang} = lang
  return { authUser: auth.authUser, currentLang }
}

export const ExpedientTypeSelect = connect(mapStateToProps, {})(ExpedientTypeS);
