import React from 'react';
import StudioService from "../../../services/config.service";
import { Select } from "antd";

const SelectStudio = () => {

	const { Option } = Select;
	const [dataStudio, setDataStudio] = React.useState([]);

	React.useEffect(() => {

		const fetchData = async () => {

			const result = await StudioService.getStudio();
			
			const options = result.data.map((item) =>
				
				<Option key={item.id} value={item.id} label={item.name}>
					<span style={{color: '#ccc'}}>{item.name}</span>
				</Option>

			);

			setDataStudio(options);
		};

	    fetchData();
	},[]);


	return dataStudio;

}

export default SelectStudio;