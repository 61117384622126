import { MailOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import React, { useState } from "react";
import axios, { authHeaders } from "../../util/Api";

const SendRequestLinkEmail = ({ reqFile, disabled }) => {

  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    sendEmail(reqFile.id)
      .then(() => message.success("Correo enviado"))
      .catch(() => message.error("No se ha podido enviar el correo"))
      .finally(() => setLoading(false));
  }

  return (
    <Button
      disabled={disabled}
      loading={loading}
      onClick={() => handleClick()}
      size="small"
      type="primary"
      icon={<MailOutlined />}
    >
      Enviar correo
    </Button>
  );
};

async function sendEmail(id) {
  const resp = await axios.get(`/expedient-file-request/send-mail/${id}`, {
    headers: {
      ...authHeaders()
    }
  });
  return resp.data;
}

export default SendRequestLinkEmail;
