import { ReactComponent as PdfButton } from "../../assets/assets2_0/pdf-button.svg";
import { ReactComponent as ExcelButton } from "../../assets/assets2_0/excel-button.svg";
import React, { useState } from "react";
import { Tooltip } from "antd";

export function ExportPdf({ onClick }) {
  const [isExcelHovered, setIsExcelHovered] = useState(false);

  return (
    <Tooltip title="Exportar a PDF">
      <PdfButton
        width={"36"}
        height={"36"}
        style={{
          zIndex: 1,
          cursor: "pointer",
          color: isExcelHovered ? "#35B6BC" : "#24335D"
        }}
        onClick={onClick}
        onMouseEnter={() => setIsExcelHovered(true)}
        onMouseLeave={() => setIsExcelHovered(false)}
      />
    </Tooltip>
  );
}

export function ExportExcel({ onClick }) {
  const [isPdfHovered, setIsPdfHovered] = useState(false);

  return (
    <Tooltip title="Exportar a Excel">
      <ExcelButton
        width={"36"}
        height={"36"}
        style={{
          zIndex: 1,
          cursor: "pointer",
          color: isPdfHovered ? "#35B6BC" : "#24335D"
        }}
        onClick={onClick}
        onMouseEnter={() => setIsPdfHovered(true)}
        onMouseLeave={() => setIsPdfHovered(false)}
      />
    </Tooltip>
  );
}
