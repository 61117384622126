import DOMPurify from 'dompurify';

export const purifyStyledText = (text, customSettings = null) => {
    const settings = {
        ALLOWED_TAGS: ['p', 'br', 'span', 'strong', 'em', 'ins'],
        ALLOWED_ATTR: ['style'],
        CUSTOM_ELEMENT_HANDLING: {
            tagNameCheck: null, // no custom elements are allowed
            attributeNameCheck: null, // default / standard attribute allow-list is used
            allowCustomizedBuiltInElements: false, // no customized built-ins allowed
        }
    };
    return DOMPurify.sanitize(text, customSettings ? customSettings : settings);
}