import React, { useEffect, useState } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Button,
  Input,
  Row,
  Col,
  DatePicker,
  message,
  Modal,
  Upload,
  Space,
  Divider
} from "antd";
import { IdentificationTypeSelect } from "../../PrebuiltFormComponents";
import { withRouter } from "react-router-dom";
import { explodeTags } from "../../PrebuiltFormComponents/TagsInput";
import moment from "moment";
import ClientService from "../../../services/client.service";
import { ClientTypeSelect } from "../../PrebuiltFormComponents/ClientTypeSelect";
import { CountrySelect } from "../../PrebuiltFormComponents/CountrySelect";
import { ProvinceSelect } from "../../PrebuiltFormComponents/ProvinceSelect";
import TagAutocomplete from "../../PrebuiltFormComponents/TagAutocomplete";
import CheckedClientsTable from "../../CheckedClientsTable";
import { useMediaQuery } from "react-responsive";
import { isTutorialCompleted, TutorialButton, TutorialText, TutorialTitle, updateTutorials } from "../../../containers/Frames/OverlayTutorial";
import { CaretDownOutlined, CheckCircleOutlined, CloseCircleOutlined, UploadOutlined } from "@ant-design/icons";
import useTutorialStateV2 from "../../../unstatedStores/TutorialStateV2";
import { ReactComponent as WhatsAppIcon2 } from '../../../assets/assets2_0/whatsapp.svg';
import { ReactComponent as CalendarIcon } from "../../../assets/assets2_0/calendar-2.svg";
import CountryService from "../../../services/geographic.service";
import { connect } from "react-redux";
import UploadSign from "./UploadSign";
import { baseURL } from "../../../util/Api";
import { history } from "../../../appRedux/store";
import CollapsablePanel from "../../../util/CollapsablePanel";


const createClient = props => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { form, client, onSaved, onUpdated, title, currentLang, showLinkButton } = props;
  const { text } = currentLang
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(false);
  const [clientToEdit, setClientToEdit] = useState(client ? client : null);
  const [country, setCountry] = useState(client ? client.country : undefined);
  const [countries, setCountries] = useState([]);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [showAdvancedFields, setShowAdvancedFields] = useState(false);

  const [signFile, setSignFile] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState(0)


  const [dniToCheck, setDniToCheck] = useState({});
  const [checking, setChecking] = useState();
  const [tutoStep, setTutoStep] = useState(1);
  const [showTutorial, setShowTutorial] = useState(!isTutorialCompleted("CREATE_CLIENT") && !isTabletOrMobile && process.env.REACT_APP_SHOW_TUTORIAL === "1");
  // const tutorialState = TutorialStateContainer.useContainer();
  const tutorialState = useTutorialStateV2();
  const { confirm } = Modal;

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {

      if (!err) {
        const code = countries.find(c => c.id === country.id).code;
        setLoading(true);
        createOrUpdateClient(values, false);
      }
    });
  };

  const handleSubmitAndLink = e => {
    e.preventDefault();
    form.validateFields((err, values) => {

      if (!err) {
        const code = countries.find(c => c.id === country.id).code;
        setLoading(true);
        createOrUpdateClient(values, true);
      }
    });
  };

  const createOrUpdateClient = (values, fastLinkToExpedient) => {
    if (clientToEdit) {
      updateClient({
        ...values,
        main_address: values.dir_ppal,
        secondary_address: values.dir_sec,
        location: values.lct,
        plate: values.plate,
        job_title: values.job_title,
        id: clientToEdit.id,
        tags: explodeTags(values.tags),
        birthdate: values.birthdate
          ? moment(values.birthdate).format("YYYY-MM-DD")
          : null,
        files: signFile
      }, fastLinkToExpedient);
    } else {
      createClient({
        ...values,
        main_address: values.dir_ppal,
        secondary_address: values.dir_sec,
        location: values.lct,
        plate: values.plate,
        job_title: values.job_title,
        tags: explodeTags(values.tags),
        birthdate: values.birthdate
          ? moment(values.birthdate).format("YYYY-MM-DD")
          : null,
        files: signFile
      }, fastLinkToExpedient);
    }
  }

  const updateClient = (values, fastLinkToExpedient) => {

    const { files } = values;
    delete values["files"];


    const form_data = new FormData();
    form_data.set("body", JSON.stringify({ ...values }));
    form_data.append("client_id", values.id);

    files &&
      files.forEach(file => {
        form_data.append("files[]", file);
      });

    ClientService.updateClient(
      form_data,
      progress => setUploadPercentage(progress)
    )
      .then(response => {
        setLoading(false);
        message.success(`${currentLang.text.client} actualizado exitosamente.`);
        onUpdated(response.data);
        if (fastLinkToExpedient) {
          history.push("/expedient/add", { client: response.data });
        }
      })
      .catch(err => {
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.exception === "Google\\Service\\Exception") {
              setLoading(false);
              onUpdated(clientToEdit);
              return;
            }
          }
        }
        message.success(`${currentLang.text.client} actualizado exitosamente.`);
        setLoading(false);
      });
  };

  const createClient = (values, fastLinkToExpedient) => {

    const { files } = values;
    delete values["files"];


    const form_data = new FormData();
    form_data.set("body", JSON.stringify({ ...values }));

    files &&
      files.forEach(file => {
        form_data.append("files[]", file);
      });

    ClientService.createClient(
      form_data,
      progress => setUploadPercentage(progress)
    )
      .then(response => {
        message.success(`${currentLang.text.client} registrado exitosamente.`);
        setLoading(false);
        onSaved({ ...response.data, createExpedient: fastLinkToExpedient });
        if (fastLinkToExpedient) {
          history.replace("/expedient/add", { client: response.data });
        }
      })
      .catch(err => {
        message.error(`No se pudo registrar el ${currentLang.text.client}.`);
        setLoading(false);
      });
  };

  const showConfirm = (clients, currentLang) => {
    confirm({
      width: isTabletOrMobile ? "100vw" : "55vw",
      zIndex: 1050,
      icon: null,
      okText: `Aceptar`,
      cancelText: "Cancelar",
      title: `El número de identificación ya se encuentra ${clients.length > 1
        ? `asignado a los siguientes ${title}s`
        : `asignado al siguiente ${title}`
        } `,
      cancelButtonProps: { style: { display: "none" } },
      content: (
        <div>
          <CheckedClientsTable
            currentLang={currentLang}
            onUpdate={client => {
              setClientToEdit(client);
              setCountry(client.country);
              Modal.destroyAll();
            }}
            clients={clients}
          />
        </div>
      ),
      onOk: () => {
        Modal.destroyAll();
      },
      onCancel: () => {
        props.onCancel();
      }
    });
  };

  useEffect(() => {

    if (
      dniToCheck != undefined
      && dniToCheck.identification_number !== ""
      && dniToCheck.identification_number !== null
      && dniToCheck.identification_number !== undefined) {
      clearTimeout(checking);

      setChecking(
        setTimeout(() => {
          dniToCheck.identification_type_id && dniToCheck.identification_number && checkDni(dniToCheck).
            then((response) => {
              if (response && response.data.length > 0) {
                showConfirm(response.data, currentLang);
              }
            })
            .catch(e => {
              message.error("No se puedo verificar esta identificación");
            });
        }, 1500)
      );
    }
  }, [dniToCheck]);

  useEffect(() => {
    if (!country) {
      if (user.studio.provincia_contacto) {
        CountryService.getCountryByProvince(user.studio.provincia_contacto)
          .then(({ data }) => setCountry(data))
      } else {
        setCountry(user.studio.province.country)
      }
    }
  }, [])

  useEffect(() => {
    const modalWrap = document.getElementsByClassName("ant-modal-wrap");
    if (modalWrap.length > 0) {
      if (tutoStep === 14) {
        modalWrap[0].scroll({
          top: 300,
          behavior: "smooth"
        });
      }
      if (tutoStep === 19) {
        updateTutorials("CREATE_CLIENT");
      }
    }
  }, [tutoStep]);

  const checkDni = async current_dni => {
    try {
      const client_found = await ClientService.clientCheck(current_dni);
      return client_found;
    } catch (error) {
      console.log(error);
    }
  };

  const fields = [
    {
      cond: true,
      key: "lastname",
      lg: 8,
      md: 8,
      xs: 24,
      _tutoStep: 2,
      item: (
        <Form.Item key="lastname" label={text.client_form.client_name}>
          {getFieldDecorator("lastname", {
            rules: [
              {
                required: true,
                message: "Debe indicar el apellido del " + title
              }
            ],
            initialValue: clientToEdit ? clientToEdit.lastname : null
          })(<Input className="responsive-input" autoComplete="cc-csc" autoFocus />)}
        </Form.Item>
      )
    },
    {
      cond: true,
      key: "firstname",
      lg: 8,
      md: 8,
      xs: 24,
      _tutoStep: 3,
      item: (
        <Form.Item key="firstname" label="Nombre">
          {getFieldDecorator("firstname", {
            rules: [
              {
                required: false,
                message: "Debe indicar el nombre del " + title
              }
            ],
            initialValue: clientToEdit ? clientToEdit.firstname : null
          })(<Input className="responsive-input" autoComplete="cc-csc" />)}
        </Form.Item>
      )
    },
    {
      cond: true,
      key: "identification_type_id",
      lg: 8,
      md: 8,
      xs: 24,
      _tutoStep: 5,
      item: (
        <IdentificationTypeSelect
          onSelect={(v) => {
            let currentDniToCheck = { ...dniToCheck };
            currentDniToCheck.identification_type_id = v;
            setDniToCheck(currentDniToCheck);
          }}
          required={false}
          getFieldDecorator={getFieldDecorator}
          identificationType={
            clientToEdit
              ? clientToEdit.identification_type
                ? clientToEdit.identification_type.id
                : null
              : null
          }
        />
      )
    },
    {
      cond: true,
      key: "identification_number",
      lg: 8,
      md: 8,
      xs: 24,
      _tutoStep: 6,
      item: (
        <Form.Item key="identification_number" label="Número de Identificación">
          {getFieldDecorator("identification_number", {
            rules: [{ required: false, message: "Este campo es requerido." }],
            initialValue: clientToEdit
              ? clientToEdit.identification_number
              : null
          })(
            <Input className="responsive-input" autoComplete="cc-csc"
              onChange={(e) => {
                let currentDniToCheck = { ...dniToCheck };
                currentDniToCheck.identification_number = e.currentTarget.value;
                setDniToCheck(currentDniToCheck);
              }}
            />
          )}
        </Form.Item>
      )
    },
    {
      cond: true,
      key: "mobile_phone",
      lg: 8,
      md: 8,
      xs: 24,
      _tutoStep: 9,
      item: (
        <Form.Item
          key="mobile_phone"
          label={
            <span>
              Teléfono móvil{" "}
            </span>
          }
        >
          {getFieldDecorator("mobile_phone", {
            rules: [
              { required: false, message: "Debe indicar el móvil." },
              { pattern: /^\d+$/, message: "Solo puede escribir numeros." }
            ],
            initialValue: clientToEdit ? clientToEdit.mobile_phone : null
          })(
            <Input
              suffix={<WhatsAppIcon2 fill="#0042c5" style={{ color: '#0042c5', fontSize: '1.5em' }} />}
              className="responsive-input" autoComplete="cc-csc"
              addonBefore={
                country && countries.length > 0
                  ? `+${countries[countries.findIndex((c) => c.id === country.id)]
                    .code
                  }`
                  : null
              }
            />
          )}
        </Form.Item>
      )
    },
    {
      cond: true,
      key: "email",
      lg: 8,
      md: 8,
      xs: 24,
      _tutoStep: 11,
      item: (
        <Form.Item key="email" label="Correo">
          {getFieldDecorator("email", {
            rules: [{ required: false, message: "Debe indicar el correo." }],
            initialValue: clientToEdit ? clientToEdit.email : null
          })(<Input className="responsive-input" autoComplete="cc-csc" />)}
        </Form.Item>
      )
    },

    {
      cond: tutoStep !== 19,
      key: "dir_ppal",
      lg: 12,
      md: 12,
      xs: 24,
      _tutoStep: 14,
      item: (
        <Form.Item key="dir_ppal" label="Dirección principal">
          {getFieldDecorator("dir_ppal", {
            rules: [{ required: false, message: "Debe indicar la dirección." }],
            initialValue: clientToEdit ? clientToEdit.main_address : null
          })(<Input className="responsive-input" autoComplete="cc-csc" />)}
        </Form.Item>
      )
    },
    {
      cond: tutoStep !== 19,
      key: "tags",
      lg: 24,
      md: 24,
      xs: 24,
      _tutoStep: 18,
      item: (
        <TagAutocomplete
          initialValue={
            clientToEdit ? clientToEdit.tags.flatMap((t) => t.name) : []
          }
          label="Tags"
          name="tags"
          filter="clients"
          getFieldDecorator={getFieldDecorator}
        />
      )
    },
  ];

  const advanced_Fields = [
    {
      cond: true,
      key: "client_type_id",
      lg: 8,
      md: 8,
      xs: 24,
      _tutoStep: 4,
      item: (
        <ClientTypeSelect
          required={false}
          title={title}
          getFieldDecorator={getFieldDecorator}
          clientType={clientToEdit ? clientToEdit.client_type_id : null}
        />
      )
    }, {
      cond: true,
      key: "country",
      lg: 8,
      md: 8,
      xs: 24,
      _tutoStep: 7,
      item: (
        <CountrySelect
          onFetched={setCountries}
          country={country}
          required={true}
          getFieldDecorator={getFieldDecorator}
          onSelect={(c) => setCountry(c)}
        />
      )
    },
    {
      cond: true,
      key: "extra_phone",
      lg: 8,
      md: 8,
      xs: 24,
      _tutoStep: 10,
      item: (
        <Form.Item key="extra_phone" label="Teléfono móvil 2">
          {getFieldDecorator("extra_phone", {
            rules: [
              { required: false, message: "Debe indicar el móvil." },
              { pattern: /^\d+$/, message: "Solo puede escribir numeros." }
            ],
            initialValue: clientToEdit ? clientToEdit.extra_phone : null
          })(
            <Input className="responsive-input" autoComplete="cc-csc"
              addonBefore={
                country && countries.length > 0
                  ? `+${countries[countries.findIndex((c) => c.id === country.id)]
                    .code
                  }`
                  : null
              }
            />
          )}
        </Form.Item>
      )
    },
    {
      cond: true,
      key: "phone",
      lg: 8,
      md: 8,
      xs: 24,
      _tutoStep: 8,
      item: (
        <Form.Item key="phone" label="Teléfono">
          {getFieldDecorator("phone", {
            rules: [
              { required: false, message: "Debe indicar el telefono." },
              { pattern: /^\d+$/, message: "Solo puede escribir numeros." }
            ],
            initialValue: clientToEdit ? clientToEdit.phone : null
          })(
            <Input className="responsive-input" autoComplete="cc-csc"
              addonBefore={
                country && countries.length > 0
                  ? `+${countries[countries.findIndex((c) => c.id === country.id)]
                    .code
                  }`
                  : null
              }
            />
          )}
        </Form.Item>
      )
    }, {
      cond: true,
      key: "province_id",
      lg: 8,
      md: 8,
      xs: 24,
      _tutoStep: 12,
      item: (
        <ProvinceSelect
          label={text.province}
          disabled={!country}
          name="province_id"
          getFieldDecorator={getFieldDecorator}
          country={country && country.id}
          province={clientToEdit ? clientToEdit.province_id : user.studio.provincia_contacto}
          required={true}
        />
      )
    }, {
      cond: tutoStep !== 19,
      key: "lct",
      lg: 8,
      md: 8,
      xs: 24,
      _tutoStep: 13,
      item: (
        <Form.Item key="lct" label="Localidad">
          {getFieldDecorator("lct", {
            rules: [{ required: false, message: "Debe indicar la localidad." }],
            initialValue: clientToEdit ? clientToEdit.location : null
          })(<Input className="responsive-input" autoComplete="cc-csc" />)}
        </Form.Item>
      )
    },
    {
      cond: tutoStep !== 19,
      key: "dir_sec",
      lg: 12,
      md: 12,
      xs: 24,
      _tutoStep: 15,
      item: (
        <Form.Item key="dir_sec" label="Dirección secundaria">
          {getFieldDecorator("dir_sec", {
            rules: [{ required: false, message: "Debe indicar la dirección." }],
            initialValue: clientToEdit ? clientToEdit.secondary_address : null
          })(<Input className="responsive-input" autoComplete="cc-csc" />)}
        </Form.Item>
      )
    }, {
      cond: tutoStep !== 19,
      key: "postal_code",
      lg: 4,
      md: 4,
      xs: 24,
      _tutoStep: 16,
      item: (
        <Form.Item key="postal_code" label="Codigo postal">
          {getFieldDecorator("postal_code", {
            rules: [
              {
                max: 10,
                message: "Maximo de 10 caracteres."
              }
            ],
            initialValue: clientToEdit ? clientToEdit.postal_code : null
          })(<Input className="responsive-input" autoComplete="cc-csc" />)}
        </Form.Item>
      )
    }, {
      cond: tutoStep !== 19,
      key: "birthdate",
      lg: 8,
      md: 8,
      xs: 24,
      _tutoStep: 17,
      item: (
        <Form.Item key="birthdate" label="Fecha de nacimiento">
          {getFieldDecorator("birthdate", {
            rules: [
              {
                required: false,
                message: "Este campo es requerido."
              }
            ],
            initialValue: clientToEdit
              ? clientToEdit.birthdate
                ? moment(clientToEdit.birthdate)
                : null
              : null
          })(<DatePicker className="responsive-input" format="DD/MM/YYYY" suffixIcon={<CalendarIcon />} />)}
        </Form.Item>
      )
    }, {
      cond: true,
      key: "job_title",
      lg: 6,
      md: 6,
      xs: 24,
      _tutoStep: 13,
      item: (
        <Form.Item key="job_title" label="Cargo">
          {getFieldDecorator("job_title", {
            rules: [{ required: false, message: "Debe indicar el cargo." }],
            initialValue: clientToEdit ? clientToEdit.job_title : null
          })(<Input className="responsive-input" autoComplete="cc-csc" />)}
        </Form.Item>
      )
    }, {
      cond: true,
      key: "plate",
      lg: 6,
      md: 6,
      xs: 24,
      _tutoStep: 13,
      item: (
        <Form.Item key="plate" label="Matricula">
          {getFieldDecorator("plate", {
            rules: [{ required: false, message: "Debe indicar la matricula." }],
            initialValue: clientToEdit ? clientToEdit.plate : null
          })(<Input className="responsive-input" autoComplete="cc-csc" />)}
        </Form.Item>
      )
    },
    {
      cond: tutoStep !== 19,
      key: "file",
      lg: 24,
      md: 24,
      xs: 24,
      _tutoStep: 18,
      item: (
        <Form.Item label="Adjuntar firma" name="file">
          <UploadSign
            onDelete={() => {
              if (client && client.sign) {
                return ClientService.deleteClientSign(client.id);
              }
            }}
            image={client && client.sign ? `${baseURL}/client/get-sign/${client.id}` : null}
            file={signFile}
            onFileSelect={fileList => setSignFile(fileList)}
            uploadPercentage={uploadPercentage}
          />
        </Form.Item>
      )
    },
    {
      cond: true,
      key: "observations",
      lg: 24,
      md: 24,
      xs: 24,
      _tutoStep: 19,
      item: (
        <Form.Item key="observations" label="Observaciones">
          {getFieldDecorator("observations", {
            initialValue: clientToEdit ? clientToEdit.observations : null
          })(<Input.TextArea className="responsive-input" autoComplete="cc-csc" />)}
        </Form.Item>
      )
    }
  ];

  return (
    <>
      {/* {(!isTabletOrMobile && process.env.REACT_APP_SHOW_TUTORIAL === "1") && <Button style={{position: 'absolute', right: 10, top: 50}}
      type="text" icon={<QuestionCircleFilled />} 
      onClick={() => {
      setShowTutorial(true);
      setTutoStep(1);
    }}></Button> }*/}
      {/* <VideoTutorialButton tooltipPlacement={"leftBottom"} tutorial={"crear_contacto"} /> */}
      <Form onSubmit={handleSubmit} layout="vertical">
        <Row type="flex" justify="start" gutter={10}>
          {fields
            .filter(field => field.cond)
            .map((field) => (
              <Col lg={field.lg} xs={field.xs}>
                <div className={field._tutoStep === tutoStep ? "fixed-form-item fade-in" : ""}>
                  {field.item}
                </div>
              </Col>
            ))}
        </Row>
        <Divider
          type="horizontal"
          orientation="left"
          style={{ borderTopColor: '#cbcaca' }}>
          <div
            className="show-advanced-options"
            onClick={() => {
              setShowAdvancedFields(!showAdvancedFields)
            }}
          >
            <CaretDownOutlined className={showAdvancedFields ? "open-advance" : "closed-advance"} /> Opciones avanzadas
          </div>
        </Divider>
        <CollapsablePanel type="block" vertical={true} open={showAdvancedFields}>
          <Row type="flex" justify="start" gutter={10}>
            {advanced_Fields
              .filter(field => field.cond)
              .map((field) => (
                <Col lg={field.lg} xs={field.xs}>
                  <div className={field._tutoStep === tutoStep ? "fixed-form-item fade-in" : ""}>
                    {field.item}
                  </div>
                </Col>
              ))}
          </Row>
        </CollapsablePanel>
        <Row type="flex" justify="start" gutter={10}>
          <Col
            lg={24}
            md={24}
            xs={24}
          >
            <Form.Item key="submit" >
              <Space wrap="auto" style={{ width: "100%", justifyContent: isTabletOrMobile ? "center" : "flex-start" }} align="center">
                {showLinkButton && <Button
                  style={isTabletOrMobile ? { width: "100%" } : { margin: "0px 10px", width: "fit-content" }}
                  type="primary"
                  onClick={(e) => handleSubmitAndLink(e)}
                  className="responsive-input responsive-button save-and-continue-button"
                  loading={loading}
                >
                  <CheckCircleOutlined style={{ fontSize: "12.5px" }} />{clientToEdit ? "Editar" : "Guardar"} y crear expediente
                </Button>}
                <Button
                  style={isTabletOrMobile ? { width: "47%" } : { margin: "0px 10px" }}
                  type="primary"
                  htmlType="submit"
                  className="responsive-input responsive-button"
                  loading={loading}
                >
                  <CheckCircleOutlined style={{ fontSize: "12.5px" }} />{clientToEdit ? "Editar" : "Guardar"}
                </Button>
                <Button
                  style={isTabletOrMobile ? { width: "47%" } : { margin: "0px 10px" }}
                  type="danger"
                  onClick={() => props.onCancel()}
                  className="ant-btn warn responsive-input responsive-button"
                  htmlType="button"
                  disabled={loading}
                >
                  <CloseCircleOutlined style={{ fontSize: "12.5px" }} />Cancelar
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form >
      {
        tutoStep > 0 && tutoStep <= 20 && showTutorial && <div className="tuto-overlay">
          <TutorialMessagesCreateContact tutoStep={tutoStep} />
          <TutorialButton right={"40%"} text={tutoStep === 20 ? "Finalizar" : "Siguiente"} onClick={() => {
            setTutoStep(tutoStep + 1);
          }} />
          <TutorialButton right={"20%"} text="Omitir" onClick={() => {
            setTutoStep(0);
            tutorialState.completeTutorial("CREATE_CLIENT");
            //
          }} />
        </div>
      }
    </>
  );
};

const TutorialMessagesCreateContact = ({ tutoStep }) => {
  return (
    <>
      {tutoStep === 1 && <TutorialTitle title="Contactos" text="¡Manos a la obra!, los datos requeridos servirán de base para generar los escritos automáticos y se utilizarán para filtrar/buscar tanto a la persona (física o jurídica) como al expediente." />}
      {tutoStep === 2 && <TutorialText title="Apellido/Razon Social" text="Apellido o razón social del contacto generado." right="5%" top="10%" />}
      {tutoStep === 3 && <TutorialText title="Nombre" text="Nombre de pila del contacto generado." left="5%" top="10%" />}
      {tutoStep === 4 && <TutorialText title="Tipo de contacto" text={`Por defecto el sistema permite seleccionar entre "cliente", "perito", "demandada" o "abogado" pero también puedes personalizar las opciones según tus necesidades desde "Configuración / Estudios / Tipos de Contacto"`} right="5%" top="10%" />}
      {tutoStep === 5 && <TutorialText title="Tipo de Identificacion" text="Permite seleccionar Documento Nacional de Identidad, Código Único de Identificación Tributaria o Laboral" left="5%" top="10%" />}
      {tutoStep === 6 && <TutorialText title="Numero de identificacion" text="Se debe completar con el número de DNI/CUIT/CUIL." left="5%" top="10%" />}
      {tutoStep === 7 && <TutorialText title="Pais" text="Selecciona el país del contacto. Por defecto se carga el país de ubicación del estudio." left="5%" top="10%" />}
      {tutoStep === 8 && <TutorialText title="Telefono" text="Completa con un número de contacto" right="5%" top="10%" />}
      {tutoStep === 9 && <TutorialText title="Telefono movil" text="Completa con un número de contacto de un móvil. Este número se asociará a la función para envíos de mensajes por eso es importante que el número se encuentre correctamente cargado sin el 0 en el código de área ni el 15 antes del número (ej de un teléfono bien cargado para Mendoza es 2614567891)" left="5%" top="10%" />}
      {tutoStep === 10 && <TutorialText title="Telefono Movil 2" text="Completa con un número de contacto" left="5%" top="10%" />}
      {tutoStep === 11 && <TutorialText title="Correo" text="Completa con el mail del contacto" left="5%" top="10%" />}
      {tutoStep === 12 && <TutorialText title="Provincia/Estado" text="Selecciona los datos que correspondan al domicilio de tu contacto" left="5%" top="10%" />}
      {tutoStep === 13 && <TutorialText title="Localidad" text="Completa con los datos que correspondan al domicilio de tu contacto" left="5%" top="10%" />}
      {tutoStep === 14 && <TutorialText title="Direccion Principal" text="Completa los datos que correspondan al domicilio real, social o laboral de tu contacto" left="5%" top="10%" />}
      {tutoStep === 15 && <TutorialText title="Direccion Secundaria" text="Completa los datos que correspondan al domicilio real, social o laboral de tu contacto" left="5%" top="10%" />}
      {tutoStep === 16 && <TutorialText title="Codigo Postal" text="Completa los datos que correspondan al domicilio real, social o laboral de tu contacto" left="5%" top="10%" />}
      {tutoStep === 17 && <TutorialText title="Fecha de nacimiento" text="Completa con los datos que correspondan a tu contacto" left="5%" top="10%" />}
      {tutoStep === 18 && <TutorialText title="Tags" text={`Permite "etiquetar" contactos según las características descriptas por el usuario. Sirven para filtrar al contacto según el atributo utilizado.`} left="5%" top="10%" />}
      {tutoStep === 19 && <TutorialText title="Observación" text="Campo adicional que permite añadir cualquier otro contenido al formulario de contacto." left="5%" top="10%" />}
      {tutoStep === 20 && <TutorialText titleStyle={{ fontSize: 30 }} fontSize={25} title="¡Ahora es tu turno!" text="¡Crea tu primer contacto para continuar con el tutorial!" left="25%" top="25%" />}
    </>
  );
}

function setPhoneCode(values, code) {
  let { phone, extra_phone, mobile_phone } = values;
  phone = phone ? `+${code}${phone}` : null;
  extra_phone = extra_phone ? `+${code}${extra_phone}` : null;
  mobile_phone = mobile_phone ? `+${code}${mobile_phone}` : null;
  return { phone, extra_phone, mobile_phone };
}

const createClientForm = Form.create({ name: "create-client" })(createClient);

const mapStateToProps = ({ lang }) => {
  const { currentLang } = lang
  return { currentLang }
}

export default withRouter(connect(mapStateToProps)(createClientForm));

createClientForm.defaultProps = {
  onUpdated: () => null,
  title: "cliente",
  showLinkButton: true
};
