import React, { useEffect, useState } from 'react';
import { Payment, initMercadoPago } from '@mercadopago/sdk-react';
import { baseURL } from '../../util/Api';
import axios from 'axios';
import { message } from 'antd';

initMercadoPago(process.env.REACT_APP_MPKEY);
export default function MercadoPagoBrick({ studioData }) {

  const [loading, setLoading] = useState(false);
  const [preference, setPreference] = useState();
  useEffect(() => {
    const fetchPreference = async () => {
      setLoading(true);
      const { data } = await axios.post(`${baseURL}/subscription/start-payment`, {
        plan_id: studioData.plan_id
      });
      setPreference({
        preferenceId: data.id,
        amount: data.items.reduce((acc, item) => acc + item.unit_price, 0),
      });      
      setLoading(false);
    }
    fetchPreference();
  }, [
    studioData
  ]);

  const customization = {
    paymentMethods: {
      creditCard: "all",
      debitCard: "all",
      bankTransfer: "all",
      atm: "all",
      wallet_purchase: "all",
      ticket: "all",
      default_installments: 1,
    },
    installments: 1,
  };
  const onSubmit = async (
    { selectedPaymentMethod, formData }
  ) => {
    return new Promise((resolve, reject) => {
      fetch(`${baseURL}/subscription/process-payment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, studioData }),
      })
        .then((response) => response.json())
        .then((response) => {
          resolve();
          message.success('Pago procesado correctamente');
          window.location.href = '/singin?success=1';
        })
        .catch((error) => {
          reject();
          message.error('Hubo un error al procesar el pago');
        });
    });
  };
  const onError = async (error) => {
    setLoading(false);
    message.error('Hubo un error al procesar el pago');
  };
  const onReady = async () => {
    setLoading(false);
  };
  if (loading) return <p>Loading...</p>
  return preference ? <Payment
    locale='es-AR'
    initialization={preference}
    customization={customization}
    onSubmit={onSubmit}
    onReady={onReady}
    onError={onError}
  /> : <></>;
}
