import React, { useState } from "react";
import { Select, message } from "antd";
import UserService from "../../../services/user.service";
import StudioService from "../../../services/config.service";

const AdminStudioSelect = ({ user }) => {
  const [dataStudio, setDataStudio] = useState([]);

  const onSelect = _studio => {
    UserService.adminChangeStudio(_studio)
      .then(s => {
        const updatedUser = JSON.stringify({ ...user, studio: s.data });
        localStorage.setItem("user", updatedUser);
        window.location.reload();
      })
      .catch(e => message.error("Error! Intente nuevamente"));
  };

  React.useEffect(() => {
    const fetchData = async () => {
      setDataStudio(await StudioService.getStudio());
    };

    fetchData();
  }, []);

  return user ? (
    <Select
      className="responsive-input"
      defaultValue={user.studio ? user.studio.id : null}
      placeholder="Estudio"
      style={{ width: "50%" }}
      onSelect={onSelect}
      optionFilterProp="children"
      showSearch
      filterOption={(input, option) =>
        option.label && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      <Select.Option value="all" key="all">
        Todos
      </Select.Option>
      {/* {console.log(dataStudio)} */}
      {dataStudio.data &&
        dataStudio.data.map(item => (
          <Select.Option key={item.id} value={item.id} label={item.name}>
            <span style={{ color: "#ccc" }}>{item.name}</span>
          </Select.Option>
        ))}
    </Select>
  ) : null;
};

export default AdminStudioSelect;
