import React, { useState, useEffect } from "react";
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, Result } from "antd";
import AuthService from "../../../services/auth.service";
import { withRouter } from "react-router-dom";
import FlyingBird from "../../../components/Widgets/FlyingBird";
import IntlMessages from "../../../util/IntlMessages";

const RegisterWithInvitation = props => {

  const { form } = props;
  const { getFieldDecorator } = form;
  const [loading, setLoading] = useState(true);
  const [invitation, setInvitation] = useState(null);

  useEffect(() => {
    AuthService.checkInvitation(props.token)
      .then(response => {
        if (response.data.valid) {
          setInvitation(response.data.invitation);
          setLoading(false);
        } else {
          message.error("La invitación es invalida");
          props.history.push("/");
        }
      })
      .catch(err => console.log(err));
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        
        //setLoading(true);
        
        values.token = props.token;

        console.log(values);

        AuthService.registerWithInvitation(values)
          .then(response => {

            if (response.data.valid) {

              message.success("Exito");
              setLoading(false);
              props.history.push("/signin")
              
            
            } else {
              
              message.error("La invitación es invalida");
              props.history.push("/signin");
           
            }

            
          })
          .catch(err => console.log(err));
      }
    });
  };

  const compareToFirstPassword = (rule, value, callback) => {
    const { form } = props;
    if (value && value !== form.getFieldValue('password')) {
      callback('¡Las dos contraseñas que ingresas son inconsistentes!');
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule, value, callback) => {
    const { form } = props;
    if (value) {
      form.validateFields(['password_confirmation'], { force: true });
    }
    callback();
  };

  const fields = [
    {
      key: "password",
      item: (
        <Form.Item
          key="password"
          label="Contraseña"
          hasFeedback
        >
          {getFieldDecorator("password", {
            rules: [
              {
                required: true,
                message: "Debe indicar su contraseña."
              },
              {
                min: 8,
                message: "Su contraseña debe ser mayor a 8 caracteres."
              },
              {
                validator: validateToNextPassword,
              }
            ]
          })(<Input.Password  width="100%" />)}
        </Form.Item>
      )
    },
    {
      key: "password_confirmation",
      item: (
        <Form.Item
          key="password_confirmation"
          label="Repite la contraseña"
          hasFeedback
        >
          {getFieldDecorator("password_confirmation", {
            rules: [
              {
                required: true,
                message: "Debe indicar su contraseña."
              },
              {
                min: 8,
                message: "Su contraseña debe ser mayor a 8 caracteres."
              },
              {
                validator: compareToFirstPassword,
              }
            ]
          })(<Input.Password  width="100%" />)}
        </Form.Item>
      )
    },
    {
      key: "submit",
      item: (
        <Form.Item key="submit" className="gx-flex-basis-100">
          <Button
            type="primary"
            htmlType="submit"
            className="full-input"
            loading={loading}
          >
            Registrarme
          </Button>
        </Form.Item>
      )
    }
  ];

  return loading ? (
    <Result
      icon={<LoadingOutlined />}
      title="Por favor, espere..."
      extra={<p className="gx-text-gray">Verificando invitación</p>}
    />
    ) : (
    
    <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <img src="https://via.placeholder.com/272x395" alt='Neature'/>
              </div>
              <div className="gx-app-logo-wid">
                <h1>Invitacion</h1>
                <p><IntlMessages id="app.userAuth.bySigning"/></p>
                <p><IntlMessages id="app.userAuth.getAccount"/></p>
              </div>
              <div className="gx-app-logo">
                <img alt="example" width="190px" src={require("assets/images/logo.svg")}/>
              </div>
            </div>
            <div className="gx-app-login-content">
              <InvitationCard
                invitation={invitation}
                extra={
                  <Form
                    onSubmit={handleSubmit}
                    className="gx-flex-row gx-justify-content-between"
                  >
                    {fields.map(field => field.item)}
                  </Form>
                }
              />     
            </div>
            
          </div>
        </div>
      </div>

  );
};

const InvitationCard = ({ invitation, extra }) => {
  const image = "https://previews.123rf.com/images/lumut/lumut1809/lumut180900186/107803056-justice-law-logo-template-vector-illsutration-design.jpg";
  return (
    <FlyingBird
      title={invitation.studio.name}
      subTitle={`${invitation.firstname} - ${invitation.role.name}`}
      name={invitation.firstname}
      image={image}
      extra={extra}
    />
  );
};

const RegisterWithInvitationForm = Form.create({
  name: "register-with-invitation"
})(RegisterWithInvitation);

export default withRouter(RegisterWithInvitationForm);
