import React, { useState, useMemo, useEffect } from "react";
import { Table, Tag, Tooltip, Button, Popconfirm, message } from "antd";
import axios, { authHeaders } from "../../../util/Api";
import {
  DeleteOutlined,
  FolderOpenOutlined,
  FolderOutlined
} from "@ant-design/icons";
import { DownloadFile } from "../../../law-components/ExpedientFiles/ExpedientFileList";
import { bytesFormatter } from "../../../util/bytesFormatter";
import { customExpandIcon } from "../../../law-components/ExpedientMovements";
import moment from "moment";
import { ExpenseFormModal } from "./ExpenseFormModal";
import { ReactComponent as DeleteIcon } from "../../../assets/assets2_0/delete.svg";
import { useScope } from "../../../hooks/useScope";

export const ExpenseTableActions = ({
  id,
  record,
  onUpdated,
  deleteExpense,
  onDeleted
}) => {

  const canUpdateExpense = useScope('expenses.update')
  const canDeleteExpense = useScope('expenses.delete')
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "2.5ch",
        alignItems: "center"
      }}
    >
      {canUpdateExpense && <ExpenseFormModal
        onUpdated={ex => {
          onUpdated(ex);
        }}
        edit={record}
      />}
      {canDeleteExpense && <Popconfirm
        title="¿Seguro desea eliminar?"
        onConfirm={() => {
          deleteExpense(id)
            .then(resp => {
              onDeleted(record);
            })
            .catch(error => {
              if (error.response && error.response.status === 403) {
                message.error("No tienes permisos para borrar un registro");
              }
              console.log(error);
            });
        }}
      >
        <DeleteIcon color="#FF5879" />
      </Popconfirm>}
    </div>
  );
};

export function ExpensesTable({ expenses, onUpdated, onDeleted, onChange }) {
  const [currentExpandedRows, setCurrentExpandedRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const cols = [
    {
      dataIndex: "type",
      title: "Tipo",
      key: "type",
      filters: [
        {
          text: "Ingreso",
          value: "IN"
        },
        {
          text: "Gasto",
          value: "OUT"
        }
      ],
      onFilter: (value, record) => record.type === value,
      render: type => {
        return type === "IN" ? (
          <Tag color="green">Ingreso</Tag>
        ) : (
          <Tag color="red">Gasto</Tag>
        );
      }
    },
    {
      dataIndex: "concept",
      title: "Concepto",
      key: "concept"
    },
    {
      dataIndex: "description",
      title: "Descripción",
      key: "description"
    },
    {
      dataIndex: "amount",
      title: "Monto",
      key: "amount",
      multiple: 1,
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => {
        return b.amount - a.amount;
      }
    },
    {
      dataIndex: "date",
      title: "Fecha",
      key: "date",
      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend", "ascend"],
      multiple: 2,
      sorter: (a, b) => {
        return moment(b.date).unix() - moment(a.date).unix();
      },
      render: date => moment(date).format("DD/MM/YYYY")
    },
    {
      dataIndex: "id",
      title: "Acciones",
      key: "actions",
      align: "center",
      render: (id, record) => {
        return (
          <ExpenseTableActions
            deleteExpense={deleteExpense}
            id={id}
            record={record}
            onDeleted={onDeleted}
            onUpdated={onUpdated}
          />
        );
      }
    }
  ];

  const handleTableChange = (pagination, filters, sorter, extra) => {
    console.log(extra);
    onChange({ filters, sorter, currentData: extra.currentDataSource });
  };

  return (
    <Table
      rowKey={record => record.id}
      pagination={{
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"],
        defaultPageSize: 10
      }}
      onChange={handleTableChange}
      loading={loading}
      className="gx-table-responsive"
      dataSource={expenses}
      columns={cols}
      expandIcon={props => customExpandIcon(props)}
      rowClassName={record => {
        let classNames = "";
        classNames += record.files.length > 0 ? "show " : "hidden ";
        classNames += record.id === -1 ? "external" : "internal";
        return classNames;
      }}
      expandable={{
        expandIconColumnIndex: 6,
        indentSize: 1,
        expandIcon: ({ expanded, onExpand, record }) =>
          record.files.length > 0 ? (
            expanded ? (
              <Button
                type="primary"
                onClick={e => onExpand(record, e)}
                icon={<FolderOpenOutlined />}
                size="small"
              />
            ) : (
              <Tooltip trigger="hover" title="Ver adjuntos">
                <Button
                  type="primary"
                  onClick={e => onExpand(record, e)}
                  icon={<FolderOutlined />}
                  size="small"
                />
              </Tooltip>
            )
          ) : null
      }}
      onExpandedRowsChange={setCurrentExpandedRows}
      expandedRowKeys={currentExpandedRows}
      expandedRowRender={(record, i) => (
        <StudioAccountFilesTable
          onUpdate={files => {
            onUpdated({ ...record, files });
          }}
          i={i}
          onEmpty={x => {
            setCurrentExpandedRows([]);
            onUpdated({ ...record, files: [] });
          }}
          files={record.files}
        />
      )}
    />
  );
}

function StudioAccountFilesTable({ files, onEmpty, i, onUpdate }) {
  const [_files, setFiles] = useState(files);

  useEffect(() => {
    setFiles(files);
  }, [files]);

  const onDeleted = id => {
    onUpdate(files.filter(file => file.id !== id));
  };

  return (
    <div style={{ margin: 5 }}>
      <Table
        pagination={false}
        size="small"
        columns={[
          {
            title: "Nombre",
            dataIndex: "name",
            render: name => name
          },
          {
            title: "Tamaño",
            dataIndex: "size",
            render: size => bytesFormatter(size)
          },
          {
            title: "Acciones",
            dataIndex: "id",
            render: (id, record) => (
              <div style={{ display: "flex", flexFlow: "row wrap" }}>
                <DownloadFile file={record} />
                <Popconfirm
                  title="¿Seguro desea eliminar?"
                  onConfirm={() => {
                    deleteAttachment(id)
                      .then(resp => {
                        const updatedFiles = files.filter(
                          file => file.id !== id
                        );
                        if (updatedFiles.length === 0) {
                          onEmpty(i);
                        }
                        setFiles(updatedFiles);
                        onDeleted(id);
                      })
                      .catch(error => {
                        console.log(error);
                      });
                  }}
                >
                  <Button
                    size="small"
                    type="danger"
                    icon={<DeleteOutlined />}
                  />
                </Popconfirm>
              </div>
            )
          }
        ]}
        dataSource={_files}
      />
    </div>
  );
}

async function deleteAttachment(id) {
  const resp = await axios.delete("/file/" + id);
  return resp.data;
}

export async function deleteExpense(id) {
  const resp = await axios.delete(`/studio-account/${id}`, {
    headers: authHeaders()
  });
  return resp.data;
}
