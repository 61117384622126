import { message } from "antd";
import React, { useEffect, useState } from "react";
import axios, { authHeaders } from "../util/Api";
import CreateExpedienteMovementModal from "./CreateExpedientMovementModal";

export const CreateLazyExpedientMovementModal = ({
  id,
  finish,
  onUpdated,
  onCancel
}) => {
  const [editableMovement, setMovement] = useState(null);

  useEffect(() => {
    if (id) {
      const hide = message.loading("Cargando la edición del movimiento...", 0);
      getFullMovement({ id }).then(setMovement).finally(() => hide());
    }
    return () => {
      setMovement(null);
    };
  }, [id]);

  return (
    <CreateExpedienteMovementModal
      onCancel={onCancel}
      expedient={editableMovement ? editableMovement.expedient : null}
      visible={id && editableMovement !== null}
      expedient_movement={editableMovement}
      onSaved={() => null}
      finish={finish}
      onUpdated={onUpdated}
    />
  );
};

async function getFullMovement({ id }) {
  const resp = await axios.get(`/expedient-movement-full/${id}`, {
    headers: authHeaders()
  });
  return resp.data;
}
