import React, { Component } from "react";
import { Affix, Layout } from "antd";
import "react-toastify/dist/ReactToastify.css";

import Echo from "laravel-echo";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Sidebar from "../Sidebar/index";
import BelowHeader from "../Topbar/BelowHeader/index";
import Topbar from "../Topbar/index";
import App from "routes/index";
import { connect } from "react-redux";

import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import { EchoContext } from "../../contexts/EchoContext";
import MediaQuery from "react-responsive";
import MailCounterContainer from "../../unstatedStores/MailNotificacion";
import { wsUrl } from "../../util/Api";
import GoogleStateContainer from "../../unstatedStores/GoogleStateStore";
import HeaderMobile from "../Topbar/BelowHeader/index.mobile";

const { Content } = Layout;

export class MainApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterExpedient: {}
    };
  }

  getContainerClass = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return "";
    }
  };
  getNavStyles = navStyle => {
    switch (navStyle) {
      case NAV_STYLE_BELOW_HEADER:
        return (
          <MediaQuery maxWidth={980}>
            {matches =>
              matches ? (
                <Affix style={{ zIndex: 51 }}>
                  <HeaderMobile />
                </Affix>
              ) : (
                <BelowHeader />
              )
            }
          </MediaQuery>
        );
      case NAV_STYLE_FIXED:
        return <Topbar />;
      case NAV_STYLE_DRAWER:
        return <Topbar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar />;
      default:
        return null;
    }
  };

  getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar />;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <Sidebar />;
      case NAV_STYLE_DRAWER:
        return <Sidebar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar />;
      default:
        return null;
    }
  };

  componentDidMount() {
    //logoutOnTokenExpire();
  }

  render() {
    const { match, width, navStyle } = this.props;
    const isDev = process.env.REACT_APP_ENV === 'development'
    const wsPort = isDev ? 2096 : 8443
    const wsHost = process.env.REACT_APP_WS_HOST || 'localhost'
    const wsConfig = {
      broadcaster: "pusher",
      key: "123456",
      wsHost: wsHost,
      [isDev ? 'wssPort' : 'wssPort']: wsPort,
      forceTLS: true,
      encrypted: false,
      disableStats: true
    }

    /* 
    //local
    const wsConfig = {
      broadcaster: "pusher",
      key: "123456",
      wsHost: wsHost,
      [isDev ? 'wsPort' : 'wsPort']: wsPort,
      forceTLS: false,
      encrypted: false,
      disableStats: true
    } */

    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_SECRET}>
        <GoogleStateContainer.Provider>
          <MailCounterContainer.Provider>
            <FilterExpedientContext.Provider
              value={{
                filterExpedient: this.state.filterExpedient,
                setState: (state, callback) => {
                  this.setState({ filterExpedient: state }, callback);
                }
              }}
            >
              <EchoContext.Provider
                value={new Echo(wsConfig)}
              >
                <Layout className="gx-app-layout">
                  {this.getSidebar(navStyle, width)}

                  <Layout style={{ maxWidth: "100vw" }}>
                    {this.getNavStyles(navStyle)}
                    <Content>
                      <StudioContext.Provider value={{ studio: 1 }}>
                        <App match={match} />
                      </StudioContext.Provider>
                    </Content>
                  </Layout>
                </Layout>
              </EchoContext.Provider>
            </FilterExpedientContext.Provider>
          </MailCounterContainer.Provider>
        </GoogleStateContainer.Provider>
      </GoogleOAuthProvider>
    );
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { width, navStyle } = settings;
  const { authUser } = auth;
  return { width, navStyle, authUser };
};
export default connect(mapStateToProps)(MainApp);

export const StudioContext = React.createContext({});
export const FilterExpedientContext = React.createContext({});
