import React, { useEffect } from "react"
import ReactGA from "react-ga4"

export function useGAPageView() {
  useEffect(() => {
    if (ReactGA.isInitialized) {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
        title: window.location.pathname
      });
    }
  }, [ReactGA.isInitialized]);
}

export function useGAClickEvent(category, action, label) {
  const handleClick = () => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };

  return handleClick;
}