export function isInViewport(elementRef) {

    if (elementRef && elementRef.current) {
        const rect = elementRef.current.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0
        );
    } else {
        return false;
    }
}