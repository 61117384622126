export default function useMe() {
    let user = localStorage.getItem('user');
    if (!user) {
        return {
            user: null
        }
    }
    user = JSON.parse(user);
    return {
        user: {
            ...user,
            isAdmin: user.role_id === 1,
            isAdminStudio: user.role_id === 2,
        }
    }
}