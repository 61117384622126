import React from 'react';
import { useMediaQuery } from "react-responsive";
import create from "zustand";
import { getCompletedTutorials } from "../containers/Frames/OverlayTutorial";

const useTutorialStateV2 = create(set => ({
  step: 0,
  showTuto: process.env.REACT_APP_SHOW_TUTORIAL === "1",
  tutorialsCompleted: getCompletedTutorials(),
  setStep: step => set({ step }),
  deleteTutorial: t => {
    set(state => ({
      tutorialsCompleted: state.tutorialsCompleted.filter(_t => t !== t)
    }));
  },
  completeTutorial: t => {
    set(state => ({
      tutorialsCompleted: [...state.tutorialsCompleted, t]
    }));
  },
  next: () => set(state => ({ step: state.step + 1 }))
}));

export default useTutorialStateV2;