import React from 'react'
import GoogleLoginButton from "./GoogleLoginButton";
import MicrosoftLoginButton from "./MicrosoftLoginButton";
import YahooLoginButton from "./YahooLoginButton";
import { Typography } from 'antd';

export default function SocialLoginButtons() {
  return <div style={{ display: 'flex', rowGap: '1ch', flexFlow: 'column' }}>
    <Typography.Text>
      o accede con:
    </Typography.Text>
    <GoogleLoginButton />
    <MicrosoftLoginButton />
    <YahooLoginButton />
  </div>
}