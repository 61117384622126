import React, { useEffect, useState } from "react";
import { Button, Select, Tooltip } from "antd";
import "../style/index.css";
import CreateStudioTaskModal from "../../../modals/CreateStudioTaskModal";
import { TaskListIcon } from "../../../util/taskListIcon";
import CourtFilterSelect from "../../PrebuiltFormComponents/CourtFilterSelect";
import { AppointmentModal } from "../../../modals/CreateAppointmentModal";
import { ReactComponent as FilterAltIcon } from "../../../assets/assets2_0/filter_alt.svg";
import CollapsablePanel from "../../../util/CollapsablePanel";
import { useMediaQuery } from "react-responsive";
import { CreateFeeModal } from "../../../modals/CreateFeeModal";

const { Option } = Select;
const MovementFilters = ({
  courtsDateRange,
  onApply,
  defaultFilters,
  hide,
  availableFilters = [],
  collapsableFilters = [],
  _selectedFilter,
  onCreatedTask,
  showMoreFiltersButton,
  toggleExtraFilters
}) => {
  const [filters, setFilters] = useState({
    public: true,
    order_by_court: null,
    court_then_hour: null,
    realizado: null,
    date: true,
    ...defaultFilters
  });
  const [order, setOrder] = useState("desc");
  const [selectedFilter, selectFilter] = useState(_selectedFilter || "hour");
  const [scheduleCheck, setScheduleCheck] = useState(false);
  const [showCreateCheckModal, setCreateCheckModal] = useState(false);
  const [showCreateFeeModal, setShowCreateFeeModal] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

  const [showExtraFilters, setShowExtraFilters] = useState(false);

  useEffect(() => {
    updateAndApply(defaultFilters);
  }, []);

  const updateAndApply = _filters => {
    const f = { ...defaultFilters, ..._filters };
    setFilters(f);
    onApply(_filters);
  };

  const timeSorter = order => {
    updateAndApply({
      date: order,
      order_by_court: null,
      court_then_hour: null,
      public: filters.public
    });
  };

  const courtFilter = order => {
    updateAndApply({
      order_by_court: order,
      date: null,
      court_then_hour: null,
      public: filters.public
    });
  };

  const courtThenHourFilter = (order, courtOrder) => {
    updateAndApply({
      order_by_court: courtOrder,
      date: null,
      court_then_hour: order,
      public: filters.public
    });
  };

  const filterByCourt = court => {
    updateAndApply({
      court: court
    });
  };

  const AgendarButton = () => {
    return (
      <Select
        value={" + Agendar"}
        className="agendar-multiple-button linear-filter"
        style={{ width: isTabletOrMobile ? "100%" : "fit-content" }}
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ marginTop: "2px" }}
        showArrow={false}
        onSelect={v => {
          switch (v) {
            case "Appointment":
              setShowAppointmentModal(true);
              break;
            case "Event":
              setScheduleCheck(true);
              setCreateCheckModal(true);
              break;
            default:
              break;
          }
        }}
      >
        <Option value="Event">Evento</Option>
        <Option value="Appointment">Turno</Option>
      </Select>
    );
  };

  const PriorityFilter = () => {
    return (
      <Select
        defaultValue={filters.priority || "all"}
        onSelect={v => updateAndApply({ priority: v !== "all" ? v : null })}
        placeholder="Ordenar por"
        className="linear-filter-priority linear-filter"
        dropdownMatchSelectWidth={225}
      >
        <Option key={1} value={"all"}>
          Todas las Prioridades
        </Option>
        <Option key={1} value={"1"}>
          Prioridad alta
        </Option>
        <Option key={2} value={"2"}>
          Prioridad media
        </Option>
        <Option key={3} value={"3"}>
          Prioridad baja
        </Option>
      </Select>
    );
  };

  const OnlyFilter = () => {
    return (
      <Select
        className="linear-filter"
        defaultValue={filters.only || "all"}
        onSelect={v => updateAndApply({ only: v === "all" ? null : v })}
        placeholder="Ordenar por"
        dropdownMatchSelectWidth={225}
      >
        <Option key={1} value={"all"}>
          Todo
        </Option>
        <Option key={2} value={"movement"}>
          Movimientos
        </Option>
        <Option key={2} value={"check"}>
          Eventos
        </Option>
        {defaultFilters.type !== "agenda" && (
          <Option key={2} value={"appointment"}>
            Turnos
          </Option>
        )}
      </Select>
    );
  };

  const PublicOwnerFilter = () => {
    return (
      <Select
        className="linear-filter"
        defaultValue={filters.public ? "all" : "1"}
        onSelect={v => updateAndApply({ public: v === "all" })}
        placeholder="Ordenar por"
        dropdownMatchSelectWidth={225}
      >
        <Option key={1} value={"all"}>
          Todos los Responsables
        </Option>
        <Option key={2} value={"1"}>
          Solo lo mío
        </Option>
      </Select>
    );
  };

  const CreateEntryButton = ({ onClick, icon, title }) => {
    return (
      <Button
        className="linear-filter"
        style={{
          borderRadius: "15px",
          height: "32px",
          width: isTabletOrMobile ? "100%" : "fit-content"
        }}
        onClick={onClick}
        type={"primary"}
        icon={icon}
      >
        {title}
      </Button>
    );
  };

  const StatusFilter = () => {
    return (
      <Select
        className="linear-status-filter linear-filter"
        defaultValue={filters.realizado || "Todo"}
        onSelect={v => updateAndApply({ realizado: "Todo" === v ? null : v })}
        placeholder="Ordenar por"
        dropdownMatchSelectWidth={225}
      >
        <Option key={4} value={"Todo"}>
          Todos los Estados
        </Option>
        <Option key={5} value="false">
          Pendientes
        </Option>
        <Option key={6} value="true">
          Hechos
        </Option>
      </Select>
    );
  };

  const OrderByFilter = () => {
    return (
      <Select
        defaultValue={selectedFilter}
        onSelect={onFilterChange}
        className="linear-filter-hour linear-filter"
        placeholder="Ordenar por"
        dropdownMatchSelectWidth={275}
      >
        <Option key={1} value="court">
          Ordenar por Juzgado
        </Option>
        <Option key={4} value="hour">
          Ordenar por Hora
        </Option>
        <Option key={5} value="court_then_hour_asc">
          ▲ Ordenar por Juzgado/Hora
        </Option>
        <Option key={6} value="court_then_hour_desc">
          ▼ Ordenar por Juzgado/Hora
        </Option>
      </Select>
    );
  };

  const apply = (filter, o) => {
    switch (filter || selectedFilter) {
      case "court":
        courtFilter(o || order);
        break;
      case "hour":
        timeSorter(o || order);
        break;
      case "court_then_hour_asc":
        courtThenHourFilter(o || order, "asc");
        break;
      case "court_then_hour_desc":
        courtThenHourFilter(o || order, "desc");
        break;
      default:
        break;
    }
  };

  const onFilterChange = filter => {
    selectFilter(filter);
    apply(filter, null);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        padding: "0px 3px",
        width: "100%"
      }}
    >
      <CreateStudioTaskModal
        show_custom_title
        schedule={scheduleCheck}
        onCreated={() => {
          onCreatedTask();
          setCreateCheckModal(false);
        }}
        task={undefined}
        visible={showCreateCheckModal}
        onCancel={() => setCreateCheckModal(false)}
      />
      <CreateFeeModal
        withoutExpedient={true}
        visible={showCreateFeeModal}
        onCancel={() => {
          setShowCreateFeeModal(false)
        }}
        onNewFee={(resp) => {
          setShowCreateFeeModal(false);
          onCreatedTask(resp);
        }}
        onUpdated={(resp) => {
          setShowCreateFeeModal(false);
        }}
        expedient={null}
        fee={null}
      />
      <AppointmentModal
        visible={showAppointmentModal}
        onCancel={() => setShowAppointmentModal(false)}
        isOutOfShift={false}
        onDone={() => {
          setShowAppointmentModal(false);
          onCreatedTask();
        }}
      />
      <div className="filters-container">
        {availableFilters.includes("public-mine") && <PublicOwnerFilter />}
        {availableFilters.includes("court-hour-filter") && (
          <OrderByFilter
            orderBy={selectedFilter}
            onFilterChange={onFilterChange}
          />
        )}
        {availableFilters.includes("asc-desc") && (
          <Select
            className="linear-filter"
            defaultValue={"asc"}
            dropdownMatchSelectWidth={225}
            onSelect={v => {
              setOrder(v);
              apply(null, v);
            }}
          >
            <Option value="desc">Descendente ▼</Option>
            <Option value="asc">Ascendente ▲</Option>
          </Select>
        )}
        {availableFilters.includes("status-filter") && <StatusFilter />}
        {availableFilters.includes("only-filter") && <OnlyFilter />}
        {availableFilters.includes("priority-filter") && <PriorityFilter />}
        {availableFilters.includes("court-filter") && (
          <CourtFilterSelect
            dateRange={courtsDateRange}
            filters={filters}
            defaultValue={"all"}
            onChange={v => filterByCourt(v)}
          />
        )}
        {showMoreFiltersButton && (
          <Tooltip title={showExtraFilters ? "Menos filtros" : "Más filtros"}>
            <Button
              icon={<FilterAltIcon fill="#35B6BC" />}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1ch",
                borderRadius: "40%"
              }}
              onClick={() => {
                setShowExtraFilters(!showExtraFilters);
              }}
            ></Button>
          </Tooltip>
        )}
        {availableFilters.includes("create-task") && (
          <CreateEntryButton
            onClick={() => {
              setScheduleCheck(false);
              setCreateCheckModal(!showCreateCheckModal);
            }}
            title={"+ Tarea"}
          />
        )}
        {availableFilters.includes("create-appointment") && (
          <CreateEntryButton
            icon={<TaskListIcon />}
            onClick={() => setShowAppointmentModal(true)}
            title={"Agendar cita"}
          />
        )}
        {availableFilters.includes("create-fee") && (
          <CreateEntryButton
            onClick={() => {
              setShowCreateFeeModal(true);
            }}
            title={"+ Honorario"}
          />
        )}
        {availableFilters.includes("agendar-multiple") && <AgendarButton />}
      </div>
      <CollapsablePanel open={showExtraFilters} vertical={true} height={"36px"}>
        <div className="filters-container" style={{ height: "36px" }}>
          {collapsableFilters.includes("public-mine") && <PublicOwnerFilter />}
          {collapsableFilters.includes("court-hour-filter") && (
            <OrderByFilter
              orderBy={selectedFilter}
              onFilterChange={onFilterChange}
            />
          )}
          {collapsableFilters.includes("asc-desc") && (
            <Select
              className="linear-filter"
              defaultValue={"asc"}
              dropdownMatchSelectWidth={225}
              onSelect={v => {
                setOrder(v);
                apply(null, v);
              }}
            >
              <Option value="desc">Descendente ▼</Option>
              <Option value="asc">Ascendente ▲</Option>
            </Select>
          )}
          {collapsableFilters.includes("status-filter") && <StatusFilter />}
          {collapsableFilters.includes("only-filter") && <OnlyFilter />}
          {collapsableFilters.includes("priority-filter") && <PriorityFilter />}
          {collapsableFilters.includes("court-filter") && (
            <CourtFilterSelect
              dateRange={courtsDateRange}
              filters={filters}
              defaultValue={"all"}
              onChange={v => filterByCourt(v)}
            />
          )}
          {collapsableFilters.includes("create-task") && (
            <CreateEntryButton
              onClick={() => {
                setScheduleCheck(false);
                setCreateCheckModal(!showCreateCheckModal);
              }}
              title={"+ Tarea"}
            />
          )}
          {collapsableFilters.includes("create-fee") && (
            <CreateEntryButton
              onClick={() => {
                setShowCreateFeeModal(true);
              }}
              title={"+ Tarea"}
            />
          )}
          {collapsableFilters.includes("create-appointment") && (
            <CreateEntryButton
              icon={<TaskListIcon />}
              onClick={() => setShowAppointmentModal(true)}
              title={"Agendar cita"}
            />
          )}
          {collapsableFilters.includes("agendar-multiple") && <AgendarButton />}
        </div>
      </CollapsablePanel>
    </div>
  );
};

export default MovementFilters;

MovementFilters.defaultProps = {
  hide: []
};
