import React from "react";
import { Switch } from "antd";
import axios, { authHeaders } from "../../../util/Api";
import CustomSwtichComponent from "../../PrebuiltFormComponents/CustomSwitchComponent";
import { useMediaQuery } from "react-responsive";

export default function ToggleMonthlyReport({ user }) {

  const [isEnabled, setIsEnabled] = React.useState(user["monthly_report"]);
  const [loading, setLoading] = React.useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const handle = () => {
    setLoading(true);
    toggleReport(user.id)
      .then(res => {
        setIsEnabled(res["monthly_report"]);
      })
      .catch(err => { })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="profile-toggle-container" style={isTabletOrMobile ? { width: "100%" } : {}} >
      Recibir resumen mensual
      <CustomSwtichComponent
        loading={loading}
        checkedChildren="Activar"
        unCheckedChildren="Desactivar"
        value={isEnabled}
        onSelect={() => handle()} />
    </div>
  );
}

async function toggleReport(user_id) {
  const resp = await axios.get(`user/toggle/monthly_report/${user_id}`, {
    headers: authHeaders()
  });
  return resp.data;
}
