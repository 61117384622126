import { Button } from 'antd'
import React from 'react'

export default function AltaButton({ extra, fixed = true }) {

  const goToCheckout = () => {
    window.location.href = 'https://www.liquidacionesjuridicas.com/wp-content/themes/businex/scripts/boton_suscribite_trial.php'
  }

  const style = fixed ? {
    position: "fixed",
    left: '20px',
    bottom: '17px',
  } : {
  };

  return <Button
    size={fixed ? 'large' : 'small'}
    type='primary'
    onClick={() => goToCheckout()}
    style={style}
  >Suscribite ahora! </Button >
}