import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import Balance from './Balance';
import LinearFilters from './LinearFilters';
import Client from './Client';
import rtfView from "./rtfView";
import designModeReducer from "./DesignMode";
import Lang from "./Lang";
import MovementsLocalFilters from "./MovementsLocalFilters";
import Fees from "./Fees";

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  lang: Lang,
  commonData: Common,
  balance: Balance,
  linearFilters: LinearFilters,
  client: Client,
  rtfView: rtfView,
  designMode: designModeReducer,
  movementlocalFilters: MovementsLocalFilters,
  fees: Fees
});

export default reducers;
