import { Typography, message, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import StudioTaskService from '../../../services/studio_task.service';
import { CustomSwitch } from '../../CustomTabs/CustomTabs';
import ExpandCollpaseText from '../../ExpandCollapseText';
import TagList from '../../TagList';
import DeleteMovementConfirm from '../components/DeleteMovementConfirm';
import PostponeConfirm from '../components/PostponeConfirm';
import { ColorBorder } from './MovementItem';
import { ReactComponent as EditIcon } from "../../../assets/assets2_0/edit.svg";
import ExpedientResponsibles from '../../ExpedientResponsibles';

const StudioTaskItem = ({ item, onEditTask, onUpdated, onPostponed, onDelete }) => {

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <div
      style={{
        width: "100%",
        border: "1px solid #CCCCCC",
        borderRadius: `${isTabletOrMobile ? "14px" : "4px"} 14px 14px 4px`,
        background: "#fff",
        position: "relative",
        margin: "1ch 0px 0.5ch 0px"
      }}
    >
      <ColorBorder
        position={isTabletOrMobile ? "bottom" : "top"}
        color={
          "#038FDE"
        }
      />
      <div className="movement-item-container">
        <div>
          <div
            style={{
              display: "flex",
              gap: "1ch",
              flexFlow: isTabletOrMobile ? "column" : "row"
            }}
          >
            <Typography.Text style={{ fontWeight: "bold", fontSize: 13 }}>
              {item.custom_title ? item.custom_title.title : item.taskable ? "AGENDA" : "TAREA"}
            </Typography.Text>
            <TaskPriority priority_number={Number(item.priority)} />
          </div>
          <div style={{ display: "flex", gap: "1ch" }}>
            <ExpandCollpaseText copyFromText={true} rows={2} text={item.observations} />
          </div>
        </div>
        <div className="movement-item-status-container">
          <ExpedientResponsibles
            withLabel
            justifyContent='start'
            exp_responsibles={item.responsible}
          />
          <ToggleStudioTaskStatus
            size="small"
            onUpdated={onUpdated}
            task={item}
          />
          <div className="movement-item-status-subcontainer">
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.6ch" }}
            >
              <Typography.Text> <span>🗓</span> {moment(item.date).format("DD/MM/YYYY")} </Typography.Text>
              <Typography.Text> <span>🕒</span> {moment(item.date).format("hh:mm A")} </Typography.Text>
            </div>
          </div>
        </div>
        <TagList data={item.tags} />
      </div>
      <div className="movement-actions-container">
        <div style={{ display: "flex", gap: "2ch", alignItems: "center" }}>
          <Tooltip trigger="hover" title="Editar check">
            <EditIcon
              width={"15.8"}
              style={{ cursor: "pointer" }}
              color = "#016ECE"
              onClick={() => onEditTask(item)}
            />
          </Tooltip>
          <PostponeConfirm
            postponeFunc={StudioTaskService.postponeStudioTask}
            itemType={"check"}
            item={item}
            isTabletOrMobile={isTabletOrMobile}
            onPostponed={(task) => onPostponed(task)}
          />
          <DeleteMovementConfirm
            itemType={"check"}
            deleteFunc={StudioTaskService.deleteStudioTask}
            isTabletOrMobile={isTabletOrMobile}
            item={item}
            onDelete={(task) => onDelete(task)}
          />
        </div>
      </div>
    </div>
  );
};

export default StudioTaskItem;

export const TaskPriority = ({ priority_number }) => {

  let priotity = {
    title: '',
    color: 'green'
  };

  if (priority_number === 1) {
    priotity = {
      title: 'ALTA',
      color: 'red'
    };
  } else if (priority_number === 2) {
    priotity = {
      title: 'MEDIA',
      color: 'gold'
    };
  } else {
    priotity = {
      title: 'BAJA',
      color: 'green'
    };
  }

  return (
    <Tag color={priotity.color} style={{ width: 'fit-content' }} >
      Prioridad: {priotity.title}
    </Tag>
  )
}

export const ToggleStudioTaskStatus = ({ onUpdated, task, disabled, short }) => {
  const [changingStatus, setChangeStatus] = useState(false);

  async function toggleStatus(id) {
    const status = await StudioTaskService.toggleStatus(id);
    return status.data;
  }

  if (short) {
    return <CustomSwitch
      mode="toggle"
      options={[
        {
          name: "PENDIENTE",
          color: "#FF5879"
        },
        {
          name: "HECHO",
          color: "#24335D"
        }
      ]}
      onChange={async () => {
        setChangeStatus(true);
        const { realizado } = await toggleStatus(task.id);
        setChangeStatus(false);
        onUpdated({ ...task, realizado });
        message.success("Se ha actualizado el estado de la tarea.");
      }}
    />
  }

  return disabled ? null : (
    <CustomSwitch
      mode="toggle"
      defaultValue={task.realizado ? 1 : 0}
      options={[
        {
          name: "PENDIENTE",
          color: "#FF5879"
        },
        {
          name: "HECHO",
          color: "#24335D"
        }
      ]}
      onChange={async () => {
        setChangeStatus(true);
        const { realizado } = await toggleStatus(task.id);
        setChangeStatus(false);
        onUpdated({ ...task, realizado });
        message.success("Se ha actualizado el estado de la tarea.");
      }}
    />
  );
};