import React, { useState } from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DeleteOutlined } from '@ant-design/icons';
import { Upload, message, Button } from 'antd';
import { authHeader, authHeaders, baseURL } from '../../../util/Api';
import ProfileService from '../../../services/profile.service';
import { ReactComponent as DeleteIcon } from "../../../assets/assets2_0/delete.svg";

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Solo puedes subir archivos de tipo JPG/PNG!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('La imagen debe tener menos de 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

class ImageUploader extends React.Component {
  state = {
    loading: false,
    currentImage: this.props.currentImage
  };

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      message.success("La imagen se almaceno satisfactoriamente.");
      getBase64(info.file.originFileObj, imageUrl => {
        this.props.updated && this.props.updated(imageUrl);
        this.setState({
          imageUrl,
          loading: false,
        })
      }
      );
    }
    if (info.file.status === 'error') {
      message.error("Ocurrio un error al tratar de guardar la imagen.");
    }
  };

  render() {
    const { currentImage } = this.state;
    const uploadButton = currentImage ? <img className="uploader-preview-page" src={`${baseURL}/profile/${this.props.name === 'avatar' ? 'photo' : 'sign'}/${this.props.user.id}`} alt={this.props.name} /> : (<div>
      <LegacyIcon style={{ fontSize: "2em" }} type={this.state.loading ? 'loading' : 'cloud-upload'} />
      <div className="ant-upload-text">Cargar</div>
    </div>);
    const { imageUrl } = this.state;
    return (
      <div 
      className="profile-image-uploader">
        <Upload
          style={{ width: "125%", backgroundColor: "#bde0fd" }}
          name={this.props.name}
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action={this.props.action || null}
          headers={{ ...authHeaders(), User: this.props.user.id }}
          beforeUpload={beforeUpload}
          onChange={this.handleChange}
        >
          {imageUrl ?
            <img className="uploader-preview-page" src={imageUrl} alt={this.props.name} /> :
            uploadButton}
        </Upload>
        <ImageDeleter currentImage={currentImage} name={this.props.name} onDeleted={() => this.setState({ currentImage: null })} />
      </div>
    );
  }
}

const ImageDeleter = ({ currentImage, name, onDeleted }) => {
  const [loading, setLoading] = useState(false);
  return currentImage ?
    <DeleteIcon
      width={"22"}
      height={"22"}
      style={{ cursor: "pointer" }}
      color="#FF5879"
      onClick={() => {
        setLoading(true)
        if (name === 'avatar') {
          ProfileService.deletePhotoProfile()
            .then(() => onDeleted())
            .finally(() => setLoading(false));
        } else {
          ProfileService.deleteSign()
            .then(() => onDeleted())
            .finally(() => setLoading(false));

        }
      }} /> : null;

}

export default ImageUploader;