import { TOGGLE_SHOW_EXPORT_EXPEDIENT, UPDATE_MOVEMENTS_LOCAL_FILTER, UPDATE_MOVEMENTS_LOCAL_SEARCH } from "../../constants/ActionTypes";

export const update_movements_filter = (filtersState) => {
    return {
        type: UPDATE_MOVEMENTS_LOCAL_FILTER,
        payload: filtersState
    }
}

export const update_movements_search = (search) => {
    return {
        type: UPDATE_MOVEMENTS_LOCAL_SEARCH,
        payload: search
    }
};

export const toggle_show_export_expedient = (show) => {
    return {
        type: TOGGLE_SHOW_EXPORT_EXPEDIENT,
        payload: show
    }
}