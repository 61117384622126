import { Button, Col, Form, Input, Popconfirm, Row, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import axios, { baseURL } from "../../util/Api";
import { PdfViewer } from "./PdfViewer";
import SignatureCanvas from "react-signature-canvas";
import useGeolocation from "@rooks/use-geolocation";
import { withRouter } from "react-router-dom";

import "./pdf.css";
import CenteredLoading from "../../law-components/CenteredLoading";
import { CheckCircleOutlined } from "@ant-design/icons";
import logo from "../../assets/images/logo.svg";
import getViewport from "../../util/helpers";

function SignPageMobile({ history }) {
  const token = window.location.search.replace("?doc=", "");
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState(null);
  const geoLocation = useGeolocation();
  const [inputType, setInputType] = useState("manual");
  const [viewPort, setViewPort] = useState(getViewport());
  const [currentView, setCurrentView] = useState("document");
  const [signing, setSigning] = useState(false);
  const [signCanvas, setSignCanvas] = useState(null);
  let inputRef = useRef(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (token) {
      setLoading(true);
      axios
        .get("/docusign/signature-request/" + token)
        .then(res => {
          const alreadySigned = res.data.signed_at !== null;
          if (alreadySigned) {
            history.push(`/metasign/done?doc=${token}`);
          } else {
            setDocument(res.data);
          }
        })
        .catch(err => {})
        .finally(() => setLoading(false));
    }
  }, [token]);

  const sign = values => {
    setSigning(true);
    const signature =
      inputType === "manual"
        ? signCanvas.getTrimmedCanvas().toDataURL("image/png")
        : inputRef.current.state.value;
    let lat,
      lng = null;
    if (geoLocation && !geoLocation.isError) {
      lat = geoLocation.lat.toString();
      lng = geoLocation.lng.toString();
    }
    const data = {
      signature,
      lat,
      lng,
      token,
      inputType
    };
    axios
      .post("/docusign/sign", data)
      .then(res => {
        history.push(`/metasign/done?doc=${token}`);
      })
      .catch(err => {})
      .finally(() => setSigning(false));
  };
  if (loading) {
    return (
      <CenteredLoading tip={"Cargando documento..."} minHeight={"100vh"} />
    );
  } else if (currentView === "document") {
    return document ? (
      <>
        <Toolbar />
        <PdfViewer
          w={viewPort.w}
          url={`${baseURL}/docusign/download/${document.docusign.id}`}
        />
        <BottomNav onChange={v => setCurrentView(v)} />
      </>
    ) : (
      <CenteredLoading tip={"Cargando documento..."} minHeight={"100vh"} />
    );
  } else {
    return (
      <>
        <Toolbar />
        <div style={{ padding: "0px 5px", overflowX: "hidden" }}>
          {document && (
            <Form onFinish={sign} form={form}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      <Typography.Text
                        style={{ fontSize: 25, fontWeight: "300" }}
                      >
                        {document && document.docusign.name}
                      </Typography.Text>
                    </Col>
                    <Col span={24}>
                      <Typography.Text
                        style={{ fontSize: 15, fontWeight: "400" }}
                      >
                        Firma
                      </Typography.Text>
                    </Col>
                    <Col span={24}>
                      {inputType === "manual" ? (
                        <SignatureCanvas
                          ref={r => {
                            setSignCanvas(r);
                          }}
                          penColor="black"
                          dotSize={0.8}
                          backgroundColor="white"
                          canvasProps={{
                            width: viewPort.w - viewPort.w * 0.01,
                            height: viewPort.h / 3,
                            className: "sigCanvas"
                          }}
                        />
                      ) : (
                        <Input autoComplete="cc-csc"
                          autoFocus
                          ref={inputRef}
                          placeholder="Escriba su firma usando el teclado"
                        />
                      )}
                    </Col>
                    <Col
                      span={24}
                      style={{ justifyContent: "center", display: "flex" }}
                    >
                      <Button.Group>
                        <Button onClick={() => signCanvas.clear()}>
                          ❌ Limpiar
                        </Button>
                        <Button
                          onClick={() => setInputType("manual")}
                          type={inputType === "manual" ? "primary" : "default"}
                        >
                          ✍️ Manual
                        </Button>
                        <Button
                          onClick={() => setInputType("textfield")}
                          type={inputType !== "manual" ? "primary" : "default"}
                        >
                          ⌨️ Campo de texto
                        </Button>
                      </Button.Group>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        marginBottom: "10vh"
                      }}
                    >
                      <Popconfirm
                        title="Confirme si desea firmar el documento"
                        okText="Si"
                        cancelText="No"
                        onConfirm={() => form.submit()}
                      >
                        <Button
                          loading={signing}
                          size="large"
                          style={{ padding: "0px 15px" }}
                          icon={<CheckCircleOutlined />}
                          type="primary"
                        >
                          Firmar el documento
                        </Button>
                      </Popconfirm>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          )}
          <BottomNav onChange={v => setCurrentView(v)} />
        </div>
      </>
    );
  }
}

function Toolbar() {
  return (
    <div
      style={{
        alignContent: "center",
        alignItems: "center",
        padding: "0px 10px",
        overflowX: "hidden",
        backgroundColor: "#02194b",
        height: "5vh",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "5px"
      }}
    >
      <img src={logo} height="25px"></img>
      <div style={{ color: "#fff" }}>Metasign</div>
    </div>
  );
}

function BottomNav({ onChange }) {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "0px",
        width: "100%",
        alignItems: "center",
        height: "10vh",
        marginTop: "10vh",
        left: 0,
        display: "flex",
        justifyContent: "space-evenly",
        backgroundColor: "#02194b"
      }}
    >
      <Button
        onClick={() => onChange("document")}
        style={{ color: "#fff" }}
        type="text"
      >
        Documento
      </Button>
      <Button
        onClick={() => onChange("sign")}
        style={{ color: "#fff" }}
        type="text"
      >
        Firma
      </Button>
    </div>
  );
}

export default withRouter(SignPageMobile);
