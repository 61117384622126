import axios from "../util/Api";

const ProfileService = {
  deleteSign: () => {
    return axios.delete(`/profile/sign`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deletePhotoProfile: () => {
    return axios.delete(`/profile/photo`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getPhotoProfile: (profile) => {
    return axios.get(`/profile/photo/${profile}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  savePhotoProfile: (profile) => {
    return axios.post(`/profile`, profile, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateUsername: (profile) => {
    return axios.patch(`/profile/update-username`, profile, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateColor: (color, user) => {
    return axios.patch(`/profile/color`, {color, user}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }
};

export default ProfileService;
