import React from "react";
import { Select } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { baseURL } from "../../../util/Api";

export default function SubscriptionSelect({ value, onChange }) {

  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      const { data } = await axios.get(`${baseURL}/subscription/plans`)
      setPlans(data);
    }
    fetchPlans();
  }, []);

  return <Select className="responsive-input" value={value} onChange={onChange}>
    {plans.map(plan => <Select.Option value={plan.id}>{plan.name}</Select.Option>)}
  </Select>
}