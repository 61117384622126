import React, { useEffect, useState } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Empty, Select, Tooltip } from "antd";
import ClientService from "../../services/client.service";
import { connect } from "react-redux";
import { ReactComponent as AddButton } from "../../assets/assets2_0/add-button.svg";
import CreateClientModal from "../../modals/CreateClientModal";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";


const { Option } = Select;

const ClientSelect = ({
  getFieldDecorator,
  client,
  required,
  reload,
  autoFocus,
  currentLang,
  form,
  showLinkContactButton = true
}) => {
  const [loading, setLoading] = useState(false);
  const [showCreateClientModal, setShowCreateClientModal] = useState(false);
  const [data, setData] = useState(client ? [client] : []);
  const [observer, setObserver] = useState();

  const { text } = currentLang

  const [value, setValue] = useState(client ? client.id : null);

  const debouncedSearch = word => {
    clearTimeout(observer);
    if (word.trim() !== "" && word !== "" && word !== null && word !== undefined) {
      setObserver(
        setTimeout(
          () =>
            fetchClients(word).then(c => {
              if (c) {
                setData(c);
              }
            }),
          1500
        )
      );
    } else {
      setData([]);
    }
  };

  const fetchClients = async word => {
    setLoading(true);
    const data = await ClientService.searchClient({ search: word, per_page: 2000 });
    let _clients = data.data.data.map(c => ({
      ...c,
      fullname: `${c.firstname} ${c.lastname}`
    }));
    setLoading(false);
    return _clients;
  };

  return (
    <div className="custom-cliente-select-container">
      <CreateClientModal
        showLinkButton={showLinkContactButton}
        visible={showCreateClientModal}
        onNewClient={c => {
          let updated_data = [...data];
          updated_data.push({ ...c, fullname: `${c.firstname} ${c.lastname}` });
          setData(updated_data);
          form.setFieldsValue({ client_id: c.id })
          setShowCreateClientModal(false);
        }}
        onCancel={() => setShowCreateClientModal(false)}
      />
      <Form.Item key="client_id" label={currentLang.text.client} style={{ flexGrow: 5 }}>
        {getFieldDecorator("client_id", {
          rules: [
            {
              required: required,
              message: `Debe indicar el ${currentLang.text.client}`
            }
          ],
          initialValue: !loading ? value : null,
        })(
          <Select
            suffixIcon={loading ? <LoadingOutlined /> : <SearchOutlined />}
            placeholder="Buscar cliente por nombre"
            value={value}
            className="responsive-input"
            autoFocus={autoFocus}
            loading={loading}
            showSearch
            filterOption={false}
            onSearch={v => {
              debouncedSearch(v);
            }}
            onSelect={(v) => {
              setValue(v)
            }}
            notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Busque los contactos ya cargados por nombre, apellido y/o razón social" />}
          >
            {data.map(client => (
              <Option key={client.id} value={client.id}>
                {client.firstname} {client.lastname}
              </Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Tooltip trigger="hover" title="Crear cliente/contacto">
        <AddButton
          width="40"
          heigth="58"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setShowCreateClientModal(true)
          }}
        />
      </Tooltip>
    </div>
  );
};

const mapStateToProps = ({ lang }) => {
  const { currentLang } = lang
  return { currentLang }
}

export default connect(mapStateToProps)(ClientSelect)

ClientSelect.defaultProps = {
  required: true
};
