import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Table, Popover } from 'antd';
import React from 'react';
import ViewClient from '../../routes/ClientPage/ViewClient';

const CheckedClientsTable = ({ clients, onUpdate, currentLang }) => {


  const columns = [
    {
      width: 30,
      key: "details",
      dataIndex: "id",
      title: "Detalles",
      render: (f, record) => (
        <Popover
          zIndex={1060}
          title={`Información del ${currentLang.text.client}`}
          trigger="hover"
          content={
            <ViewClient
              currentLang={currentLang}
              fields={[
                "email",
                "name",
                "phone",
                "mobile_phone",
                "extra_phone",
                "main_address",
                "secondary_address",
                "tags",
                "observations",
                "identification",
                "sign",
                "plate",
                "job_title",
                "location",
                "birthdate"
              ]}
              client={record}
            />
          }
        >
          <InfoCircleOutlined />
        </Popover>)
    },
    {
      width: '25vw',
      key: "firstname",
      dataIndex: "firstname",
      title: "Nombre y Apellido",
      render: (f, record) => `${record.firstname || ""} ${record.lastname || ""}`
    },
    {
      key: "identification_type",
      dataIndex: "identification_type",
      title: "Identificación",
      render: (identification_type, record) =>
        `${identification_type ? identification_type.code : ""} ${record.identification_number ? record.identification_number : ""}`
    },
    {
      width: 30,
      align: "center",
      key: "actions",
      dataIndex: "id",
      title: "Acción",
      render: (id, record) => (
        <EditOutlined style={{ color: "#038fde", fontSize: "18px" }} type="primary" size='small' onClick={(e) => onUpdate(record)} />
      )
    }

  ]
  return (
    <Table
      style={{ overflowX: 'hidden', overflowY: 'overlay', maxHeight: "150px" }}
      pagination={{ hideOnSinglePage: true }}
      showHeader={false}
      columns={columns}
      dataSource={clients}
    />


  )
}

export default CheckedClientsTable;
