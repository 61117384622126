import React from "react";
import ReactDOM from "react-dom";
import NextApp from "./NextApp";
import { unregister } from "./registerServiceWorker";
// Add this import:
import { AppContainer } from "react-hot-loader";
import { ClearBrowserCacheBoundary } from "react-clear-browser-cache";
import ReactGA from "react-ga4";

// TEST
//trackingId: "G-L6ED0HGL15",
// PROD
// trackingId: "G-TKC9G1NY2F",
ReactGA.initialize([
  {
    trackingId: "G-TKC9G1NY2F",
  },
]);

// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <ClearBrowserCacheBoundary auto fallback="Actualizando metajuridico..." duration={3600000}>
      <AppContainer>
        <NextApp />
      </AppContainer>
    </ClearBrowserCacheBoundary>,
    document.getElementById("root")
  );
};

// Do this once
unregister();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept("./NextApp", () => {
    render(NextApp);
  });
}
