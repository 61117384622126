import { Result, Button } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import CenteredLoading from "../../law-components/CenteredLoading";
import { downloadFileFunc } from "../../law-components/ExpedientMovements";
import axios, { baseURL } from "../../util/Api";

function AlreadySigned({ history }) {
  const token = window.location.search.replace("?doc=", "");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(null);
  useEffect(() => {
    if (token) {
      setLoading(true);
      axios
        .get(`docusign/info-by-token/${token}`)
        .then(res => {
          setName(res.data.path);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return loading ? <CenteredLoading minHeight={"50vh"} tip="Cargando..." /> : (
    <Result
      status="success"
      title="El documento ha sido firmado exitosamente."
      extra={[
        <Button
          onClick={() => {
            if (token) {
              downloadFileFunc(
                `${baseURL}/docusign/download-signed-by-token/${token}`,
                name.substr(name.length - 44).substr(0, 20) + ".pdf"
              );
            }
          }}
          type="primary"
          key="console"
        >
          Descargar
        </Button>,
        <Button onClick={() => history.push("/")} key="buy">
          Salir
        </Button>
      ]}
    />
  );
}

export default withRouter(AlreadySigned);
