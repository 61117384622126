import React, { useRef, useState } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Row, Col, Divider } from "antd";
import CheckboxGroup from "antd/lib/checkbox/Group";
import { useMediaQuery } from "react-responsive";

export const SelectColumns = ({ handleChange, show, optionsDefault }) => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const renderLabel = (label) => {
    return (
      <>
        {label}
        <Divider type="vertical" style={{ borderLeft: "2px solid #d9d9d9", height: "2.2em", margin: "0px" }} />
      </>
    )
  }

  const options = [
    { label: "Caratula ", value: "front", divider: true },
    { label: "N° Carpeta ", value: "folder", divider: true },
    { label: "N° Expediente ", value: "expedient_number", divider: true },
    { label: "Responsable ", value: "responsible", divider: true },
    { label: "Juzgado ", value: "court", divider: true },
    { label: "F. Inicio ", value: "init_date", divider: true },
    { label: "F. Dda ", value: "demand_begin_date", divider: true },
    { label: "F. Insta Acción", value: "last_action_date", divider: true },
    { label: "Monto ", value: "claimed_amount", divider: true },
    { label: "Estado ", value: "status", divider: true },
    { label: "Acciones ", value: "actions", divider: true },
    { label: "Balance ", value: "balance", divider: true },
    { label: "Tags ", value: "tags", divider: true },
    { label: "Partes ", value: "parts", divider: false },
  ];

  const customStyle = {
    display: isTabletOrMobile ? "inline-flex" : "block",
    border: "1px solid #d9d9d9",
    borderRadius: "14px",
    padding: "12px",
    margin: "15px 0px",
  };

  return (
    <div style={{ display: show ? "block" : "none", overflowY: "hidden", height: isTabletOrMobile ? "80px" : "auto" }}>
      <Row type="flex" justify="start">
        <Col lg={24} xs={24} style={{
          overflowY: "hidden",
          overflowX: "overlay"
        }}>
          <Form.Item key="columns">
            <CheckboxGroup

              onChange={(columns) => handleChange && handleChange(columns)}
              className="redesign-checkbox-group"
              defaultValue={optionsDefault}
              options={options.map((option) => {
                return ({
                  label: option.divider ? renderLabel(option.label) : option.label,
                  value: option.value
                })
              })}
              style={customStyle}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
