import axios, { authHeaders } from "../util/Api";
import { getPercentage } from "./expedient_movement.service";

const RtfService = {
  rtfDetails: id => {
    return axios.get(`rtf/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createMovementFromDoc: (expedientId, docId, format) => {
    return axios.post('rtf/create-movement-from-doc', {
      expedientId,
      docId,
      format
    },
      {
        headers: { ...authHeaders() }
      })
  },
  getVars: () => {
    return axios.get("rtf-vars", {
      headers: { ...authHeaders() }
    });
  },
  getDynamicFields: (studio_id) => {
    return axios.get(`rtf-dynamic-fields/${studio_id}`, {
      headers: { ...authHeaders() }
    });
  },
  getLetterVars: (context = null) => {
    return axios.get(`letter-vars${context ? "-context-menu" : ""}`, {
      headers: { ...authHeaders() }
    });
  },
  getLetterTemplates: (letter_type = null) => {
    return axios.get(`letter-templates`, {
      params: {
        letter_type
      }, headers: { ...authHeaders() }
    });
  },
  rename: (id, name) => {
    return axios.patch(
      `/rtf-rename/${id}`,
      {
        name
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
  },
  renameGroup: (id, name) => {
    return axios.patch(
      `/rtf-group-rename/${id}`,
      {
        name
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
  },
  getDocs: () => {
    return axios.get(`/rtf`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getGroupedDocs: () => {
    return axios.get(`/rtf-and-groups`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  saveAsHtml: data => {
    return axios.post(`/save-editor-rtf`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateAsHtml: data => {
    return axios.post(`/update-editor-rtf`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  delete: rtf => {
    return axios.delete(`/rtf/${rtf}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteGroup: rtf_group => {
    return axios.delete(`/rtf-group/${rtf_group}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  visualize: (rtf, expedient) => {
    return axios.get(
      `/rtf-visualize/${rtf}${expedient ? `/${expedient}` : ""}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
  },
  RtfFillManually: (rtf, expedient) => {
    return axios.get(
      `/rtf/fill-manually/${rtf}${expedient ? `/${expedient}` : ""}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      }
    );
  },
  downloadTestPage: () => {
    return axios
      .get(`/print-test-page`, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then(
        resp =>
          new Blob([resp.data], {
            type: "application/pdf"
          })
      );
  },
  donwloadDocumentLetter: (data = {}) => {
    return axios
      .post(`/download-document-letter`, data, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then(
        resp =>
          new Blob([resp.data], {
            type: "application/pdf"
          })
      );
  },
  donwloadLaboralLetter: data => {
    return axios
      .post(`/download-laboral-letter`, data, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then(
        resp =>
          new Blob([resp.data], {
            type: "application/pdf"
          })
      );
  },
  downloadRtfAsPdf: (id, expedient_id) => {
    return axios
      .get(
        `/rtf-as-pdf-download/${id}${expedient_id ? `/${expedient_id}` : ""}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      )
      .then(
        resp =>
          new Blob([resp.data], {
            type: "application/pdf"
          })
      );
  },
  downloadfromEditor: async (expedient_id, data) => {
    return axios
      .post(
        `/download-from-editor${expedient_id ? `/${expedient_id}` : ""}`, data,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      )
      .then(
        resp =>
          new Blob([resp.data], {
            type: data.format === "rtf" ? "application/rtf" : data.format === "docx" ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document" : "application/pdf"
          })
      );
  }, fillManuallyAndSave: async (rtf_id, vars, expedient) => {
    return axios.
      post(
        `/rtf/save-filled/${rtf_id}${expedient ? `/${expedient.id}` : ""}`, vars, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
  }, fillManuallyAndDownload: async (rtf_id, vars, format, expedient) => {
    return axios.
      post(`/rtf/donwload-filled/${rtf_id}${expedient ? `/${expedient.id}` : ""}`, { ...vars, format },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
      .then(
        resp =>
          new Blob([resp.data], {
            type: format === "pdf" ? "application/pdf" : "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          })
      );
  }, getRtfTemplatesByStudio: studio_id => {
    return axios.get(`rtf-templates-by-studio/${studio_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getRtfTemplate: template_id => {
    return axios.get(`rtf-templates/${template_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getRtfGroupsByStudio: (studio_id) => {
    return axios.get(`/rtf-groups-by-studio/${studio_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createRtfGroup: ({ name, keys }) => {
    return axios.post("/rtf-group", { name, keys }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  attachItemsToRtfGroup: (groupId, keys) => {
    return axios.patch(`/attach-to-rtf-group/${groupId}`, {
      keys
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  ungroupRtf: (rtf_id) => {
    return axios.post(`/ungroup-rtf/${rtf_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  ungroupRtfGroup: (group_id) => {
    return axios.post(`/ungroup-rtf-group/${group_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },

};

export default RtfService;
