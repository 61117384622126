import { CheckCircleOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Progress, Upload } from 'antd';
import React, { useState } from 'react';

const { Dragger } = Upload;

const DragAndDropper = ({ onChange, onFileSelect, uploadPercentage, infoText, buttonText, files, multiple = true, style }) => {

  const [fileList, setFileList] = useState(files ? files : []);

  const _onChange = (fileList) => {
    let checkedFiles = [];
    if (multiple) {
      checkedFiles = fileList.fileList;
    } else {
      const removed = fileList.file.status === "removed";
      checkedFiles = removed ? [] : fileList.fileList.filter((file) => file.uid === fileList.file.uid);
    }
    setFileList(checkedFiles);
    onFileSelect && onFileSelect(checkedFiles);
  };

  return (
    <>
      <Dragger
        key="file"
        name="file"
        multiple={multiple}
        onChange={_onChange}
        fileList={fileList}
        beforeUpload={(file, fileList) => {
          return false;
        }}
        style={{ ...style }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{infoText || "Haga click o arrastre archivos aquí para adjuntar"}</p>
        {multiple && <p className="ant-upload-hint">
          Puede adjuntar uno o multiples archivos
        </p>}
        <Button type="primary" icon={<UploadOutlined />} style={{ margin: "25px 5px" }}>
          {buttonText || "Seleccionar"}
        </Button>

        {fileList.length > 0 ? uploadPercentage > 0 ?
          <p className="file-list-notification">Cargando archivos</p> :
          <p className="file-list-notification"><CheckCircleOutlined /> Archivos preparados</p> : null
        }

        {fileList.length > 0 && uploadPercentage > 0 &&
          <Progress strokeWidth={2} showInfo={false} status="active" strokeColor={"#02AD1E"} percent={uploadPercentage} type="line" style={{ margin: '10px', width: '90%' }} />
        }
      </Dragger>
    </>



  )
}

export default DragAndDropper;