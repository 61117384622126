import { message } from "antd";

export const defaultProps = {
    options: [
        "inline",
        "colorPicker"
    ],
    inline: {
        inDropdown: false,
        options: ['bold', 'italic', 'underline']
    },
    fontFamily: {
        options: [
            "Arial",
            "Arial Black",
            "DejaVu Sans",
            "Georgia",
            "Impact",
            "Limelight",
            "Noir Pro",
            "Times New Roman",
            "Ubuntu",
            "Verdana"
        ]
    },
    colorPicker: {
        className: undefined,
        component: undefined,
        popupClassName: "color-picker-styled-input",
        colors: ['rgb(255,255,255)', 'rgb(255,255,0)', 'rgb(0,255,0)', 'rgb(0,255,255)',
            'rgb(255,0,255)', 'rgb(0,0,255)', 'rgb(255,0,0)', 'rgb(0,0,139)', 'rgb(0,139,139)', 'rgb(0,100,0)', 'rgb(128,0,128)', 'rgb(139,0,0)',
            'rgb(169,169,169)', 'rgb(0,0,0)'],
    }

};