import React, { useEffect, useState } from 'react'
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {  Select, Button, Popconfirm, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import ExpedientMovementService from '../../services/expedient_movement.service';
import ExpedientService from '../../services/expedient.service';
import Authorization from '../../components/Authorization';

const MovementMassActions = ({ selectedRowsId, form , reload, showTrash }) => {
    
    const { Option } = Select;

    const { getFieldDecorator } = form;

    const [ data, setData ] = useState({});
    const [coResponsibles,setCoResponsibles] = useState(undefined);

    

    const [loading, setLoading] = useState(false);

    const getFilters = () => {

        setLoading(true);

        ExpedientService.getFilertsExpedient()
        .then( ({ data }) => setData(data))
        .finally(()=> setLoading(false));
        
    }

    useEffect(() => {
    
        getFilters();

    }, []);

    const onResponsibleSelect = value => {
            if ( coResponsibles == undefined)  {
                const filteredCoResponsibles = data.users.filter((coResponsible) => coResponsible.id != value );
                setCoResponsibles(filteredCoResponsibles);
            } else {
                const filteredCoResponsibles = coResponsibles.filter((coResponsible) => coResponsible.id != value );
                setCoResponsibles(filteredCoResponsibles);
            }
            

    }

    const movementStatus = [
        {
            'id': 1,
            'name': 'HECHO'
        },
        {
            'id': 0,
            'name': 'PENDIENTE'
        }
    ];

    const onResponsibleDeselect = value => {
        const toAdd = data.users.filter((user) => user.id == value);
        let filteredCoResponsibles = coResponsibles;
        filteredCoResponsibles.push(toAdd[0]);
        filteredCoResponsibles.sort(function(a,b){return a.id-b.id});
        setCoResponsibles(filteredCoResponsibles);
    }

    const handleSubmit = e => {

        e.preventDefault();

        form.validateFields((err, values) => {
            if (!err) {
                
                setLoading(true);
                
                values.selectedRowsId = selectedRowsId;
                values.delete = undefined;
                console.log(values);
                ExpedientMovementService.massActionsOfExpedientMovements(values)
                    .then( res => (res.status == 200) && reload())
                    .catch((err)=>console.log(err))
                    .finally( () => setLoading(false))

            }
        });

        // 
    }

    async function onDelete() {
        
        setLoading(true);
        const values = {};
        values.selectedRowsId = selectedRowsId;
        values.delete = true;
        
        console.log(values);
        const deleted = await ExpedientMovementService.massActionsOfExpedientMovements(values)
            .then( res => (res.status == 200) && reload())
            .catch((err)=>console.log(err))
            .finally( () => setLoading(false))

        message.info('Los movimientos seleccionados han sido eliminados');
      }

    

    return (
        <>
            <Form layout="inline" onSubmit={handleSubmit}>
                <Form.Item>
                    
                    {getFieldDecorator('responsibles',{})(

                        <Select
                            placeholder="Responsables"
                            style={{width: '20vw'}}
                            loading={loading}
                            mode="multiple"
                            onSelect={(selectedValue) => onResponsibleSelect(selectedValue)
                            }
                            onDeselect={(deselctedValue) => onResponsibleDeselect(deselctedValue)}
                        >
                            {data.users && data.users.map( (user) => (
                                <Option 
                                    key={ user.id } 
                                    value={ user.id }
                                >
                                    { `${user.firstname} ${user.lastname}` }
                                </Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item>
                    
                    {getFieldDecorator('co_responsibles',{})(

                        <Select
                            placeholder="Co-Responsables"
                            style={{width: '20vw'}}
                            loading={loading}
                            mode="multiple"
                            disabled={!coResponsibles || coResponsibles.length == data.users.length }
                        >
                            {coResponsibles && coResponsibles.map( (user) => (
                                <Option 
                                    key={ user.id } 
                                    value={ user.id }
                                >
                                    { `${user.firstname} ${user.lastname}` }
                                </Option>
                            ))}
                        </Select>
                    )}
                    
                </Form.Item>
                <Form.Item>
                    
                    {getFieldDecorator('realizado',{})(

                        <Select
                            placeholder="Estado de Movimiento"
                            style={{width: '20vw'}}
                            loading={loading}
                        >
                            {movementStatus && movementStatus.map( (status) => (
                            
                                <Option key={ status.id } value={ status.id } >{ status.name }</Option>
                            ))}
                        </Select>

                    )}

                </Form.Item>
                
                        <Authorization
                            permission="expedient.delete"
                            onRender={() => (
                                <Popconfirm 
                                    title="¿Seguro desea eliminar los elementos seleccionados?"   
                                    onConfirm={onDelete}>
                                    <Button 
                                        type={'danger'} 
                                        icon={<DeleteOutlined />}
                                        loading={loading}
                                        >
                                        Eliminar movimientos
                                    </Button>
                            </Popconfirm>)}
                        />
                    
                <Form.Item>
                
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Guardar
                    </Button>
                
                </Form.Item>
        </Form>
        </>
    )
}

const MovementMassActionsForm = Form.create()(MovementMassActions);
export default MovementMassActionsForm
