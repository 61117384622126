import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import { Select, Form } from "antd";
import GeographicService from "../../services/geographic.service";
const { Option } = Select;

const Antd4ProvinceSelect = ({
  country,
  value,
  multi,
  onChange,
  disabled
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [_value, setValue] = useState(value)

  useEffect(() => {
    if (country) {
      setLoading(true);
      GeographicService.getProvinces(country)
        .then(data => {
          setData(data.data.provinces);
          setLoading(false);
        })
        .catch(err => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [country]);

  return (
    <Select
    className="responsive-input"
      aria-autocomplete="none"
      disabled={(!country && !data) || disabled}
      defaultValue={_value}
      value={_value}
      optionFilterProp="children"
      showSearch
      allowClear
      onChange={v => {
        onChange(v);
        setValue(v);
      }}
      loading={loading}
      mode={multi ? "multiple" : "default"}
    >
      {data.map(province => (
        <Option key={province.id} value={province.id}>
          {province.name}
        </Option>
      ))}
    </Select>
  );
};

export default Antd4ProvinceSelect;
