import { Select } from 'antd';
import React from 'react';

const { Option } = Select;

const PeriodSelector = ({ value, onChange, form }) => {
    const options = [
        {
            value: "unique",
            label: "Único"
        },
        {
            value: "weekly",
            label: "Semanal"
        },
        {
            value: "biweekly",
            label: "Quincenal"
        },
        {
            value: "monthly",
            label: "Mensual"
        },
        {
            value: "bimonthly",
            label: "Bimestral"
        },
        {
            value: "quarterly",
            label: "Trimenstral"
        },
        {
            value: "biannual",
            label: "Semestral"
        }
    ];

    return (
        <Select
            key={"pay_every"}
            value={value}
            onSelect={(v) => {
                onChange && onChange(v)
            }}
            className="responsive-input"
        >
            {options.map((option) => {
                return (
                    <Option key={option.value} value={option.value}>
                        {option.label}
                    </Option>
                )
            })}
        </Select>
    )
}

export default PeriodSelector;