import { Tag } from 'antd'
import moment from 'moment'
import React from 'react'
import { MobileTableContainer, MobileTableRow } from '../../ClientPage/components/ClientList.mobile'
import { ExpenseTableActions } from './ExpensesTable'

export const ExpensesTableMobile = ({ expenses, onDeleted, onUpdated, deleteExpense }) => {
  return <>
    {expenses.map(expense => (<MobileTableContainer
      style={{
        width: "100%",
        border: "1.5px solid #CCCCCC",
        borderRadius: "14px",
        background: "#fff",
        position: "relative",
        margin: "1ch 0px 0.5ch 0px",
        display: "grid",
        gap: "0px 0px"
      }}>
      <MobileTableRow>
        <td style={{ width: "45%" }}><b>Tipo</b></td>
        <td>{expense.type === "IN" ? (
          <Tag color="green">Ingreso</Tag>
        ) : (
          <Tag color="red">Gasto</Tag>
        )}</td>
      </MobileTableRow>
      <MobileTableRow>
        <td style={{ width: "45%" }}><b>Concepto</b></td>
        <td>{expense.concept}</td>
      </MobileTableRow>
      <MobileTableRow>
        <td style={{ width: "45%" }}><b>Descripción</b></td>
        <td>{expense.description}</td>
      </MobileTableRow>
      <MobileTableRow>
        <td style={{ width: "45%" }}><b>Monto</b></td>
        <td>{expense.amount}</td>
      </MobileTableRow>
      <MobileTableRow>
        <td style={{ width: "45%" }}><b>Fecha</b></td>
        <td>{moment(expense.date).format('DD/MM/YYYY')}</td>
      </MobileTableRow>
      <MobileTableRow>
        <td style={{ width: "45%" }}><b>Acciones</b></td>
        <td><ExpenseTableActions
          onUpdated={onUpdated}
          deleteExpense={deleteExpense}
          id={expense.id}
          record={expense}
          onDeleted={onDeleted} /></td>
      </MobileTableRow>
    </MobileTableContainer>
    ))}
  </>
}