import React, { useState } from "react";
import MovementsFiltersContainer from "../../../unstatedStores/ExpedientMovementFilters";
import { Typography, message, Tooltip } from "antd";
import { ReactComponent as PdfButton } from "../../../assets/assets2_0/pdf-button.svg";
import { ReactComponent as ExcelButton } from "../../../assets/assets2_0/excel-button.svg";

const ExportMovementDashButtons = ({ exportFunc, exportFilename }) => {
  const movementsFiltersState = MovementsFiltersContainer.useContainer();
  let { movements, filters } = movementsFiltersState.filters;
  const [loading, setLoading] = useState(false);

  const [isExcelHovered, setIsExcelHovered] = useState(false);
  const [isPdfHovered, setIsPdfHovered] = useState(false);

  return loading ? (
    <Typography.Text>EXPORTANDO</Typography.Text>
  ) : (
    movements.length > 0 && (
      <div
        style={{
          display: "flex",
          gap: "1em",
          alignItems: "center",
          justifyContent: "flex-end",
          marginRight: "20px"
        }}
      >
        <Tooltip title="Exportar a Excel">
          <ExcelButton
            width={"36"}
            height={"36"}
            style={{
              zIndex: 120,
              cursor: "pointer",
              color: isExcelHovered ? "#35B6BC" : "#24335D"
            }}
            onMouseEnter={() => setIsExcelHovered(true)}
            onMouseLeave={() => setIsExcelHovered(false)}
            onClick={() => {
              if (movements && movements.length > 0) {
                setLoading(true);
                exportAs(
                  { ...filters, showAll: true, format: "xlsx" },
                  exportFilename,
                  exportFunc
                ).finally(() => {
                  setLoading(false);
                });
              }
            }}
          />
        </Tooltip>
        <Tooltip title="Exportar a PDF">
          <PdfButton
            width={"36"}
            height={"36"}
            style={{
              zIndex: 120,
              cursor: "pointer",
              color: isPdfHovered ? "#35B6BC" : "#24335D"
            }}
            onMouseEnter={() => setIsPdfHovered(true)}
            onMouseLeave={() => setIsPdfHovered(false)}
            onClick={() => {
              if (movements && movements.length > 0) {
                setLoading(true);
                exportAs(
                  { ...filters, showAll: true, format: "pdf" },
                  exportFilename,
                  exportFunc
                ).finally(() => {
                  setLoading(false);
                });
              }
            }}
          />
        </Tooltip>
        {/* <VideoTutorialButton tooltipPlacement={"left"} tutorial={"crear_expediente"} /> */}
      </div>
    )
  );
};

export default ExportMovementDashButtons;

const exportAs = (filters, exportFilename, exportFunc) => {
  return exportFunc(filters)
    .then(pdf => {
      let file_title = `${exportFilename}.${filters.format}`;
      const aEle = document.createElement("a");
      const href = window.URL.createObjectURL(pdf);
      aEle.href = href;
      aEle.download = file_title;
      document.body.appendChild(aEle);
      aEle.click();
      document.body.removeChild(aEle);
      window.URL.revokeObjectURL(href);
    })
    .catch(err => {
      message.error("Ha ocurrido un error al generar el reporte");
    })
    .finally(() => {});
};
