import axios from "../util/Api";

const AppointmentService = {
  getAppointmentById: (id, url = "appointment") => {
    return axios.get(`${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getAppointmentsByUser: (user, url = "appointment/user") => {
    return axios.get(`${url}/${user}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  busyHours: (userId, date) => {
    return axios.get(`/appointment/hours-availables/${userId}/${date}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getUserSettings: (userId) => {
    return axios.get(`/setting/by-user/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  saveUserSettings: (userId, payload) => {
    return axios.post(`/setting`, { ...payload, userId }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
  },
  storeAppointment: (payload, url = "appointment") => {
    return axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  checkAvailability: (payload, url = "appointment/check-availability") => {
    return axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  checkDateAvailability: (payload, url = "appointment/check-date-availability") => {
    return axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateAppointment: (payload, id, url = "/appointment") => {
    return axios.put(`${url}/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteAppointment: (id, url = "/appointment") => {
    return axios.delete(`${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  uploadAppointmentFile: (id, payload, url = "/file/upload-appointment-file") => {
    return axios.post(`${url}/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteFile: (id, url = "/file") => {
    return axios.delete(`${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createClient: (client, url = "/appointment/create-client") => {
    return axios.post(url, client, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  exportAsPdf: async (params) => {
    const resp = await axios.get('appointment/export-as-pdf', {
      params: params,
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return new Blob([resp.data], {
      type: "application/pdf"
    });
  }
};

export default AppointmentService;
