import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Checkbox, Input, message } from "antd";
import { Link, withRouter } from "react-router-dom";
import AuthService from "../services/auth.service";
import { userSignIn } from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import generateCustomCSSGradient from "../util/backgroundSplitByDiagonal";
import { ReactComponent as LawIcon } from "../../src/assets/assets2_0/law_icon.svg";
import { ReactComponent as UserCircleIcon } from "../assets/assets2_0/user_circle.svg";

const FormItem = Form.Item;

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      success: undefined,
      error: undefined,
      loading: false
    };
  }

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          success: undefined,
          error: undefined,
          loading: true
        });

        AuthService.resetPassword(values)
          .then(response => {
            if (response.status == 200) {
              message.success("Exito");

              setTimeout(() => {
                return this.redirectToSingIn();
              }, 2000);
            }
          })
          .catch(err => {
            debugger;
            const error = err.response.data || "Ha ocurrido un error";
            message.error(error);
          })
          .finally(() => {
            this.setState({
              loading: false
            });
          });
      }
    });
  };

  redirectToSingIn() {
    return this.props.history.push("/");
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("¡Las dos contraseñas que ingresas son inconsistentes!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["password_confirmation"], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    console.log(this.props.match);
    return (
      <div
        className="gx-app-login-wrap"
        style={{
          backgroundImage: generateCustomCSSGradient("#24335D", "#3D529E")
        }}
      >
        <LawIcon className="svg-signin-icon" />
        <div className="sign-in-logo-container">
          <a href="https://metajuridico.com">
            <img
              alt="example"
              width={"340px"}
              height={"100px"}
              src={require("assets/images/header-logo.svg")}
            />
          </a>
        </div>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
                <UserCircleIcon className="user-icon" />
              </div>
              <div className="gx-app-logo-wid">
                <h3>
                  <IntlMessages id="app.userAuth.resetPassword" />
                </h3>
                <p>
                  <IntlMessages id="appModule.enterPasswordReset" />
                </p>
              </div>
            </div>

            <div className="gx-app-login-content" style={{ width: "75%" }}>
              <Form
                onSubmit={this.handleSubmit}
                className="gx-signin-form gx-form-row0"
              >
                <FormItem>
                  {getFieldDecorator("email", {
                    initialValue: this.props.match.params.email,
                    rules: [
                      {
                        required: true,
                        type: "email",
                        message: "Ingrese un correo electrónico válido!"
                      }
                    ]
                  })(
                    <Input
                      disabled
                      autoComplete="cc-csc"
                      type="hidden"
                      placeholder="Email"
                      className="responsive-input"
                    />
                  )}
                </FormItem>

                <FormItem>
                  {getFieldDecorator("password", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Por favor ingrese su contraseña!"
                      },
                      {
                        min: 8,
                        message: "Su contraseña debe ser mayor a 8 caracteres."
                      },
                      {
                        validator: this.validateToNextPassword
                      }
                    ]
                  })(
                    <Input.Password
                      placeholder="Nueva contraseña"
                      className="responsive-input"
                    />
                  )}
                </FormItem>

                <FormItem>
                  {getFieldDecorator("password_confirmation", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Por favor ingrese su contraseña!"
                      },
                      {
                        min: 8,
                        message: "Su contraseña debe ser mayor a 8 caracteres."
                      },
                      {
                        validator: this.compareToFirstPassword
                      }
                    ]
                  })(
                    <Input.Password
                      placeholder="Confirmar nueva contraseña"
                      className="responsive-input"
                    />
                  )}
                </FormItem>

                <FormItem>
                  {getFieldDecorator("token", {
                    initialValue: this.props.match.params.token
                  })(<Input autoComplete="cc-csc" type="hidden" />)}
                </FormItem>

                <FormItem>
                  <Button
                    type="primary"
                    className="gx-mb-0"
                    htmlType="submit"
                    loading={this.state.loading}
                  >
                    <IntlMessages id="app.userAuth.reset" />
                  </Button>
                </FormItem>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalResetPasswordForm = Form.create()(ResetPassword);

export default withRouter(WrappedNormalResetPasswordForm);
