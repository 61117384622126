import { Button, message, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import contactsIcon from '../../assets/images/contacts_48dp.png';
import GoogleOAuthService from '../../services/google_oauth.service';

const {confirm} = Modal;

const SyncContacts = ({defaultVisible}) => {

    const [syncing, setSyncing] = useState(false);

    useEffect(() => {
        if(defaultVisible) {
            showConfirm();
        }
    }, [defaultVisible])

    const showConfirm = () => {
        confirm({
          icon:null,
          okText:"Si",
          cancelText: "No",          
          cancelButtonProps: {type:"danger"},
          title: <span><img width={24} src={contactsIcon} /> Sincronizar contactos</span>,
          content: '¿Quieres sincronizar los contactos del estudio con tu cuenta de google?',
          onOk() {
            setSyncing(true);
            GoogleOAuthService.syncContacts()
                .then(() => {
                    message.success("Contactos sincronizados");
                    setSyncing(false);
                })
                .catch(() => {
                    message.success("Ocurrio un error mientras se sincronizaban los contactos");
                    setSyncing(false);
                });
          },
          onCancel() {
            console.log('No');
          },
        });
      }

    return (
        <>
            <Button disabled={syncing} onClick={() => showConfirm()}><img src={contactsIcon} width={24} style={{marginRight: 5, bottom: 5}} /> Sincronizar contactos</Button>
        </>
    );
} 

export default SyncContacts;

SyncContacts.defaultProps = {
    defaultVisible: false
}