import axios from '../util/Api';

const StudioTaskService = {
  createStudioTask: (data) => {
    return axios.post('/studio-task', data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }, updateStudioTask: (data) => {
    return axios.post('/studio-task/update', data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getChecksAgendaByUser: (params, signal) => {
    return axios.get(`checks-agenda-by-user`, {
      params,
      signal,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getStudioTasksByDate: (params, signal) => {
    return axios.get(`/studio/tasks/filter-by`, {
      params,
      signal,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }, postponeStudioTask: params => {
    return axios.post("/studio-task/postpone", params, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }, deleteStudioTask: task_id => {
    return axios.delete(`/studio-task/${task_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  toggleStatus: id => {
    return axios.get(`/studio-task/toggle-status/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  exportAs: async (params) => {
    return axios
      .get("/export-csv-xls-checks", {
        params,
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
      .then(
        resp =>
          new Blob([resp.data], {
            type:
              params.format === "xlsx"
                ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                : "application/pdf"
          })
      );
  }
}

export default StudioTaskService;
