import React from 'react'
import useVersionUpdater from "../../hooks/useVersionUpdater";
import { WarningOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

export default function VersionUpdater() {
  const [askForUpdate, clearCacheAndUpdate] = useVersionUpdater()

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const onOk = () => {
    clearCacheAndUpdate()
  }

  return <>
    {askForUpdate && <div
      style={{
        padding: isTabletOrMobile ? "10px 0px" : "0px",
        fontSize: "14px",
        justifyContent: "center",
        minHeight: "40px",
        color: "#fff",
        position: "sticky",
        top: "0px",
        zIndex: 100,
        backgroundColor: "#fb9400",
        alignContent: "center",
        textAlign: "center",
        cursor: "pointer",
        color: "black"
      }}
      onClick={(e) => {
        onOk()
      }}
    >
      <WarningOutlined style={{ color: "black" }} /> {" "}
      <u>Hay una nueva versión disponible de este sitio. Actualízalo haciendo click aquí para mantener el correcto funcionamiento.</u>
    </div>}
  </>
}