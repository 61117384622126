import React, { useState } from 'react'
import { Select, Row, Col, Divider } from 'antd'
import { ReactComponent as FilterIcon } from "../../../assets/assets2_0/filter.svg";
import { ReactComponent as CloseIcon } from "../../../assets/assets2_0/close-icon.svg";

function AdditionalElementControl({
    handleChange,
    fieldsSelected
}) {

    const { Option } = Select;

    const additionalFilters = [
        {
            name: "Co-responsable (Movimientos)",
            value: "Co-responsable (Movimientos)",
            keyElement: "movement-co-responsible",
            keySwitch: "movement-co-responsible-switch",
        },
        {
            name: "Secretaria",
            value: "Secretaria",
            keyElement: "secretary",
            keySwitch: "secretary-switch",
        },
        {
            name: "Conceptos Contables",
            value: "Conceptos Contables",
            keyElement: "accounting-concepts",
            keySwitch: "accounting-concepts-switch",
        },
        {
            name: "Tipo de proceso",
            value: "Tipo de proceso",
            keyElement: "process-type",
            keySwitch: "process-type-switch",
        },
        {
            name: "Tags Expedientes",
            value: "Tags Expedientes",
            keyElement: "tags-expedients-additional",
            keySwitch: "tags-expedients-additional-switch",
        },
        {
            name: "Tags Clientes",
            value: "Tags Clientes",
            keyElement: "tags-clients",
            keySwitch: "tags-clients-switch",
        },
        /*{
            name:"Movimiento Realizado",
            value:"Movimiento Realizado",
            keyElement:"realizado",
            keySwitch:"realizado-switch",
        },*/
        {
            name: "Caratula",
            value: "Caratula",
            keyElement: "front",
            keySwitch: "front-switch",
        },
        {
            name: "Dias sin instar a la acción",
            value: "Dias sin instar a la acción",
            keyElement: "last-activity",
            keySwitch: "last-activity-switch",
        }, {
            name: "Dias sin movimientos",
            value: "Dias sin movimientos",
            keyElement: "last-movement",
            keySwitch: "last-movement-switch",
        },
        {
            name: "Judicial/Extrajudicial",
            value: "Judicial/Extrajudicial",
            keyElement: "extra_judicial",
            keySwitch: "extra_judicial-switch",
        },
        {
            name: "Partes",
            value: "Partes",
            keyElement: "parts",
            keySwitch: "parts-switch",
        },
        {
            name: "Contacto",
            value: "Contacto",
            keyElement: "client",
            keySwitch: "client-switch",
        }
    ];

    const [additionalFiltersState, setAdditionalFiltersState] = useState(additionalFilters.filter(({ value }) => {
        let result = fieldsSelected.find((el) => value == el.value);
        return value != (result && result.value);
    }));

    return (
        <>
            <Row type="flex" justify="start" style={{ margin: "10px 0px", justifyContent: "flex-end" }} >
                <Col lg={16} xs={24}>
                    <Select
                        showArrow
                        allowClear
                        clearIcon={<CloseIcon className="clickable-icon" with={15} height={15} />}
                        placeholder={
                            <div style={{ display: "flex", alignItems: "center", color: "#000000d9" }}>
                                <FilterIcon />
                                <Divider type="vertical" style={{ borderLeft: "2px solid #d9d9d9", height: "2.2em" }} />
                                Agregar Filtros
                            </div>
                        }
                        size="large"
                        className="responsive-input"
                        mode="multiple"
                        style={{ width: "100%", }}
                        onChange={(value, event) => {
                            handleChange(value)
                        }}
                    >
                        {
                            additionalFiltersState &&
                            additionalFiltersState.map((element) => (
                                <Option value={element.keyElement}>{element.name}</Option>
                            ))
                        }

                    </Select>
                </Col>
            </Row>
        </>
    )
}

export default AdditionalElementControl
