import { useState } from "react";
import { createContainer } from "unstated-next";
import GoogleGDisabled from "../assets/images/google_g_disabled.png";

const useGoogleIntegrationState = () => {
  const [googleState, setGoogleState] = useState({
    authorized: false,
    userInfo: { picture: GoogleGDisabled }
  });

  let set = state => {
    setGoogleState(state);
  };

  return { set, googleState };
};

const GoogleStateContainer = createContainer(useGoogleIntegrationState);
export default GoogleStateContainer;
