import React from "react";
import Icon from '@ant-design/icons';

const TaskListSVG = ({ color = "#ffffff" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill={color}
        width="15px"
        height="15px"
        viewBox="0 0 24 24"
    >
        <path d="M5,22h14c1.103,0,2-0.897,2-2V5c0-1.103-0.897-2-2-2h-2c0-0.553-0.447-1-1-1H8C7.447,2,7,2.447,7,3H5C3.897,3,3,3.897,3,5 v15C3,21.103,3.897,22,5,22z M5,5h2v2h10V5h2v15H5V5z" />
        <path d="M11 13.586L9.207 11.793 7.793 13.207 11 16.414 16.207 11.207 14.793 9.793z" />
    </svg>
);

export const TaskListIcon = props => {

    const { color } = props;

    return (
        <Icon component={() => TaskListSVG({ color: color })} {...props} style={{ fontSize: 18 }} />
    )
};