import React from 'react';
import { Button, Card, Form, Input } from "antd";
import Antd4CountrySelect from "../../law-components/PrebuiltFormComponents/Antd4CountrySelect";
import Antd4ProvinceSelect from "../../law-components/PrebuiltFormComponents/Antd4ProvinceSelect";
import { useCallback } from "react";
import SubscriptionSelect from './components/subscription-select';

export default function AltaMpForm({ onFinish }) {

  const [form] = Form.useForm();
  const country = Form.useWatch("country", form);
  const password = Form.useWatch("password", form);
  const confirm_password = Form.useWatch("confirm_password", form);

  const isPasswordValid = useCallback(() => {
    return password === confirm_password;
  }, [password, confirm_password]);

  const handleFinish = (values) => {
    onFinish(values);
  }

  return <Card className='gx-card' title={<h2>Alta de estudio</h2>}>
    <Form onFinish={handleFinish} form={form} layout='vertical' initialValues={{
      password: '',
      confirm_password: ''
    }}>
      <Form.Item name='name' label="Nombre del estudio" required>
        <Input className='responsive-input' />
      </Form.Item>
      <Form.Item name='representante' label="Representante (nombre y apellido)" required>
        <Input className='responsive-input' />
      </Form.Item>
      <Form.Item name='country' label="Pais" required>
        <Antd4CountrySelect />
      </Form.Item>
      <Form.Item name='province' label="Provincia" required>
        <Antd4ProvinceSelect country={country} />
      </Form.Item>
      <Form.Item name='email' label="Email" required>
        <Input type='email' className='responsive-input' />
      </Form.Item>
      <Form.Item name='password' label="Password" required>
        <Input.Password className='responsive-input' />
      </Form.Item>
      <Form.Item
        hasFeedback
        validateStatus={isPasswordValid() ? 'success' : 'error'}
        help={isPasswordValid() ? null : 'Las contraseñas no coinciden'}
        name='confirm_password'
        label="Password (confirmar)"
        required>
        <Input.Password className='responsive-input' />
      </Form.Item>
      <Form.Item name='plan_id' label="Subscripción" required>
        <SubscriptionSelect />
      </Form.Item>
      <Button htmlType='submit' type="primary">Completar</Button>
    </Form>
  </Card>
}