import React from "react";
import { Tag, List } from "antd";

const TagList = ({ data }) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "start",
        alignContent: "middle"
      }}
    >
      {data.map(tag => (
        <Tag color="volcano" key={tag.id}>
          {tag.name}
        </Tag>
      ))}
    </div>
  );
};

export default TagList;
