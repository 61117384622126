import axios from "../util/Api";

const InMailService = {
  countUnreaded: () => {
    return axios.get(`/in_mail/count-unreaded`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  delete: (id) => {
    return axios.patch(`/in_mail/delete/${id}`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  restore: (id) => {
    return axios.patch(`/in_mail/restore/${id}`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  send: (data) => {
    return axios.post(`/in_mail/send`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  inbox: (params) => {
    return axios.get(`/in_mail/inbox`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  outbox: (params) => {
    return axios.get(`/in_mail/outbox`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  trash: (params) => {
    return axios.get(`/in_mail/trash`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getMessage: (id) => {
    return axios.get(`/in_mail/get-message/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  read: (id) => {
    return axios.patch(`/in_mail/read/${id}`, null, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }
};

export default InMailService;
