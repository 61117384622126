import React, { useCallback, useContext, useEffect } from 'react'
import { Calendar, Typography } from 'antd'
import { TurnoSelectorContext, calculateMinuteSteps, turnoDefaultHours } from './TurnoSelectorContext'
import moment from 'moment'
import AppointmentService from '../../../services/appointment.service'

export default function TDateSelect() {

  const { date, busyHours, availableDays, user, duration, availableHours, isOverturn, setAvailableHours, setMaxDuration, setAvailableDays, setDate, setSteps, setLoadingHours, setBusyHours } = useContext(TurnoSelectorContext)
  const defaultDays = [0, 1, 2, 3, 4, 5, 6]

  const handleSelect = (d) => {
    setDate(d)
  }

  useEffect(() => {
    const fetchHoursAvailables = async () => {
      setLoadingHours(true)
      const user_settings = await AppointmentService.getUserSettings(user)
      const _availableHours = !isOverturn ? (user_settings.data.available_hours || [0, 23]) : [0, 23];
      const _availableDays = user_settings.data.available_days || defaultDays;
      const _maxDuration = user_settings.data.max_duration_of_appointments;

      setMaxDuration(_maxDuration)
      setAvailableDays(_availableDays)
      setAvailableHours(_availableHours)

      const busyHours = await AppointmentService.busyHours(user, date.format("YYYY-MM-DD"))
      /* debugger */
      const _steps = calculateMinuteSteps(duration, isOverturn ? turnoDefaultHours : _availableHours);
      let _availableSteps = hideBusySteps(busyHours.data, _steps)

      setBusyHours(busyHours.data)
      setSteps(_availableSteps)
      setLoadingHours(false)
    }
    if (date && user) {
      fetchHoursAvailables()
    }
  }, [date, user])

  useEffect(() => {
    if (busyHours) {
      const s = calculateMinuteSteps(duration, isOverturn ? turnoDefaultHours : availableHours);
      const h = hideBusySteps(busyHours, s)
      setSteps(h)

    }
  }, [isOverturn])

  const disabledDate = useCallback((d) => {
    if (isOverturn) return false
    if (availableDays.length === 0) return false
    let dow = d.day()
    if (dow === 0) dow = 6
    else dow = dow - 1
    return !availableDays.includes(dow)
  }, [availableDays, isOverturn])

  return <div className='t-calendar'>
    <Typography.Text>Selecciona la fecha</Typography.Text>
    <Calendar disabledDate={d => disabledDate(d)} value={date || moment()} onSelect={handleSelect} fullscreen={false} />
  </div>

}

export function hideBusySteps(busySteps, steps) {
  /* debugger */
  const _busySteps = busySteps.map(s => {
    const startingHour = s.starting_time.split(':')[0]
    const endingHour = s.ending_time.split(':')[0]
    const startingMinute = s.starting_time.split(':')[1]
    const endingMinute = s.ending_time.split(':')[1]
    return [moment().set({ hour: startingHour, minutes: startingMinute }), moment({ hour: endingHour, minutes: endingMinute })]
  })
  const defaultSteps = steps.map(s => {
    const h = s.split(':')
    return [moment().set({ hour: h[0], minutes: h[1] })]
  })
  const availableSteps = defaultSteps.filter(defaultStep => {
    return !_busySteps.some(b => {
      return defaultStep[0].isBetween(b[0], b[1], undefined, '[]')
    })
  })
  return availableSteps.map(s => s[0].format('HH:mm'))
}