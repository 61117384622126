import { SET_LANG } from "../../constants/ActionTypes";
import base from '../../assets/langs/base.json'
import defensoria from '../../assets/langs/defensoria.json'
import penal from '../../assets/langs/penal.json'

const initialLang = localStorage.getItem("LANG") || 'base'

const initialState = {
    currentLang: {
        lang: initialLang,
        text: getLangJson(initialLang)

    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_LANG:
            return {
                ...state,
                currentLang: {
                    lang: action.payload,
                    text: getLangJson(action.payload)
                },
            };
        default:
            return state;
    }
};

function getLangJson(lang) {
    switch (lang) {
        case 'base':
            return base
        case 'mpdm':
            return {...base, ...defensoria}
        case 'penal':
            return {...base, ...defensoria, ...penal}
        default:
            return base
    }
}