import React, { useState, useEffect } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from "antd";
import JudicialService from "../../services/judicial.service";
const { Option } = Select;

export const FueroSelect = ({
  getFieldDecorator,
  jurisdiction,
  fuero,
  onSelect,
  mode,
  required,
  disabled,
  style
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (jurisdiction) {
      setLoading(true);
      console.log(getFieldDecorator);
      JudicialService.getFueros(jurisdiction)
        .then(data => {
          setData(data.data);
          setLoading(false);
        })
        .catch(err => console.log(err));
    }
  }, [jurisdiction]);

  return mode === "form" ? (
    <Form.Item key="fuero" label="Fuero">
      {getFieldDecorator("fuero", {
        rules: [
          {
            required,
            message: "Debe indicar el fuero."
          }
        ],
        initialValue: !loading ? fuero || null : null
      })(
        <Select
          key={`${jurisdiction}-fuero`}
          className="responsive-input"
          optionFilterProp="children"
          showSearch
          disabled={disabled}
          allowClear
          style={{ width: "100%" }}
          loading={loading}
          onChange={v => onSelect(v)}
        >
          {data.map(_fuero => (
            <Option key={_fuero.id} value={_fuero.id}>
              {_fuero.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  ) : (
    <Select
      className="responsive-input"
      style={style}
      placeholder="Fuero"
      optionFilterProp="children"
      showSearch
      disabled={disabled}
      allowClear
      loading={loading}
      onChange={v => onSelect(v)}
    >
      {data.map(_fuero => (
        <Option key={_fuero.id} value={_fuero.id}>
          {_fuero.name}
        </Option>
      ))}
    </Select>
  );
};

FueroSelect.defaultProps = {
  mode: "form",
  onSelect: () => null,
  required: false,
  disabled: false,
  style: {}
};
