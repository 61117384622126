import axios, { authHeaders } from "../util/Api";

const UserService = {
  adminChangeStudio: (studio) => {
    return axios.post('user/change-studio', { studio }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createStudio: (studio, url = "/studio") => {
    return axios.post(url, studio, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateMevCredentials: (mev) => {
    return axios.post("/user/mev-data", mev, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteMevCredentials: () => {
    return axios.delete("/user/mev-data", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getUsers: (studio, trashed, profile, receiver, appointments) => {
    return axios.get(`/users-by-studio/${studio}`, {
      params: {
        trashed,
        profile,
        appointments,
        receiver //Para almacenar el ID del destinatario en caso de que pertenezca a otro estudio.
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }, getUsersByStudioSign: (studio, trashed, profile) => {
    return axios.get(`/users-by-sign/${studio}`, {
      params: {
        trashed,
        profile,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getLoginLogs: (userId) => {
    return axios.get(`/login-logs/${userId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getClients: () => {
    return axios.get("/studio/clients", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }, checkStoreUsers: () => {
    return axios.get("/check-store-users", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getFeatureUsageByUser: async (feature) => {
    const { data } = await axios.get(`/feature-usage/${feature}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return data;
  },
  getLawyers: () => {
    return axios.get("/user/lawyers", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  toggle2faAsAdmin: async (userId) => {
    const { data } = await axios.post("user/toggle-two-fa-as-admin", {
      userId
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    return data;
  },
  changeMyPassword: ({ old_password, new_password }) => {
    return axios.post(`/change-my-password`, {
      old_password,
      new_password
    }, {
      headers: { ...authHeaders() }
    });
  }
};

export default UserService;
