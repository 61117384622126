import { Button, Input, List, message, Popconfirm, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from "styled-components";
import ClientService from '../../../services/client.service';
import { ReactComponent as DeleteIcon } from "../../../assets/assets2_0/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/assets2_0/edit.svg";
import { ReactComponent as EyeIcon } from "../../../assets/assets2_0/eye.svg";
import { ReactComponent as ViewExpedientIcon } from '../../../assets/assets2_0/view-expedients.svg';
import { clientsFetched, clientDeleted, clientUpdated } from '../../../appRedux/actions/Client';
import { deleteClient } from '..';
import CallLog from '../../../law-components/CallLog';
import { PlusCircleOutlined } from '@ant-design/icons';
import ExcelButton from '../../../util/ExcelButton';
import PdfButton from '../../../util/PdfButton';
import StudioService from '../../../services/studio.service';
import { downloadBlob } from '../../ExpenseList';
import { history } from "../../../appRedux/store";
import { ReactComponent as CopyIcon } from '../../../assets/assets2_0/copy.svg';

const { Search } = Input;

const ClientListMobile = ({ fetchFunction, authUser, createClientModal, reload, showClient, editClientModal, associatedExpedients, clientsFetched, clients, clientDeleted, clientUpdated }) => {

  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [exportingXlsx, setExportingXlsx] = useState(false);
  const [exportingPdf, setExportingPdf] = useState(false);

  const [search, setSearch] = useState("");

  useEffect(() => {
    const params = {
      studio_id: authUser.studio_id
    };
    setLoading(true);
    fetchFunction(params)
      .then(datasource => {
        console.log({ data: datasource.data });
        clientsFetched(datasource.data.data)
        setPagination({
          current: datasource.data.current_page,
          pageSize: datasource.data.per_page,
          total: datasource.data.total,
          nextUrl: datasource.data.next_page_url,
          prevUrl: datasource.data.prev_page_url
        });
        setLoading(false);
      });
  }, [reload]);

  const onSearch = (w, studio = undefined) => {
    setLoading(true);
    let pg = pagination;
    if (w !== search) {
      pg = { ...pagination, current: 1 };
    }
    setSearch(w);
    const params = {
      studio_id: studio,
      page: pagination.current ? pg : 1,
      search: w
    };
    const fect_function = w && w !== "" ? ClientService.searchClient : fetchFunction;
    fect_function(params)
      .then(({ data }) => {
        console.log(data);
        clientsFetched(data.data);
        setPagination({
          current: data.current_page,
          pageSize: data.per_page,
          total: data.total,
          nextUrl: data.next_page_url,
          prevUrl: data.prev_page_url
        });
      })
      .catch(err => {
        message.error("Ha ocurrido un error");
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const handleListChange = (
    _pagination,
    filters = "",
    studio_id = authUser.studio_id
  ) => {
    setLoading(true);
    const pager = { ..._pagination };
    setPagination(pager);
    const params = {
      studio_id: studio_id,
      page: pager.current ? pager.current : 1,
      search: search,
      pageSize: _pagination.pageSize
    };
    fetchFunction(params).then(datasource => {
      setLoading(false);
      console.log(datasource)
      clientsFetched(datasource.data.data);
      setPagination({
        current: datasource.data.current_page,
        pageSize: datasource.data.per_page,
        total: datasource.data.total,
        nextUrl: datasource.data.next_page_url,
        prevUrl: datasource.data.prev_page_url
      });
    });
  };

  return (
    <>
      <HeaderDiv>Contactos</HeaderDiv>
      <ActionsContainer>
        <Search
          style={{ width: '85%' }}
          placeholder="Buscar Contacto"
          allowClear
          size="large"
          onSearch={(v) => onSearch(v, authUser.studio_id)}
        />
        <Button onClick={() => createClientModal()} icon={<PlusCircleOutlined />} type="primary" size="large" />
      </ActionsContainer>
      <div style={{ display: "flex", gap: "5px", width: "100%", justifyContent: "right", padding: "15px 15px 0px 0px" }}>
        <ExcelButton
          loading={exportingXlsx}
          width={"40px"}
          height={"40px"}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setExportingXlsx(true);
            StudioService.exportClientsAs({ pageSize: "no_limit", search: search === '' ? null : search }, "xlsx")
              .then(data => {
                downloadBlob(data, "listado_clientes.xlsx");
              })
              .catch(err => { })
              .finally(() => {
                setExportingXlsx(false);
              });
          }}
        />
        <PdfButton
          loading={exportingPdf}
          width={"40px"}
          height={"40px"}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setExportingPdf(true);
            StudioService.exportClientsAs({ pageSize: "no_limit", search: search === '' ? null : search }, "PDF")
              .then(data => {
                downloadBlob(data, "listado_clientes.pdf");
              })
              .catch(err => { })
              .finally(() => {
                setExportingPdf(false);
              });
          }}
        />
      </div>
      <List
        loading={loading}
        dataSource={clients}
        pagination={{
          ...pagination,
          style: { margin: "2px 0px" },
          showSizeChanger: true,
          position: "bottom",
          size: "small",
          onChange: (page) => handleListChange({ ...pagination, current: page })
        }}
        renderItem={(client) => {
          return (
            <List.Item style={{ padding: "15px" }}>
              <ClientItem
                client={client}
                clientUpdated={clientUpdated}
                clientDeleted={clientDeleted}
                showClient={showClient}
                editClientModal={editClientModal}
                associatedExpedients={associatedExpedients}
              />
            </List.Item>
          )
        }}
      />
    </>
  )
}

const ClientItem = ({ client, showClient, editClientModal, associatedExpedients, clientDeleted, clientUpdated }) => {
  return (
    <MobileTableContainer
      style={{
        width: "100%",
        border: "1.5px solid #CCCCCC",
        borderRadius: "14px",
        background: "#fff",
        position: "relative",
        margin: "1ch 0px 0.5ch 0px",
        display: "grid",
        gap: "0px 0px"
      }}>
      <MobileTableRow>
        <td style={{ width: "45%" }}><b>Nombre y Apellido</b></td>
        <td>{client.firstname} {client.lastname}</td>
      </MobileTableRow>
      <MobileTableRow>
        <td><b>Tags</b></td>
        <td>{client.tags.map(tag => <Tag color="#016ECE">{tag.name}</Tag>)}</td>
      </MobileTableRow>
      <MobileTableRow>
        <td><b>Identificación</b></td>
        <td>{`${client.identification_type ? client.identification_type.code : ""} ${ClientService.identification_number ? ClientService.identification_number : ""}`}</td>
      </MobileTableRow>
      <MobileTableRow>
        <td><b>Correo</b></td>
        <td>{client.email}</td>
      </MobileTableRow>
      <MobileTableRow>
        <td><b>Celular</b></td>
        <td>{client.mobile_phone}</td>
      </MobileTableRow>
      <MobileTableRow>
        <td><b>Llamadas</b></td>
        <td>
          <CallLog contact={client} asModal notify showExpedient size="middle" newCall={false} />
        </td>
      </MobileTableRow>
      <MobileTableRow style={{ borderBottom: 'none' }}>
        <td style={{ width: '30%' }}><b>Acciones</b></td>
        <td style={{ width: '70%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '1.55em' }}>

          <EditClientButton editClientModal={() => editClientModal(client)} />
          <ViewClientDetailsButton showClient={() => showClient(client)} />
          <RemoveClientButton id={client.id} onDeleted={data => clientDeleted(data)} />
          <CreateExpedientButton client={client} />
          <AssociatedExpButton associatedExpedients={() => associatedExpedients(client)} />



        </td>
      </MobileTableRow>
    </MobileTableContainer>)
}

const RemoveClientButton = ({ id, onDeleted }) => {
  return (
    <>
      <Popconfirm
        title="¿Eliminar registro?"
        onConfirm={() => deleteClient(id).then((data) => {
          onDeleted(data);
        })}
      >
        <DeleteIcon style={{ cursor: "pointer" }} color="#FF5879" />
      </Popconfirm>
    </>
  )
}

const ViewClientDetailsButton = ({ showClient }) => {
  return (
    <>
      <EyeIcon
        style={{ cursor: 'pointer' }}
        onClick={showClient}
        color="#28c5b5"
      />
    </>
  )
}

const EditClientButton = ({ editClientModal }) => {
  return (
    <EditIcon
      style={{ cursor: "pointer" }}
      onClick={editClientModal}
      color = "#016ECE"
    />
  )
}

const AssociatedExpButton = ({ associatedExpedients }) => {
  return (
    <ViewExpedientIcon
      style={{ cursor: "pointer" }}
      onClick={associatedExpedients}
      color = "#016ECE"
    />
  )
}

const CreateExpedientButton = ({ client }) => {
  return (
    <CopyIcon
      fill="#038FDE"
      style={{ cursor: 'pointer' }}
      onClick={() =>
        history.push("/expedient/add", { client: client })
      }
    />
  )
}

const HeaderDiv = styled.div`
  margin: 1.25em 0px;  
  width: 100%;
  font-weight: 800;
  text-align: center;
  font-size: 1.4em;
`;

const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 15px;
`;

export const MobileTableContainer = styled.table`
  border: 1px solid #CCCCCC;
  border-radius: 14px;
  background: #fff;
  margin: 5px;
`;

export const MobileTableRow = styled.tr`
  border-bottom: 1px solid #CCCCCC;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  &:first-child {
    font-weight: 500;
  }
`;

const mapStateToProps = ({ auth, client }) => {
  const { authUser } = auth;
  const { clientsFetched, clients, clientDeleted, clientUpdated } = client;
  return { authUser, clientsFetched, clients, clientDeleted, clientUpdated };
};

export default connect(mapStateToProps, {
  clientsFetched,
  clientDeleted,
  clientUpdated
})(ClientListMobile);