import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import { Badge, Button, Modal, Popconfirm, Table, Tooltip } from "antd";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import ExpedientDetailContainer from "../../unstatedStores/ExpedientDetailState";
import axios, { authHeader, baseURL } from "../../util/Api";
import { bytesFormatter } from "../../util/bytesFormatter";
import { deleteFile, DownloadFile } from "../ExpedientFiles/ExpedientFileList";

const RequestedFileList = ({ files }) => {

  const [visible, setVisible] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const expedientState = ExpedientDetailContainer.useContainer();
  return (
    <>
      <Modal
        footer={
          <>
            <DiscardFilesButton reqFileId={expedientState.scan.id} />
          </>
        }
        width={isTabletOrMobile ? "99vw" : "50vw"}
        title="Documentos recibidos"
        visible={visible}
        onCancel={() => setVisible(false)}
      >
        <FileList files={expedientState.scan.files} />
      </Modal>
      <Badge style={{zIndex: 3}} count={expedientState.scan.files.length}>
        <Button
          disabled={expedientState.scan.files.length === 0}
          onClick={() => setVisible(true)}
          className="contrast-button"
          icon={<InboxOutlined />}
        />
      </Badge>
    </>
  );
};

const DiscardFilesButton = ({reqFileId}) => {

  const [loading, setLoading] = useState(false);
  const expedientState = ExpedientDetailContainer.useContainer();

  return (
    <Popconfirm
      title={`¿Desea eliminar todos los archivos enviados por el cliente?`}
      onConfirm={() => {
        setLoading(true);
        discardAllFiles(reqFileId)
          .then(reqFile => {
            expedientState.updateScan(reqFile);
          })
          .catch(e => console.log(e))
          .finally(() => setLoading(false));
      }}
    >
      <Button loading={loading} icon={<DeleteOutlined />} className="delete-button">
        Descartar documentos
      </Button>
    </Popconfirm>
  );
};

const FileList = ({ files }) => {
  const expedientState = ExpedientDetailContainer.useContainer();

  const cols = [
    {
      dataIndex: "name",
      key: "name",
      title: "Nombre"
    },
    {
      dataIndex: "mimetype",
      key: "type",
      title: "Tipo"
    },
    {
      dataIndex: "size",
      key: "size",
      title: "Tamaño",
      render: size => bytesFormatter(size)
    },
    {
      key: "actions",
      dataIndex: "id",
      title: "Acciones",
      align: "center",
      render: (id, record) => ( 
        <>
          <DownloadFile file={record} />
          <Tooltip trigger="hover" title="Eliminar archivo">
            <Popconfirm
              title={`¿Seguro desea eliminar el archivo "${record.name}"?`}
              onConfirm={() => {
                deleteFile(record.id)
                  .then(resp => {
                    expedientState.deleteScanFile(record);
                  })
                  .catch(err => console.log(err));
              }}
            >
              <Button
                icon={<DeleteOutlined />}
                type="danger"
                size="small"
                shape="circle"
              />
            </Popconfirm>
          </Tooltip>
        </>
      )
    }
  ];
  return <Table className="gx-table-responsive" columns={cols} dataSource={files} />;
};

async function discardAllFiles(id) {
  const resp = await axios.delete(`${baseURL}/expedient-file-request/discard-files/${id}`, {
    headers: {
      ...authHeader
    }
  });
  return resp.data;
}

export default RequestedFileList;
