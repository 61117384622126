import React, { useEffect, useState } from "react";
import StudioService from "../../../services/studio.service";
import UserService from "../../../services/user.service";

export function useMevIntegration() {
    const [mev, setMev] = useState(null)
    const [loading, setLoading] = useState(true)

    const fetchMev = async () => {
        setLoading(true)
        const resp = await StudioService.getMevData()
        const hasMevCredentials = resp.data ? Boolean(resp.data.mev_user || resp.data.available_crendentials.length > 0 || resp.data.personalMev.length > 0) : false
        setMev({
            ...resp.data,
            hasMevCredentials
        })
        setLoading(false)
    }

    useEffect(() => {
        fetchMev()
    }, [])

    return { mev, loading, fetchMev }

}



export function useDeleteMevIntegration() {
    const deleteMevStudioCredentials = async () => {
        await StudioService.deleteMevData()
    }

    const deleteMevUserCredentials = async () => {
        await UserService.deleteMevCredentials()
    }

    return { deleteMevStudioCredentials, deleteMevUserCredentials }
}
