import React, { useState } from "react";
import { Popconfirm, Button, message, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import ExpedientMovementService from "../../../services/expedient_movement.service";
import { ReactComponent as DeleteIcon } from "../../../assets/assets2_0/delete.svg";

const DeleteMovementConfirm = ({
  item,
  onDelete,
  isTabletOrMobile,
  itemType,
  deleteFunc
}) => {
  const [deleting, setDeleting] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleConfirm = () => {
    setDeleting(true);
    deleteFunc(item.id)
      .then(deleted => {
        onDelete(deleted.data);
        message.success(`${itemType} borrado`);
      })
      .catch(e => {
        console.log(e);
        message.error("Ha ocurrido un error");
      })
      .finally(() => setDeleting(false));
  };

  return (
    <Tooltip trigger="hover" title={`Eliminar ${itemType}`}>
      <Popconfirm
        onConfirm={handleConfirm}
        placement={isTabletOrMobile ? "top" : "topRight"}
        title={`¿Está seguro que desea borrar este ${itemType}?`}
      >
        <DeleteIcon
          style={{ cursor: "pointer" }}
          width={"14"}
          color={isHovered ? "#FF5879" : "#95001D"}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      </Popconfirm>
    </Tooltip>
  );
};

export default DeleteMovementConfirm;
