import { message, Popconfirm } from "antd";
import React, { useState } from "react";
import axios, { baseURL, authHeaders } from "../../util/Api";
import { ReactComponent as DeleteIcon } from '../../assets/assets2_0/delete.svg';

const DeleteLogButton = ({ id, onDeleted }) => {
  const [loading, setLoading] = useState(false);
  return (
      <Popconfirm
        title="¿Seguro desea eliminar esta llamada?"
        onConfirm={() => {
          setLoading(true);
          deleteLog(id)
            .then(onDeleted)
            .catch(e => message.error("No se pudo eliminar el registro"))
            .finally(() => setLoading(false));
        }}
      >
        <DeleteIcon color="#FF5879"/>
      </Popconfirm>
  );
};

async function deleteLog(id) {
  const resp = await axios.delete(`${baseURL}/call-log/${id}`, {
    headers: {
      ...authHeaders()
    }
  });
  return resp.data;
}

export default DeleteLogButton;
