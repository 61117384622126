import { Popover, Typography } from "antd";
import React from "react";
import ExpedientInfo from ".";
import axios, { authHeaders } from "../../util/Api";

export const LazyExpedientInfo = ({ uuid, children }) => {
  const [expedient, setExpedient] = React.useState();

  return (
    <Popover
      onVisibleChange={visible => {
        if (visible && !expedient) {
          loadExpedientInfo({ uuid }).then(setExpedient);
        }
      }}
      trigger="hover"
      placement="rightBottom"
      content={
        expedient ? (
          <ExpedientInfo showObservations={false} expedient={expedient} />
        ) : (
          <Typography.Paragraph>Cargando...</Typography.Paragraph>
        )
      }
    >
      {children}
    </Popover>
  );
};

async function loadExpedientInfo({ uuid }) {
  const resp = await axios.get(`/expedient/${uuid}`, {
    headers: authHeaders()
  });
  return resp.data;
}
