import React from "react";
import { FaSignature } from "react-icons/fa";
import { AppstoreOutlined } from "@ant-design/icons";
import { ReactComponent as MailPhoneIcon } from "../../assets/assets2_0/mail_phone.svg";
import { ReactComponent as NotePadIcon } from "../../assets/assets2_0/note_pad.svg";
import { ReactComponent as ExpedientsIcon } from "../../assets/assets2_0/expedients.svg";
import { ReactComponent as ListIcon } from "../../assets/assets2_0/listados.svg";
import { ReactComponent as StatisticsIcon } from "../../assets/assets2_0/statistics.svg";
import { ReactComponent as SecretaryIcon } from "../../assets/assets2_0/secretary.svg";
import { ReactComponent as SettingsIcon } from "../../assets/assets2_0/settings.svg";

export const menuItems = ({ hasMev }) => {
  return [
    {
      key: "dashboard",
      icon: <AppstoreOutlined style={{ fontSize: "22px" }} />,
      text: "Dashboard",
      link: "/home",
      children: [],
      cond: true
    },
    {
      key: "contacts",
      icon: (
        <MailPhoneIcon
          style={{
            fontSize: "22px"
          }}
        />
      ),
      text: "Contactos",
      link: "#",
      tutoInfo:
        "Desde aqui puedes manejar tus contactos (clientes, peritos, etc...)",
      tutoStep: 1,
      cond: true,
      children: [
        {
          key: "create-contact",
          icon: "plus-circle",
          text: "Crear Contacto",
          link: "/contact/add",
          children: [],
          cond: true
        },
        {
          key: "list-contact",
          icon: "unordered-list",
          text: "Contactos",
          link: "/contacts",
          children: [],
          cond: true
        }
      ]
    },
    {
      key: "expedients",
      icon: <ExpedientsIcon />,
      text: "Expedientes",
      tutoStep: 3,
      link: "#",
      cond: true,
      children: [
        {
          key: "create-expedient",
          icon: "plus-circle",
          text: "Crear Expediente",
          link: "/expedient/add",
          children: [],
          cond: true
        },
        {
          key: "list-expedient",
          icon: "unordered-list",
          text: "Expedientes",
          link: "/expedient",
          children: [],
          cond: true
        },
        {
          key: "import-from-expedient",
          icon: "unordered-list",
          text: "Importar desde la MEV",
          link: "/expediente/importar-mev",
          children: [],
          cond: hasMev
        }
      ]
    },
    {
      key: "calculator-main",
      icon: "calculator",
      text: "Calculadoras",
      link: "#",
      cond: true,
      children: [{
        key: "calculator-sub",
        icon: "calculator",
        text: "Calculadoras",
        link: "/calculadoras",
        children: [],
        cond: true
      }, {
        key: "settlements",
        icon: "calculator",
        text: "Mis liquidaciones",
        link: "/settlements",
        children: [],
        cond: true
      }]
    },
    {
      key: "rtf",
      icon: <NotePadIcon />,
      text: "Escritos",
      link: "/#",
      tutoStep: 2,
      cond: true,
      children: [{
        key: "rtf-page",
        icon: "plus-circle",
        text: "Escritos",
        link: "/rtf",
        children: [],
        cond: true
      },
      {
        key: "list-expedient",
        icon: "unordered-list",
        text: "SRT",
        link: "/srt",
        children: [],
        cond: true
      }]
    },
    {
      key: "listados",
      icon: <ListIcon />,
      text: "Listados",
      link: "/listados",
      children: [],
      cond: true,
      tutoStep: 4
    },
    {
      key: "chart",
      icon: <StatisticsIcon />,
      text: "Estadisticas",
      link: "/statistics",
      children: [],
      cond: true,
      tutoStep: 5
    },
    {
      key: "secretary",
      icon: <SecretaryIcon />,
      text: "Secretaria",
      link: "#",
      tutoStep: 7,
      cond: true,
      children: [
        {
          key: "letters",
          icon: "file",
          text: "Cartas",
          link: "#",
          children: [
            {
              key: "document-letter",
              icon: "file",
              text: "Carta documento",
              link: "/document-letter",
              children: [],
              cond: true
            },
            {
              key: "laboral-letter",
              icon: "user",
              text: "Carta laboral",
              link: "/laboral-letter",
              children: [],
              cond: true
            },
            {
              key: "letter-templates",
              icon: "user",
              text: "Templates de Cartas",
              link: "/letter-templates",
              children: [],
              cond: true
            }
          ]
        },
        {
          key: "calls",
          icon: "phone",
          text: "Llamadas",
          link: "/llamadas",
          children: [],
          cond: true
        },
        {
          key: "schedule",
          icon: "schedule",
          text: "Agenda",
          link: "/schedule",
          children: [],
          cond: true
        },
        {
          key: "expense-list",
          icon: "wallet",
          text: "Planilla de gastos",
          link: "/expense-list",
          children: [],
          cond: true
        },
        {
          key: "metasign",
          icon: <FaSignature />,
          text: "Metasign",
          link: "/metasign",
          children: [],
          cond: true
        }
      ]
    },
    {
      key: "account",
      icon: "bank",
      text: "Cuentas",
      link: "/studio-account",
      children: [],
      cond: true
    },
    {
      key: "support",
      icon: "customer-service",
      text: "Soporte",
      link: "/support",
      children: [],
      cond: true
    },
    {
      key: "settings",
      icon: <SettingsIcon />,
      text: "Configuracion",
      link: "/settings",
      children: [],
      cond: true,
      tutoStep: 8
    }
  ].filter(item => {
    return item.cond;
  });
}
