import {
  Button,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Typography
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import axios, { baseURL } from "../../util/Api";
import { PdfViewer } from "./PdfViewer";
import SignatureCanvas from "react-signature-canvas";
import useGeolocation from "@rooks/use-geolocation";
import { withRouter } from "react-router-dom";
import logo from "../../assets/images/logo.svg";

import "./pdf.css";
import CenteredLoading from "../../law-components/CenteredLoading";
import { CheckCircleOutlined } from "@ant-design/icons";

function SignPage({ history }) {
  const token = window.location.search.replace("?doc=", "");
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState(null);
  const geoLocation = useGeolocation();
  const [inputType, setInputType] = useState("manual");
  const [signCanvas, setSignCanvas] = useState(null);
  let inputRef = useRef(null);
  const [form] = Form.useForm();
  const [signing, setSigning] = useState(false);

  useEffect(() => {
    if (token) {
      setLoading(true);
      axios
        .get("/docusign/signature-request/" + token)
        .then(res => {
          const alreadySigned = res.data.signed_at !== null;
          if (alreadySigned) {
            history.push(`/metasign/done?doc=${token}`);
          } else {
            setDocument(res.data);
          }
        })
        .catch(err => {})
        .finally(() => setLoading(false));
    }
  }, [token]);

  const sign = values => {
    setSigning(true);
    const signature =
      inputType === "manual"
        ? signCanvas.getTrimmedCanvas().toDataURL("image/png")
        : inputRef.current.state.value;
    let lat,
      lng = null;
    if (geoLocation && !geoLocation.isError) {
      lat = geoLocation.lat.toString();
      lng = geoLocation.lng.toString();
    }
    const data = {
      signature,
      lat,
      lng,
      token,
      inputType
    };
    axios
      .post("/docusign/sign", data)
      .then(res => {
        history.push(`/metasign/done?doc=${token}`);
      })
      .catch(err => {})
      .finally(() => {
        setSigning(false);
      });
  };

  return loading ? (
    <CenteredLoading tip={"Cargando documento..."} minHeight={"100vh"} />
  ) : (
    <Form form={form} onFinish={sign}>
      <Toolbar />
      <div style={{ padding: 5 }}>
        {document && (
          <Row gutter={[10, 10]}>
            <Col span={12}>
              <PdfViewer
                url={`${baseURL}/docusign/download/${document.docusign.id}`}
              />
            </Col>
            <Col span={12}>
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Typography.Text style={{ fontSize: 25, fontWeight: "300" }}>
                    {document && document.docusign.name}
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  <Typography.Text style={{ fontSize: 15, fontWeight: "300" }}>
                    Firma
                  </Typography.Text>
                </Col>
                <Col span={24}>
                  {inputType === "manual" ? (
                    <SignatureCanvas
                      ref={r => {
                        setSignCanvas(r);
                      }}
                      penColor="black"
                      dotSize={0.8}
                      backgroundColor="white"
                      canvasProps={{
                        width: 500,
                        height: 200,
                        className: "sigCanvas"
                      }}
                    />
                  ) : (
                    <Input autoComplete="cc-csc"
                      ref={inputRef}
                      placeholder="Escriba su firma usando el teclado"
                    />
                  )}
                </Col>
                <Col span={24}>
                  <Button.Group>
                    <Button onClick={() => signCanvas.clear()}>
                      ❌ Limpiar
                    </Button>
                    <Button
                      onClick={() => setInputType("manual")}
                      type={inputType === "manual" ? "primary" : "default"}
                    >
                      ✍️ Manual
                    </Button>
                    <Button
                      onClick={() => setInputType("textfield")}
                      type={inputType !== "manual" ? "primary" : "default"}
                    >
                      ⌨️ Campo de texto
                    </Button>
                  </Button.Group>
                </Col>
                <Col>
                  <Popconfirm
                    title="Confirme si desea firmar el documento"
                    okText="Si"
                    cancelText="No"
                    onConfirm={() => form.submit()}
                  >
                    <Button
                      loading={signing}
                      icon={<CheckCircleOutlined />}
                      type="primary"
                    >
                      Firmar el documento
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    </Form>
  );
}

function Toolbar() {
  return (
    <div
      style={{
        alignContent: "center",
        alignItems: "center",
        padding: "0px 10px",
        overflowX: "hidden",
        backgroundColor: "#02194b",
        height: "5vh",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "5px"
      }}
    >
      <img src={logo} height="25px"></img>
      <div style={{ color: "#fff" }}>Metasign</div>
    </div>
  );
}

export default withRouter(SignPage);
