import React from 'react';

const CollapsablePanel = ({ open, children, vertical, height, width, type = "flex" }) => {
    const showClassName = vertical ? 'open-vertically' : 'open-horizontally';
    const hideClassName = vertical ? 'hide-vertically' : 'hide-horizontally';

    const height_ = vertical && open ? height || "100%" : "0px";
    const width_ = !vertical && open ? width || "100%" : "0px";

    return (
        <div className={`${open ? showClassName : hideClassName}`} style={{ height: vertical ? height_ : "auto", width: !vertical ? width_ : 'auto', display: type }}>
            {children}
        </div>
    )
}

export default CollapsablePanel;