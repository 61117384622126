import React, { useState } from 'react'
import '@ant-design/compatible/assets/index.css';
import { Select, Progress, Form } from "antd";
import TagService from '../../services/tag.service';


function Antd4TagAutocomplete({
  initialValue,
  required = false,
  name = "tags",
  label = "Tags",
  filter = null,

}) {

  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchedTag, setSearchetTag] = useState('');
  const [observer, setObserver] = useState();

  const debouncedSearch = (word) => {
    clearTimeout(observer);
    if (word !== "" && word !== null && word !== undefined) {
      setObserver(
        setTimeout(
          () => fetchTags(word)
            .then((c) => {
              if (c) {
                setData(c)
              }
            })
          , 1500))
    } else {
      setData([])
    }
  }

  const fetchTags = async word => {

    setLoading(true);
    const data = await TagService.getTagsByStudio({ search: word, per_page: 2000, filter });
    let _tags = data.data;
    setLoading(false);
    return _tags;

  };


  return (
    <Form.Item
      layout="vertical"
      name={name}
      key={name}
      label={label}
      initialValue={initialValue}
      rules={[
        {
          required: required,
          message: "Debe indicar el tag."
        }
      ]}
    >
      <Select
        className="responsive-input"
        placeholder="Escribe un Tag..."
        loading={loading}
        mode="multiple"
        showSearch
        filterOption={false}
        onSearch={v => {
          debouncedSearch(v);
          setSearchetTag(v);
        }}
        //onSelect={(values)}
        onFocus={v => setData([])}
      >
        {searchedTag != "" && searchedTag != null && !data.map(x => x.name.toLowerCase()).includes(searchedTag.toLowerCase()) &&
          <Option value={searchedTag} label={searchedTag}>
            {searchedTag}
          </Option>}

        {loading &&
          <Option disabled>
            {<Progress percent={99.9} showInfo={false} type="line" status="active" />}
          </Option>}

        {!loading && searchedTag.length > 0 && data && data.map(tag => (
          <Option label={tag.name} value={tag.name}>
            {tag.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default Antd4TagAutocomplete;