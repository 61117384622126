import React, { useState } from "react";
import { CheckCircleOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { Popover, Input, Button, Tooltip } from "antd";
import { ReactComponent as WhatsAppIcon } from "../../assets/assets2_0/whatsapp.svg";

const WhatsAppQuickMessage = ({ phone, button, tooltip }) => {
  let __phone = phone;

  const [isHovered, setIsHovered] = useState(false);

  if (phone.country.code == "54") {
    __phone = `${phone.country.code}9${phone.mobile_phone}`;
  } else {
    __phone = `${phone.country.code}${phone.mobile_phone}`;
  }

  return (
    <Popover
      title="Enviar WhatsApp rapido"
      placement="left"
      trigger="click"
      content={<MessageForm phone={__phone} />}
    >
      <Tooltip title={tooltip}>
        <WhatsAppIcon
          fill={isHovered ? "#0185F8" : "#002A4E"}
          className="exp-part-action-item"
          onClick={e => e.stopPropagation()}
          style={{ cursor: "pointer" }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      </Tooltip>
    </Popover>
  );
};

const MessageForm = phone => {
  const [message, updateMessage] = useState(null);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-bewteen"
      }}
    >
      <Input.TextArea
        rows={3}
        onKeyUp={v => updateMessage(v.target.value)}
        placeholder="Escribe tu WhatsApp aqui"
      />
      <Button
        style={{ marginTop: "2px" }}
        size="small"
        type="primary"
        icon={<CheckCircleOutlined />}
        onClick={() => {
          window.open(
            `https://web.whatsapp.com/send?text=${message}&phone=${phone.phone}`,
            "_newtab"
          );
        }}
      >
        Enviar mensaje
      </Button>
    </div>
  );
};
export default WhatsAppQuickMessage;
