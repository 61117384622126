import React from "react";
import GoogleOAuthService from "../../../services/google_oauth.service";
import { Button } from "antd";

class GoogleLogut extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      login: true,
      authorized: false
    }
  }

  componentDidMount() {
    GoogleOAuthService.validateAuthorization()
      .then(response => {
        this.setState({
          login: false
        });
        if (response.status == 200) {
          this.setState({
            authorized: response.data.authorized
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  logout = () => {
    GoogleOAuthService.logout()
      .then(response => {

        if (response.status == 200) {
          this.setState({
            authorized: false
          });
          this.props.onLogOut();
        }

      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    if (this.state.authorized == false) {
      return null;
    } else {
      return <li> <Button to={'#'} onClick={this.logout} className="delete-button">
        Desvincular mi cuenta
      </Button> </li>
    }
  }


}

export default GoogleLogut;
