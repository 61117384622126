import axios from "../util/Api";

const JudicialService = {
  fullStructure: provinceId => {
    return axios.get(`/full-structure/${provinceId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createJurisdiction: jurisdiction => {
    return axios.post("/jurisdiction", jurisdiction, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createFuero: fuero => {
    return axios.post("/fuero", fuero, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createCourt: court => {
    return axios.post("/court", court, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createSecretary: secretary => {
    return axios.post("/secretary", secretary, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateJurisdiction: jurisdiction => {
    return axios.patch(`/jurisdiction/${jurisdiction.id}`, jurisdiction, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateFuero: fuero => {
    return axios.patch(`/fuero/${fuero.id}`, fuero, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateCourt: court => {
    return axios.patch(`/court/${court.id}`, court, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateSecretary: secretary => {
    return axios.patch(`/secretary/${secretary.id}`, secretary, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getJurisdictions: province => {
    return axios.get(`/jurisdiction/by-province/${province}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getFueros: jurisdiction => {
    return axios.get(`/fuero/by-jurisdiction/${jurisdiction}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getCourts: fuero => {
    return axios.get(`/court/by-fuero/${fuero}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getSecretaries: court => {
    return axios.get(`/secretary/by-court/${court}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getDashboardCourts: (params, signal) => {
    return axios.get(`/dashboard-courts`, {
      params,
      signal,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteJurisdiction: jurisdiction => {
    return axios.delete(`/jurisdiction/${jurisdiction}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteFuero: fuero => {
    return axios.delete(`/fuero/${fuero}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteCourt: court => {
    return axios.delete(`/court/${court}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteSecretary: secretary => {
    return axios.delete(`/secretary/${secretary}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getStudios: (url = "/studio") => {
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getClients: () => {
    return axios.get("/studio/clients", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }
};

export default JudicialService;
