import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, message, Popconfirm, Typography } from 'antd';
import moment, { now } from 'moment';
import React, { useState } from 'react';
import ExpedientService from '../../services/expedient.service';
import "./index.css";

const FilesMassActions = ({ showActions, cleanSelection, onSaved, selectedFiles, expedient, reload }) => {

    const [loading, setLoading] = useState(false);
    const [compress, setCompress] = useState(false);
    const [grayscale, setGrayscale] = useState(false);
    const [save, setSave] = useState(true);
    const [download, setDownload] = useState(true);

    const processFiles = (selectedFiles) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('body', JSON.stringify({
            merge: selectedFiles.length > 1,
            compress: compress,
            grayscale: grayscale,
            save: save,
            download: download,
            files: selectedFiles,
            expedient_id: expedient.id
        }));

        ExpedientService.processFiles(formData)
            .then((res) => {

                if (download) {
                    const blob = new Blob([res.data], {
                        type: "application/pdf"
                    });
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    let file_name = `procesado_${moment(moment.now()).format('hh_mm_DD_MM_YYYY')}.pdf`
                    a.download = file_name;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                }

                if (save) {
                    reload();
                }

                cleanSelection();
                message.success("Documento procesado correctamente.");
            })
            .catch((e) => {
                console.log(e);
                message.error("Ha ocurrido un error");
            })
            .finally(() => setLoading(false));
    }

    const deleteFiles = (selectedFiles) => {
        setLoading(true);
        ExpedientService.deleteFiles(selectedFiles)
            .then((res) => {
                cleanSelection();
                reload();
                message.info(`${res.data.length} elementos borrados correctamente.`);
            })
            .catch((e) => {
                console.log(e);
                message.error("Ha ocurrido un error");
            })
            .finally(() => setLoading(false));
    }

    return (
        showActions ? <div className='files-mass-actions-container'>
            <Typography.Text style={{ fontWeight: 600 }}> Procesar Archivos </Typography.Text>
            <div className='files-mass-actions'>
                <div>
                    <Checkbox checked={selectedFiles.length > 1} disabled />
                    Combinar
                </div>
                <div>
                    <Checkbox checked={compress} onChange={() => setCompress(!compress)} />
                    Comprimir
                </div>
                <div>
                    <Checkbox checked={grayscale} onChange={() => setGrayscale(!grayscale)} />
                    Convertir a escala de grises
                </div>
                <div>
                    <Checkbox
                        checked={save}
                        onChange={() => {
                            setSave(!save)
                        }}
                    />
                    Guardar
                </div>
                <div>
                    <Checkbox
                        checked={download}
                        onChange={() => {
                            setDownload(!download)
                        }}
                    />
                    Descargar
                </div>
            </div>
            <div className='files-mass-actions-buttons'>
                <Button
                    disabled={!save && !download}
                    style={{ height: "35px" }}
                    type="primary"
                    htmlType="submit"
                    className="responsive-input responsive-button"
                    loading={loading}
                    onClick={() => processFiles(selectedFiles)}
                >
                    <CheckCircleOutlined style={{ fontSize: "12.5px" }} /> Procesar
                </Button>
                <Button
                    style={{ height: "35px" }}
                    type="danger"
                    onClick={() => cleanSelection()}
                    className="ant-btn warn responsive-input responsive-button"
                    htmlType="button"
                    loading={loading}
                >
                    <CloseCircleOutlined style={{ fontSize: "12.5px" }} /> Limpiar selcción
                </Button>
                <Popconfirm
                    title="¿Seguro desea eliminar los elementos seleccionados?"
                    onConfirm={() => deleteFiles(selectedFiles)}
                >
                    <Button
                        style={{ height: "35px" }}
                        type="danger"
                        className="ant-btn warn responsive-input responsive-button"
                        htmlType="button"
                        loading={loading}
                    >
                        <CloseCircleOutlined style={{ fontSize: "12.5px" }} /> Borrar archivos
                    </Button>
                </Popconfirm>
            </div>
        </div> : <></>
    )
}

export default FilesMassActions;