import create from 'zustand'

export const useExpedientMovementForm = create((set) => ({
    fields: {},
    setFields: (newFields) => set((state) => {

        const { fields: previousFields } = state;

        const fields = Object.assign(previousFields, newFields)

        return ({ fields: fields })
    }),
    clearForm: () => set((state) => {
        return ({ fields: {} })
    }),
}))