import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import { Select, Form } from "antd";
import GeographicService from "../../services/geographic.service";
const { Option } = Select;

const Antd4CountrySelect = ({
  multi = false,
  value,
  onChange = () => null
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    GeographicService.getCountries()
      .then(data => {
        setData(data.data);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, []);

  return (
      <Select
        className="responsive-input"
        optionFilterProp="children"
        showSearch
        defaultValue={value}
        onChange={v => onChange(v)}
        loading={loading}
        mode={multi ? "multiple" : "default"}
      >
        {data.map(country => (
          <Option key={country.id} value={country.id}>
            {country.name}
          </Option>
        ))}
      </Select>
  );
};

export default Antd4CountrySelect;
