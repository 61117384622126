import { DEFENSORIA_DESIGN } from "../../constants/ThemeSetting";

// initial state
const initialState = {
    // checking mode from localstorage if falsey (e.g. 0, null, undefined, etc.) it will be false, otherwise true
    isDefensoriaDesign: !!JSON.parse(localStorage.getItem("defensoriamode")),
};

const designModeReducer = (state = initialState, action) => {
    switch (action.type) {
        case DEFENSORIA_DESIGN:
            return {
                ...state,
                // getting value from the action file and changing design mode State.
                isDefensoriaDesign: action.payload,
            };
        default:
            return state;
    }
};

export default designModeReducer;