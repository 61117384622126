import React, { useState, useEffect, cloneElement, useRef } from "react";
import "./style/index.css";
import {
  SyncOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  List,
  Button,
  message,
  Avatar,
  Empty,
} from "antd";
import CenteredLoading from "../CenteredLoading";
import moment from "moment";
import { history } from "../../appRedux/store";
import ExpedientMovementService from "../../services/expedient_movement.service";
import LinearCalendar from "./components/LinearCalendar";
import MovementFilters from "./components/MovementFilters";
import { useMediaQuery } from "react-responsive";
import MovementsFiltersContainer from "../../unstatedStores/ExpedientMovementFilters";
import CreateStudioTaskModal from "../../modals/CreateStudioTaskModal";
import { CreateLazyExpedientMovementModal } from "../../modals/CreateLazyExpedientMovementModal";
import { CustomSwitch } from "../CustomTabs/CustomTabs";
import HideShowFiltersButton from "./components/HideShowFiltersButton";
import { connect } from "react-redux";
import ExportMovementDashButtons from "./components/ExportDashButtons";
import MovementItem from "./DashboardItems/MovementItem";
import StudioTaskItem from "./DashboardItems/StudioTaskItem";
import AppointmentItem from "./DashboardItems/AppointmentItem";
import { isInViewport } from "../../util/IsInViewPort";
import CollapsablePanel from "../../util/CollapsablePanel";
import useStartLoading from "../../hooks/useStartLoading";

const SmallMovementCalendar = ({
  exportFilename,
  exportFunc,
  fetchFunc,//Fetch functions must receive "params" and "signal" parameters 
  _filters,
  availableFilters,
  collapsableFilters,
  _selectedFilter,
  type,
  tutoStep,
  inView,
  filtersCollapsed,
  loadingTip,
  customEmptyTab = undefined
}) => {
  const formattedNow = moment(moment.now()).format("YYYY-MM-DD");
  const [reload, setReload] = useState(false);
  const [dateRange, setDateRange] = useState({ start: formattedNow, end: formattedNow });
  const [editableMovement, setMovementToEdit] = useState(null);
  const [editableTask, setTaskToEdit] = useState(null);
  const [movementModalVisible, setMovementModalVisible] = useState(false);
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const [movementUpdated, setUpdatedMovement] = useState(null);
  const [filters, setFilters] = useState(_filters);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });
  const applyFilters = _filters => {
    setFilters(f => ({ ...f, ..._filters }));
  };

  const topElementRef = useRef(null);
  const tabRef = useRef(null);
  const startLoading = useStartLoading(tabRef);


  //Hacemos set de la fecha con la que realizamos la consulta en caso de haber hecho clic en URL de una notificación de CHECK de studio
  useEffect(() => {
    const address_to_task = history.location.hash.includes("#tasks-");
    if (address_to_task) {
      const task_date = history.location.hash.replace("#tasks-", "");
      setDateRange(moment(task_date).format("YYYY-MM-DD"));
    }
  }, []);

  return (
    <div ref={tabRef}>
      {startLoading && <React.Fragment>
        <CreateLazyExpedientMovementModal
          onCancel={() => {
            setMovementModalVisible(false);
            setMovementToEdit(null);
          }}
          id={editableMovement ? editableMovement.id : null}
          finish={() => {
            setReload(!reload)
            setMovementModalVisible(false);
            setMovementToEdit(null);
          }}
          onUpdated={(movement, attach) => {
            !attach && setMovementModalVisible(false);
            !attach && setMovementToEdit(null);
            setReload(!reload)
            setUpdatedMovement(movement);
            setDateRange(dateRange);
          }}
        />
        <CreateStudioTaskModal
          show_custom_title
          task={editableTask}
          visible={taskModalVisible}
          onCancel={() => {
            setTaskModalVisible(false);
            setTaskToEdit(null);
          }}
          onUpdated={task => {
            setTaskModalVisible(false);
            setTaskToEdit(null);
            setUpdatedMovement(task);
            setReload(!reload)
            setDateRange(dateRange);
          }}
        />



        <Row
          type="flex"
          justify="start"
          align="middle"
          gutter={{ sm: [2.5, 10], xs: [2.5, 10], md: [2.5, 10] }}
        >
          <Col span={24} style={{ display: "flex", alignItems: "center" }} >
            <Row style={{ padding: 0, margin: 0, width: "100%", gap: isTabletOrMobile ? "0.5em" : "0px" }} gutter={{ sm: [2.5, 10], xs: [2.5, 10], md: [2.5, 10] }}>
              <Col ref={topElementRef} span={isTabletOrMobile ? 24 : 7} className="linear-calendar-controls-container">
                <LinearCalendar useDateRange={true} onChange={d => setDateRange(d)} style={{ width: "100%" }} />
                {isTabletOrMobile && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <HideShowFiltersButton />
                  <ExportMovementDashButtons
                    exportFilename={exportFilename}
                    exportFunc={exportFunc}
                    onRestarted={() => null}
                    key={`export-dash-${type}`}
                  />
                </div>}
              </Col>
              <Col span={isTabletOrMobile ? 24 : 15} style={{ display: "flex", alignItems: "start" }}>
                {!filtersCollapsed && (
                  <MovementFilters
                    courtsDateRange={dateRange}
                    _selectedFilter={_selectedFilter || "hour"}
                    availableFilters={isTabletOrMobile ? availableFilters.concat(collapsableFilters) : availableFilters}
                    collapsableFilters={collapsableFilters}
                    defaultFilters={{ ...filters, type }}
                    onApply={applyFilters}
                    onCreatedTask={() => setReload(!reload)}
                    showMoreFiltersButton={collapsableFilters && collapsableFilters.length > 0 && !isTabletOrMobile}
                  />
                )}
              </Col>
              {!isTabletOrMobile && <Col span={2} style={{ display: "flex", alignItems: "start" }}>
                <ExportMovementDashButtons
                  exportFilename={exportFilename}
                  exportFunc={exportFunc}
                  onRestarted={() => null}
                  key={`export-dash-${type}`}
                />
              </Col>}
            </Row>
          </Col>

          <Col span={24}>
            <DashboardList
              customEmptyTab={customEmptyTab}
              topElementRef={topElementRef}
              loadingTip={loadingTip}
              inView={inView}
              type={type}
              fetchFunc={fetchFunc}
              filters={filters}
              movementUpdated={movementUpdated}
              dateRange={dateRange}
              onEditMovement={movement => {
                setMovementToEdit({
                  ...movement,
                  movement_type: movement.movement_type.id,
                  date: moment(movement.date)
                });
                setMovementModalVisible(true);
              }}
              onEditTask={task => {
                setTaskToEdit({ ...task });
                setTaskModalVisible(true);
              }}
              onPostponed={movement => {
                setReload(!reload)
                setUpdatedMovement({ ...movement });
                setDateRange(dateRange);
              }}
              onDelete={movement => {
                setReload(!reload)
                setUpdatedMovement({ ...movement });
                setDateRange(dateRange);
              }}
              reloadPage={() => setReload(!reload)}
              shouldReload={reload}
            />
          </Col>
        </Row>
      </React.Fragment>}
    </div>
  );
};

export const DashboardList = ({
  customEmptyTab,
  dateRange,
  onEditMovement,
  onEditTask,
  onPostponed,
  onDelete,
  movementUpdated,
  fetchFunc,
  filters,
  type,
  inView,
  loadingTip,
  shouldReload,
  reloadPage,
  topElementRef
}) => {
  const [filteredMovements, setFilteredMovements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [retry, setRetry] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

  const movementsFiltersState = MovementsFiltersContainer.useContainer();

  const [abortControllerState, setAbortControllerState] = useState(undefined);

  const [pagination, setPagination] = useState({
    style: { margin: "2px 0px" },
    pageSizeOptions: ["5", "10", "20", "50"],
    showSizeChanger: true,
    position: "bottom",
    current: 1,
    pageSize: 5
  });

  const pageChange = (page, pageSize) => {
    scrollToTopIfNecessary()
    fetchFunc && fetchData({ current: page, pageSize: pageSize });
  };

  //Al renderizar la vista, navegar en fecha y hacer reload
  useEffect(() => {
    scrollToTopIfNecessary()
    fetchFunc && fetchData({ current: 1, pageSize: pagination.pageSize });

    return () => {
      if (loading && abortControllerState !== undefined) {
        abortControllerState.abort("Componente desmontado");
      }
    }
  }, [dateRange, shouldReload, filters]);

  const fetchData = ({ current, pageSize }) => {

    const currentPagination = { page: current, pageSize };

    if (loading && abortControllerState !== undefined) {
      abortControllerState.abort("Nueva request en proceso")
    }

    const abortController = new AbortController();
    const newSignal = abortController.signal;
    setAbortControllerState(abortController)

    setLoading(true);
    const filter = { ...filters, ...currentPagination, dateRange, type };
    fetchFunc(filter, newSignal)
      .then(({ data }) => {
        const parsedData = data.data.map(movement =>
          movement.id === -1
            ? {
              ...movement,
              responsible: movement.responsible.map(r => r.responsible_id)
            }
            : movement
        );

        movementsFiltersState.set(parsedData, dateRange, type, filter);
        setFilteredMovements(parsedData);
        setPagination({ ...pagination, pageSize: data.per_page, current: data.current_page, total: data.total, nextUrl: data.next_page_url, prevUrl: data.prev_page_url })
        setLoading(false);
      })
      .catch(e => {
        if (e.message !== "canceled") {
          message.error("Ha ocurrido un error");
          console.log(e);
          setRetry(true);
          setLoading(false)
        }
      });
  };

  function scrollToTopIfNecessary() {
    if (!isInViewport(topElementRef)) {
      window.scrollTo({ top: isTabletOrMobile ? 0 : 115, behavior: 'smooth' });
    }
  }

  if (!loading && !retry) {
    return (
      <div style={{ minHeight: "250px" }}>
        <List
          dataSource={filteredMovements}
          pagination={{
            ...pagination,
            onChange: pageChange
          }}
          locale={{
            emptyText: customEmptyTab ? cloneElement(customEmptyTab, { onNewEntry: reloadPage }) : <Empty description="No Hay datos" />
          }}

          renderItem={item => {

            const listItem = item.source === "check" ? <StudioTaskItem /> : item.source === "appointment" ? <AppointmentItem /> : <MovementItem />;
            return (
              <List.Item style={{ padding: 1, margin: 1 }}>
                {cloneElement(listItem, {
                  key: item.id,
                  item: item,
                  onUpdated: (item) => reloadPage(),
                  onSelect: (selectedItem) => {
                    /* setMovements(markMovement(selectedItem, movements));
                    setFilteredMovements(
                      markMovement(selectedItem, filteredMovements)
                    ); */
                  },
                  onEditMovement: movement => onEditMovement(movement),
                  onEditTask: task => onEditTask(task),
                  onPostponed: item => onPostponed(item),
                  onDelete: item => onDelete(item)
                })}
              </List.Item>
            )

          }}
        />
      </div>
    );
  } else if (loading && !retry) {
    return (
      <div style={{ minHeight: "250px" }}>
        <CenteredLoading tip={loadingTip} />
      </div>
    );
  } else {
    return (
      <Button
        icon={<SyncOutlined />}
        type="primary"
        onClick={() => {
          setRetry(false);
          fetchData({ current: 1, pageSize: 20 });
        }}
      >
        Reintentar
      </Button>
    );
  }
};

function markMovement(movement, movementList) {
  return movementList.map(_movement =>
    _movement.id === movement.id
      ? { ..._movement, checked: !_movement.checked }
      : _movement
  );
}

export const ToggleMovementStatus = ({
  onUpdated,
  movement,
  disabled,
  short
}) => {
  const [changingStatus, setChangeStatus] = useState(false);

  async function toggleStatus(id) {
    const status = await ExpedientMovementService.toggleStatus(id);
    return status.data;
  }

  if (short) {
    return (
      <CustomSwitch
        mode="toggle"
        options={[
          {
            name: "PENDIENTE",
            color: "#FF5879"
          },
          {
            name: "HECHO",
            color: "#24335D"
          }
        ]}
        onChange={async () => {
          setChangeStatus(true);
          const { realizado } = await toggleStatus(movement.id);
          setChangeStatus(false);
          onUpdated({ ...movement, realizado });
          message.success("Se ha actualizado el estado del movimiento.");
        }}
      />
    );
  }

  return disabled ? null : (
    <CustomSwitch
      mode="toggle"
      defaultValue={movement.realizado ? 1 : 0}
      options={[
        {
          name: "PENDIENTE",
          color: "#FF5879"
        },
        {
          name: "HECHO",
          color: "#24335D"
        }
      ]}
      onChange={async () => {
        setChangeStatus(true);
        const { realizado } = await toggleStatus(movement.id);
        setChangeStatus(false);
        onUpdated({ ...movement, realizado });
        message.success("Se ha actualizado el estado del movimiento.");
      }}
    />
  );
};

const mapStateToProps = ({ linearFilters }) => {
  const { filtersCollapsed } = linearFilters;
  return { filtersCollapsed };
};

export default connect(mapStateToProps)(SmallMovementCalendar);

SmallMovementCalendar.defaultProps = {
  hideFilters: []
};

export const UserAvatar = ({ responsible, color, marginTop, style, size = "small" }) => (
  <Avatar
    size={size}
    shape="circle"
    style={{
      boxShadow: "1px 1px 5px grey",
      marginRight: 3,
      backgroundColor: color,
      cursor: "default",
      marginTop: marginTop || "8px",
      ...style
    }}
  >
    {responsible.firstname ? responsible.firstname[0].toUpperCase() : ""}
    {responsible.lastname ? responsible.lastname[0].toUpperCase() : ""}
  </Avatar>
);

UserAvatar.defaultProps = {
  color: "#3D529E"
};

DashboardList.defaultProps = {
  filters: {}
};

ToggleMovementStatus.defaultProps = {
  size: "default",
  disabled: false
};
