import { useState, useEffect } from 'react'
import useIsVisible from './useIsVisible';

const useStartLoading = (elementRef) => {


    const [startLoading, setStartLoading] = useState(false);

    const tabVisible = useIsVisible(elementRef);

    useEffect(() => {
        if (tabVisible) {
            setStartLoading(true)
        }
    }, [tabVisible])

    return startLoading;
}

export default useStartLoading