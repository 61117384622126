import React, { useEffect } from "react";
import { Button, Input, Row, Col, Tooltip } from "antd";
import { useState } from "react";

export function ExpiresAtInput({ value, onChange, onDisable, disableEdit = false }) {

  const [expiresAt, setExpiresAt] = useState(value > 0 - 1 ? value : 0);
  const [enabled, setEnabled] = useState(value > -1);

  useEffect(() => {
    setExpiresAt(value > -1 ? value : 0);
    setEnabled(value > 0);
  }, [value]);

  const handleChange = v => {
    onChange && onChange(v > -1 ? v : 0);
  };

  return (
    <Input.Group>
      <Row gutter={10}>
        <Col span={12}>
          <Input className="responsive-input" autoComplete="cc-csc"
            disabled={!enabled}
            value={value > -1 ? value : 0}
            onChange={e => {
              handleChange(e.currentTarget.value);
              setExpiresAt(e.currentTarget.value);
            }}
          />
        </Col>
        <Col span={12}>
          <Tooltip title={"No Caducar"}>
            <Button type="primary"
              disabled={disableEdit}
              style={{ width: '100%' }}
              className="responsive-input responsive-button"
              onClick={() => {
                onDisable(!enabled);
                setEnabled(!enabled);
                handleChange(0);
                setExpiresAt(0);
              }}>{enabled ? "No Caducar" : "Caducar"}</Button></Tooltip>
        </Col>
      </Row>
    </Input.Group>
  );
}
