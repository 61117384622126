import { LoadingOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import React, { useState } from 'react';
import { ReactComponent as ColoredDot } from '../../assets/assets2_0/colored-dot.svg';
import { ReactComponent as NonColoredDot } from '../../assets/assets2_0/non-colored-dot.svg';


const CustomSwtichComponent = ({
    form,
    value,
    onSelect,
    checkedChildren,
    unCheckedChildren,
    fieldName,
    uniSwitch,
    loading,
    disabledYes = false,
    disabledNo = false
}) => {

    const _click = (val) => {
        form && form.setFieldsValue({ [fieldName]: val })
        onSelect && onSelect(val);
    }

    const disableUniSwitch = disabledNo || disabledYes;

    return uniSwitch ? (
        <div compact className="custom-switch" >
            <Input
                readOnly
                disabled={loading || disabledYes}
                className="responsive-input"
                onClick={(e) => {
                    if (!disableUniSwitch) {
                        _click(!value)
                    }
                }}
                value={value ? checkedChildren : unCheckedChildren}
                suffix={loading ? <LoadingOutlined /> : value ?
                    <ColoredDot
                        onClick={() => {
                            if (!disableUniSwitch) {
                                _click(!value)
                            }
                        }}
                    /> :
                    <NonColoredDot
                        onClick={() => {
                            if (!disableUniSwitch) {
                                _click(!value)
                            }
                        }}
                    />}
                style={{ width: "100%", cursor: loading || disableUniSwitch ? 'no-drop' : 'pointer', userSelect: 'none' }}
            />
        </div>
    ) : (
        <div compact className="custom-switch" >
            <Input
                readOnly
                disabled={loading || disabledYes}
                className="responsive-input"
                onClick={(e) => {
                    _click(true)
                }}
                value={checkedChildren}
                suffix={loading ? <LoadingOutlined /> : value ? <ColoredDot onClick={() => { if (!disabledNo) { _click(false) } }} /> : <NonColoredDot onClick={() => { if (!disabledYes) { _click(true) } }} />}
                style={{ width: "50%", cursor: loading || disabledYes ? 'no-drop' : 'pointer', userSelect: 'none' }}
            />
            <Input
                readOnly
                disabled={loading || disabledNo}
                className="responsive-input"
                onClick={(e) => {
                    if (!disabledNo) { _click(false) }
                }}
                value={unCheckedChildren}
                suffix={loading ? <LoadingOutlined /> : value ? <NonColoredDot
                    onClick={(e) => {
                        if (!disabledNo) { _click(false) }
                    }} /> : <ColoredDot
                    onClick={(e) => {
                        if (!disabledYes) { _click(true) }
                    }} />}
                style={{ width: "50%", cursor: loading || disabledNo ? 'no-drop' : 'pointer', userSelect: 'none' }}
            />
        </div>
    )
}

export default CustomSwtichComponent;

CustomSwtichComponent.defaultProps = {
    unCheckedChildren: "No",
    checkedChildren: "Si"
}