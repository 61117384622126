import React, { Component, useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Avatar, Badge, Typography, Popover, Spin, Tooltip } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { baseURL } from "../../util/Api";
import { avatarStore } from "../../rxjsStore/avatarStore";
import GoogleOAuthService from "../../services/google_oauth.service";
import GoogleIntegration from "../../routes/ConfigPage/ThirdPartyConfigPage/GoogleIntegration";
import Modal from "antd/lib/modal/Modal";
import GoogleStateContainer from "../../unstatedStores/GoogleStateStore";
import { ReactComponent as GoogleIcon } from "../../assets/assets2_0/google.svg";
import { ReactComponent as ExitIcon } from "../../assets/assets2_0/exit.svg";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import googleDisabled from "../../assets/images/google_g_disabled.png";
class UserInfo extends Component {
  state = {
    firstname: this.props.user.firstname,
    lastname: this.props.user.lastname,
    id: this.props.user.id,
    src: `${baseURL}/profile/photo/${this.props.user.id}`
  };

  avatar = null;

  componentDidMount() {
    this.avatar = avatarStore.avatar.subscribe(image => {
      this.setState({ src: image });
    });
  }

  componentWillUnmount() {
    this.avatar.unsubscribe();
  }

  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => this.props.onProfileClick()}>
          <UserOutlined /> Mi Perfil
        </li>
        <li onClick={() => this.props.userSignOut()}>
          <ExitIcon /> Salir{" "}
        </li>
      </ul>
    );
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "7.5px" }}>
        {!this.props.hideName && (
          <Typography.Text
            ellipsis
            style={{ color: "#fff", fontWeight: 300, maxWidth: "100px" }}
          >
            {this.props.user.firstname} {this.props.user.lastname}
          </Typography.Text>
        )}
        <Popover
          overlayClassName="gx-popover-horizantal"
          placement="bottomRight"
          content={userMenuOptions}
          trigger="click"
        >
          <Tooltip trigger="hover" title="Mi Perfil">
            <Avatar
              src={this.state.src}
              className="gx-avatar gx-pointer"
              alt=""
              key={"useravatar"}
              style={{ cursor: "pointer" }}
              icon={
                <img
                  src={`https://ui-avatars.com/api/?name=${this.props.user.firstname}+${this.props.user.lastname}&background=8987C0&size=128`}
                />
              }
            />
          </Tooltip>
        </Popover>
      </div>
    );
  }
}

export const GoogleQuickLogin = () => {
  const googleStateContainer = GoogleStateContainer.useContainer();
  const [loading, setLoading] = useState(false);
  const [modalOpen, toggleModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    GoogleOAuthService.validateAuthorization()
      .then(resp => {
        if (resp.data) {
          googleStateContainer.set(resp.data);
        }
      })
      .catch(e => console.log(e))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {!loading ? (
        <Badge
          dot
          color={
            googleStateContainer.googleState.authorized ? "green" : "#cccccc54"
          }
        >
          {googleStateContainer.googleState.authorized ? (
            <GoogleIcon
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer"
              }}
              onClick={() => {
                toggleModal(!modalOpen);
              }}
            />
          ) : (
            <img
              style={{
                width: "20px",
                height: "20px",
                cursor: "pointer"
              }}
              onClick={() => {
                toggleModal(!modalOpen);
              }}
              width={20}
              src={googleDisabled}
            ></img>
          )}
        </Badge>
      ) : (
        <Spin />
      )}
      <Modal
        title="Integración con Google"
        footer={null}
        visible={modalOpen}
        onCancel={() => toggleModal(false)}
      >
        <GoogleIntegration withoutCard />
      </Modal>
    </>
  );
};

export default connect(null, { userSignOut })(UserInfo);
