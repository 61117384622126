import React, { useState, useEffect } from "react";
import {
  LeftOutlined,
  RightOutlined
} from "@ant-design/icons";
import { Button, Input, Select, Space } from "antd";
import moment from "moment";
import { history } from "../../../appRedux/store";
import { useMediaQuery } from "react-responsive";

const { Option } = Select;

const LinearCalendar = ({ onChange, useDateRange, style }) => {
  const [selectedDate, selectDate] = useState(moment());
  const [period, setPeriod] = useState("days");
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

  //Aqui seteamos la fecha en caso de haber hecho clic en la  URL de un notificacion de check de estudio
  useEffect(() => {
    const address_to_task = history.location.hash.includes("#tasks-");
    if (address_to_task) {
      const task_date = history.location.hash.replace("#tasks-", "");
      selectDate(moment(task_date));
    }
  }, []);

  const DateTypeSelect = () => {
    return (useDateRange ?
      <Select
        value={"Ver..."}
        className="dashboard-select"
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ marginTop: "2px" }}
        suffixIcon={null}
        onSelect={(v) => {
          switch (v) {
            case "today":
              setPeriod("days")
              onDateChange({
                start: moment(),
                end: moment(),
                setDisplayDate: selectDate,
                onChange,
                useDateRange
              })
              break;
            case "last-week":
              setPeriod("weeks")
              onDateChange({
                start: moment().clone().subtract(1, "weeks"),
                end: moment(),
                setDisplayDate: selectDate,
                onChange,
                useDateRange
              })
              break;
            case "last-two-weeks":
              setPeriod("two-weeks")
              onDateChange({
                start: moment().clone().subtract(2, "weeks"),
                end: moment(),
                setDisplayDate: selectDate,
                onChange,
                useDateRange
              })
              break;
            case "last_month":
              setPeriod("months")
              onDateChange({
                start: moment().clone().subtract(1, "months"),
                end: moment(),
                setDisplayDate: selectDate,
                onChange,
                useDateRange
              })
              break;
            case "last_year":
              setPeriod("years")
              onDateChange({
                start: moment().clone().subtract(1, "years"),
                end: moment(),
                setDisplayDate: selectDate,
                onChange,
                useDateRange
              })
              break;
            case "all":
              setPeriod("days")
              onDateChange({
                start: null,
                end: null,
                setDisplayDate: selectDate,
                onChange,
                useDateRange
              })
              break;
            default:
              onDateChange({
                start: moment(),
                end: moment(),
                setDisplayDate: selectDate,
                onChange,
                useDateRange
              })
              break;
          }
        }}
      >
        <Option value="today" >hoy</Option>
        <Option value="last-week">Última semana</Option>
        <Option value="last-two-weeks">Últimas dos semanas</Option>
        <Option value="last_month">Último mes</Option>
        <Option value="last_year">Último año</Option>
        <Option value="all">Ver todo</Option>
      </Select> :
      <Button
        style={{ borderRadius: "14px" }}
        type="primary"
        onClick={() => {
          setPeriod("days")
          onDateChange({
            start: moment(),
            end: moment(),
            setDisplayDate: selectDate,
            onChange,
            useDateRange
          })
        }}
      >
        Ver hoy
      </Button>
    );
  };

  const navigateBack = () => {

    let end;
    let start;

    if (period === "days") {
      end = selectedDate.clone().subtract(1, period);
      start = end.clone();
    } else {
      end = selectedDate.clone().subtract(period === "two-weeks" ? 2 : 1, period);
      start = end.clone().subtract(period === "two-weeks" ? 2 : 1, period);
    }


    onDateChange({
      start: start,
      end: end,
      setDisplayDate: selectDate,
      onChange,
      useDateRange
    })
  }

  const navigateForward = () => {

    let end;
    let start;

    if (period === "days") {
      start = selectedDate.clone().add(1, period);
      end = start.clone();
    } else {
      start = selectedDate.clone();
      end = start.clone().add(period === "two-weeks" ? 2 : 1, period);

    }

    onDateChange({
      start: start,
      end: end,
      setDisplayDate: selectDate,
      onChange,
      useDateRange
    })
  }

  const yearPeriod = period === "years";

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      flexFlow: isTabletOrMobile ? 'column' : 'row',
      gap: isTabletOrMobile ? '2ch' : '0.5ch',
      ...style
    }} >
      <Space.Compact className="linear-date-change" size="small" >
        <Button
          style={{ border: "none", boxShadow: "none" }}
          type="ghost"
          onClick={() => navigateBack()}
        >
          <LeftOutlined />
          <span>
            {selectedDate
              .clone()
              .subtract(period === "two-weeks" ? 2 : 1, period)
              .format(yearPeriod ? "MM/YYYY" : "DD/MM")}
          </span>
        </Button>
        <DateTypeSelect />
        <Button
          style={{ border: "none", boxShadow: "none" }}
          type="ghost"
          onClick={() => navigateForward()}
        >
          <span>
            {selectedDate
              .clone()
              .add(period === "two-weeks" ? 2 : 1, period)
              .format(yearPeriod ? "MM/YYYY" : "DD/MM")}{" "}
            <RightOutlined />
          </span>
        </Button>
      </Space.Compact >
      <Input
        className="linear-date-picker responsive-input"
        style={{ minHeight: "25px", fontSize: "12.5px" }}
        type="date"
        placeholder="Fecha"
        onChange={e => {
          setPeriod("days")
          onDateChange({
            start: moment(e.target.value),
            end: moment(e.target.value),
            setDisplayDate: selectDate,
            onChange,
            useDateRange
          })
        }}
      />
    </div >
  );
};

function onDateChange({ start, end, setDisplayDate, onChange, useDateRange, next, back }) {

  if (useDateRange) {
    if (!start && !end) {
      onChange(null)
      setDisplayDate(moment());
    } else {
      setDisplayDate(end);
      onChange({ start: start.format("YYYY-MM-DD"), end: end.format("YYYY-MM-DD") });
    }
    return;
  }

  setDisplayDate(start);
  onChange(start);
}

export default LinearCalendar;
