import { SET_LANG } from "../../constants/ActionTypes";

export const handleLangChange = (e) => async (dispatch) => {
    
    localStorage.setItem("LANG", e);
  
    dispatch({
      type: SET_LANG,
      payload: e,
    });
  };