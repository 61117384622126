import React from "react";
import { parseJwt } from "../../util/jwt.helper";


const Authorization = ({permission, onRender}) => {
	const tokenParse =parseJwt();


	const verify = () => {
		
		if (tokenParse.authUserRole === 1) {
			
			return true;

		} else {

			return tokenParse.claims && tokenParse.claims.includes(permission);
		}

	}
	
	return verify() ? onRender() : null;

}  


export default Authorization;