import { PhoneOutlined } from "@ant-design/icons";
import { Button, message, Modal, Tooltip } from "antd";
import React, { cloneElement, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import axios, { authHeaders, baseURL } from "../../util/Api";
import CenteredLoading from "../CenteredLoading";
import CallLogForm from "./CallLogForm";
import CallLogList from "./CallLogList";
import { ReactComponent as PhoneIcon2 } from "../../assets/assets2_0/phone.svg";

const CallLog = ({
  expedientId,
  contact,
  notify,
  asModal,
  showExpedient,
  size,
  newCall,
  date,
  edit,
  style,
  customButtonIcon
}) => {
  const [visible, setVisible] = useState(false);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (visible || !asModal) {
      setLoading(true);
      if (expedientId) {
        getExpedientLogs(expedientId)
          .then(setLogs)
          .catch(e => message.error("No se puediron cargar los registros."))
          .finally(() => setLoading(false));
      } else if (contact) {
        getClientLogs(contact.id)
          .then(setLogs)
          .catch(e => message.error("No se puediron cargar los registros."))
          .finally(() => setLoading(false));
      } else {
        getStudioLogs(date)
          .then(setLogs)
          .catch(e => message.error("No se puediron cargar los registros."))
          .finally(() => setLoading(false));
      }
    }
  }, [visible, date]);

  return asModal ? (
    <>
      <Modal
        destroyOnClose
        width={isMobile ? "100vw" : "75vw"}
        footer={null}
        title="Registro de llamadas"
        visible={visible}
        onCancel={() => setVisible(false)}
      >
        {newCall && (
          <CallLogForm
            onCreated={log => setLogs([log, ...logs])}
            expedient_id={expedientId}
            contact={contact}
          />
        )}
        <CallLogList
          notify={notify}
          logs={logs}
          onDeleted={setLogs}
          onUpdated={setLogs}
          showExpedient={showExpedient}
          edit={edit}
        />
      </Modal>
      {customButtonIcon ? (
        cloneElement(customButtonIcon, {
          style: { cursor: "pointer" },
          fill: isHovered ? "#0185F8" : "#002A4E",
          onClick: () => setVisible(true),
          onMouseEnter: () => setIsHovered(true),
          onMouseLeave: () => setIsHovered(false)
        })
      ) : (
        <Tooltip title="Registro de llamadas">
          <PhoneIcon2
            style={{ cursor: "pointer" }}
            fill={isHovered ? "#0185F8" : "#002A4E"}
            onClick={() => setVisible(true)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
        </Tooltip>
      )}
    </>
  ) : (
    <>
      {loading ? (
        <CenteredLoading tip="Cargando llamadas" minHeight="10vh" />
      ) : (
        <CallLogList
          onUpdated={setLogs}
          edit={edit}
          notify={notify}
          logs={logs}
          onDeleted={setLogs}
          showExpedient={showExpedient}
        />
      )}
    </>
  );
};

async function getExpedientLogs(expedient_id) {
  const resp = await axios.get(
    `${baseURL}/expedient-call-log/${expedient_id}`,
    {
      headers: {
        ...authHeaders()
      }
    }
  );
  return resp.data;
}

async function getClientLogs(contact_id) {
  const resp = await axios.get(`${baseURL}/contact-call-log/${contact_id}`, {
    headers: {
      ...authHeaders()
    }
  });
  return resp.data;
}

async function getStudioLogs(date) {
  const resp = await axios.get(`${baseURL}/studio-call-log`, {
    params: {
      date
    },
    headers: {
      ...authHeaders()
    }
  });
  return resp.data;
}

CallLog.defaultProps = {
  notify: true,
  showExpedient: true,
  size: "small",
  newCall: true,
  edit: false
};

export default CallLog;
