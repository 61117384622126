import React, { useEffect, useState } from "react";
import { CheckOutlined } from '@ant-design/icons';
import { Select, Popover, Button } from "antd";
import ExpedientService from "../../services/expedient.service";

const { Option } = Select;

const ChangeExpedientStatus = ({ expedient, onDone }) => {
  const [status, setStatus] = useState([]);
  const [selectedStatus, selectStatus] = useState(null);
  const [changingStatus, setChangingStatus] = useState(false);

  useEffect(() => {
    ExpedientService.getExpedientStatus()
      .then(status => setStatus(status.data))
      .catch(err => console.log(err));
  }, []);

  const changeStatus = () => {
    setChangingStatus(true);
    ExpedientService.changeExpedientStatus({
      expedient: expedient.id,
      status: selectedStatus
    })
      .then(response => {
        onDone(response.data);
        setChangingStatus(false);
      })
      .catch(err => {
        setChangingStatus(false);
        console.log(err);
      });
  };

  return (
    <div>
      <Select
        onSelect={status => selectStatus(status)}
        placeholder="Seleccionar status"
        style={{ width: "100%" }}
      >
        {status.map((_status, idx) => (
          <Option key={idx} value={_status.id}>
            {_status.name}
          </Option>
        ))}
      </Select>
      <Button
        size="small"
        type="primary"
        icon={<CheckOutlined />}
        loading={changingStatus}
        style={{ width: "100%", marginTop: 5 }}
        onClick={() => changeStatus()}
      >
        Cambiar
      </Button>
    </div>
  );
};

export const ChangeExpedientStatusPopOver = ({
  expedient,
  onSelect,
  child,
  onDone
}) => {
  const [popOverVisible, showPopOver] = useState(false);

  return (
    <Popover
      visible={popOverVisible}
      onVisibleChange={v => showPopOver(v)}
      title="Cambiar status del expediente"
      trigger="click"
      content={
        <ChangeExpedientStatus
          expedient={expedient}
          onDone={r => {
            onDone(r);
            showPopOver(false);
          }}
          onSelect={onSelect}
        />
      }
    >
      {child}
    </Popover>
  );
};

export default ChangeExpedientStatus;
