import { Tooltip } from "antd";
import React, { useState } from "react";
import ExpedientMovementService from "../../../services/expedient_movement.service";
import { ReactComponent as ThumbtackIcon } from "../../../assets/assets2_0/thumbtack.svg";
import { LoadingOutlined } from "@ant-design/icons";

const ToggleInstaAccionScraping = ({ scrapingMovement, onUpdated }) => {
  const [checked, setChecked] = useState(scrapingMovement.insta_la_accion);
  const [loading, setLoading] = useState(false);

  const handleChange = () => {
    setLoading(true);
    ExpedientMovementService.toggleScrapingInstaAccion({
      id: scrapingMovement.scraping_id
    })
      .then(() => {
        const _c = !checked;
        setChecked(_c);
        onUpdated && onUpdated({ ...scrapingMovement, insta_la_accion: _c });
      })
      .catch()
      .finally(() => {
        setLoading(false);
      });
  };
  if (loading) return <LoadingOutlined></LoadingOutlined>
  return (
    <Tooltip trigger={["hover"]} title={checked ? "No instar a la acción" : "Instar a la acción"}>
      <ThumbtackIcon
        width={"16.8"}
        fill={checked ? "#d44c53" : "#CCCCCC"}
        style={{
          cursor: "pointer",
          fill: checked ? "#d44c53" : "#CCCCCC"
        }} onClick={() => handleChange()}>

      </ThumbtackIcon>
    </Tooltip>
  );
};

export const ToggleInstaAccionMevScraping = ({ movement, onUpdated }) => {
  const [checked, setChecked] = useState(movement.insta_la_accion);
  const [loading, setLoading] = useState(false);

  const handleChange = () => {
    setLoading(true);
    ExpedientMovementService.toggleMevScrapingInstaAccion({
      id: movement.id
    })
      .then(() => {
        const _c = !checked;
        setChecked(_c);
        onUpdated && onUpdated({ ...movement, insta_la_accion: _c, realizado: _c ? true : movement.realizado });
      })
      .catch()
      .finally(() => {
        setLoading(false);
      });
  };
  if (loading) return <LoadingOutlined></LoadingOutlined>
  return (
    <Tooltip trigger={["hover"]} title={checked ? "No instar a la acción (MEV)" : "Instar a la acción (MEV)"}>
      <ThumbtackIcon
        width={"16.8"}
        fill={checked ? "#d44c53" : "#CCCCCC"}
        style={{
          cursor: "pointer",
          fill: checked ? "#d44c53" : "#CCCCCC"
        }} onClick={() => handleChange()}>

      </ThumbtackIcon>
    </Tooltip>
  );
};

export default ToggleInstaAccionScraping;
