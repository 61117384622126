import { useState } from "react";
import { createContainer } from "unstated-next";

const useExpedientState = () => {
  
  const [scan, setScan] = useState({files: []});

  let updateScan = (_scan) => {
    _scan && setScan(_scan);
  };

  let deleteScanFile = (file) => {
    setScan({...scan, files: scan.files.filter(f => f.id !== file.id)});
  }

  return { scan, updateScan, deleteScanFile };
};

const ExpedientDetailContainer = createContainer(useExpedientState);
export default ExpedientDetailContainer;
