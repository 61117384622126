import { Subject } from "rxjs";

const avatar = new Subject();

export const avatarStore = {
  avatar: avatar.asObservable(),
  updateAvatar: url => {
    avatar.next(url);
  }
};
