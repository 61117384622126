import React from 'react';
import { onCopy } from '../../../routes/RtfPage/RtfInfo';
import { purifyStyledText } from '../../../util/purifyText';
import "../styles.css"
export const StyledText = ({ styledText, copyFromText = false }) => {

    //THIS COMPONENT IS MEANT TO SHOW DRAFT-JS TEXT

    const formatLinks = (styledText) => {
        const delimiter = /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi;
        let formattedTextArray = '';
        formattedTextArray = styledText.split(delimiter).map(word => {
            const match = word.match(delimiter);
            if (match) {
                const url = match[0];
                return (
                    `<a target="_blank" href="${url.startsWith('http') ? url : 'http://' + url}" >${url}</a>`
                );
            }
            return word;
        });
        return formattedTextArray.join(' ')
    }

    return (
        <div className='collapse-expand-styled-text' onClick={() => {
            if (copyFromText) {
                //TEXT MUST BE DRAFT EDITOR TEXT
                onCopy(purifyStyledText(styledText, {
                    ALLOWED_TAGS: [], CUSTOM_ELEMENT_HANDLING: {
                        tagNameCheck: null,
                        attributeNameCheck: null,
                        allowCustomizedBuiltInElements: false,
                    }
                }));
            };
        }}
            dangerouslySetInnerHTML={{ __html: formatLinks(styledText) }}>

        </div>
    );
};
