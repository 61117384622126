
import { message, Tooltip } from "antd";
import React, { useState } from "react";
import ExpedientMovementService from "../../services/expedient_movement.service";
import { ReactComponent as ThumbtackIcon } from "../../assets/assets2_0/thumbtack.svg";

const TogglePinMovement = ({ onUpdated, movement, disabled }) => {
  const [changingStatus, setChangeStatus] = useState(false);

  async function toggleStatus(id) {
    const status = await ExpedientMovementService.togglePin(id);
    return status.data;
  }

  return !disabled ? (
    <Tooltip
      trigger="hover"
      title={movement.pinned ? "Soltar movimiento" : "Fijar movimiento arriba"}
    >
      <ThumbtackIcon
        width={"16.8"}
        fill={movement.pinned ? "#d44c53" : "#CCCCCC"}
        style={{
          cursor: "pointer",
          fill: movement.pinned ? "#d44c53" : "#CCCCCC"
        }}
        onClick={async () => {
          setChangeStatus(true);
          const { pinned } = await toggleStatus(movement.id);
          setChangeStatus(false);
          onUpdated({ ...movement, pinned });
          message.success("Se ha actualizado el movimiento.");
        }}
      />
    </Tooltip>
  ) : null;
};

export default TogglePinMovement;
