import React, { useEffect } from 'react'
import { userSocialSignIn } from '../../appRedux/actions/Auth';
import { connect } from 'react-redux';
import { Result } from 'antd';

function HandleThirdPartyLoginCallback({ userSocialSignIn }) {
  const token = window.location.search.split('=')[1]

  useEffect(() => {
    if (token) {
      userSocialSignIn({ token })
    }
  }, [token])

  return <Result
    title="Autenticado correctamente"
    subTitle="Redirigiendo..."
  />
}

const mapStateToProps = ({ auth }) => {
  const { token } = auth;
  return { token };
};

export default connect(mapStateToProps, { userSocialSignIn })(
  HandleThirdPartyLoginCallback
);

