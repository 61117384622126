import React, { useEffect } from "react";
import { ConnectedRouter } from "react-router-redux";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";

import "assets/vendors/style";
import "styles/wieldy.less";
import "react-datepicker/dist/react-datepicker.css";
import configureStore, { history } from "./appRedux/store";
import App from "./containers/App/index";
import ExpedientDetailContainer from "./unstatedStores/ExpedientDetailState";
import TutorialStateContainer from "./unstatedStores/TutorialState";

export const store = configureStore();

const NextApp = () => {

  return (
    <TutorialStateContainer.Provider>
      <ExpedientDetailContainer.Provider>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Switch>
              <Route path="/" component={App} />
            </Switch>
          </ConnectedRouter>
        </Provider>
      </ExpedientDetailContainer.Provider>
    </TutorialStateContainer.Provider>
  );
};

export default NextApp;
