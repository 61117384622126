import { Button, Modal } from "antd";
import { ExpenseForm } from "./ExpenseForm";
import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { ReactComponent as AddButton } from "../../../assets/assets2_0/add-button.svg";
import { ReactComponent as EditIcon } from "../../../assets/assets2_0/edit.svg";
import { useMediaQuery } from "react-responsive";
export function ExpenseFormModal({ edit, onUpdated, onCreated }) {
  const [visible, setVisible] = React.useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

  const CreateButton = () =>
    isTabletOrMobile ? (
      <AddButton
        style={{ cursor: "pointer" }}
        onClick={() => {
          setVisible(true);
        }}
      />
    ) : (
      <Button
        className="responsive-input responsive-button"
        onClick={() => {
          setVisible(true);
        }}
        type="primary"
      >
        Nuevo registro
      </Button>
    );

  return (
    <>
      <div>
        {!edit && <CreateButton />}
        {edit && (
          <EditIcon
            color="#016ECE"
            onClick={() => {
              setVisible(true);
            }}
          />
        )}
      </div>
      <Modal
        destroyOnClose
        title={edit ? "Editar registro" : "Nuevo registro"}
        footer={null}
        visible={visible}
        closable
        onCancel={() => {
          setVisible(false);
        }}
      >
        <ExpenseForm
          onCreated={x => {
            onCreated(x);
            setVisible(false);
          }}
          onUpdated={x => {
            setVisible(false);
            onUpdated(x);
          }}
          expense={edit}
        />
      </Modal>
    </>
  );
}
