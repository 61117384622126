import React, { useState, useEffect } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Spin,
  Row,
  Col,
  Select,
  Card,
  message,
  DatePicker,
  TimePicker,
  Input,
} from "antd";
import moment from "moment";
import 'moment/locale/es';

import AppointmentService from "../../services/appointment.service";
import UserService from '../../services/user.service';

const AppointmentFrame = (props) => {
  const { form } = props;
  const { getFieldDecorator } = form;
  const [isCreated, setCreated] = useState(false);

  const [users, setUsers] = useState(null);
  const [selectedUser, selectUser] = useState(null);
  const [selectedSpecialty, selectSpecialty] = useState(null);
  const [currentShifts, selectShifts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState('');
  const [lawyerError, setLawyerError] = useState('');

  useEffect(() => {
    fetchData();

    return () => form.resetFields();
  }, [])

  useEffect(() => {
    if (users && props.match.params.user > 1) {
      const selectedUser = users.find(user => user.id == props.match.params.user);
      selectUser(selectedUser);

      selectSpecialty(selectedUser.specialties && selectedUser.specialties[0] ? selectedUser.specialties[0].id : null);

      if (selectedUser && !selectedUser.specialties[0]) {
        setDisabled(true);
        setLawyerError('Este abogado no posee especialidades.')
      }
    }
  }, [props.match.params.user, users])

  useEffect(() => {
    setLoading(true);

    form.setFieldsValue({
      specialty: selectedSpecialty
    });

    if (selectedUser && selectedSpecialty) {
      selectShifts(selectedUser.shifts.filter(
        shift => shift.user_id == selectedUser.id && shift.specialty_id == selectedSpecialty
      ))
    }

    setLoading(false);
  }, [selectedUser, selectedSpecialty])

  const fetchData = async () => {
    setLoading(true);

    await UserService.getUsers(null,null,null,null,1)
      .then(res => {
        setUsers(res.data);
        selectUser(res.data[0] && res.data[0]);
        selectSpecialty(res.data[0] && res.data[0].specialties[0] ? res.data[0].specialties[0].id : null);
      })
      .catch(err => handleBadResponse(err));

    setLoading(false);
  }

  const handleBadResponse = (error) => {
    if (!error.response) {
      message.error('Error de conexión');
    } else {
      message.error('Ha ocurrido un error');
    }

    console.log('Ha ocurrido un error', error);
  }

  const isShiftAvailable = async (specialty, date, startingTime, endingTime) => {
    const payload = {
      'user_id': selectedUser.id,
      'date': date.format('YYYY-MM-DD'),
    };

    const isAvailable = await AppointmentService.checkDateAvailability(payload)
      .then(async res => {
        const isAvailable = res.data.available;

        if (!isAvailable) {
          setError('No hay cupos disponibles para esta fecha.');
          return false;
        } else {
          return true;
        }
      }).catch(err => handleBadResponse(err))

    if (isAvailable) {
      for (const shift of selectedUser.shifts) {
        if (shift.specialty_id == specialty && date.format('e') == shift.day_of_week && date.format('YYYY-MM-DD') != shift.disabled_date) {
          if (moment(startingTime, 'HH:mm').format('HH:mm') >= moment(shift.starting_time, 'HH:mm:ss').format('HH:mm')
            && endingTime <= shift.ending_time) {

            return true;
          } else {
            continue;
          }
        }
      }
    }

    setError('Este horario se encuentra fuera de turno.');
    return false;
  }

  const onChangeUser = async (id) => {
    setLoading(true);
    setDisabled(false);
    setError('');
    setLawyerError('');

    const user = await users.find(user => user.id == id);

    selectUser(user);
    selectSpecialty(user.specialties && user.specialties[0] ? user.specialties[0].id : null);

    if (user && !user.specialties[0]) {
      setDisabled(true);
      setLawyerError('Este abogado no posee especialidades.')
    }

    setLoading(false);
  }

  const disabledDates = (date) => {
    const today = new moment;

    if (date.format('DDD') < today.format('DDD')) {
      return true;
    }

    if (currentShifts) {
      const shifts = currentShifts.filter(shift => shift.day_of_week == date.format('e') && shift.disabled_date != date.format('YYYY-MM-DD'))

      return shifts.length ? false : true;
    }
  }

  const disabledHours = () => {
    const date = form.getFieldValue('date');
    const now = new moment;
    let hours = [];

    if (date.format('DDD') == now.format('DDD')) {
      for (let i = 0; i < 24; i++)
        if (i < now.format('H')) hours.push(i);
    }

    return hours;
  }

  const disabledMinutes = (selectedHour) => {
    const now = new moment;
    let minutes = [];

    if (selectedHour == now.format('H')) {
      for (let i = 0; i < 60; i++)
        if (i < now.format('m')) minutes.push(i);
    }

    return minutes;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        setLoading(true);
        setError('');
        setLawyerError('');

        let time = moment(values.time);
        const duration = (selectedUser && selectedUser.setting) && selectedUser.setting.max_duration_of_appointments;

        let payload = {
          'user_id': selectedUser.id,
          'specialty_id': values.specialty,
          'client_id': null,
          'date': values.date.format('YYYY-MM-DD'),
          'starting_time': time.format('HH:mm'),
          'ending_time': time.add(duration, 'm').format('HH:mm'),
          'duration': duration,
          'status': 'pending',
          'observation': values.observation,
        };

        const client = {
          'firstname': values.firstname,
          'lastname': values.lastname,
          'email': values.email,
          'mobile_phone': values.mobile_phone
        };

        if (!(await isShiftAvailable(values.specialty, values.date, payload.starting_time, payload.ending_time))) {
          setLoading(false);
          return;
        };

        await AppointmentService.checkAvailability(payload)
          .then(async res => {
            const isAvailable = res.data.available;

            if (payload.duration != res.data.duration) {
              payload.duration = res.data.duration;
              payload.ending_time = moment(payload.starting_time, 'HH:mm').add(res.data.duration, 'm').format('HH:mm');
            }

            if (!isAvailable) {
              setError('Este horario no se encuentra disponible.');
            } else {
              await AppointmentService.createClient(client)
                .then(async res => {
                  if (res.data.id) {
                    const client = res.data.id;
                    payload.client_id = client;

                    await AppointmentService.storeAppointment(payload)
                      .then(res => {
                        form.resetFields();
                        setCreated(true);
                      })
                      .catch(err => handleBadResponse(err));
                  }
                })
                .catch(err => handleBadResponse(err))
            }
          }).catch(err => handleBadResponse(err))

        setLoading(false);
      }
    });
  };

  const mobilePhoneLabel = () => {
    return (
      <span>
        Teléfono Móvil <span style={{ fontStyle: 'italic', color: '#aaaaaa' }}>(+123456789)</span>
      </span>
    )
  }

  const ObservationLabel = () => {
    return (
      <span>
        Observación <span style={{ fontStyle: 'italic', color: '#aaaaaa' }}>(190 caracteres)</span>
      </span>
    )
  }

  const fields = [
    {
      key: "user",
      lg: 12,
      md: 12,
      xs: 24,
      item: (
        <Form.Item key="user" label="Abogado">
          {getFieldDecorator("user", {
            rules: [
              {
                required: true,
                message: "Debes seleccionar un Abogado.",
              },
            ],
            initialValue: selectedUser && selectedUser.id,
          })(
            <Select onChange={onChangeUser} disabled={props.match.params.user > 1 ? true : false}>
              {users && (
                users.map((user, i) => {
                  return (
                    <Select.Option value={user.id} key={i}>
                      {user.firstname} {user.lastname}
                    </Select.Option>
                  )
                })
              )}
            </Select>
          )}
        </Form.Item>
      ),
    },
    {
      key: "lawyerError",
      lg: 24,
      md: 24,
      xs: 24,
      item: (
        <Row type='flex' justify='center' className='error-text'>
          {lawyerError}
        </Row>
      ),
    },
    {
      key: "specialty",
      lg: 12,
      md: 12,
      xs: 24,
      item: (
        <Form.Item key="specialty" label="Especialidad">
          {getFieldDecorator("specialty", {
            rules: [
              {
                required: true,
                message: "Debes seleccionar una especialidad.",
              },
            ],
            initialValue: selectedSpecialty && selectedSpecialty,
          })(
            <Select onChange={selectSpecialty} disabled={disabled}>
              {selectedUser && selectedUser.specialties.map((specialty, i) => {
                return (
                  <Select.Option value={specialty.id} key={i}>
                    {specialty.name}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Form.Item>
      ),
    },
    {
      key: "date",
      lg: 12,
      md: 12,
      xs: 24,
      item: (
        <Form.Item key="date" label="Fecha">
          {getFieldDecorator("date", {
            rules: [
              {
                required: true,
                message: "Debes establecer una fecha para tu cita.",
              },
            ]
          })(
            <DatePicker format={'YYYY-MM-DD'} className='full-width' disabledDate={disabledDates} disabled={disabled} />
          )}
        </Form.Item>
      ),
    },
    {
      key: "time",
      lg: 12,
      md: 12,
      xs: 24,
      item: (
        <Form.Item key="time" label="Hora">
          {getFieldDecorator("time", {
            rules: [
              {
                required: true,
                message: "Debes establecer la hora de tu cita.",
              },
            ],
          })(
            <TimePicker format={'hh:mm a'}
              minuteStep={selectedUser && selectedUser.setting.max_duration_of_appointments}
              use12Hours={true}
              className='full-width'
              disabledHours={disabledHours}
              disabledMinutes={disabledMinutes}
              disabled={disabled} />
          )}
        </Form.Item>
      ),
    },
    {
      key: "observation",
      lg: 24,
      md: 24,
      xs: 24,
      item: (
        <Form.Item key="observation" label={ObservationLabel()}>
          {getFieldDecorator("observation", {
            initialValue: ''
          })(
            <Input.TextArea  width="100%" maxLength={190} rows={4} />
          )}
        </Form.Item>
      ),
    },
    {
      key: "error",
      lg: 24,
      md: 24,
      xs: 24,
      item: (
        <Row type='flex' justify='center' className='error-text'>
          {error}
        </Row>
      ),
    },
    {
      key: "title",
      lg: 8,
      md: 8,
      xs: 24,
      item: (
        <Form.Item key="title">
          <Row type='flex' justify='start' className='event-modal-title'>
            {`Tus datos`}
          </Row>
        </Form.Item>
      ),
    },
    {
      key: "firstname",
      lg: 12,
      md: 12,
      xs: 24,
      item: (
        <Form.Item key="firstname" label="Nombre">
          {getFieldDecorator("firstname", {
            rules: [
              {
                required: true,
                message: "Debe indicar su nombre."
              }
            ],
          })(<Input autoComplete="cc-csc" />)}
        </Form.Item>
      )
    },
    {
      key: "lastname",
      lg: 12,
      md: 12,
      xs: 24,
      item: (
        <Form.Item key="lastname" label="Apellido">
          {getFieldDecorator("lastname", {
            rules: [
              {
                required: true,
                message: "Debe indicar su apellido."
              }
            ],
          })(<Input autoComplete="cc-csc" />)}
        </Form.Item>
      )
    },
    {
      key: "email",
      lg: 12,
      md: 12,
      xs: 24,
      item: (
        <Form.Item key="email" label="E-mail">
          {getFieldDecorator("email", {
            rules: [{ required: true, message: "Debe indicar un correo electrónico." }],
          })(<Input autoComplete="cc-csc" />)}
        </Form.Item>
      )
    },
    {
      key: "mobile_phone",
      lg: 12,
      md: 12,
      xs: 24,
      item: (
        <Form.Item key="mobile_phone" label={mobilePhoneLabel()}>
          {getFieldDecorator("mobile_phone", {
            rules: [{ required: true, message: "Debe indicar un móvil." }],
          })(<Input autoComplete="cc-csc" />)}
        </Form.Item>
      )
    },
    {
      key: "submit",
      lg: 8,
      md: 8,
      xs: 24,
      item: (
        <>
          <Form.Item key="submit">
            <Button
              type="primary"
              htmlType="submit"
              className="full-input"
              style={{ width: '100%' }}
              loading={loading}
              disabled={disabled}
            >
              Guardar
            </Button>
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <>
      <Card title={`Agenda tu cita`} className='full-width full-height overflow'>
        {!isCreated ?
          <Form onSubmit={handleSubmit} layout="vertical">
            <Row type="flex" justify="start">
              {fields.map((field) => (
                <Col lg={field.lg} xs={field.xs}>
                  {field.item}
                </Col>
              ))}
            </Row>
          </Form>
          :
          <>
            <Row type='flex' justify='center' className='full-width event-modal-title appointment-created'>
              ¡Gracias por agendar tu cita con nosotros!
            </Row>
            <Row type='flex' justify='center' className='full-width appointment-created'>
              <Button
                onClick={() => setCreated(false)}
                type="primary"
                htmlType="submit"
                className="full-input"
                style={{ width: '100%' }}
                loading={loading}
              >
                Atras
              </Button>
            </Row>
          </>
        }
      </Card>
    </>
  );
};

const CreateAppointmentFrame = Form.create({
  name: "create-call-registry",
})(AppointmentFrame);

export default CreateAppointmentFrame;
