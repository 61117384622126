import React from "react";
import { Modal } from "antd";
import TurnoSelector from "../routes/AssistantSchedulePage/TurnoSelector";

export const AppointmentModal = ({ visible, onCancel, onDone, onUpdated, datetime, isOutOfShift, appointment }) => {

  const isTabletOrMobile = window.matchMedia("(max-width: 1224px)").matches;

  return (
    <Modal
      open={visible}
      width={isTabletOrMobile ? '95vw' : '70vw'}
      title={`Agendar cita ${isOutOfShift ? 'en sobreturno' : ''}`}
      footer={false}
      destroyOnClose
      closable
      centered
      onCancel={() => onCancel(false)}
    >
      <TurnoSelector onSaved={() => onDone()} onUpdated={(appointmentUpdated) => onUpdated(appointmentUpdated)} appointment={appointment} />
      {/* <CreateAppointmentForm
        isOutOfShift={isOutOfShift}
        datetime={datetime}
        onDone={onDone}
        onCancel={() => onCancel(false)}
      /> */}
    </Modal>
  );
};
