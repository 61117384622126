import React, { useState, useEffect } from "react";
import { List, Tooltip, Typography } from "antd";
import { onCopy } from "../../routes/RtfPage/RtfInfo";
import { UserAvatar } from "../LawCalendar/SmallMovementCalendar";
import { ReactComponent as UserIcon } from "../../assets/assets2_0/user.svg";

const ExpedientResponsibles = ({ exp_responsibles, justifyContent = "auto", withLabel }) => {
  const [_responsibles, setResponsibles] = useState(exp_responsibles);
  useEffect(() => {
    setResponsibles(exp_responsibles);
  }, [exp_responsibles]);

  return (
    <div style={{ display: 'flex', justifyContent: justifyContent, alignItems: 'center', gap: '1ch' }}>
      {withLabel && <UserIcon width={"12.5"} height="17" />}
      {withLabel && <Typography.Text>Responsables</Typography.Text>}
      {_responsibles.map(r => (
        <Tooltip title={`${r.firstname} ${r.lastname}`}>
          <span>
            <UserAvatar
              marginTop="0px"
              responsible={r}
              color={r.profile ? r.profile.color : "#3D529E"}
            />
          </span>
        </Tooltip>
      ))}
    </div>
  );
};

export default ExpedientResponsibles;
