import { SET_INSTALLMENT_TO_VIEW_PAYMENTS, TOGGLE_RELOAD_ACCOUNT, SET_FEE_TO_EDIT, SET_PAYMENT_TO_EDIT, TOGGLE_VIEW_PAYMENTS_MODAL, TOGGLE_CREATE_FEE_MODAL, TOGGLE_REGISTER_PAYMENT_MODAL, SET_INSTALLMENT_TO_REGISTER_PAYMENT } from "../../constants/ActionTypes";

const INIT_STATE = {
    showFeeModal: false,
    showRegisterPaymentModal: false,
    showViewPaymentsModal: false,
    paymentToEdit: null,
    feeToEdit: null,
    installmentToRegisterPayment: null,
    installmentToViewPayment: null,
    reloadAccount: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TOGGLE_CREATE_FEE_MODAL: {
            return { ...state, showFeeModal: action.payload }
        }

        case TOGGLE_REGISTER_PAYMENT_MODAL: {
            return { ...state, showRegisterPaymentModal: action.payload }
        }

        case SET_INSTALLMENT_TO_REGISTER_PAYMENT: {
            return { ...state, installmentToRegisterPayment: action.payload }
        }

        case TOGGLE_VIEW_PAYMENTS_MODAL: {
            return { ...state, showViewPaymentsModal: action.payload }
        }

        case SET_INSTALLMENT_TO_VIEW_PAYMENTS: {
            return { ...state, installmentToViewPayment: action.payload }
        }

        case SET_FEE_TO_EDIT: {
            return { ...state, feeToEdit: action.payload }
        }

        case SET_PAYMENT_TO_EDIT: {
            return { ...state, paymentToEdit: action.payload }
        }

        case TOGGLE_RELOAD_ACCOUNT: {
            return { ...state, reloadAccount: !state.reloadAccount }
        }

        default:
            return state;
    }
}

