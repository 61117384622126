import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { createContainer } from "unstated-next";
import { getCompletedTutorials, updateTutorials } from "../containers/Frames/OverlayTutorial";

const useTutorialStepper = () => {
  const [step, _setStep] = useState(0);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [showTuto, setShowTuto] = useState(!isTabletOrMobile && process.env.REACT_APP_SHOW_TUTORIAL === "1");
  const [tutorialsCompleted, setTutorialsCompleted] = useState(getCompletedTutorials());

  let deleteTutorial = t => {
    setTutorialsCompleted(tutorialsCompleted.filter(_t => t !== t));
    if(t === 'DASHBOARD') {
      setStep(1); 
    }
  }

  let completeTutorial = t => {
    setTutorialsCompleted([...tutorialsCompleted, t]);
    updateTutorials(t);
  }

  let setStep = c => {
    _setStep(c);
  };

  let next = () => {
    console.log(step + 1);
    _setStep(step + 1);
  };

  return { step, setStep, next, showTuto, setShowTuto, deleteTutorial, completeTutorial, tutorialsCompleted };
};

const TutorialStateContainer = createContainer(useTutorialStepper);
export default TutorialStateContainer;
