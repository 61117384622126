import React, { useState } from 'react';
import { Steps } from 'antd';
import AltaMpForm from './AltaMpForm';
import MercadoPagoBrick from './MercadoPagoBrick';

export default function AltaStudio() {

  const [current, setCurrent] = useState(0);
  const [studioData, setStudioData] = useState();

  const steps = [
    {
      title: 'Datos del estudio',
      description: 'Registro',
      content: <AltaMpForm onFinish={(values) => {
        setStudioData(values);
        next();
      }} />,
    },
    {
      title: 'Checkout',
      description: 'Subscripción',
      content: <MercadoPagoBrick studioData={studioData} />,
    },
  ]

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return <div style={{ padding: '5%' }}>
    <Steps
      progressDot
      direction="horizontal"
      current={current}
      items={steps}
    />
    <div className="steps-content">{steps[current].content}</div>

  </div>
}