import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";

const CallInOutToggle = ({ value, onChange }) => {
  const [v, setV] = useState(value || "IN");

  const handleChange = () => {
    const toggleVal = v === "IN" ? "OUT" : "IN";
    setV(toggleVal);
    onChange && onChange(toggleVal);
  };

  return (
    <Button 
      style={{width: '100%'}}
      onClick={() => handleChange()}
      icon={
        v === "IN" ? (
          <ArrowLeftOutlined style={{ transform: "rotate(-30deg)" }} />
        ) : (
          <ArrowRightOutlined style={{ transform: "rotate(-30deg)" }} />
        )
      }
      className={v === "IN" ? "edit-button" : "delete-button"}
    >
      {v === "IN" ? "Entrante" : "Saliente"}
    </Button>
  );
};

export default CallInOutToggle;
