import { Form, Input, Button, message } from "antd";
import React, { useState } from "react";
import axios, { authHeaders } from "../../util/Api";

const RequestFilesForm = ({ expedient, reqFileInfo, onSubmitted }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = values => {
    setLoading(true);
    generateRequestFile(
      { ...values, expedient_id: expedient.id },
      reqFileInfo ? reqFileInfo.id : null
    )
      .then(resp => {
        onSubmitted(resp);
        message.success("Indicaciones guardadas.");
      })
      .catch(e => console.log(e))
      .finally(() => setLoading(false));
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit} initialValues={reqFileInfo}>
      <Form.Item
        rules={[{ required: true, message: "Escriba algunas indicaciones" }]}
        label="Indicaciones"
        name="indications"
      >
        <Input.TextArea  rows={3}></Input.TextArea>
      </Form.Item>
      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit">
          Guardar
        </Button>
      </Form.Item>
    </Form>
  );
};

async function generateRequestFile(data, id) {
  let resp;
  if (id) {
    resp = await axios.patch(`/expedient-file-request/${id}`, data, {
      headers: {
        ...authHeaders()
      }
    });
  } else {
    resp = await axios.post(`/expedient-file-request`, data, {
      headers: {
        ...authHeaders()
      }
    });
  }
  return resp.data;
}

export default RequestFilesForm;
