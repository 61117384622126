import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Table, Tag } from "antd";
import React from "react";
import moment from "moment";
import DeleteLogButton from "./DeleteLogButton";
import NotifyCallButton from "./NotifyCallButton";
import { Link } from "react-router-dom";
import CallLogForm from "./CallLogForm";
import { useMediaQuery } from "react-responsive";
import MobileTableEntry from "../../routes/RtfPage/components/MobileTableEntry";

const CallLogList = ({
  logs,
  onDeleted,
  notify,
  showExpedient,
  edit,
  onUpdated
}) => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' })

  let cols = [
    {
      dataIndex: "type",
      key: "type",
      title: "Tipo",
      width: "15%",
      render: type => (
        <Tag
          icon={
            type === "IN" ? (
              <ArrowLeftOutlined style={{ transform: "rotate(-30deg)" }} />
            ) : (
              <ArrowRightOutlined style={{ transform: "rotate(-30deg)" }} />
            )
          }
          color={type === "IN" ? "green" : "red"}
        >
          {type === "IN" ? "ENTRANTE" : "SALIENTE"}
        </Tag>
      ),
      filters: [
        {
          text: "ENTRANTE",
          value: "IN"
        },
        {
          text: "SALIENTE",
          value: "OUT"
        }
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0
    },
    {
      dataIndex: "contact",
      key: "contact",
      title: "Contacto",
      width: "15%",
      render: contact => `${contact.firstname || ""} ${contact.lastname || ""}`
    },
    {
      dataIndex: "called_at",
      key: "called_at",
      title: "Fecha",
      width: "15%",
      render: called_at => moment(called_at).format("DD/MM/YYYY hh:mm a"),
      sorter: (a, b) => {
        return moment(a.called_at).unix() - moment(b.called_at).unix();
      }
    },
    {
      dataIndex: "observation",
      key: "observation",
      title: "Observación",
      width: "30%"
    }
  ];

  if (showExpedient) {
    cols = [
      ...cols,
      {
        dataIndex: "expedient",
        key: "expedient",
        title: "Exp.",
        width: "10%",
        render: exp =>
          !exp ? (
            "-"
          ) : (
            <Link to={`/expedient/details#${exp.uuid}`}>
              {exp.expedient_number}
            </Link>
          )
      }
    ];
  }

  cols = [
    ...cols,
    {
      dataIndex: "id",
      key: "actions",

      title: "Acciones",
      width: "15%",
      render: (id, log) => {
        const notifyTo = log.expedient
          ? log.expedient.expedient_responsibles.map(r => r.id)
          : [];
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '1ch' }}>
            <DeleteLogButton
              id={id}
              onDeleted={deletedLog => {
                onDeleted(logs.filter(l => l.id !== deletedLog.id));
              }}
            />
            {notify && (
              <NotifyCallButton call_log_id={id} notify_to={notifyTo} />
            )}
            {edit && (
              <CallLogForm
                expedient_id={log.expedient_id}
                contact={log.contact}
                callLog={log}
                onUpdated={_log => {
                  onUpdated && onUpdated(logs.map(l => l.id === _log.id ? _log : l));
                }}
              />
            )}
          </div>
        );
      }
    }
  ];

  return isTabletOrMobile ? <>
    {logs.map(log => <MobileTableEntry item={log} columns={cols} key={log.id} />)}
  </> : (
    <Table
      className="gx-table-responsive"
      columns={cols}
      dataSource={logs || []}
    />
  );
};

export default CallLogList;
