import { Button } from "antd";
import React, { useState } from "react";
import axios, { authHeaders } from "../../util/Api";

const CloseRequestLinkButton = ({ reqFileInfo }) => {

  console.log(reqFileInfo);

  const [closed, setClosed] = useState(reqFileInfo.closed);
  const [loading, setLoading] = useState(false);

  const handleToggle = () => {
    setLoading(true);
    openOrCloseButton(reqFileInfo.id)
      .then(resp => {
        setClosed(!closed);
        setLoading(false);
      })
      .catch(e => console.log(e))
      .finally(() => setLoading(false));
  };

  return (
    <Button
      loading={loading}
      onClick={() => handleToggle()}
      size="small"
      className={closed ? "edit-button" : "delete-button"}
    >
      {closed ? "Activar link" : "Desactivar link"}
    </Button>
  );
};

async function openOrCloseButton(id) {
  const resp = await axios.patch(`/expedient-file-request-toggle/${id}`, {}, {
    headers: {
      ...authHeaders()
    }
  });
  return resp.data;
}

export default CloseRequestLinkButton;
