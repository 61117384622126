import React, { useState, useEffect } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from "antd";
import CountryService from "../../services/geographic.service";
const { Option } = Select;

export const ProvinceSelect = ({
  getFieldDecorator,
  country,
  province,
  onSelect,
  name,
  required,
  disabled = false,
  label
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (country) {
      CountryService.getProvinces(country)
        .then(data => {
          setData(data.data.provinces);
          setLoading(false);
        })
        .catch(err => console.log(err));
    } else {
      setLoading(false);
    }
  }, [country]);

  return getFieldDecorator ? (
    <Form.Item key="province" label={label}>
      {getFieldDecorator(name || "province", {
        rules: [
          {
            required,
            message: `Debe indicar la ${label}.`
          }
        ],
        initialValue: province || null
      })(
        <Select
          className="responsive-input"
          optionFilterProp="children"
          showSearch
          allowClear
          placeholder={label}
          style={{ width: "100%" }}
          loading={loading}
          onSelect={v => onSelect(v)}
          disabled={disabled}
        >
          <Option key={'none'} value={null}>Ninguna</Option>
          {data.map(_province => (
            <Option key={_province.id} value={_province.id}>
              {_province.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  ) : (
    <Select
      allowClear
      optionFilterProp="children"
      showSearch
      placeholder={label}
      style={{ width: "100%" }}
      loading={loading}
      onSelect={v => onSelect(v)}
      disabled={disabled}
    >
      <Option key={'none'} value={null}>Ninguna</Option>
      {data.map(_province => (
        <Option key={_province.id} value={_province.id}>
          {_province.name}
        </Option>
      ))}
    </Select>
  );
};

ProvinceSelect.defaultProps = {
  onSelect: () => null,
  required: true,
  label: 'Provincia/Estado'
};