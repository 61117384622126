import React, { useEffect } from 'react';
import moment from 'moment';
import { Typography } from 'antd';
import ExpedientMovementService from '../../services/expedient_movement.service';
import { onCopy } from '../../routes/RtfPage/RtfInfo';
import "../../../src/law-components/ExpedientInfo/index.css";

export function ExpiresAtInfo({ expedient }) {

    const [expiresAt, setExpiresAt] = React.useState("-");
    const [expiresIn, setExpiresIn] = React.useState();

    useEffect(() => {
        ExpedientMovementService.getLastActionMovement(expedient.id)
            .then(response => {
                if (response.data) {
                    setExpirationDate(response.data);
                }
            }).catch(e => {
                console.log(e);
            });
    }, [expedient.expires_in, expedient.expedient_type.expires_in]);

    const setExpirationDate = (lastMovementDate) => {
        let _exp_at = "-";
        if (expedient.expires_in) {
            _exp_at = moment(lastMovementDate).add(expedient.expires_in, "days").format('DD/MM/YYYY');
            setExpiresIn(expedient.expires_in);
        } else if (expedient.expedient_type.expires_in) {
            _exp_at = moment(lastMovementDate).add(expedient.expedient_type.expires_in, "days").format('DD/MM/YYYY');
            setExpiresIn(expedient.expedient_type.expires_in);
        }
        setExpiresAt(_exp_at);
    }

    return <>
        <Typography.Text strong>Caduca el </Typography.Text>
        <Typography.Text
            className="copyable-text"
            onClick={(event) => {
                if (event.currentTarget.innerHTML !== "-") {
                    onCopy(event.currentTarget.innerHTML)
                }
            }}
        >{expiresIn > 0 ? expiresAt : "-"}</Typography.Text>
    </>;
}