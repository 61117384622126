import { Button, Empty } from 'antd';
import React from 'react'


const EmptyExpedientMovements = ({ AddNew }) => {
    return (
        <div className='custom-empty' >
            <Empty description={
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "5px" }}>
                    <span>Este expediente aún no contiene movimientos</span>
                    <span>Crea una nuevo con el siguiente boton</span>
                    <Button
                        className="responsive-input responsive-button"
                        style={{ borderRadius: "15px", height: "32px", backgroundColor: "#016ece", width: 'fit-content' }}
                        onClick={AddNew}
                        type={"primary"}
                    >
                        Nuevo Movimiento
                    </Button >
                </div>
            } />
        </div>
    )
}

export default EmptyExpedientMovements