import React, { useState, useEffect } from "react";
import { Select, Form } from "antd";
import { useMediaQuery } from "react-responsive";
import '@ant-design/compatible/assets/index.css';
import ExpedientMovementService from "../../services/expedient_movement.service";

const { Option } = Select;

const ImportantFilter = ({ value, onSelect }) => {
    const isTabletorMobile = useMediaQuery({ query: "(max-width: 768px)" });
    return (
        <Select
            className="responsive-input"
            defaultValue={value}
            onSelect={v => onSelect(v)}
            style={{
                width: isTabletorMobile ? "100%" : "14%",
            }}
            size="large"
            placeholder="Importancia"
        >
            <Option key={10} value="todo">
                Todo
            </Option>
            <Option key={11} value="important">
                Importante
            </Option>
            <Option key={12} value="noimportant">
                No importante
            </Option>
        </Select>
    );
};

const JudicialFilter = ({ value, onSelect }) => {
    const isTabletorMobile = useMediaQuery({ query: "(max-width: 768px)" });
    return (
        <Select
            className="responsive-input"
            defaultValue={value}
            onSelect={v => onSelect(v)}
            style={{
                width: isTabletorMobile ? "100%" : "14%",
            }}
            size="large"
            placeholder="Judicial"
        >
            <Option key={7} value="todo">
                Todo
            </Option>
            <Option key={8} value="judicial">
                Judicial
            </Option>
            <Option key={9} value="extrajudicial">
                Extra judicial
            </Option>
        </Select>
    );
};

const StatusFilter = ({ value, onSelect }) => {
    const isTabletorMobile = useMediaQuery({ query: "(max-width: 768px)" });
    return (
        <Select
            className="responsive-input"
            defaultValue={value}
            onSelect={v => onSelect(v)}
            style={{
                width: isTabletorMobile ? "100%" : "14%",
            }}
            size="large"
            placeholder="Status"
        >
            <Option key={4} value="todo">
                Todo
            </Option>
            <Option key={5} value="pendiente">
                Pendiente
            </Option>
            <Option key={6} value="realizado">
                Hecho
            </Option>
        </Select>
    );
};

const MovementTypeFilter = ({
    currentMovementType,
    expedient,
    multi = false,
    allowNull = false,
    noLabel = false,
    type = "all",
    containerStyle,
    onChange = () => null
}) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        ExpedientMovementService.movementTypeUsedInExpedient(expedient)
            .then(data => {
                setData(data.data);
                setLoading(false);
            })
            .catch(err => console.log(err));

    }, []);

    return (
        <Form.Item
            style={{ ...containerStyle }}
            initialValue={currentMovementType}
            name="movement_type_id"
            key="movement_type_id"
            label={noLabel ? null : `Tipo${multi ? 's' : ''} de movimiento${multi ? 's' : ''}`}
            rules={
                [
                    {
                        required: true,
                        message: "Debe indicar el tipo de movimiento."
                    }
                ]
            }
        >
            <Select
                placeholder="Tipo de movimiento"
                className="responsive-input"
                style={{ width: "100%" }}
                optionFilterProp="children"
                showSearch
                onChange={v => {
                    if (v !== null) {
                        onChange(data[data.findIndex(m => m.id === v)])
                    } else {
                        onChange(null)
                    }

                }}
                loading={loading} mode={multi ? "multiple" : "default"}>
                {allowNull && <Option key="all" value={null}>VER TODO</Option>}
                {data.map(movement_type => (
                    <Option key={movement_type.id} value={movement_type.id}>
                        {movement_type.name}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export {
    StatusFilter,
    JudicialFilter,
    ImportantFilter,
    MovementTypeFilter
};