import '@ant-design/compatible/assets/index.css';
import { Select, Form } from "antd";
import UserService from "../../services/user.service";
import React, { useState, useEffect } from "react";

const { Option } = Select;

const Antd4UserSelect = ({
  users = [] || null,
  mode = "default",
  formKey,
  label = "Usuarios",
  hideOnSelect = false,
  withTrashed,
  required = false
}) => {

  const [currentUsers, setCurrentUser] = useState(users);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    UserService.getUsers(1, true)
      .then(data => {
        if (withTrashed) {
          setData(data.data);
        } else {
          //Ocultar usuarios borrados a menos que se esté editando y/o esos usuarios borrados esten pre-seleccionados
          const users_list = [...data.data].filter(user => (user.deleted_at === null || users && users.includes(user.id)));
          setData(users_list);
        }
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, [currentUsers]);

  return (
    <Form.Item
      key={formKey}
      name={formKey}
      label={label}
      initialValue={currentUsers}
      rules={[
        {
          required: required,
          message: "Debe indicar el(los) responsable(s)"
        }
      ]}
    >
      <Select
        className='responsive-input'
        defaultValue={currentUsers}
        onSelect={() => {
          hideOnSelect && setOpen(false);
        }}
        onDeselect={() => {
          hideOnSelect && setOpen(false);
        }}
        onDropdownVisibleChange={setOpen}
        open={open}
        optionFilterProp="children"
        showSearch
        loading={loading} mode={mode}>
        {data.map(user => (
          <Option key={user.id} value={user.id} style={{ color: user.deleted_at ? 'red' : 'inherit' }}>
            {user.firstname} {user.lastname}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export const UserSelect = ({ currentUser, onSelect }) => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    UserService.getUsers()
      .then(data => {
        setData(data.data);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, [currentUser]);

  const handleSelect = (v) => {
    onSelect(v)
  }

  return <Select
    className='responsive-input'
    defaultValue={currentUser}
    value={currentUser}
    onSelect={handleSelect}
    onDropdownVisibleChange={setOpen}
    open={open}
    optionFilterProp="children"
    showSearch
    loading={loading}>
    {data.map(user => (
      <Option key={user.id} value={user.id} style={{ color: user.deleted_at ? 'red' : 'inherit' }}>
        {user.firstname} {user.lastname}
      </Option>
    ))}
  </Select>
}

export default Antd4UserSelect;
