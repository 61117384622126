import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Tag
} from "antd";
import axios, { authHeaders, baseURL } from "../../util/Api";
import React, { useState } from "react";
import Antd4ClientSelect from "../../routes/LettersPage/components/antd4ClientSelect";
import {
  EditOutlined,
  PlusCircleOutlined,
  PlusOutlined
} from "@ant-design/icons";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";
import CreateClientModal from "../../modals/CreateClientModal";
import CallInOutToggle from "./CallInOutToggle";
import { ReactComponent as EditIcon } from '../../assets/assets2_0/edit.svg'
const CallLogForm = ({
  onCreated,
  shortButton,
  expedient_id,
  contact,
  callLog,
  onUpdated
}) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [_contact, setContact] = useState(contact);
  const [form] = useForm();

  const handleSubmit = values => {
    let _values = values;
    if (_contact) {
      Object.assign(_values, {
        client_id: _contact.id,
      });
    }
    setLoading(true);
    if (callLog) {
      updateLog({ ..._values, expedient_id }, callLog.id)
        .then(log => {
          onUpdated && onUpdated(log);
          message.success("Registro editado");
          setVisible(false);
        })
        .catch(e => {
          console.log(e);
          message.error("Ha ocurrido un error");
        })
        .finally(() => setLoading(false));
    } else {
      createLog({ ..._values, expedient_id })
        .then(log => {
          form.resetFields();
          onCreated(log);
          message.success("Registro guardado");
          setVisible(false);
        })
        .catch(e => message.error("Ha ocurrido un error"))
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <Modal
        destroyOnClose
        title="Nueva llamada"
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={
            callLog
              ? {
                ...callLog,
                called_at: moment(callLog.called_at)
              }
              : { called_at: moment(), type: "IN" }
          }
        >
          <Form.Item
            label="Tipo"
            name="type"
            rules={[
              { required: true, message: "Debe indicar el tipo de llamada" }
            ]}
          >
            <CallInOutToggle />
          </Form.Item>
          {!_contact ? (
            <Form.Item
              label="Contacto"
              name="client_id"
              rules={[{ required: true, message: "Debe indicar un contacto" }]}
            >
              <Antd4ClientSelect
                onNewClient={c => {
                  setContact(c);
                }}
              />
            </Form.Item>
          ) : (
            <Form.Item>
              <Tag
                style={{ width: "100%" }}
                closable
                onClose={() => setContact()}
              >
                {_contact.firstname} {_contact.lastname}
              </Tag>
            </Form.Item>
          )}
          <Form.Item
            label="Fecha y hora de la llamada"
            name="called_at"
            rules={[{ required: true, message: "Debe indicar fecha y hora" }]}
          >
            <DatePicker className="responsive-input" format="DD/MM/YYYY HH:MM" />
          </Form.Item>
          <Form.Item label="Observación" name="observation">
            <Input.TextArea className="responsive-input" rows={3}></Input.TextArea>
          </Form.Item>
          <Form.Item>
            <Button
              className="responsive-input"
              icon={<PlusCircleOutlined />}
              type="primary"
              loading={loading}
              htmlType="submit"
            >
              Registrar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {callLog ? (
        <EditIcon
          onClick={() => setVisible(true)}
          style={{ cursor: 'pointer' }}
          color= "#016ECE"
        />
      ) : (
        <Button
          className='responsive-button responsive-input'
          type="primary"
          onClick={() => setVisible(true)}
          icon={<PlusOutlined />}
          children={shortButton ? null : "Nueva llamada"}
        />
      )}
    </>
  );
};

async function createLog(values) {
  const resp = await axios.post(`${baseURL}/call-log`, values, {
    headers: {
      ...authHeaders()
    }
  });
  return resp.data;
}

async function updateLog(values, id) {
  const resp = await axios.patch(`${baseURL}/call-log/${id}`, values, {
    headers: {
      ...authHeaders()
    }
  });
  return resp.data;
}

export default CallLogForm;
