import React, { useState, useEffect } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from "antd";
import JudicialService from "../../services/judicial.service";
import { connect } from "react-redux";
const { Option } = Select;

const JurisdictionSelect = ({
  getFieldDecorator,
  jurisdiction,
  onSelect,
  province,
  mode,
  required,
  disabled,
  style,
  currentLang
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (province) {
      setLoading(true);
      JudicialService.getJurisdictions(province)
        .then(data => {
          setData(data.data);
          setLoading(false);
        })
        .catch(err => console.log(err));
    }
  }, [province]);

  return mode === "form" ? (
    <Form.Item key="jurisdiction" label={currentLang.text.jurisdiction}>
      {getFieldDecorator("jurisdiction", {
        rules: [
          {
            required,
            message: `Debe indicar la ${currentLang.text.jurisdiction}.`
          }
        ],
        initialValue: !loading ? jurisdiction || null : null
      })(
        <Select
          className="responsive-input"
          style={style}
          placeholder={currentLang.text.jurisdiction}
          optionFilterProp="children"
          showSearch
          disabled={disabled}
          allowClear
          loading={loading}
          onChange={v => onSelect(v)}
        >
          {data.map(_jurisdiction => (
            <Option key={_jurisdiction.id} value={_jurisdiction.id}>
              {_jurisdiction.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  ) : (
    <Select
      key={`${province}-jurisdiction`}
      className="responsive-input"
      style={style}
      placeholder={currentLang.text.jurisdiction}
      optionFilterProp="children"
      showSearch
      dropdownMatchSelectWidth
      disabled={disabled}
      allowClear
      loading={loading}
      onChange={v => onSelect(v)}
    >
      {data.map(_jurisdiction => (
        <Option key={_jurisdiction.id} value={_jurisdiction.id}>
          {_jurisdiction.name}
        </Option>
      ))}
    </Select>
  );
};

const mapStateToProps = ({ lang }) => {
  const { currentLang } = lang
  return { currentLang }
}

JurisdictionSelect.defaultProps = {
  mode: "form",
  onSelect: () => null,
  required: false,
  disabled: false,
  style: {}
};

export default connect(mapStateToProps)(JurisdictionSelect)