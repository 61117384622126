import { Checkbox } from 'antd';
import React, { useState } from 'react';

const CustomCheckbox = ({ defaultChecked, handleChange }) => {

    const [checked, setChecked] = useState(defaultChecked);

    return (
        <Checkbox
            className={!checked ? "switch-checkbox movement-status-checkbox" : "movement-status-checkbox"}
            checked={checked}
            onChange={(e) => {
                setChecked(!checked)
                handleChange && handleChange(e)
            }} />
    )
}

export default CustomCheckbox;