import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Typography } from "antd";

export const CenteredLoading = ({ tip, children, minHeight }) => {
  return (
    <div
      style={{
        minHeight: minHeight || "40px",
        marginTop: 10,
        display: 'grid',
        width: "100%",
        placeItems: "center",
        textAlign: "center"
      }}
    >
      <Spin
        size="large"
        indicator={<LoadingOutlined />}
        children={children}
        style={{ color: "#3D529E" }}
      />
      <Typography.Text style={{ color: "#3D529E" }}><br />{tip}</Typography.Text>
    </div>
  );
};

export default CenteredLoading;

CenteredLoading.defaultProps = {
  tip: "",
  children: null
};
