import React from 'react';
import Echo from "laravel-echo"
const client = require('pusher-js')

const EchoInstance = new Echo({
    broadcaster: 'pusher',
    key: '123456',
    wsHost: 'localhost',
    wsPort: 6001,
    forceTLS: false,
    disableStats: false,
    client: client
});

export const EchoContext = React.createContext(EchoInstance)