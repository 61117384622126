import { Subject } from "rxjs";

const subject = new Subject();

export const lastMovementUpdater = {
  updater: subject.asObservable(),
  updatelastMovementCounter: movement => {
    subject.next(movement);
  }
};
