import axios from "../util/Api";

const TagService = {
  searchTag: (params = {}) => {
    return axios.get(`/tag/search`, {
      params:params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },getTagsByStudio: (params = {}) => {
    return axios.get('/get/tags/studio',{
      params:params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
    
  }
};

export default TagService;
