import { LoadingOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { ReactComponent as ExcelIcon } from "../assets/assets2_0/excel-button.svg";
import { Tooltip } from "antd";

const ExcelButton = ({ width, height, onClick, style, loading }) => {
  const [isExcelHovered, setIsExcelHovered] = useState(false);

  return (
    <div style={{ width: width, height: height, position: "relative" }}>
      {!loading && (
        <Tooltip title="Exportar a Excel">
          <ExcelIcon
            width={width}
            height={height}
            style={{
              ...style,
              cursor: "pointer",
              color: isExcelHovered ? "#35B6BC" : "#24335D"
            }}
            onClick={() => onClick && onClick()}
            onMouseEnter={() => setIsExcelHovered(true)}
            onMouseLeave={() => setIsExcelHovered(false)}
          />
        </Tooltip>
      )}
      {loading && (
        <div
          style={{
            width: width,
            height: height,
            position: "absolute",
            top: "0px",
            left: "0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#3D529E",
            borderRadius: "50%"
          }}
        >
          <LoadingOutlined style={{ color: "#fff" }} />
        </div>
      )}
    </div>
  );
};

export default ExcelButton;
