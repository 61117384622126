import React, { useState, useEffect, useMemo } from "react";
import { Modal, Typography } from "antd";
import ExpedientMovementFormWithSteps from "../law-components/Forms/ExpedientMovementFormWithSteps/ExpedientMovementFormWithSteps";
import { useExpedientMovementForm } from "../hooks/useExpedientMovementFormState";
import { useMediaQuery } from "react-responsive";

const CreateExpedienteMovementModal = ({
  visible,
  onCancel,
  expedient,
  onSaved,
  onUpdated,
  onFileUpload,
  expedient_movement,
  finish
}) => {
  const [_step, _setStep] = useState(0);
  const [fileListLength, setFileListLength] = useState(0);
  const [expedientMovement, setExpedientMovement] = useState(
    expedient_movement
  );

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const { clearForm } = useExpedientMovementForm();
  const [lastFile, onFileUploaded] = useState(null);

  useEffect(() => {
    setExpedientMovement(expedient_movement);
  }, [expedient_movement]);

  return useMemo(() => {
    return <Modal
      destroyOnClose
      visible={visible}
      onCancel={() => {
        _setStep(0);
        onCancel();
        clearForm();
      }}
      footer={null}
      title={
        <Typography.Text style={{ fontWeight: 800 }}>
          Nuevo movimiento
        </Typography.Text>}
      centered
      closable
      style={{ marginTop: '2.5ch' }}
      width={isTabletOrMobile ? "95vw" : "80vw"}
    >
      <ExpedientMovementFormWithSteps
        onCancel={() => {
          onCancel();
        }}
        onSaved={(m, attach) => {
          finish && finish(m)
          attach && _setStep(1);
          onSaved(m, attach);
          setExpedientMovement(m);
          clearForm();
        }}
        onUpdated={(m, attach) => {
          attach && _setStep(1);
          onUpdated(m, attach);
          clearForm();
        }}
        expedient={expedient}
        expedient_movement={expedient_movement}
      />
    </Modal>
  }, [visible]
  );
};
const stepContentStyle = {
  paddingTop: 20
};

CreateExpedienteMovementModal.defaultProps = {
  finish: () => null,
  onFileUpload: () => null
};
export default CreateExpedienteMovementModal;
