import { CloseOutlined, UnlockOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Modal } from "antd";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import UserService from "../../../services/user.service";

const ChangeMyPassword = () => {
  const [modalV, setModalV] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

  return (
    <>
      <Modal
        closeIcon={<CloseOutlined />}
        width={isTabletOrMobile ? "90%" : "60%"}
        destroyOnClose
        footer={null}
        title="Cambiar mi contraseña"
        onCancel={() => setModalV(false)}
        visible={modalV}
      >
        <ChangePasswordForm
          onChanged={() => {
            setModalV(false);
            message.success("Su contraseña ha sido actualizada");
          }}
        />
      </Modal>
      <Button
        style={{ width: "auto", minHeight: "45px", borderRadius: "15px", border: "0px" }}
        className="reponsive-input responsive-button"
        onClick={() => setModalV(true)}
        type="primary"
        icon={<UnlockOutlined />}
      >
        Cambiar contraseña
      </Button>
    </>
  );
};

const ChangePasswordForm = ({ onChanged }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = values => {
    setLoading(true);
    UserService.changeMyPassword(values)
      .then(resp => {
        setLoading(false);
        onChanged();
      })
      .catch(e => {
        message.error("Error al actualizar la contraseña");
        setLoading(false);
      });
  };
  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <Form.Item
        name="old_password"
        rules={[{ required: true, message: "Campo requerido" }]}
        label="Contraseña actual"
      >
        <Input.Password className="responsive-input" />
      </Form.Item>
      <Form.Item
        name="new_password"
        rules={[
          { required: true, message: "Campo requerido" },
          {
            min: 8,
            message: "Su contraseña debe ser mayor a 8 caracteres."
          }
        ]}
        label="Nueva contraseña"
      >
        <Input.Password className="responsive-input" />
      </Form.Item>
      <Form.Item
        name="new_password_confirm"
        rules={[
          { required: true, message: "Campo requerido" },
          {
            min: 8,
            message: "Su contraseña debe ser mayor a 8 caracteres."
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("new_password") === value) {
                return Promise.resolve();
              }
              return Promise.reject("Las contraseñas no coinciden");
            }
          })
        ]}
        label="Confirme contraseña (nueva)"
      >
        <Input.Password className="responsive-input" />
      </Form.Item>
      <Form.Item label="">
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: "auto", minHeight: "45px", borderRadius: "15px", border: "0px" }}
          className="reponsive-input responsive-button"
        >
          Actualizar contraseña
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ChangeMyPassword;
