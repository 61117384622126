import {TOGGLE_COLLAPSE_FILTERS} from '../../constants/ActionTypes'
import getViewport from '../../util/helpers';

const {w} = getViewport();
const INIT_STATE = {
  filtersCollapsed: w < 960
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case TOGGLE_COLLAPSE_FILTERS: {
      return {...state, filtersCollapsed: action.payload};
    }
    default:
      return state;
  }
}
