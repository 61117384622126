import { CheckCircleOutlined } from "@ant-design/icons";
import { message, Modal, Input, Form, Button, Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { history } from "../../appRedux/store";
import TicketService from "../../services/ticket.service";
import { ReactComponent as HeadphonesIcon } from "../../assets/assets2_0/headphones.svg";

const TicketModal = () => {
  return (
    <>
      <Tooltip trigger="hover" title="Soporte Tecnico">
        <HeadphonesIcon
          style={{ width: "19px", cursor: "pointer" }}
          onClick={() => history.push("/tickets")}
        />
      </Tooltip>
    </>
  );
};

export default TicketModal;

export const TicketForm = ({ onDone, onCancel, replyOf, subject }) => {
  const [sent, send] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const ticketTypes = ["Consulta", "Reclamo", "Pedido"];

  const handleSubmit = async values => {
    setLoading(true);
    values.reply_of = replyOf ? replyOf : null;
    /* values.subject = replyOf ? subject : values.subject; */
    try {
      const response = await TicketService.ticketToAdmin(values);
      message.success("Solicitud enviada");
      form.resetFields();
      onDone && onDone(response.data);
    } catch (error) {
      console.log(error);
      message.error("Ha ocurrido un error");
    } finally {
      setLoading(false);
    }
  };

  return sent ? (
    <div
      style={{
        boxSizing: "border-box",
        height: "250px",
        textAlign: "center",
        alignContent: "space-between"
      }}
    >
      <CheckCircleOutlined style={{ fontSize: "100px", color: "#c1c1c1" }} />
      <br />
      ¡Ticket enviado!
    </div>
  ) : (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      {!replyOf && (
        <Form.Item
          name="subject"
          key="subject"
          label="Tipo de solicitud"
          rules={[
            {
              required: true,
              message: "Debe seleccionar el asunto del mensaje"
            }
          ]}
        >
          <Select key="subject">
            {ticketTypes.map(type => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        name="body"
        key="body"
        label="Texto"
        rules={[
          {
            required: true,
            message: "Desarrolle el texto de su mensaje"
          }
        ]}
      >
        <Input.TextArea rows="4" />
      </Form.Item>

      <Form.Item key="submit">
        <Button type="primary" htmlType="submit" loading={loading}>
          Enviar
        </Button>
      </Form.Item>
    </Form>
  );
};
