import React, { useState } from "react";
import { Form, Col, Row, DatePicker, Input, Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import axios, { authHeaders } from "../../../util/Api";

export function ExpenseForm({ expense, onUpdated, onCreated }) {
  const [fileList, setFileList] = useState([]);
  const [type, setType] = useState(expense ? expense.type : "OUT");
  const [date, setDate] = useState(moment());
  const [loading, setLoading] = useState(false);

  const uploadProps = {
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList([...newFileList]);
    },
    beforeUpload: file => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList
  };

  const handleSubmit = values => {
    setLoading(true);
    const data = new FormData();
    data.append("file", fileList[0]);
    data.append("type", type);
    data.append("date", date.format("YYYY-MM-DD"));
    data.append("description", values.description);
    data.append("amount", values.amount);
    data.append("concept", values.concept);
    if (expense) {
      updateExpenseRecord(data, expense.id)
        .then(resp => {
          onUpdated(resp);
        })
        .catch(err => {
          if (err.response && err.response.status === 403) {
            message.error("No tienes permisos para editar un registro");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      submitExpenseRecord(data)
        .then(resp => {
          onCreated(resp);
        })
        .catch(err => {
          if (err.response && err.response.status === 403) {
            message.error("No tienes permisos para crear un registro");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={
        expense ? { ...expense, date: moment(expense.date) } : null
      }
    >
      <Row type="flex" justify="start" gutter={[10, 10]}>
        <Col span={24}>
          <Form.Item name="date" label="Fecha">
            <DatePicker
              onChange={setDate}
              defaultValue={moment()}
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </Col>
        <Col span={18}>
          <Form.Item name="concept" label="Concepto">
            <Input autoComplete="cc-csc" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="description" label="Descripción">
            <Input.TextArea row={3}></Input.TextArea>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="type" label="Tipo">
            <Button
              style={{
                color: "#fff",
                backgroundColor: type === "IN" ? "#1890ff" : "#ff4d4f"
              }}
              onClick={() => {
                setType(type === "IN" ? "OUT" : "IN");
              }}
            >
              {type === "IN" ? "INGRESO" : "GASTO"}
            </Button>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="amount" label="Monto">
            <Input autoComplete="cc-csc" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Upload {...uploadProps} multiple>
              <Button icon={<UploadOutlined />}>Adjuntar</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Button loading={loading} type="primary" htmlType="submit">
              {expense ? "Actualizar" : "Guardar"}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

async function updateExpenseRecord(data, id) {
  const resp = await axios.post(`/studio-account/${id}`, data, {
    headers: authHeaders()
  });
  return resp.data;
}

async function submitExpenseRecord(data) {
  const resp = await axios.post("/studio-account", data, {
    headers: authHeaders()
  });
  return resp.data;
}
