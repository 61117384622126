import { InfoCircleOutlined, SelectOutlined } from '@ant-design/icons';
import { Table, Popover, Typography, Descriptions, Badge, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import ExpedientResponsibles from '../ExpedientResponsibles';
import { JudicialEstructureInfo } from "../../law-components/ExpedientInfo"
import AccountBalance from '../ExpedientAccount/components/AccountBalance';

const { Item } = Descriptions;

const CheckedExpedientsTable = ({ fieldKey, fieldLabel, expedients, onUpdate, currentLang }) => {

  const columns = [
    {
      width: 30,
      key: "details",
      dataIndex: "id",
      render: (id, record) => (
        <Popover
          placement="top"
          zIndex={1060}
          title="Resumen del expediente"
          trigger="hover"
          content={
            <ViewExpedient
              currentLang={currentLang}
              fields={[
                "observations",
                "client",
                "front",
                "expedient_number",
                "observations",
                "folder",
                "jurisdiction",
                "status",
                "expedient_type",
                "claimed_amount",
                "reponsibles",
                "demand_begin_date",
                "init_date",
                "claim_date"
              ]}
              expedient={record}
            />
          }
        >
          <InfoCircleOutlined />
        </Popover>)
    }, {
      width: '28vw',
      key: "front",
      dataIndex: "front",
      title: "Expediente",
    },
    {
      title: `${fieldLabel}`,
      dataIndex: `${fieldKey}`,
      key: `${fieldKey}`,
      render: (key, expedient) => (
        <Typography.Text style={{ fontSize: ".7rem", color: "#038fde" }}>
          {key}
        </Typography.Text>
      )
    },
    {
      align: "center",
      key: "actions",
      dataIndex: "id",
      title: "Acción",
      render: (id, record) => (
        <Tooltip title="Visualizar">
          <SelectOutlined
            style={{ color: "#038fde", fontSize: "18px" }}
            type="primary"
            size='small'
            onClick={() => onUpdate(record)}
          />
        </Tooltip>


      )
    }

  ]
  return (
    <Table
      style={{ overflowX: 'hidden', overflowY: 'overlay', maxHeight: "150px" }}
      pagination={{ hideOnSinglePage: true }}
      showHeader={false}
      columns={columns}
      dataSource={expedients}
    />


  )
}

export default CheckedExpedientsTable;

const ViewExpedient = ({ expedient, fields, currentLang }) => {

  return (
    <Descriptions bordered>
      {fields.includes("client") && (
        <Item label={currentLang.text.client}>
          {expedient.client.firstname} {expedient.client.lastname}
        </Item>
      )}
      {fields.includes("front") && (
        <Item label="Caratula">
          {expedient.front}
        </Item>
      )}
      {fields.includes("expedient_number") && (
        <Item label="Nº de Expediente">
          {expedient.header ? (
            `${expedient.expedient_number}(${expedient.header.radicacion}-
              ${expedient.header.codigo_interno})`
          ) : (
            expedient.expedient_number
          )}
        </Item>
      )}
      {fields.includes("folder") && (
        <Item label="Nº de Carpeta">{expedient.folder}</Item>
      )}
      {fields.includes("jurisdiction") && (
        <Item label="Tribunal.">
          <JudicialEstructureInfo expedient={expedient} />
        </Item>
      )}
      {fields.includes("status") && (
        <Item label="Status">
          <Badge
            color={expedient.status ? expedient.status.color : "#038DEF"}
            status="processing"
            text={expedient.status.name}
          />
        </Item>
      )}
      {fields.includes("expedient_type") && (
        <Item label="Tipo">{expedient.expedient_type.name}</Item>
      )}
      {fields.includes("claimed_amount") && (
        <Item label="Monto reclamado">
          <AccountBalance amount={expedient.claimed_amount} size="1rem" />
        </Item>
      )}
      {fields.includes("responsibles") && (
        <Item label="Responsables">
          <ExpedientResponsibles
            exp_responsibles={expedient.expedient_responsibles}
          />
        </Item>
      )}
      {fields.includes("init_date") && (
        <Item label={currentLang.text.fecha_inicio}>
          {moment(expedient.init_date).format("DD/MM/YYYY")}
        </Item>
      )}
      {fields.includes("claim_date") && (
        <Item label="F. de reclamo">
          {expedient.claim_date
            ? moment(expedient.claim_date).format("DD/MM/YYYY")
            : "-"}
        </Item>
      )}
      {fields.includes("demand_begin_date") && (
        <Item label="F. de ingreso">
          {expedient.demand_begin_date
            ? moment(expedient.demand_begin_date).format("DD/MM/YYYY")
            : "-"}
        </Item>
      )}
    </Descriptions>
  );
};