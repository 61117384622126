import { Badge, Button, Popover, Table, Tag, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import ExpedientInfo from "../../../law-components/ExpedientInfo";
import moment from 'moment';
import { LazyExpedientInfo } from "../../../law-components/ExpedientInfo/LazyExpedientInfo";
import { connect } from "react-redux";

const AssociatedExpedients = ({ expedients, currentLang }) => {
  const cols = [
    {
      title: "Caratula",
      dataIndex: "front",
      key: "front"
    },
    {
      title: "N Expediente",
      dataIndex: "expedient_number",
      key: "expedient_number",
      render: (expedient_number, expedient) => (
        <Link to={`/expedient/details#${expedient.uuid}`}>
          <LazyExpedientInfo uuid={expedient.uuid}>
            <Typography.Text style={{ fontSize: ".7rem", color: "#038fde" }}>
              {expedient_number}
            </Typography.Text>
          </LazyExpedientInfo>
        </Link>
      )
    },
    {
      title: "Parte",
      dataIndex: "part",
      key: "part",
      render: (part) => <Tag>{part || currentLang.text.client}</Tag>
    },
    {
      title: "F. Inicio",
      dataIndex: "init_date",
      key: "init_date",
      render: (init_date) => moment(init_date).format("L")
    },
    {
      title: "F. Demanda",
      dataIndex: "demand_begin_date",
      key: "demand_begin_date"
    },
    {
      title: "Monto",
      dataIndex: "claimed_amount",
      key: "claimed_amount",
      render: claimed_amount => parseFloat(claimed_amount).toFixed(0)
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: ({ color, name }) => (
        <span>
          <Badge color={color} />
          {name}
        </span>
      )
    },
    {
      title: "Abrir",
      dataIndex: "uuid",
      key: "open",
      render: (uuid) => (
        <Link to={`/expedient/details#${uuid}`}>
          <Button size="small" type="primary">Abrir</Button>
        </Link>
      )
    }
  ];

  return <Table className="gx-table-responsive" dataSource={expedients} columns={cols}></Table>;
};

const mapStateToProps = ({ lang }) => {
  const { currentLang } = lang
  return { currentLang };
};

export default connect(mapStateToProps, {})(AssociatedExpedients);
