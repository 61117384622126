import { message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import JudicialService from '../../services/judicial.service';

const { Option } = Select;

const CourtFilterSelect = ({ value, onChange, filters, dateRange }) => {

    const isSmallDesktop = useMediaQuery({ query: "(max-width: 1366px)" });
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [abortControllerState, setAbortControllerState] = useState(undefined);

    useEffect(() => {

        if (loading && abortControllerState !== undefined) {
            abortControllerState.abort("Nueva request en proceso")
        }

        const abortController = new AbortController();
        const newSignal = abortController.signal;
        setAbortControllerState(abortController)

        setLoading(true)
        const { court, ...filterWithOurtCourt } = filters;
        JudicialService.getDashboardCourts({ ...filterWithOurtCourt, dateRange }, newSignal)
            .then(({ data }) => {
                setOptions(data)
                setLoading(false)
            })
            .catch(e => {
                if (e.message !== "canceled") {
                    message.error("Ha ocurrido un error")
                    setLoading(false)
                    console.log(e)
                }

            })

        return () => {
            if (loading && abortControllerState !== undefined) {
                abortControllerState.abort("Componente desmontado");
            }
        }
    }, [filters, dateRange])



    return (
        <Select
            loading={loading}
            className="linear-court-filter linear-filter"
            defaultValue={"all"}
            value={value}
            onChange={(v) => onChange(v !== "all" ? v : null)}
            placeholder="Juzgado"
            dropdownMatchSelectWidth={300}
        >
            <Option key="all" value="all">
                Todos los Tribunales
            </Option>
            {options.length > 0 ? options.map((court) => {
                return (
                    <Option key={court.id} value={court.id}>
                        {court.name}
                    </Option>
                )
            }) : <></>}
        </Select>
    )
}

export default CourtFilterSelect;