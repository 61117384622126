import { Avatar, Button, List, notification, Popconfirm, Popover } from 'antd';
import React, { useContext, useEffect } from 'react';
import { EchoContext } from '../../contexts/EchoContext';
import moment from 'moment';
import { Link } from 'react-router-dom';
import NotificationsService from '../../services/notifications.service';
import MailCounterContainer from '../../unstatedStores/MailNotificacion';
import { ReactComponent as BellIcon } from '../../assets/assets2_0/bell.svg';
const Notifications = (props) => {
  const echo = useContext(EchoContext);
  const { user } = props;
  const mailCounterState = MailCounterContainer.useContainer();

  useEffect(() => {
    echo.channel(`Metagestion.User.${user.id}`)
      .listen('.new-movement', (e) => onNewNotification(e, props.onNotification))
      .listen('.new-expedient', (e) => onNewNotification(e, props.onNotification))
      .listen('.movement-updated', (e) => onNewNotification(e, props.onNotification))
      .listen('.expedient-updated', (e) => onNewNotification(e, props.onNotification))
      .listen('.movement-deleted', (e) => onNewNotification(e, props.onNotification))
      .listen('.expedient-deleted', (e) => onNewNotification(e, props.onNotification))
      .listen('.expedient-restored', (e) => onNewNotification(e, props.onNotification))
      .listen('.expedient-expired', (e) => onNewNotification(e, props.onNotification))
      .listen('.prescription-alert', (e) => onNewNotification(e, props.onNotification))
      .listen('.call-log-notification', (e) => onNewNotification(e, props.onNotification))
      .listen('.scan-alert', (e) => onNewNotification(e, props.onNotification))
      .listen('.new-mail', (e) => mailCounterState.add(1))
      .listen('.subscription-renewed', (e) => {
        // onNewNotification(e, props.onNotification);
        window.location.reload();
      })
      .listen('.session-killer', (e) => {
        onNewNotification(e, props.onNotification);
        setTimeout(() => {
          localStorage.clear();
          window.location.href = '/signin';
        }, 4000)

      });
    return () => echo.disconnect();
  }, []);

  return (
    <Popover
      overlayClassName='overlayClassName'
      style={{ padding: '0px 10px' }}
      trigger={"click"}
      destroyTooltipOnHide
      placement="bottom"
      content={props.children}>
      <BellIcon style={{
        width: '17px',
        cursor: 'pointer'
      }} />
    </Popover>
  );
}

export const NotificationList = ({ notifications, onReaded, onAllReaded }) => {

  return (
    <>
      <div className='notifications-header'>
        {notifications.length > 0 &&
          <Popconfirm title="¿Esta seguro de marcarlas todas como leidas?" onConfirm={() => {
            markAllAsReaded().then(r => onAllReaded());
          }}>
            <span>
              <Button type="link" size="small">Marcar todas como leidas</Button></span>
          </Popconfirm>}
      </div>
      <List
        className='notifications-popover'
        itemLayout="vertical"
        dataSource={notifications} renderItem={(notification, i) => {
          return (
            <List.Item key={i}>
              <List.Item.Meta
                avatar={<Avatar src="https://ui-avatars.com/api/?background=8987C0&color=fff&name=M+G" />}
                title={<Link to={notification.link ? notification.link : '#'} style={{ fontSize: '12px', fontWeight: 'normal' }}>{notification.message}</Link>}
                description={<div>
                  {notification.link && <span>
                    {moment(notification.created_at).format('L LT')}
                  </span>}
                  {notification.readed === 'no' && <Button onClick={() => {
                    readNotification(notification.id).then(r => {
                      onReaded(notification.id, r);
                    });
                  }} size="small" style={{ fontSize: '0.85rem' }} type="link">Marcar como leido</Button>}
                </div>}
              />

            </List.Item>
          );
        }} />
    </>
  );
}

export default Notifications;

async function readNotification(notification_id) {
  const result = await NotificationsService.readNotification(notification_id)
  return result.data;
}

async function markAllAsReaded() {
  const result = await NotificationsService.markAllAsReaded();
  return result.data;
}

function onNewNotification(e, cb) {
  notification.open({ message: e.message, type: 'info', duration: 4 })
  cb(e);
}
