import axios from '../util/Api';

const AuthService = {
  getUsersTable: (params = {}) => {

    return axios.get('/paginate/user', {
      params: params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createRole: (role, url = '/role') => {
    return axios.post(url, role, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateRole: (role, url = '/role') => {
    console.log(role);
    url += `/${role.id}`;

    role._method = 'PUT';

    return axios.post(url, role, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getRoles: (params = {}) => {
    return axios.get('/paginate/role', {
      params: params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createUser: (user, url = '/user') => {
    return axios.post(url, user, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateUser: (values) => {
    return axios.post(`/user/${values.id}`, values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  restoreOrDeleteUser: (id) => {
    return axios.post(`/user/${id}/restore-or-delete`, { id }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getAccessPg: (params) => {
    return axios.get(`/paginate/module`, {
      params: params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createAccess: (claim, url = '/claim') => {
    return axios.post(url, claim, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateAccess: (values, claim, url = '/claim') => {
    url += `/${claim}`;
    values._method = "PUT";
    return axios({
      url: url,
      method: "post",
      data: values,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getModules: () => {
    return axios.get('/module', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createModule: (values) => {
    return axios.post('/module', values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateRoleAccess: (access) => {
    return axios.post('/role_claim', access, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  sendInvitation: (invitation) => {
    return axios.post('/invitation', invitation, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getInviations: (url = '/paginate/invitation') => {
    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  checkInvitation: (token) => {
    return axios.get(`/invitation/check-token/${token}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  registerWithInvitation: (values) => {
    return axios.post('/invitation/consume', values, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getClaim: (claim, url = '/get/claim') => {
    return axios({
      method: 'post',
      url: url,
      data: {
        claim: claim
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteAccess: (values = {}, claim, url = '/claim') => {
    url += `/${claim}`;
    values._method = "DELETE";
    return axios({
      url: url,
      method: "post",
      data: values,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateCustomClaims: (values, url = '/custom/claims/create-or-update') => {
    console.log(values);
    return axios({
      url: url,
      method: "post",
      data: {
        claim_id: values['claim_id'],
        user_id: values['user_id'],
        role_id: values['role_id'],
        studio_id: values['studio_id'],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })

  },
  resetUserClaims: (user_id, studio_id) => {
    console.log(user_id, studio_id)
    return axios.post('custom/claims/reset', {
      user_id: user_id,
      studio_id: studio_id
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })

  },
  forgotPassword: (values, url = '/auth/forgot') => {
    return axios({
      url: url,
      method: "post",
      data: {
        email: values.email,
      }
    });
  },
  resetPassword: (values, url = '/auth/password-reset') => {
    return axios({
      url: url,
      method: "post",
      data: values
    });
  },
  resetPasswordEmail: (id, values = {}) => {
    const url = `user/${id}/reset-password-email`
    return axios({
      url: url,
      method: "post",
      data: values,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
}

export default AuthService;
