import React, { useEffect, useState } from 'react'
import axios, { baseURL } from '../util/Api';

export const useUpgradeablePlans = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    SubscriptionService.getUpgradeablePlans()
      .then(response => {
        setPlans(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  return [plans, loading];
}

export const usePlans = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    SubscriptionService.getPlans()
      .then(response => {
        setPlans(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  return [plans, loading];
}

const SubscriptionService = {
  getUpgradeablePlans: () => {
    return axios.get(`${baseURL}/subscription/plans/upgradeables`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    })
  },
  getPlans: () => {
    return axios.get(`${baseURL}/subscription/plans`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  processSubscription: (preapproval_id) => {
    return axios.post(`${baseURL}/subscription/process`, {
      preapproval_id
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`
      }
    });
  }
}

export default SubscriptionService;
