import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Button, Spin, Tag, Typography } from "antd"
import { TurnoSelectorContext } from './TurnoSelectorContext'
import { calculateMinuteSteps } from './TurnoSelectorContext'
import { hideBusySteps } from './TDateSelect'

export default function THourSelect({ appointment }) {

  const { setHour, hour, steps, availableHours, isOverturn, user, date, duration, busyHours, loadingHours } = useContext(TurnoSelectorContext)
  if (!steps || !user || !date) return <div />

  const [hours, setHours] = useState([]);

  useEffect(() => {
    let _defaultSteps = steps
    if (isOverturn) {
      _defaultSteps = calculateMinuteSteps(duration)
    }
    let _availableSteps = hideBusySteps(busyHours, _defaultSteps);
    if (appointment && !_availableSteps.includes(`${appointment.starting_time.substr(0, 5)}`)) {
      _availableSteps = [`${appointment.starting_time.substr(0, 5)}`, ..._availableSteps].sort((a, b) => Number(a.split(":")[0]) - Number(b.split(":")[0]))
    }
    setHours(_availableSteps);
  }, [steps, isOverturn, busyHours])


  return <div className='turno-selector-hours-container'>
    <div className='turno-selector-hours' >
      {!loadingHours ? <>
        <div style={{ display: 'flex', columnGap: 5 }}>
          <Typography.Text>Selecciona la hora</Typography.Text>
          {isOverturn && <Tag color='red'>Sobreturno</Tag>}
        </div>
        {hours
          .filter(h => {
            return availableHours.includes(Number(h.split(":")[0]))
          })
          .map(step => {
            return <HourButton
              step={step}
              selected={hour === step}
              setHour={setHour}
              key={step}
            />
          })}
      </> :
        <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
          <Spin />
          Cargando horas disponibles
        </div>}
    </div>
  </div>
}

const HourButton = ({ step, selected, setHour }) => {
  return <Button
    onClick={() => setHour(step)}
    className='turno-hour-button' key={step}
    type={selected ? 'primary' : 'default'}>{step}</Button>
}