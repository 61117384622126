import React from "react";
import { Statistic, Row, Col, Card, Typography } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined, VerticalAlignMiddleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const StatisticCard = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.25ch 1.5ch;
  gap: 1.5ch;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0.75ch 1ch;
    gap: 0.2ch;
  }
  align-items: center;
  color: #fff;
  background-color: ${p => p.backgroundColor};
  border-radius: 10px;
  * {
    color: #fff;
  }
`

const AmountContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
`

export function AccumulatedExpenses({ incoming, outgoing, balance }) {
  
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' })

  return (
    <Row gutter={[10, 10]}>
      <Col span={isTabletOrMobile ? 8 : undefined}>
        <StatisticCard backgroundColor="#25bbac">
          <Typography.Text>Ingresos</Typography.Text>
          {!isTabletOrMobile && <Typography.Text>|</Typography.Text>}
          {isTabletOrMobile && <hr style={{width: '100%'}}></hr>}
          <AmountContainer>
            <Typography.Text>{incoming.toFixed(2)}</Typography.Text>
            <ArrowUpOutlined></ArrowUpOutlined>
          </AmountContainer>
        </StatisticCard>
      </Col>
      <Col span={isTabletOrMobile ? 8 : undefined}>
        <StatisticCard backgroundColor="#ff5779">
          <Typography.Text>Gastos</Typography.Text>
          {!isTabletOrMobile && <Typography.Text>|</Typography.Text>}
          {isTabletOrMobile && <hr style={{width: '100%'}}></hr>}
          <AmountContainer>
            <Typography.Text>{outgoing.toFixed(2)}</Typography.Text>
            <ArrowDownOutlined></ArrowDownOutlined>
          </AmountContainer>
        </StatisticCard>
      </Col>
      <Col span={isTabletOrMobile ? 8 : undefined}>
        <StatisticCard backgroundColor="#026ece">
          <Typography.Text>Saldo</Typography.Text>
          {!isTabletOrMobile && <Typography.Text>|</Typography.Text>}
          {isTabletOrMobile && <hr style={{width: '100%'}}></hr>}
          <AmountContainer>
            <Typography.Text>{balance.toFixed(2)}</Typography.Text>
            <VerticalAlignMiddleOutlined></VerticalAlignMiddleOutlined>
          </AmountContainer>
        </StatisticCard>
      </Col>
    </Row>
  );
}
