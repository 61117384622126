import React from "react";
import { connect } from "react-redux";
import { toggleCollapseFilters } from "../../../appRedux/actions/LinearFilters";
import { Button } from "antd";
import { ReactComponent as FilterAltIcon } from "../../../assets/assets2_0/filter_alt.svg";

function HideShowFiltersButton(props) {
  return (
    <Button
      icon={<FilterAltIcon fill="#038fde" />}
      style={{ width: "fit-content", display: 'flex', alignItems: 'center', gap: '1ch' }}
      onClick={() => {
        props.toggleCollapseFilters(!props.filtersCollapsed);
      }}
    >
      Filtros
    </Button>
  );
}

const mapStateToProps = ({ linearFilters }) => {
  const { filtersCollapsed } = linearFilters;
  return { filtersCollapsed };
};

export default connect(mapStateToProps, {
  toggleCollapseFilters
})(HideShowFiltersButton);
