import React, { useEffect, useState } from "react";
import "./scan.less";
import logo from "../../assets/images/logo.svg";
import RequestFileUploader from "./RequestFileUploader";
import axios from "axios";
import { baseURL } from "../../util/Api";
import { message, Row, Col, Empty, Card } from "antd";
import CenteredLoading from "../../law-components/CenteredLoading";
import ScanFileList from "./ScanFileList";
import { isMobile } from "react-device-detect";

const Scan = () => {
  const uuid = window.location.hash.replace("#", "");
  const [reqFile, setReqFile] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadExpedient(uuid)
      .then(setReqFile)
      .catch(e => {
        message.error(
          "Ha ocurrido un problema con el enlace que intenta cosultar."
        );
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <ScanHeader />
      <main className="scan-main">
        {!loading ? (
          reqFile ? (
            <Row gutter={[25, 5]} justify="center">
              <Col span={24}>
                <Row>
                  <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                    <h3>{reqFile.expedient.front}</h3>
                  </Col>
                </Row>
              </Col>
              <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                <RequestFileUploader
                  infoText="Haga click para activar la camara y escanear la información solicitada"
                  reqFile={reqFile}
                  onUpload={setReqFile}
                />
              </Col>
              <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
                <Row>
                  <Col span={24}>
                    <Card title="Indicaciones" className="gx-card">
                      <p style={{ textAlign: 'justify' }}>{reqFile.indications}</p>
                    </Card>
                  </Col>
                  <Col span={24}>
                    <Card title="Archivos cargados" className="gx-card">
                      <ScanFileList files={reqFile.files} />
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Empty description="La pagina a la que intenta acceder esta inactiva." />
          )
        ) : (
          <CenteredLoading tip="Cargando" />
        )}
      </main>
      <footer></footer>
    </>
  );
};

const ScanHeader = () => {
  return (
    <header className="scan-header">
      <img style={{ maxHeight: "70px" }} src={logo}></img>
      {!isMobile && <h3 className="title-header" style={{ color: "#fff", alignSelf: "flex-end" }}>
        📤 Solicitud de documentos
      </h3>}
    </header>
  );
};

async function loadExpedient(uuid) {
  const resp = await axios.get(`${baseURL}/scan/${uuid}`);
  return resp.data;
}

export default Scan;
