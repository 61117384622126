import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  message,
  Row,
  Table,
  Tag,
  Tooltip,
  Typography
} from "antd";
import React, { useMemo, useState } from "react";
import axios from "axios";
import moment from "moment";
import "./ExternalExpedientView.css";
import { CgArrowsExchangeV } from "react-icons/cg";
import {
  FolderOpenOutlined,
  FolderOutlined,
  SearchOutlined
} from "@ant-design/icons";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from "react-google-recaptcha-v3";
import ExpedientFileList from "../../law-components/ExpedientFiles/ExpedientFileList";
import { baseURL } from "../../util/Api";
import { useGAPageView } from "../../hooks/useGA";
import ExpandCollpaseStyledText from "../../law-components/StyledTextArea/components/ExpandCollpaseStyledText";

export function ExternalExpedientView() {
  useGAPageView();
  const [expedient, setExpedient] = useState();

  return expedient ? (
    <ExpedientView backToSearch={() => setExpedient()} expedient={expedient} />
  ) : (
    <GoogleReCaptchaProvider reCaptchaKey="6Lee-dEaAAAAALPta2xKewq9wiUMwcZIg0X_KPEU">
      <SearchExpedient setExpedient={setExpedient} />
    </GoogleReCaptchaProvider>
  );
}

function SearchExpedient({ setExpedient }) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [idSearch, setIdSearch] = useState();
  const [loading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState(false);

  const search = async () => {
    setLoading(true);
    const recaptchaStatus = process.env.REACT_APP_RECAPTCHA_ENABLED;
    if (recaptchaStatus === "enabled") {
      setVerifying(true);
      const token = await executeRecaptcha("public_expedient_search");
      setVerifying(false);
      if (token) {
        try {
          const exp = await searchExpedient(idSearch, token);
          setExpedient(exp);
        } catch (error) {
          message.info(
            "No pudimos encontrar un expediente con el codigo que ingreso."
          );
        } finally {
          setLoading(false);
        }
      }
    }
  };
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingLeft: "30%",
        paddingRight: "30%",
        paddingTop: "10%",
        paddingBottom: "10%",
        display: "grid",
        placeItems: "center"
      }}
    >
      <Typography.Title level={1}>Buscar Expediente</Typography.Title>
      <Input
        autoComplete="cc-csc"
        placeholder="Ingrese el codigo de seguimiento proporcionado por su abogado."
        onKeyUp={e => {
          if (e.key === "Enter") {
            search(e.target.value);
          } else {
            setIdSearch(e.target.value);
          }
        }}
        style={{ padding: 20 }}
        size="large"
      />
      <Button
        loading={loading || verifying}
        onClick={() => search()}
        style={{
          paddingLeft: "90px",
          paddingRight: "90px",
          height: "60px",
          fontSize: "20px"
        }}
        type="primary"
      >
        Buscar
      </Button>
      <footer>
        powered by{" "}
        <span style={{ fontWeight: "bold" }}>METAJURIDICO LEGALTECH</span>
      </footer>
    </div>
  );
}

function ExpedientView({ expedient, backToSearch }) {
  return (
    <div style={{ width: "100%", padding: "5%" }}>
      <Row>
        <Col span={20}>
          <Typography.Title level={3}>{expedient.front}</Typography.Title>
        </Col>
        <Col span={4}>
          <Button
            onClick={() => backToSearch()}
            shape="round"
            type="primary"
            icon={<SearchOutlined />}
          >
            Volver a buscar
          </Button>
        </Col>
        <Col span={24}>
          <Typography.Text>
            N° DE EXPEDIENTE:{" "}
            <span style={{ fontWeight: "bold" }}>
              {expedient.expedient_number || "-"}
            </span>
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Typography.Text>
            {expedient.jurisdiction && (
              <span>{expedient.jurisdiction.name}</span>
            )}
            {expedient.fuero && <span> / {expedient.fuero.name}</span>}
            {expedient.court && <span> / {expedient.court.name}</span>}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Card className="gx-card">
            <MovementsTableView expedient={expedient} />
          </Card>
        </Col>
      </Row>
      <footer style={{ textAlign: "center" }}>
        powered by{" "}
        <span style={{ fontWeight: "bold" }}>METAJURIDICO LEGALTECH</span>
      </footer>
    </div>
  );
}

function MovementsTableView({ expedient }) {
  const [movements, setMovements] = useState(expedient.expedient_movements);
  const [currentExpandedRows, setCurrentExpandedRows] = useState([]);

  const sortMovements = (a, b) => {
    return b.id - a.id;
  };

  const cols = [
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      render: (text, record) => moment(record.date).format("DD/MM/YYYY")
    },
    {
      title: "Tipo",
      dataIndex: "movement_type",
      key: "type",
      render: (text, record) => (
        <Tag color={record.movement_type.color}>
          {record.movement_type.name}
        </Tag>
      )
    },
    {
      title: "Observación",
      dataIndex: "observations",
      key: "observations",
      render: (text, record) => (
        <ExpandCollpaseStyledText
          copyable={true}
          rows={2}
          text={record.public_access_observation || record.observations}
        />
      )
    }
  ];

  return (
    <Table
      rowKey={"key"}
      pagination={false}
      style={{ padding: "0px 2px 0px 2px" }}
      dataSource={movements
        .map(item => ({ ...item, key: item.id }))
        .sort((a, b) => moment(b.date).unix() - moment(a.date).unix())}
      columns={cols}
      expandable={{
        expandIconColumnIndex: 9,
        indentSize: 1,
        expandIcon: ({ expanded, onExpand, record }) =>
          record.hasFiles ? (
            expanded ? (
              <Button
                type="primary"
                onClick={e => onExpand(record, e)}
                icon={<FolderOpenOutlined />}
                size="small"
              />
            ) : (
              <Tooltip trigger="hover" title="Ver adjuntos">
                <Button
                  type="primary"
                  onClick={e => onExpand(record, e)}
                  icon={<FolderOutlined />}
                  size="small"
                />
              </Tooltip>
            )
          ) : null
      }}
      onExpandedRowsChange={setCurrentExpandedRows}
      expandedRowKeys={currentExpandedRows}
      expandedRowRender={(record, i) => (
        <ExpedientFileList
          allow
          onAllFilesDeleted={() => {
            setCurrentExpandedRows(
              currentExpandedRows.filter(cer => cer !== i)
            );
          }}
          showUploader={false}
          service={{
            getFiles: movement => {
              const urlbase = process.env.REACT_APP_API_ROUTE;
              return axios.get(
                `${urlbase}/api/expedient-movement/${movement}/public-files`,
                {}
              );
            }
          }}
          expedient={record}
          donwloadFileRoute={`${baseURL}/expedient-movement/download-public-file/`}
          plublicAccess={true}
          showActions
        />
      )}
    />
  );
}

function MovementsView({ expedient }) {
  const [movements, setMovements] = useState(expedient.expedient_movements);

  const sortMovements = (a, b) => {
    return b.id - a.id;
  };

  return (
    <Card
      extra={
        <CgArrowsExchangeV
          className="sort-icon"
          onClick={() => {
            setMovements(expedient.expedient_movements.sort(sortMovements));
          }}
          size={32}
        />
      }
      title="Movimientos del expediente"
      className="gx-card"
    >
      <ul>
        {movements.map(movement => (
          <li style={{ listStyle: "none" }} key={movement.id}>
            <Row
              gutter={[16, 16]}
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "15px"
              }}
            >
              <Col span={24}>
                <ExpandCollpaseStyledText
                  copyable={true}
                  rows={2}
                  text={
                    movement.public_access_observation || movement.observations
                  }
                />
              </Col>
              <Col span={6}>
                <Typography.Text
                  style={{
                    backgroundColor: "#038DEF",
                    padding: "0px 10px 0px 10px"
                  }}
                  className="rounded-container"
                >
                  {moment(movement.date).format("DD/MM/YYYY HH:mm")}
                </Typography.Text>
              </Col>
              <Col span={6}>
                {movement.realizado ? (
                  <div className="done rounded-container">REALIZADO</div>
                ) : (
                  <div className="pending rounded-container">PENDIENTE</div>
                )}
              </Col>
              <Col span={6}>
                <Typography.Text
                  className="movement_type rounded-container"
                  style={{ backgroundColor: movement.movement_type.color }}
                >
                  {movement.movement_type.name}
                </Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text>
                  {moment(movement.date).format("DD/MM/YYYY HH:mm")}
                </Typography.Text>
              </Col>
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
          </li>
        ))}
      </ul>
    </Card>
  );
}

async function searchExpedient(id, recaptchaToken) {
  const urlbase = process.env.REACT_APP_API_ROUTE;
  const resp = await axios.get(urlbase + "/api/public-expedient/" + id, {
    params: {
      recaptchaToken
    }
  });

  resp.data.expedient_movements = resp.data.expedient_movements.map(
    movement => ({
      ...movement,
      hasFiles: movement.files_count > 0
    })
  );
  return resp.data;
}
