import { FileExcelOutlined, FilePdfOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Typography } from "antd";
import React from "react";
import { ExpenseFormModal } from "./components/ExpenseFormModal";
import { ExpensesTable } from "./components/ExpensesTable";
import axios, { authHeaders } from "../../util/Api";
import { RangeTimeExpenseListSelect } from "./components/RangeTimeExpenseListSelect";
import { AccumulatedExpenses } from "./components/AccumulatedExpenses";
import { ExportExcel, ExportPdf } from "../ExpedientPage/ExportExcel";
import { useMediaQuery } from "react-responsive";
import { ExpensesTableMobile } from "./components/ExpensesTable.mobile";
import { useGAPageView } from "../../hooks/useGA";
import { useScope } from "../../hooks/useScope";

export default function () {
  const [edit, setEdit] = React.useState(null);
  const [exporting, setExporting] = React.useState(false);
  const [expenses, setExpenses] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [range, setRange] = React.useState(null);
  const [filters, setFilters] = React.useState([]);
  const [sorters, setSorters] = React.useState([]);
  const [currentData, setCurrentData] = React.useState([]);
  const isTableOrMobile = useMediaQuery({ query: '(max-width: 960px)' })
  const canCreateExpense = useScope('expenses.create')
  const canListExpenses = useScope('expenses.index')

  useGAPageView()
  React.useEffect(() => {
    getExpenses(range)
      .then(resp => {
        setExpenses(resp);
        setCurrentData(resp);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, [range]);

  const accumulates = React.useMemo(() => calculateExpenses(currentData), [
    currentData
  ]);

  return (
    <Row gutter={[10, 15]} style={{ padding: '1ch' }}>
      <Col span={24} style={{ textAlign: 'center' }}><Typography.Text style={{ fontWeight: 'bold', fontSize: '22px' }}>Planilla de Gastos</Typography.Text></Col>
      <Col xxl={10} xl={10} xs={24} sm={10} md={24}>
        {canListExpenses && <AccumulatedExpenses
          incoming={accumulates.in}
          outgoing={accumulates.out}
          balance={accumulates.in - accumulates.out}
        />}
      </Col>
      <Col xxl={6} xl={6} xs={20} sm={6} md={20}>
        <RangeTimeExpenseListSelect onChange={setRange} />
      </Col>
      <Col xxl={4} xl={4} xs={4} sm={4} md={4}>
        {canCreateExpense && <ExpenseFormModal
          onCreated={x => {
            const updatedExpenses = [...expenses, x];
            const updatedCurrentData = [...currentData, x];
            setExpenses(updatedExpenses);
            setCurrentData(updatedCurrentData);
          }}
          onUpdated={x => {
            const updatedExpenses = expenses.map(e => (e.id === x.id ? x : e));
            const updatedCurrentData = currentData.map(e => (e.id === x.id ? x : e));
            setExpenses(updatedExpenses);
            setCurrentData(updatedCurrentData);
          }}
        />}
      </Col>
      <Col xxl={4} xl={4} xs={24} sm={4} md={24}>
        {canListExpenses && <div style={{ display: 'flex', gap: '1.5ch', alignItems: 'center' }}>
          <Typography.Text>EXPORTAR</Typography.Text>
          <ExportExcel
            disabled={exporting}
            onClick={() => {
              setExporting(true);
              exportXls({ sorters, filters, timeRange: range })
                .then(data => {
                  downloadBlob(data, "Gastos.xlsx");
                })
                .catch(err => { })
                .finally(() => {
                  setExporting(false);
                });
            }}
            icon={<FileExcelOutlined />}
            type="primary"
          />
          <ExportPdf
            disabled={exporting}
            onClick={() => {
              setExporting(true);
              exportPdf({ sorters, filters, timeRange: range })
                .then(data => {
                  downloadBlob(data, "Gastos.pdf");
                })
                .catch(err => { })
                .finally(() => {
                  setExporting(false);
                });
            }}
            icon={<FilePdfOutlined />}
            type="primary"
          />
        </div>}
      </Col>
      <Col xxl={24} xs={24}>
        {isTableOrMobile ? canListExpenses && <ExpensesTableMobile
          onChange={({ filters, sorter, currentData }) => {
            setCurrentData(currentData);
            setFilters(filters);
            setSorters({ field: sorter.field, order: sorter.order });
          }}
          onDeleted={x => {
            const updatedExpenses = expenses.filter(e => e.id !== x.id);
            const updatedCurrentData = currentData.filter(e => e.id !== x.id);
            setExpenses(updatedExpenses);
            setCurrentData(updatedCurrentData);
          }}
          onUpdated={x => {
            const updatedExpenses = expenses.map(e => (e.id === x.id ? x : e));
            const updatedCurrentData = currentData.map(e => (e.id === x.id ? x : e));
            setExpenses(updatedExpenses);
            setCurrentData(updatedCurrentData);
          }}
          expenses={expenses}
          onEdit={setEdit}
        /> : canListExpenses && <ExpensesTable
          onChange={({ filters, sorter, currentData }) => {
            setCurrentData(currentData);
            setFilters(filters);
            setSorters({ field: sorter.field, order: sorter.order });
          }}
          onDeleted={x => {
            const updatedExpenses = expenses.filter(e => e.id !== x.id);
            const updatedCurrentData = currentData.filter(e => e.id !== x.id);
            setExpenses(updatedExpenses);
            setCurrentData(updatedCurrentData);
          }}
          onUpdated={x => {
            const updatedExpenses = expenses.map(e => (e.id === x.id ? x : e));
            const updatedCurrentData = currentData.map(e => (e.id === x.id ? x : e));
            setExpenses(updatedExpenses);
            setCurrentData(updatedCurrentData);
          }}
          expenses={expenses}
          onEdit={setEdit}
        />}
      </Col>
    </Row >
  );
}

async function exportPdf({ sorters, filters, timeRange }) {
  const resp = await axios.get("/studio-account/export-as/pdf", {
    params: {
      sorters,
      filters,
      timeRange
    },
    headers: authHeaders(),
    responseType: "blob"
  });
  const blob = new Blob([resp.data], {
    type: "application/pdf"
  });
  return blob;
}

async function exportXls({ sorters, filters, timeRange }) {
  const resp = await axios.get("/studio-account/export-as/xls", {
    params: {
      sorters,
      filters,
      timeRange
    },
    headers: authHeaders(),
    responseType: "blob"
  });
  const blob = new Blob([resp.data], {
    type:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
  });
  return blob;
}

export function downloadBlob(data, name) {
  const aEle = document.createElement("a");
  const href = window.URL.createObjectURL(data);
  aEle.href = href;
  aEle.download = name;
  document.body.appendChild(aEle);
  aEle.click();
  document.body.removeChild(aEle);
  window.URL.revokeObjectURL(href);
}

async function getExpenses(timeRange) {
  const resp = await axios.get("/studio-account", {
    params: {
      timeRange
    },
    headers: authHeaders()
  });
  return resp.data;
}

function calculateExpenses(expenses) {
  const _in = expenses
    .filter(x => x.type === "IN")
    .map(x => ({ ...x, amount: Number.parseFloat(x.amount) }))
    .reduce((acc, cur) => acc + cur.amount, 0);
  const out = expenses
    .filter(x => x.type === "OUT")
    .map(x => ({ ...x, amount: Number.parseFloat(x.amount) }))
    .reduce((acc, cur) => acc + cur.amount, 0);
  return { in: _in, out };
}
