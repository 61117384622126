import { Button, Col, DatePicker, Form, Input, InputNumber, message, Row, Select, Space } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import StudioTaskService from "../../../services/studio_task.service";
import { parseJwt } from "../../../util/jwt.helper";
import PeriodSelector from "./components/PeriodSelector";
import { ReactComponent as CalendarIcon } from "../../../assets/assets2_0/calendar-2.svg";
import CustomSwtichComponent from "../../PrebuiltFormComponents/CustomSwitchComponent";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import FeeService from "../../../services/fee.service";
import { baseURL } from "../../../util/Api";
import SelectExpedientToLinkModal from "../../../routes/CalculadorasInsideExpedient/components/SelectExpedientToLinkModal";

const CreateFeeForm = ({ fee, onCancel, onUpdated, onDone, expedient, withoutExpedient }) => {

  const [loading, setLoading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0.0);

  const [recurrent, setIsRecurrent] = useState(fee ? Boolean(fee.is_recurrent) : false);
  const [feeAmount, setFeeAmount] = useState(fee ? Math.abs(fee.amount) : null);
  const [installmentQty, setInstallmentQty] = useState(fee ? fee.installments.length : 1);
  const [period, setPeriod] = useState(fee ? fee.pay_every : "monthly");
  const [pactDate, setPactDate] = useState(fee ? moment(fee.date) : moment());
  const [linkToExpedient, setLinkToExpedient] = useState(false);
  const [_expedient, setExpedient] = useState(expedient);

  const [form] = useForm();
  const { user } = parseJwt();
  const is_super_admin = user.role_id === 1;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' })

  const submitData = async (values) => {

    {
      form.validateFields()
        .then(values => {
          setLoading(true);
          Object.assign(values, {
            expedient_id: _expedient ? _expedient.id : null,
            front: values.front,
            expedient_type_id: process.env.REACT_APP_UNASSIGNED_EXPEDIENT_TYPE_ID,
            client_id: _expedient && _expedient.client ? _expedient.client.id : null,
            date: moment(values.date).format("YYYY-MM-DD"),
            recurretion_end_date: recurrent && values.recurretion_end_date ? moment(values.recurretion_end_date).format("YYYY-MM-DD") : null,
            amount: values.recurrent_amount ? values.recurrent_amount : values.amount,
            recurrent_amount: recurrent ? values.recurrent_amount : null,
            installments: values.installments
              .map(installment => ({
                ...installment,
                due_date: moment(installment.due_date).format("YYYY-MM-DD")
              }))
          });


          const { files } = values;

          if (fee) {
            Object.assign(values, {
              id: Math.abs(fee.id)
            });
          }

          const form_data = new FormData();
          form_data.set("body", JSON.stringify({ ...values }));
          files &&
            files.forEach(file => {
              form_data.append("files[]", file.originFileObj);
            });

          if (fee) {
            Object.assign(values, {
              id: Math.abs(fee.id)
            });

            FeeService.updateStudioTask(form_data, setUploadPercentage)
              .then((res) => {
                onUpdated && onUpdated(res.data);
                message.success("Honorario actualizado exitosamente");
              })
              .catch((e) => {
                message.error(`Ha ocurrido un error.`);
                console.log(e);
              })
              .finally(() => setLoading(false));
          } else {
            FeeService.createFee(form_data, setUploadPercentage)
              .then((res) => {
                onDone && onDone(res.data);
                message.success("Honorario creado exitosamente");
              })
              .catch((e) => {
                message.error("Ha ocurrido un error");
                console.log(e);
              })
              .finally(() => setLoading(false));
          }
        })
        .catch(error => {
          setLoading(false);
          console.log(error);
        });
    }
  }

  const handleInstallmentsChange = ({ amount, installmentQty, period, pactDate, recurrent }) => {
    if (amount && installmentQty && period && pactDate) {
      const { installments: current_installments_list } = form.getFieldsValue(["installments"]);
      let installments = [];
      let positive_diference = (Number(amount / installmentQty).toFixed(2) * installmentQty) - amount;
      let useCase = getSumIndex(period);
      for (var i = 1; i <= installmentQty; i++) {
        let current_installment = current_installments_list[i - 1];
        let prev_installment = current_installments_list[i - 2];
        installments.push({
          mode: current_installment ? current_installment.mode : prev_installment ? prev_installment.mode : "CHEQUE",
          due_date: moment(pactDate).add(i * useCase.multiplier, useCase.index),
          //En la ultima cuota restamos el excedente de redondear el monto de cada cuota a dos decimales, 
          //de tal forma que la suma de los montos de las cuotas no sea mayor al monto del honorario
          amount: Number(Number(amount / installmentQty).toFixed(2) - (positive_diference > 0 && i == installmentQty ? positive_diference : 0)).toFixed(2),
          installment_number: `${i} de ${recurrent ? "-" : installmentQty}`
        });
      }
      form.setFieldsValue({
        installments: installments,
      });
    }
  };

  const installments_headers = [
    "Modalidad",
    "Monto",
    "Detalle",
    "Fecha de pago"
  ];

  const fields = [

    {
      key: "front",
      lg: 23,
      md: 23,
      xs: 24,
      cond: withoutExpedient,
      roles: [],
      showOnEdit: false,
      item: (
        <Form.Item
          key="front"
          label="Caratula de expediente"
          name="front"
          help={linkToExpedient ? "Este honorario se encuentra vinculado a un expediente" : ""}
          rules={[
            {
              required: true,
              message: "Debe indicar la la caratula"
            }
          ]}
        >
          <Input className="responsive-input" autoComplete="cc-csc" />
        </Form.Item>
      )
    }, {
      key: "link-expedient",
      lg: 1,
      md: 1,
      xs: 24,
      cond: withoutExpedient,
      roles: [],
      showOnEdit: false,
      item: (
        <div style={{ display: "flex", alignItems: "center", minHeight: isTabletOrMobile ? "auto" : "100px" }} >
          <SelectExpedientToLinkModal
            showUnlink={linkToExpedient}
            unLink={() => {
              setLinkToExpedient(false)
              setExpedient(null)
              form.setFieldsValue({ front: null })
            }}
            cond={true}
            icon={false}
            action={(optionalSettlement, expedient) => {
              setLinkToExpedient(true)
              setExpedient(expedient)
              form.setFieldsValue({ front: expedient.front })
            }} />
        </div>
      )
    },
    {
      key: "date",
      lg: 8,
      md: 8,
      xs: 24,
      cond: true,
      roles: [],
      showOnEdit: true,
      _tutoStep: 12,
      item: (
        <Form.Item
          key={"date"}
          label="Fecha de pacto"
          name="date"
          rules={[
            {
              required: true,
              message: "Debe indicar la fecha"
            }
          ]}
        >
          <DatePicker
            defaultValue={pactDate}
            onSelect={(v) => {
              setPactDate(v)
              handleInstallmentsChange({ amount: feeAmount, installmentQty: installmentQty, period: period, pactDate: v, recurrent: recurrent })
            }}
            size="large"
            className="responsive-input"
            format="DD/MM/YY"
            suffixIcon={<CalendarIcon />}
          />
        </Form.Item>
      )
    }, {
      key: 'is_recurrent',
      lg: 8,
      md: 8,
      xs: 24,
      cond: true,
      item: (
        <Form.Item key={"is_recurrent"} label="Es recurrente" name="is_recurrent" valuePropName="checked">
          <CustomSwtichComponent
            onSelect={(v) => {
              setIsRecurrent(v)
              if (v) {
                form.setFieldsValue({ installment_quantity: 1 });
                setInstallmentQty(1);

                if (period === "unique") {
                  form.setFieldsValue({ pay_every: "monthly" });
                  setPeriod("monthly")
                  handleInstallmentsChange({ amount: feeAmount, pactDate: pactDate, period: "monthly", installmentQty: 1, recurrent: v });
                } else {
                  handleInstallmentsChange({ amount: feeAmount, pactDate: pactDate, period: period, installmentQty: 1, recurrent: v });
                }
              }

            }}

            fieldName={"is_recurrent"}
            value={recurrent}
            form={form}
          />
        </Form.Item>
      )
    }, {
      key: "recurretion_end_date",
      lg: 8,
      md: 8,
      xs: 24,
      cond: true,
      roles: [],
      showOnEdit: true,
      _tutoStep: 12,
      item: (
        recurrent ? <Form.Item
          key={"recurretion_end_date"}
          label="Fin de recurrencia"
          name="recurretion_end_date"
          rules={[
            {
              required: false,
              message: "Debe indicar la fecha"
            }
          ]}
        >
          <DatePicker size="large" className="responsive-input" format="DD/MM/YY" suffixIcon={<CalendarIcon />} />
        </Form.Item> : <></>
      )
    }, {
      key: 'bill_number',
      lg: 8,
      md: 8,
      xs: 24,
      cond: true,
      item: (
        <Form.Item
          key={"bill_number"}
          label="N° de Factura"
          name="bill_number"
        >
          <Input className="responsive-input" />
        </Form.Item>
      )
    }, {
      key: 'amount',
      lg: 8,
      md: 8,
      xs: 24,
      cond: !recurrent,
      item: (
        <Form.Item
          key="amount"
          name="amount"
          label="Monto"
          rules={[
            {
              required: true,
              message: "Debe indicar el monto del compromiso"
            }, { pattern: /^\d*[.]?\d+?$/, message: "Solo puede escribir numeros. Indique decimales utilizando un punto (.)" }
          ]}
        >
          <Input
            onChange={(e) => {
              let new_v = e.currentTarget.value.replace(",", ".");
              setFeeAmount(new_v)
              form.setFieldsValue({ amount: new_v });
              handleInstallmentsChange({ amount: new_v, installmentQty: installmentQty, period: period, pactDate: pactDate, recurrent: recurrent })
            }}
            value={feeAmount}
            className="responsive-input"
            autoComplete="cc-csc"
          />
        </Form.Item>
      )
    }, {
      key: 'recurrent_amount',
      lg: 8,
      md: 8,
      xs: 24,
      cond: recurrent,
      item: (
        <Form.Item
          key="recurrent_amount"
          name="recurrent_amount"
          label="Monto recurrente"
          rules={[
            {
              required: true,
              message: "Debe indicar el monto del compromiso"
            }, { pattern: /^\d*[.]?\d+?$/, message: "Solo puede escribir numeros. Indique decimales utilizando un punto (.)" }
          ]}
        >
          <Input
            onChange={(e) => {
              let new_v = e.currentTarget.value.replace(",", ".");
              setFeeAmount(new_v);
              form.setFieldsValue({ recurrent_amount: new_v });
              handleInstallmentsChange({ amount: new_v, installmentQty: installmentQty, period: period, pactDate: pactDate, recurrent: recurrent })
            }}
            value={feeAmount}
            className="responsive-input"
            autoComplete="cc-csc"
          />
        </Form.Item>
      )
    }, {
      key: 'installment_quantity',
      lg: 4,
      md: 4,
      xs: 24,
      cond: true,
      item: (
        <Form.Item
          key={"installment_quantity"}
          label="N° de Cuotas"
          name="installment_quantity"
          rules={[
            {
              required: true,
              message: "Debe indicar el número de cuotas."
            },
            {
              validator: async (_, value) => {
                if (Number(value) < 1) {
                  return Promise.reject("La cantidad de cuotas debe ser mayor a 0.")
                } else {
                  return Promise.resolve();
                }

              }
            }
          ]}
        >
          <InputNumber
            disabled={recurrent || period === "unique"}
            onChange={(v) => {
              setInstallmentQty(v)
              handleInstallmentsChange({ amount: feeAmount, installmentQty: v, period: period, pactDate: pactDate, recurrent: recurrent })
            }}
            min={1}
            step={1}
            defaultValue={1}
            className="responsive-input"
          />
        </Form.Item>
      )
    },
    {
      key: 'pay_every',
      lg: 4,
      md: 4,
      xs: 24,
      cond: true,
      item: (
        <Form.Item key={"pay_every"} label="Periodo" name="pay_every">
          <PeriodSelector
            onChange={(v) => {
              if (v === "unique") {
                form.setFieldsValue({ installment_quantity: 1 });
                setPeriod(v)
                setInstallmentQty(1)
                setIsRecurrent(false)
                handleInstallmentsChange({ amount: feeAmount, installmentQty: 1, period: v, pactDate: pactDate, recurrent: false })
              } else {
                setPeriod(v)
                handleInstallmentsChange({ amount: feeAmount, installmentQty: installmentQty, period: v, pactDate: pactDate, recurrent: recurrent })
              }
            }}
            value={period}
          />
        </Form.Item>
      )
    }, {
      key: 'observation',
      lg: 24,
      md: 24,
      xs: 24,
      cond: true,
      item: (
        <Form.Item key={"observation"} label="Observaciones" name="observation">
          <Input className="responsive-input" />
        </Form.Item>
      )
    }, {
      key: 'installments',
      lg: 24,
      md: 24,
      xs: 24,
      cond: true,
      item: (
        <div style={{ width: "100%", height: "200px", overflowY: "overlay", overflowX: isTabletOrMobile ? "overlay" : "hidden", paddingRight: "15px" }}>
          Cuotas
          <Row style={{ width: isTabletOrMobile ? "550px" : "auto" }} gutter={10}>
            {installments_headers.map((header, i) => <Col key={`key-col-${i}`} span={6}>{header}</Col>)}
          </Row>
          <Form.List name="installments">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, i) => (
                  <Row style={{ width: isTabletOrMobile ? "550px" : "auto" }} key={`key-fow-${i}`} gutter={10}>
                    <Col span={6}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'mode']}
                        rules={[
                          {
                            required: true,
                            message: 'Missing mode',
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          defaultValue={"CHEQUE"}
                          className="responsive-input"
                        >
                          {mode_options.map((option) => {
                            return (
                              <Select.Option key={option.value} value={option.value}>
                                {option.label}
                              </Select.Option>
                            )
                          })}
                        </Select>
                      </Form.Item>

                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...field}

                        name={[field.name, 'amount']}
                        rules={[
                          {
                            required: true,
                            message: "Debe indicar el monto del compromiso"
                          }, { pattern: /^\d*[.]?\d+?$/, message: "Solo puede escribir numeros." },
                          {
                            validator: async (_, value) => {
                              let installments = form.getFieldValue("installments");
                              let installments_amount = installments.map((installment) => Number(installment.amount)).reduce((prev, val) => prev + val)
                              let minor_limit = Number(Number(feeAmount) - 0.01);
                              let major_limit = Number(Number(feeAmount) + 0.01);
                              if ((minor_limit <= Number(installments_amount)) && Number(installments_amount) <= major_limit) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject("La suma del monto de las cuotas debe ser igual al monto total del honorario.")
                              }

                            }
                          }
                        ]}
                      >
                        <Input disabled={recurrent} className="responsive-input" autoComplete="cc-csc" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        disabled
                        {...field}
                        name={[field.name, 'installment_number']}
                        rules={[
                          {
                            required: true,
                            message: "Debe indicar el número de la cuota"
                          }
                        ]}
                      >
                        <Input readOnly className="responsive-input" autoComplete="cc-csc" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'due_date']}
                        rules={[
                          {
                            required: true,
                            message: "Debe indicar la fecha de pago"
                          }
                        ]}
                      >
                        <DatePicker disabled={recurrent} size="large" className="responsive-input" format="DD/MM/YY" suffixIcon={<CalendarIcon />} />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}

                {/* <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Añadir cuota
                                    </Button>
                                </Form.Item> */}
              </>
            )}
          </Form.List>
        </div>
      )
    }, {
      key: "submit",
      lg: 24,
      md: 24,
      xs: 24,
      cond: true,
      item: (
        <>
          <Form.Item key="submit">
            <Button
              style={isTabletOrMobile ? { width: "47%" } : { margin: "0px 10px" }}
              className="responsive-input responsive-button"
              id="noattach"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Guardar
            </Button>

            <Button
              type="danger"
              style={isTabletOrMobile ? { width: "47%" } : { margin: "0px 10px" }}
              className="ant-btn warn responsive-input responsive-button"
              onClick={() => onCancel()}
              htmlType="button"
              disabled={loading}
            >
              Cancelar
            </Button>
          </Form.Item>
        </>
      )
    }
  ]
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={submitData}
      initialValues={fee ? {
        date: pactDate,
        bill_number: fee.bill_number,
        amount: feeAmount,
        recurrent_amount: Math.abs(fee.recurrent_amount),
        observation: fee.observation,
        installment_quantity: installmentQty,
        installments: fee.installments.map((i) => ({ ...i, due_date: moment(i.due_date), installment_number: `${i.installment_number} de ${fee.installments.length}`, mode: i.mode })),
        recurretion_end_date: fee.recurretion_end_date ? moment(fee.recurretion_end_date) : null,
        is_recurrent: recurrent,
        pay_every: period
      } : {
        date: pactDate,
        bill_number: null,
        observation: null,
        recurrent_amount: null,
        amount: feeAmount,
        installment_quantity: installmentQty,
        installments: [],
        recurretion_end_date: null,
        is_recurrent: recurrent,
        pay_every: period
      }}
    >
      <Row gutter={10} /* style={{ flexDirection: "inherit" }} */>
        {
          fields
            .filter((item) => item.cond)
            .map((field, i) => (
              <Col key={`key-fee-form-${i}`} xs={field.xs} lg={field.lg} md={field.md}>
                <div>
                  {field.item}
                </div>
              </Col>
            ))
        }
      </Row>
    </Form >)
}

export default CreateFeeForm;

function getSumIndex(period) {

  const periods = [
    {
      value: "unique",
      index: "weeks",
      multiplier: 0
    },
    {
      value: "weekly",
      index: "weeks",
      multiplier: 1
    }, {
      value: "biweekly",
      index: "weeks",
      multiplier: 2
    }, {
      value: "monthly",
      index: "months",
      multiplier: 1
    }, {
      value: "bimonthly",
      index: "months",
      multiplier: 2
    }, {
      value: "quarterly",
      index: "months",
      multiplier: 3
    }, {
      value: "biannual",
      index: "months",
      multiplier: 6
    }];

  const useCase = periods.filter((entry) => entry.value === period);
  return useCase[0];
}

const mode_options = [
  {
    value: "CHEQUE",
    label: "Cheque"
  },
  {
    value: "EFECTIVO",
    label: "Efectivo"
  },
  {
    value: "TARJETA DE CRÉDITO",
    label: "Tarjeta de crédito"
  },
  {
    value: "TRANSFERENCIA BANCARIA",
    label: "Transferencia bancaria"
  }
];