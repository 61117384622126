import React, { useState, useEffect } from "react";
import '@ant-design/compatible/assets/index.css';
import { Select, Form } from "antd";
import ExpedientMovementService from "../../services/expedient_movement.service";
const { Option } = Select;

const Antd4MovementTypeSelect = ({
  currentMovementType,
  expedientType,
  showAll = false,
  multi = false,
  allowNull = false,
  noLabel = false,
  type = "all",
  containerStyle,
  onChange = () => null
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (showAll) {
      ExpedientMovementService.getMovementTypeByExpedient(type)
        .then(data => {
          setData(data.data);
          setLoading(false);
        })
        .catch(err => console.log(err));
    } else {
      ExpedientMovementService.getMovementTypeByExpedientType(expedientType)
        .then(data => {
          setData(data.data);
          setLoading(false);
        })
        .catch(err => console.log(err));
    }
  }, []);

  return (
    <Form.Item
      style={{ ...containerStyle }}
      initialValue={currentMovementType}
      name="movement_type_id"
      key="movement_type_id"
      label={noLabel ? null : `Tipo${multi ? 's' : ''} de movimiento${multi ? 's' : ''}`}
      rules={
        [
          {
            required: true,
            message: "Debe indicar el tipo de movimiento."
          }
        ]
      }
    >
      <Select
        placeholder="Tipo de movimiento"
        className="responsive-input"
        style={{ width: "100%" }}
        optionFilterProp="children"
        showSearch
        onChange={v => onChange(v ? data[data.findIndex(m => m.id === v)] : null)}
        loading={loading} mode={multi ? "multiple" : "default"}>
        {allowNull && <Option key="all" value={null}>VER TODO</Option>}
        {data.map(movement_type => (
          <Option key={movement_type.id} value={movement_type.id}>
            {movement_type.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default Antd4MovementTypeSelect;