import React from "react";
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Upload, Button } from "antd";
import { baseURL } from "../../util/Api";
import { parseJwt } from "../../util/jwt.helper";
import StudioService from "../../services/studio.service";
import checkStorage from "./StorageWarning";

const Uploader = ({
  expedient,
  movement,
  origin,
  filelistChange,
  onFileUpload
}) => {

  const { user } = parseJwt();

  const uploaderProps = {
    style: {},
    beforeUpload: async (file, fileList) => {

      let filesSize = 0;
      filesSize = fileList.map((file_data) => file_data.size).reduce((a, b) => a + Number.parseInt(b));

      /*En caso de no haber almacenamiento suficiente disponible, CheckStorage retorna Upload.LIST_IGNORE
        lo cual detiene la carga de archivos y no los lista debajo del uploader. 
      */

      const storage = checkStorage({
        user_role_id: user.role_id,
        studio_id: user.studio_id,
        fileSize: filesSize
      });

      return storage;
    },
    name: "file",
    multiple: true,
    headers: {
      [origin]:
        origin === "Expedient"
          ? expedient.id
          : JSON.stringify({
            id: movement.id,
            expedient: { id: movement.expedient.id }
          }),
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
    showUploadList: { downloadIcon: false },
    action:
      origin === "Expedient"
        ? `${baseURL}/file`
        : `${baseURL}/file/in-movement`,
    onChange: function (info) {
      filelistChange(info.fileList.length);
      if (info.file.status === "done") {
        const pivot = origin === "Expedient" ? { expedient } : { movement };
        onFileUpload({
          ...info.file.response,
          pivot
        });
      }
    }
  };

  return (
    <div style={{ boxSizing: 'content-box', margin: '5px' }}>
      <Upload.Dragger {...uploaderProps} >
        <br />
        <p>
          <InboxOutlined style={{ color: "#038fde", fontSize: "50px", margin: "6px 6px" }} />
        </p>
        <p>
          Haga click o arrastre archivos aquí para adjuntar
        </p>
        <br />

        <Button type="primary" icon={<UploadOutlined />} style={{ width: "70%", margin: '5px' }}>
          Cargar archivos
        </Button>
      </Upload.Dragger>
    </div>

  );
};
Uploader.defaultProps = {
  origin: "Expedient"
};
export default Uploader;
