import React from 'react'
import { Button } from "antd";
import Logo from "../../assets/images/google_g.png";
import { apiRoute } from '../../util/Api';

export default function GoogleLoginButton() {
  return <a href={`${apiRoute}/login/google`}>
    <Button block size='large' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: 2.5, width: '250px' }}>
      <img src={Logo} width={16} alt="Google Logo" />
      Gmail
    </Button>
  </a>
}