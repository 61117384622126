import { Tooltip } from 'antd';
import React from 'react';
import { UserAvatar } from '../LawCalendar/SmallMovementCalendar';

export const MovementResponibles = ({ responsibles }) => {
    return responsibles.map(responsible => (
        <div style={{ width: "55px", height: "39px" }}>
            <Tooltip
                trigger="hover"
                title={`${responsible.firstname} ${responsible.lastname}`}
            >
                <span>
                    <UserAvatar
                        responsible={responsible}
                        color={
                            responsible.profile ? responsible.profile.color : "#3D529E"
                        }
                    />
                </span>
            </Tooltip>
        </div>))
}