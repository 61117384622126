import React from 'react'
import { Button } from "antd";
import Logo from "../../assets/images/yahoo.jpeg";
import { apiRoute } from '../../util/Api';

export default function YahooLoginButton() {
  return <a href={`${apiRoute}/login/yahoo`}>
    <Button size='large' style={{
      display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: 2.5,
      width: '250px'
    }}>
      <img src={Logo} width={16} alt="Yahoo Logo" />
      Yahoo
    </Button>
  </a>
}