import axios, { authHeaders } from "../util/Api";

const NotificationsService = {
    readNotification: (notification_id) => {
        return axios.get(`read-notification/${notification_id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    markAllAsReaded: () => {
        return axios.get('read-all-notification', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    getPushNotifications: () => {
        return axios.get('notification-history', {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    deleteSms: (id, type) => {
        return axios.delete(`sms-queue/${id}/${type}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    getSmsQueue: (message_type) => {
        return axios.get(`sms-queue/${message_type}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    getVariables: () => {
        return axios.get(`notification-message-template-variables`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    getMovementTemplateConfig: (movement, type) => {
        return axios.get(`expedient-movement/${movement}/get-message-template-config/${type}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    updateMovementMessageTemplate: (data) => {
        return axios.post(`expedient-movement/update-message-template`, {data}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    getCurrentTemplateConfigByMovementType: (movement_type, type) => {
        return axios.get(`movement-type/${movement_type}/get-message-template-config/${type}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    updateMessageTemplate: (data) => {
        return axios.post(`movement-type/update-message-template`, {data}, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    getNotificationsTemplates: (params) => {
        return axios.get("/notification-message-template/get", {
            params,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    storeNotificationTemplate: (template) => {
        return axios.post("/notification-message-template", template, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    updateTemplate: (template) => {
        return axios.patch(`/notification-message-template/${template.id}`, template, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    deleteTemplate: (id) => {
        return axios.delete(`/notification-message-template/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        });
    },
    storePrescriptionNotfConfig: (values) => {
        return axios.post(`prescription-notification`, {...values}, {
            headers: {
                ...authHeaders()
            }
        });
    },
    updatePrescriptionNotfConfig: (id, values) => {
        return axios.patch(`prescription-notification/${id}`, {...values}, {
            headers: {
                ...authHeaders()
            }
        });
    },
    getPrescriptionNotfConfig: () => {
        return axios.get(`prescription-notification`, {
            headers: {
                ...authHeaders()
            }
        });
    }
};

export default NotificationsService;
