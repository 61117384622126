import React from "react";
import { Descriptions, Empty, Typography } from "antd";
import TagList from "../../law-components/TagList";
import { onCopy } from "../RtfPage/RtfInfo";
import { baseURL } from "../../util/Api";
import { connect } from "react-redux";
import moment from "moment";

const { Item } = Descriptions;

const ViewClient = ({ client, fields, currentLang }) => {
  if (!client) return <Empty description={`Seleccione un ${currentLang.text.client}`} />;
  return (
    <Descriptions bordered>
      {fields.includes("name") && (
        <Item label="👥 Nombre">
          <Typography.Text style={{ cursor: 'pointer' }} onClick={(event) => onCopy(event.currentTarget.innerHTML)}>
            {client.firstname} {client.lastname}
          </Typography.Text>
        </Item>
      )}
      {fields.includes("identification") && (
        <Item label="🆔 Identificación">
          <Typography.Text style={{ cursor: 'pointer' }} onClick={(event) => onCopy(event.currentTarget.innerHTML)}>
            {client.identification_type ? client.identification_type.code : ""} {client.identification_number}
          </Typography.Text>
        </Item>
      )}
      {fields.includes("birthdate") && (
        <Item label="👶 Fecha de nacimiento">
          <Typography.Text style={{ cursor: 'pointer' }} onClick={(event) => onCopy(event.currentTarget.innerHTML)}>
            {client.birthdate ? moment(client.birthdate).format("DD/MM/YYYY") : "-"}
          </Typography.Text>
        </Item>
      )}
      {fields.includes("email") && <Item label="✉️ Email">
        <Typography.Text style={{ cursor: 'pointer' }} onClick={(event) => onCopy(event.currentTarget.innerHTML)}>
          {client.email}
        </Typography.Text>
      </Item>}
      {fields.includes("phone") && (
        <Item label="📞 Telefono">
          <Typography.Text style={{ cursor: 'pointer' }} onClick={(event) => onCopy(event.currentTarget.innerHTML)}>
            {client.phone}
          </Typography.Text>
        </Item>
      )}
      {fields.includes("mobile_phone") && (
        <Item label="📱 Telefono movil">
          <Typography.Text style={{ cursor: 'pointer' }} onClick={(event) => onCopy(event.currentTarget.innerHTML)}>
            {client.mobile_phone}
          </Typography.Text>
        </Item>
      )}
      {fields.includes("extra_phone") && (
        <Item label="☎️ Telefono extra.">
          <Typography.Text style={{ cursor: 'pointer' }} onClick={(event) => onCopy(event.currentTarget.innerHTML)}>
            {client.extra_phone}
          </Typography.Text>
        </Item>
      )}
      {fields.includes("location") && (
        <Item label="🌇 Localidad">
          <Typography.Text style={{ cursor: 'pointer' }} onClick={(event) => onCopy(event.currentTarget.innerHTML)}>
            {client.location}
          </Typography.Text>

        </Item>
      )}
      {fields.includes("main_address") && (
        <Item label="📌 Dirección ppal.">
          <Typography.Text style={{ cursor: 'pointer' }} onClick={(event) => onCopy(event.currentTarget.innerHTML)}>
            {client.main_address}
          </Typography.Text>

        </Item>
      )}
      {fields.includes("secondary_address") && (
        <Item label="📌 Dirección sec.">
          <Typography.Text style={{ cursor: 'pointer' }} onClick={(event) => onCopy(event.currentTarget.innerHTML)}>
            {client.secondary_address}
          </Typography.Text>
        </Item>
      )}
      {fields.includes("tags") && (
        <Item label="🏷️ Tags">
          <TagList data={client.tags || []} />
        </Item>
      )}
      {fields.includes("observations") && (
        <Item label="💬 Observaciones">
          <Typography.Text style={{ cursor: 'pointer' }} onClick={(event) => onCopy(event.currentTarget.innerHTML)}>
            {client.observations}
          </Typography.Text>
        </Item>
      )}
      {fields.includes("job_title") && (
        <Item label="Cargo">
          <Typography.Text style={{ cursor: 'pointer' }} onClick={(event) => onCopy(event.currentTarget.innerHTML)}>
            {client.job_title}
          </Typography.Text>
        </Item>
      )}
      {fields.includes("plate") && (
        <Item label="Matricula">
          <Typography.Text style={{ cursor: 'pointer' }} onClick={(event) => onCopy(event.currentTarget.innerHTML)}>
            {client.plate}
          </Typography.Text>
        </Item>
      )}
      {fields.includes("sign") && (
        <Item label="firma">
          {client.sign ? <img alt="example" style={{ height: "100px", borderRadius: "15px" }} src={`${baseURL}/client/get-sign/${client.id}`} /> : <></>}
        </Item>
      )}
    </Descriptions>
  );
};




export default ViewClient;


ViewClient.defaultProps = {
  fields: [
    "email",
    "name",
    "phone",
    "mobile_phone",
    "extra_phone",
    "main_address",
    "secondary_address",
    "tags",
    "observations",
    "identification",
    "sign",
    "plate",
    "job_title",
    "location",
    "birthdate"
  ]
};
