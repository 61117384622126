import React, { useState } from "react";
import "@ant-design/compatible/assets/index.css";
import { Button, Input, Form } from "antd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  userSignIn,
  useUser,
  validateTwoFaCode
} from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha
} from "react-google-recaptcha-v3";
import { ReactComponent as UserCircleIcon } from "../assets/assets2_0/user_circle.svg";
import { ReactComponent as ShieldSecureIcon } from "../assets/assets2_0/shield_secure.svg";
import generateCustomCSSGradient from "../util/backgroundSplitByDiagonal";
import { ReactComponent as LawIcon } from "../../src/assets/assets2_0/law_icon.svg";
import KnowMoreMessage from "../components/Widgets/KnowMoreMessage";
import SocialLoginButtons from "../components/SocialLoginButtons/SocialLoginButtons";

const FormItem = Form.Item;

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.recaptcha = React.createRef();
    this.state = {};
  }

  handle2FACode = values => {
    this.props.validateTwoFaCode({
      ...values,
      redirect: this.state.redirect,
      two_fa_token: this.state.twoFaToken
    });
  };

  handleSubmit = values => {
    const response = this.props.userSignIn({
      ...values,
      redirect: this.state.redirect
    });
  };

  componentDidMount() {
    const locationState = this.props.history.location.state;
    const params = new URLSearchParams(this.props.history.location.search);
    const two_fa_token = params.get("two_fa_token") || this.props.twoFaToken;
    const two_fa_enabled = params.get("two_fa_enabled") || this.props.twoFaEnabled;

    this.setState({
      redirect: locationState
        ? locationState.from === "/login"
          ? "/home"
          : locationState.from
        : null,
      isDefensoriaDesign: this.props.isDefensoriaDesign,
      twoFaToken: two_fa_token || this.props.twoFaToken,
      twoFaEnabled: two_fa_enabled || this.props.twoFaEnabled
    });
  }

  componentDidUpdate() {
    if (this.props.token !== null) {
      this.props.history.push(
        this.state.redirect
          ? this.state.redirect.pathname +
          (this.state.redirect.hash || this.state.redirect.search)
          : "/"
      );
      this.setState({ redirect: null });
    }
  }

  render() {
    const { isDefensoriaDesign } = this.state;
    const hard_color = isDefensoriaDesign ? "#F0869D" : "#24335D";
    const soft_color = isDefensoriaDesign ? "#B22A4D" : "#3D529E";
    return (
      <GoogleReCaptchaProvider reCaptchaKey="6Lee-dEaAAAAALPta2xKewq9wiUMwcZIg0X_KPEU">
        <div
          className="gx-app-login-wrap"
          style={{
            backgroundImage: generateCustomCSSGradient(hard_color, soft_color)
          }}
        >
          <LawIcon className="svg-signin-icon" />
          <div className="sign-in-logo-container">
            <a href="https://metajuridico.com">
              <img
                alt="example"
                width={isDefensoriaDesign ? "500px" : "340px"}
                height={isDefensoriaDesign ? "90px" : "60px"}
                src={
                  isDefensoriaDesign
                    ? require("assets/images/defensoria_logo_blanco.svg")
                    : require("assets/images/header-logo.svg")
                }
              />
            </a>
          </div>
          <div className="gx-app-login-container">
            <div className="gx-app-login-main-content">
              <div className="gx-app-logo-content">
                <div className="gx-app-logo-wid">
                  <UserCircleIcon className="user-icon" />
                  <h3>
                    <IntlMessages id="app.userAuth.signIn" />
                  </h3>
                  <p>
                    <IntlMessages id="app.userAuth.bySigning" />
                  </p>
                </div>
              </div>
              <SignInForm
                twoFaToken={this.state.twoFaToken}
                twoFaEnabled={this.state.twoFaEnabled}
                handleSubmit={
                  this.state.twoFaEnabled
                    ? this.handle2FACode
                    : this.handleSubmit
                }
              />
              <SocialLoginButtons />
            </div>
            <InfoView />
          </div>
          <KnowMoreMessage />
          <Privacy />
        </div>
        <AppVersion />
      </GoogleReCaptchaProvider>
    );
  }
}

const AppVersion = () => {
  const latestVersion = localStorage.getItem("APP_VERSION");
  return (
    <span
      style={{
        position: "absolute",
        bottom: "5px",
        left: "5px",
        color: "#abaaaa"
      }}
    >
      v{latestVersion}
    </span>
  );
};

const SignInForm = ({ handleSubmit, twoFaEnabled, twoFaToken }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [verifying, setVerifying] = useState(false);
  const { setUser } = useUser();

  const searchParams = new URLSearchParams(window.location.search);

  const email = searchParams.get("email");

  const attachToken = async values => {
    const recaptchaStatus = process.env.REACT_APP_RECAPTCHA_ENABLED;
    if (recaptchaStatus === "enabled") {
      setVerifying(true);
      const token = await executeRecaptcha("login_page");
      setVerifying(false);
      if (token) {
        handleSubmit({ ...values, token, twoFaToken, setUser });
      }
    } else {
      handleSubmit({ ...values });
    }
  };

  return (
    <div className="gx-app-login-content">
      <Form onFinish={attachToken} className="gx-signin-form gx-form-row0">
        {!twoFaEnabled && (
          <FormItem
            hasFeedback
            name="email"
            initialValue={email}
            rules={[
              { required: true, message: "Escriba su correo electronico." }
            ]}
          >
            <Input
              size="large"
              placeholder="Email"
              className="responsive-input"
            />
          </FormItem>
        )}
        {!twoFaEnabled && (
          <FormItem
            hasFeedback
            name="password"
            rules={[{ required: true, message: "Escriba su contraseña" }]}
          >
            <Input
              size="large"
              type="password"
              placeholder="Password"
              className="responsive-input"
            />
          </FormItem>
        )}
        {twoFaEnabled && (
          <FormItem
            rules={[
              { required: true, message: "Escriba su codigo de verificacion" }
            ]}
            hasFeedback
            name="two_fa_code"
            labelCol={{ span: 24, style: { fontSize: "14px" } }}
            label="Ingrese el codigo de verificacion que le enviamos a su correo electronico"
          >
            <Input
              autoComplete="cc-csc"
              maxLength={6}
              placeholder="· · · · · ·"
              style={{ padding: 20, fontSize: 40, textAlign: "center" }}
              type="text"
            />
          </FormItem>
        )}
        <FormItem>
          ¿Olvidaste tu contraseña?{" "}
          <Link to="/forgot-password">
            <IntlMessages id="app.userAuth.forgotPassword" />
          </Link>
        </FormItem>
        <FormItem>
          <Button
            size="large"
            loading={verifying}
            type="primary"
            className="gx-mb-0"
            htmlType="submit"
          >
            {verifying ? (
              "Verificando"
            ) : (
              <IntlMessages id="app.userAuth.login" />
            )}
          </Button>
        </FormItem>
      </Form>
      {/* <PoliciesAndPrivacy /> */}
    </div>
  );
};

const Privacy = () => {
  return (
    <div className="privacy">
      <ShieldSecureIcon width={"20"} fill="#fff" />
      <a
        title="Pol&iacute;tica de Privacidad"
        href="https://metajuridico.com/politica-de-privacidad/"
        target="_blank"
        className="know-more-link"
        style={{ "z-index": "100" }}
      >
        Pol&iacute;tica de Privacidad
      </a>
    </div>
  );
};

const PoliciesAndPrivacy = () => {
  return (
    <>
      <p style={{ textAlign: "center" }}>&nbsp;</p>
      <p style={{ textAlign: "center" }}>
        <em>
          <strong>
            <span
              style={{
                color: "#000000",
                backgroundColor: "#ffffff",
                fontSize: 12
              }}
            >
              &iquest;Para qu&eacute; sirve esta P&aacute;gina?
            </span>
          </strong>
        </em>
      </p>
      <p style={{ textAlign: "center" }}>
        <span
          style={{ color: "#000000", backgroundColor: "#ffffff", fontSize: 10 }}
        >
          Nuestro software utiliza un panel de acceso espec&iacute;fico para los
          usuarios. Si deseas obtener m&aacute;s datos acerca de
          MetaJur&iacute;dico te invitamos a visitar nuestra web haciendo
          <a
            title="Meta Juridico"
            href="https://www.metajuridico.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> click aqu&iacute;</strong>
          </a>
          .
        </span>
      </p>
    </>
  );
};

const mapStateToProps = ({ auth, designMode }) => {
  const { token, twoFaEnabled, twoFaToken } = auth;
  const { isDefensoriaDesign } = designMode;
  return { token, twoFaEnabled, twoFaToken, isDefensoriaDesign };
};

export default connect(mapStateToProps, { userSignIn, validateTwoFaCode })(
  SignIn
);
