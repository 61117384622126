import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "./pdf.css";

export function PdfViewer({ url, w, h, reqAuth = false, loadingTip = "cargando...", onLoaded }) {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [renderedPageNumber, setRenderedPageNumber] = useState(null);

  const Authorization = `Bearer ${localStorage.getItem("token")}`;

  useEffect(() => {
    if (url) {
      loadPdf(url, reqAuth, Authorization).then(_url => {
        setPdfUrl(_url);
      });
    }
  }, [url]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    onLoaded && onLoaded()
  };

  const isLoading = renderedPageNumber !== pageNumber;

  return pdfUrl ? (
    <Row gutter={[5, 10]} justify="center">
      <Col span={24}>
        <Button.Group>
          <Button
            disabled={pageNumber === 1}
            onClick={() => setPageNumber(pageNumber - 1)}
            icon={<MdChevronLeft />}
          />
          <Button style={{ cursor: "default" }}>
            {pageNumber} de {numPages}
          </Button>
          <Button
            disabled={pageNumber === numPages}
            onClick={() => setPageNumber(pageNumber + 1)}
            icon={<MdChevronRight />}
          />
        </Button.Group>
      </Col>
      <Col span={24}>
        <Document
          error={"Error al cargar el documento"}
          className={"pdf-viewer"}
          file={pdfUrl}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {isLoading && renderedPageNumber ?
            <Page
              /** 
              * IMPORTANT: Keys are necessary so that React will know which Page component
              * instances to use.
              * Without keys, on page number update, React would replace the page number
              * in 1st and 2nd page components. This may cause previously rendered page
              * to render again, thus causing a flash.
              * With keys, React, will add prevPage className to previously rendered page, 
              * and mount new Page component instance for the new page.
              */
              key={renderedPageNumber}
              className="prevPage"
              pageNumber={renderedPageNumber}
              width={w || 600}
              scale={1}
            /> : null}
          <Page key={pageNumber} scale={1} width={w || 600} pageNumber={pageNumber}
            onRenderSuccess={() => {
              setRenderedPageNumber(pageNumber)
            }
            } />
        </Document>
      </Col>
    </Row>
  ) : (
    <div>
      {loadingTip}
    </div>
  );
}

async function loadPdf(url, reqAuth, Authorization) {

  const headers = {
    responseType: "blob",
    Authorization: reqAuth ? Authorization : null
  };

  const response = await fetch(url, {
    method: "GET",
    headers: new Headers(headers)
  });
  const blob = await response.blob();
  const pdfUrl = window.URL.createObjectURL(blob);
  return pdfUrl;
}
