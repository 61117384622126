import { Modal, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import CenteredLoading from '../../law-components/CenteredLoading';
import axios, { authHeader, baseURL } from '../../util/Api';
import { getBase64 } from '../../law-components/Forms/ImageUploader';
import { PdfViewer } from '../../routes/MetaSign/PdfViewer';
import { useMediaQuery } from 'react-responsive';
import { DownloadFile } from '../../law-components/ExpedientFiles/ExpedientFileList';
import DownloadPreviewImage from './DownloadPreviewImage';
import PreviewImage from './PreviewImage';

const PreviewFileModal = ({ file, setShowModal, showModal, setFileToPreview }) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [allowDownload, setAllowDownload] = useState(false);
    const [previewUrlImage, setPreviewUrlImage] = useState(null);

    const is_image = ["png", "jpg", "jpeg"].includes(file.mimetype.toLowerCase());
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const textDocumentWidth = isTabletOrMobile ? (vw * 0.98) : 900;
    const modalWidth = is_image ? "auto" : textDocumentWidth;
    const subFactor = isTabletOrMobile ? 50 : 100;

    const bodyStyle = is_image ? {
        maxHeight: "90vh",
        maxWidth: isTabletOrMobile ? "98vw" : "50vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    } : {
        maxHeight: "90vh",
        overflowY: "auto"
    };

    return (
        <Modal

            title={<div style={{ width: "95%", display: "flex", alignItems: "center" }}>
                <Typography.Text style={{ whiteSpace: "normal" }} ellipsis>
                    {file.name}
                </Typography.Text>
                &nbsp;&nbsp;&nbsp;
                {
                    allowDownload ? is_image && previewUrlImage ? <DownloadPreviewImage file={file} imageSrc={previewUrlImage} /> :
                        <DownloadFile placement="bottomLeft" file={file} /> : null
                }
            </div >}
            centered
            open={showModal}
            onCancel={() => {
                setShowModal(false)
                setFileToPreview(undefined)
            }}
            footer={false}
            width={modalWidth}
            bodyStyle={bodyStyle}
            destroyOnClose
        >

            {
                is_image ?
                    <PreviewImage
                        file={file}
                        onImageLoaded={(allowDownload, previewUrlImage) => {
                            setAllowDownload(allowDownload);
                            setPreviewUrlImage(previewUrlImage);
                        }}
                    />
                    : <PdfViewer
                        loadingTip={<CenteredLoading tip="Cargando previsualización" />}
                        w={(textDocumentWidth - subFactor)}
                        url={`${baseURL}/file/preview/${file.id}`}
                        reqAuth={true}
                        onLoaded={() => setAllowDownload(true)}
                    />
            }
        </Modal >
    )
}

export default PreviewFileModal;