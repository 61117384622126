import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import axios from '../../util/Api';
import { message } from 'antd';
import CenteredLoading from '../../law-components/CenteredLoading';

const PreviewImage = ({ file, onImageLoaded }) => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [widerThanTall, setWiderThanTall] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [previewUrlImage, setPreviewUrlImage] = useState(null);

    const Authorization = `Bearer ${localStorage.getItem("token")}`;

    useEffect(() => {
        async function fetchPreviewFile(Authorization) {
            setLoading(true)
            try {
                const fileToPreview = await axios.get(`file/preview/${file.id}`,
                    {
                        responseType: "blob",
                        headers: {
                            Authorization: Authorization
                        }
                    });

                const blob = new Blob([fileToPreview.data]);
                const bmp = await createImageBitmap(blob);
                const { width, height } = bmp;
                var imageSrc = window.URL.createObjectURL(blob);
                setWiderThanTall(width > height);
                bmp.close();
                setPreviewUrlImage(imageSrc);
                onImageLoaded && onImageLoaded(true, imageSrc)
            } catch (error) {
                message.error("Ha ocurrido un error");
                setShowError(true);
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        if (file) {
            fetchPreviewFile(Authorization);
        }
    }, [file])

    return (

        loading ? <CenteredLoading tip="Cargando previsualización" /> :
            showError ? <p>La imagen no se ha podido cargar.</p> :
                <img style={{
                    height: !widerThanTall ? isTabletOrMobile ? "45vh" : "80vh" : "auto",
                    width: widerThanTall ? isTabletOrMobile ? "92vw" : "48vw" : "auto"
                }} src={previewUrlImage} alt={"alt text"} />
    )
}

export default PreviewImage;