import React from "react";

import Widget from "components/Widget/index";


const FlyingBird = ({image, title, subTitle, name, extra}) => {
  return (
    <Widget>
      <div className="gx-media gx-align-items-center gx-mb-4">
        <div className="gx-mr-3">
          <img src={image} width="75" height="75" alt='flying'/>
        </div>
        <div className="gx-media-body">
          <h2 className="gx-mb-1">
            {title}
          </h2>
          <p className="gx-text-grey gx-mb-0">{subTitle}</p>
        </div>
      </div>
      <p className="gx-mb-4">{name}, gracias por aceptar la invitacion a ...</p>
      {extra}
    </Widget>
  );
};

export default FlyingBird;
