import React, { useState, useEffect } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from "antd";
import JudicialService from "../../services/judicial.service";
const { Option } = Select;

export const CourtSelect = ({
  getFieldDecorator,
  fuero,
  court,
  onSelect,
  mode,
  required,
  disabled,
  style
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (fuero) {
      setLoading(true);
      JudicialService.getCourts(fuero)
        .then(data => {
          setData(data.data);
          setLoading(false);
        })
        .catch(err => console.log(err));
    }
  }, [fuero]);

  return mode === "form" ? (
    <Form.Item key="court" label="Corte">
      {getFieldDecorator("court", {
        rules: [
          {
            required,
            message: "Debe indicar la corte."
          }
        ],
        initialValue: !loading ? court || null : null
      })(
        <Select
          className="responsive-input"
          optionFilterProp="children"
          showSearch

          disabled={disabled}
          allowClear
          loading={loading}
          onChange={v => onSelect(v)}
        >
          {data.map(_court => (
            <Option key={_court.id} value={_court.id}>
              {_court.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  ) : (
    <Select
      key={`${fuero}-court`}
      className="responsive-input"
      style={style}
      optionFilterProp="children"
      showSearch
      placeholder="Corte"
      disabled={disabled}
      allowClear
      loading={loading}
      onChange={v => onSelect(v)}
    >
      {data.map(_court => (
        <Option key={_court.id} value={_court.id}>
          {_court.name}
        </Option>
      ))}
    </Select>
  );
};

CourtSelect.defaultProps = {
  mode: "form",
  onSelect: () => null,
  required: false,
  disabled: false,
  style: {}
};
