import { Table } from "antd";
import React from "react";
import { bytesFormatter } from "../../util/bytesFormatter";

const ScanFileList = ({ files }) => {
  const cols = [
    {
      dataIndex: "name",
      key: "name",
      title: "Nombre"
    },
    {
      dataIndex: "mimetype",
      key: "type",
      title: "Tipo"
    },
    {
      dataIndex: "size",
      key: "size",
      title: "Tamaño",
      render: (size) => bytesFormatter(size)
    }
  ];
  return <Table className="gx-responsive-table" columns={cols} dataSource={files} />;
};

export default ScanFileList;
