import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import Auxiliary from "util/Auxiliary";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { menuItems } from "./sidebar-conf";
import { parseJwt } from "../../util/jwt.helper";
import Scrollbars from "react-custom-scrollbars";
import { ConditionalWrapper } from "../../util/helpers";
import { DownOutlined } from "@ant-design/icons";

class SidebarContent extends Component {
  getNoHeaderClass = navStyle => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const { themeType, pathname, isDefensoriaDesign } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];

    const customScrollStyle = {
      position: "initial",
      marginBottom: "-3px",
      marginRight: "-3px"
    };
    const sidebarContainerStyle = {
      boxShadow: "inset 0px 4px 5px -3px #000",
      backgroundColor: isDefensoriaDesign ? "#B22A4D" : "#3D529E"
    };

    return (
      <Auxiliary>
        <div className="gx-sidebar-content">
          <Scrollbars
            style={customScrollStyle}
            autoHide
            hideTracksWhenNotNeeded
            renderView={props => (
              <div {...props} style={customScrollStyle} className="view" />
            )}
          >
            <Menu
              style={sidebarContainerStyle}
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={"dark"}
              mode="inline"
            >
              {buildMenuItems({ isDefensoriaDesign: isDefensoriaDesign })}
            </Menu>
          </Scrollbars>
        </div>
      </Auxiliary>
    );
  }
}

const buildMenuItems = ({ isDefensoriaDesign }) => {
  const { user } = parseJwt();
  const renderMenu = (item) => item.children.length < 1 ? (
    <Menu.Item key={item.key}>
      <RenderItemContent item={item} itemType={"link"} />
    </Menu.Item>
  ) : (
    <Menu.SubMenu
      key={`sub-${item.key}`}
      className={
        isDefensoriaDesign
          ? `defensoria-side-bar-sub-menu`
          : `default-side-bar-sub-menu`
      }
      title={<RenderItemContent item={item} itemType={"title"} />}
    >
      {item.children.map(renderMenu)}
    </Menu.SubMenu>
  )
  return menuItems({ hasMev: false })
    .filter(item => {
      if (item.key === "letters") {
        if (!user.studio) return true;
        if (user.studio.province.country_id !== 1) return false;
      }
      if (item.key === "account") {
        if (!user.studio) return true;
        if (user.role_id !== 1) return false;
      }
      if (item.key === "support") {
        if (!user.studio && process.env.REACT_APP_SHOW_TICKETS === "1")
          return true;
        if (user.role_id !== 1) return false;
      }
      if (item.key === "calculator") {
        if (!user.studio) return true;
        if (user.studio.province.country_id !== 1) return false;
      }
      return true;
    })
    .map(renderMenu);
};

const RenderItemContent = ({ item, itemType }) => {
  return (
    <span>
      <ConditionalWrapper
        condition={itemType === "link"}
        wrapper={children => <Link to={item.link}>{children}</Link>}
      >
        {typeof item.icon === "string" ? (
          <LegacyIcon style={{ color: "#fff" }} type={item.icon}></LegacyIcon>
        ) : (
          <span style={{ marginRight: "10px", color: "#fff" }}>
            {item.icon}
          </span>
        )}
        <span style={{ color: "#fff" }}>{item.text}</span>
      </ConditionalWrapper>
    </span>
  );
};

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, designMode }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  const { isDefensoriaDesign } = designMode;
  return { navStyle, themeType, locale, pathname, isDefensoriaDesign };
};
export default connect(mapStateToProps)(SidebarContent);
