import React from "react";
import { Switch } from "antd";
import axios, { authHeaders } from "../../../util/Api";
import CustomSwtichComponent from "../../PrebuiltFormComponents/CustomSwitchComponent";
import { useMediaQuery } from "react-responsive";

export default function ToggleTwoFa({ user }) {

  const [isEnabled, setIsEnabled] = React.useState(user["two_fa_enabled"]);
  const [loading, setLoading] = React.useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });

  const handle = () => {
    setLoading(true);
    toggle2fa()
      .then(res => {
        setIsEnabled(res["two_fa_enabled"]);
      })
      .catch(err => { })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="profile-toggle-container" style={isTabletOrMobile ? { width: "100%" } : {}} >
      Autenticacion de dos factores
      <CustomSwtichComponent
        loading={loading}
        checkedChildren="Activar"
        unCheckedChildren="Desactivar"
        value={isEnabled}
        onSelect={() => handle()} />
    </div>
  );
}

async function toggle2fa() {
  const resp = await axios.get("user/toggle-two-fa", {
    headers: authHeaders()
  });
  return resp.data;
}
