import {INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET, TWO_FA_ENABLED, TWO_FA_TOKEN} from "../../constants/ActionTypes";

const INIT_STATE = {
  token: localStorage.getItem('token'),
  initURL: '/home',
  authUser: JSON.parse(localStorage.getItem('user') || "[]"),
  twoFaEnabled: false,
  twoFaToken: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case TWO_FA_TOKEN: {
      return {...state, twoFaToken: action.payload};
    }

    case TWO_FA_ENABLED: {
      return {...state, twoFaEnabled: action.payload};
    }

    case INIT_URL: {
      return {...state, initURL: action.payload};
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: '/signin'
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    default:
      return state;
  }
}
