import React from "react";
import { Row, Select, Col} from "antd";
import moment from "moment";
import { DatePicker, Space } from "antd";

const { Option } = Select;
const { RangePicker } = DatePicker;

export function RangeTimeExpenseListSelect({ onChange }) {
  const [selectedValue, selectValue] = React.useState("");
  const [timeRange, setTimeRange] = React.useState([]);
  const [showRangePicker, setShowRangePicker] = React.useState(false);

  const handleTimeRangeChange = value => {
    let v = [];
    if (value === "1") {
      v = [
        moment()
          .startOf("day")
          .format("YYYY-MM-DD"),
        moment()
          .endOf("day")
          .format("YYYY-MM-DD")
      ];
    } else if (value === "2") {
      v = [
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      ];
    } else if (value === "3") {
      v = [
        moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      ];
    } else if (value === "4") {
      v = [
        moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      ];
    } else if (value === "5") {
      v = [
        moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      ];
    } else {
      setShowRangePicker(true);
    }
    if (value !== "6") {
      onChange(v);
      showRangePicker && setShowRangePicker(false);
    }
  };

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Select
          className="responsive-input"
          onChange={handleTimeRangeChange}
          placeholder="Rango de tiempo"
          style={{ width: "100%" }}
        >
          <Option value="1">Hoy</Option>
          <Option value="2">Ayer</Option>
          <Option value="3">Últimos 7 días</Option>
          <Option value="4">Últimos 30 días</Option>
          <Option value="5">Este mes</Option>
          <Option value="6">Personalizado</Option>
        </Select>
      </Col>
      <Col span={24}>
        {showRangePicker && (
          <RangePicker
            className="responsive-input"
            onChange={x => {
              onChange([x[0].format("YYYY-MM-DD"), x[1].format("YYYY-MM-DD")]);
            }}
            format="DD/MM/YYYY"
            style={{ width: "100%" }}
          />
        )}
      </Col>
    </Row>
  );
}
