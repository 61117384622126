// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';
export const TWO_FA_ENABLED = "TWO_FA_ENABLED";
export const TWO_FA_TOKEN = "TWO_FA_TOKEN";

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';

//Linear Filters const
export const TOGGLE_COLLAPSE_FILTERS = 'TOGGLE_COLLAPSE_FILTERS';

//Client const
export const CLIENTS_FETCHED = 'CLIENTS_FETCHED';
export const CLIENT_DELETED = 'CLIENT_DELETED';
export const CLIENT_UPDATED = 'CLIENT_UPDATED';
export const CLIENT_CREATED = 'CLIENT_CREATED';

//RTF
export const SET_RTF_VIEW = 'SET_RTF_VIEW';

//LANG
export const SET_LANG = 'SET_LANG'

//Movement Local Filters

export const UPDATE_MOVEMENTS_LOCAL_FILTER = "UPDATE_MOVEMENTS_LOCAL_FILTER";
export const UPDATE_MOVEMENTS_LOCAL_SEARCH = "UPDATE_MOVEMENTS_LOCAL_SEARCH";
export const TOGGLE_SHOW_EXPORT_EXPEDIENT = "TOGGLE_SHOW_EXPORT_EXPEDIENT";

//Fees 

export const TOGGLE_CREATE_FEE_MODAL = 'TOGGLE_CREATE_FEE_MODAL';
export const TOGGLE_REGISTER_PAYMENT_MODAL = 'TOGGLE_REGISTER_PAYMENT_MODAL';
export const TOGGLE_VIEW_PAYMENTS_MODAL = 'TOGGLE_VIEW_PAYMENTS_MODAL';
export const TOGGLE_RELOAD_ACCOUNT = 'TOGGLE_RELOAD_ACCOUNT';

export const SET_INSTALLMENT_TO_REGISTER_PAYMENT = 'SET_INSTALLMENT_TO_REGISTER_PAYMENT';
export const SET_FEE_TO_EDIT = 'SET_FEE_TO_EDIT';
export const SET_PAYMENT_TO_EDIT = 'SET_PAYMENT_TO_EDIT';
export const SET_INSTALLMENT_TO_VIEW_PAYMENTS = 'SET_INSTALLMENT_TO_VIEW_PAYMENTS';