import React from 'react';
import { Modal, Upload } from 'antd';
import StudioService from '../../services/studio.service';
import { parseJwt } from '../../util/jwt.helper';

const { warning } = Modal;

const checkStorage = async ({ user_role_id, studio_id, fileSize, onChecked, onCantStorage }) => {

  const is_super_admin = user_role_id === 1;

  if (is_super_admin || fileSize === 0 ) {
    //El administrador general puedo almacenar archivos independientemente del capacidad de almacenamiento del estudio
    //Si no se carga ningún archivo, se ejecuta directamente la operación 
    onChecked && onChecked();
  } else {
    try {
      const response = await StudioService.checkAvailableStorage(studio_id, fileSize);
      if (response.status == 200) {
        onChecked && onChecked();
      }
    } catch (error) {
      console.log(error);
      showStorageWarning();
      onCantStorage && onCantStorage();
      return Upload.LIST_IGNORE;
    }
  }
}
export default checkStorage;

export const checkStorageFromUploader = async (file, fileList) => {

  const { user } = parseJwt();

  let filesSize = 0;
  filesSize = fileList.map((file_data) => file_data.size).reduce((a, b) => a + Number.parseInt(b));

  /*       En caso de no haber almacenamiento suficiente disponible, CheckStorage retorna Upload.LIST_IGNORE
        lo cual detiene la carga de archivos y no los lista debajo del uploader. */

  const storage = checkStorage({
    user_role_id: user.role_id,
    studio_id: user.studio_id,
    fileSize: filesSize
  });

  return storage;
}

export const showStorageWarning = () => {
  warning({
    title: 'El espacio disponible no es suficiente.',
    content: (
      <div>
        Pongase en contacto con el administrador del estudio o visite <a href="https://metajuridico.com/" target="_blank" rel="noopener noreferrer">metajuridico.com</a> y amplíe el plan de almacenamiento.
      </div>
    ),
    okText: 'Cerrar'
  });
}