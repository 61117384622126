import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { menuItems } from "../Sidebar/sidebar-conf";
import { parseJwt } from "../../util/jwt.helper";
import { useMevIntegration } from "../../routes/ConfigPage/ThirdPartyConfigPage/useMevIntegration";

class HorizontalNav extends Component {
  render() {
    const { pathname, themeType } = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    const { user } = parseJwt();

    return (
      <MainMenu
        user={user}
        defaultOpenKeys={defaultOpenKeys}
        selectedKeys={selectedKeys}
        themeType={themeType}
      />
    );
  }
}

const MainMenu = ({ defaultOpenKeys, selectedKeys, user }) => {

  // const hasMev = (user.studio && !!user.studio.mev_scraping);
  const { mev: hasMev } = useMevIntegration()

  const renderMenu = item => {
    if (item.key === 'import-from-expedient') {
      if (!hasMev) return null;
    }
    return item.children.length < 1 ? (
      <Menu.Item key={item.key}>
        <Link to={item.link} className="menu-item-horizontal">
          <span>
            {item.text}
          </span>
        </Link>
      </Menu.Item>
    ) : (
      <Menu.SubMenu
        key={`sub-${item.key}`}
        title={
          <span className="menu-item-horizontal">{item.text}</span>
        }
      >
        {item.children
          .filter(subItem => {
            if (subItem.key === "calculator") {
              if (!user.studio) return true;
              if ([1].includes(user.studio.plan)) {
                return true;
              }
              return false;
            }
            return true;
          })
          .map(renderMenu)}
      </Menu.SubMenu>
    )

  }

  return (
    <>
      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        mode="horizontal"
        className="menu-horizontal"
      >
        {menuItems({
          hasMev: (user.studio && !!user.studio.mev_scraping),
        })
          .filter(item => {
            if (item.key === "letters") {
              if (!user.studio) return true;
              if (user.studio.province.country_id !== 1) return false;
            }
            if (item.key === "account") {
              if (!user.studio) return true;
              if (user.role_id !== 1) return false;
            }
            if (item.key === "support") {
              if (!user.studio && process.env.REACT_APP_SHOW_TICKETS === "1")
                return true;
              if (user.role_id !== 1) return false;
            }
            if (item.key === 'calculator') {
              if (!user.studio) return true;
              if (user.studio.province.country_id !== 1) return false;
            }
            return true;
          })
          .map(renderMenu)}
      </Menu>
    </>
  );
};

HorizontalNav.propTypes = {};
const mapStateToProps = ({ settings }) => {
  const { themeType, navStyle, pathname, locale } = settings;
  return { themeType, navStyle, pathname, locale };
};
export default connect(mapStateToProps)(HorizontalNav);
