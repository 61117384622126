import { message, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import ExpandCollpaseText from "../ExpandCollapseText";
import { ReactComponent as CandadoGris } from "../../assets/assets2_0/candado_gris.svg";
import ExpandCollpaseStyledText from "../StyledTextArea/components/ExpandCollpaseStyledText";
import { StyledText } from "../StyledTextArea/components/StyledText";

export const MovementObservations = ({
  record,
  updateMovementObservation,
  reload
}) => {
  const [_record, setRecord] = useState(record);
  useEffect(() => {
    setRecord(record);
  }, [record]);

  const isTabletorMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const { observations, is_public } = _record;
  const content =
    _record.id === -1 &&
      _record.foja_number !== null &&
      _record.foja_number !== ""
      ? observations
      : observations;
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "1ch", width: "100%" }}>
      {!is_public && <CandadoGris height="20" />}
      <ExpandCollpaseStyledText
        copyable={true}
        editable={!isTabletorMobile}
        rows={3}
        onEdit={
          _record.id !== -1 && !_record.id_tramite_mev
            ? text => {
              updateMovementObservation(text, _record.id)
                .then(data => {
                  setRecord(data);
                  message.success("Observación editada");
                })
                .catch(e => {
                  message.error("Ocurrio un error");
                  console.log(e)
                });
            }
            : null
        }
        text={content}
      />
    </div>
  );
};
