import { Switch, Typography } from "antd";
import React, { useState } from "react";
import { onCopy } from "../../routes/RtfPage/RtfInfo";
import axios, { authHeaders } from "../../util/Api";

export function TogglePublicAccess({ expedientId, defaultValue, onChange }) {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(defaultValue);
  return (
    <Switch
      size="small"
      loading={loading}
      checked={value}
      onChange={value => {
        setLoading(true);
        toggle(expedientId)
          .then(resp => {
            setValue(resp.public_access);
            if(resp.public_code) {
              onChange(resp.public_code, resp.public_access);
            }
          })
          .catch(() => {})
          .finally(() => setLoading(false));
      }}
    />
  );
}

async function toggle(id) {
  const response = await axios.patch(
    `/expedient/update-public-acccess`,
    {
      id
    },
    {
      headers: authHeaders()
    }
  );
  return response.data;
}

export function CopyablePublicAccessCode({ code }) {
  return (
    <Typography.Text
      className="copyable-text"
      onClick={event => onCopy(event.currentTarget.innerHTML)}
    >
      {code}
    </Typography.Text>
  );
}
