import React from 'react';
import RegisterWithInvitationForm from '../../law-components/Forms/RegisterWithInvitationForm';
import { withRouter } from 'react-router-dom';

const RegisterWithInvitationPage = (props) => {
    const token = props.location.search.replace('?token=', '');
    return (
     <RegisterWithInvitationForm token={token} />
    )
}

export default withRouter(RegisterWithInvitationPage);
