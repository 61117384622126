import React from "react";
import TutorialStateContainer from "../../unstatedStores/TutorialState";
import LegalTech from "../../assets/images/pie.png";
import axios, { authHeaders } from "../../util/Api";
import { Button, Typography } from "antd";
import { QuestionCircleFilled } from '@ant-design/icons';
import useTutorialStateV2 from "../../unstatedStores/TutorialStateV2";

const OverlayTutorial = () => {
  //const tutorialState = TutorialStateContainer.useContainer();
  const tutorialState = useTutorialStateV2();
  return (
    <div className="tuto-overlay">
      {tutorialState.step === 0 && <WelcomeText />}
      {tutorialState.step === 0 && (
        <img
          style={{ position: "absolute", left: "0px", bottom: "0px" }}
          src={LegalTech}
          width="350"
          height="400"
        ></img>
      )}
      <TutorialButton
        text="Siguiente"
        right="30%"
        onClick={() => {
          tutorialState.next();
          tutorialState.completeTutorial("DASHBOARD");
        }}
      />
      <TutorialButton
        text="Omitir"
        right="15%"
        onClick={() => {
          tutorialState.next();
        }}
      />
    </div>
  );
};

export const TutorialTitle = ({title, text, position, image}) => {
  return (
    <>
      <Typography.Title
        className="text-focus-in"
        style={{
          color: "#da8300",
          position: "absolute",
          top: "15%",
          left: "5%",
          fontWeight: "300",
        }}
      >
        <strong>{title}</strong>
      </Typography.Title>
      <p 
        style={{
          color: "#fff",
          position: "absolute",
          top: "21.5%",
          left: "5.25%",
          fontWeight: "300",
          fontSize: '18px'
        }}
      >
        {text}
      </p>
    </>
  );
}

export const WelcomeText = () => {
  return (
    <h1
      className="text-focus-in"
      style={{
        color: "#fff",
        position: "absolute",
        top: "25%",
        left: "5%",
        fontWeight: "300"
      }}
    >
      Bienvenido a <strong>Metajuridico Legaltech</strong>, en esta pequeña guia
      te enseñaremos los fundamentos basicos del sistema. Aprenderemos de forma rapida a crear un contacto,
      un expediente y asignar movimientos.
    </h1>
  );
};

export const RightAlignedInfo = ({ title, text, icon }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "25%",
        right: "5%",
        width: "40vw"
      }}
    >
      <h1
        className="text-focus-in"
        style={{
          color: "#fff",
          fontWeight: "500"
        }}
      >
        {icon} {title}
      </h1>
      <p
        className="text-focus-in"
        style={{
          color: "#fff",
          fontWeight: "300",
          fontSize: 18
        }}
      >
        {text}
      </p>
    </div>
  );
};

export const TutorialText = ({
  title,
  text,
  icon,
  left,
  top,
  right,
  bottom,
  width,
  fontSize,
  titleStyle
}) => {
  return (
    <div
      style={{
        position: "absolute",
        top,
        left,
        right,
        bottom,
        width: width || "40vw"
      }}
    >
      <h1
        className="text-focus-in"
        style={{
          color: "#fff",
          fontWeight: "500",
          ...titleStyle
        }}
      >
        {icon} {title}
      </h1>
      <p
        className="text-focus-in"
        style={{
          color: "#fff",
          fontWeight: "300",
          fontSize: fontSize || 18
        }}
      >
        {text}
      </p>
    </div>
  );
};

export const LeftAlignedInfo = ({ title, text, icon }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "25%",
        left: "5%",
        width: "40vw"
      }}
    >
      <h1
        className="text-focus-in"
        style={{
          color: "#fff",
          fontWeight: "500"
        }}
      >
        {icon} {title}
      </h1>
      <p
        className="text-focus-in"
        style={{
          color: "#fff",
          fontWeight: "300",
          fontSize: 18
        }}
      >
        {text}
      </p>
    </div>
  );
};

export const TutorialButton = ({ text, right, onClick, disabled, top }) => {
  return (
    <Button
      disabled={disabled}
      type="text"
      onClick={onClick}
      style={{
        border: "none",
        cursor: "pointer",
        padding: 10,
        fontSize: 25,
        position: "absolute",
        right,
        bottom: "10px",
        color: "#fff",
        top
      }}
    >
      {text}
    </Button>
  );
};

export function isTutorialCompleted(tutorial) {
  const tutos = getCompletedTutorials();
  return tutos.includes(tutorial);
}

export function getCompletedTutorials() {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    const { tutorials_completed } = user;
    return tutorials_completed;
  } else {
    return ["DASHBOARD"];
  }
}

export const RestartTutorialButton = ({tutorial}) => {
  
  const tutorialState = TutorialStateContainer.useContainer();

  const cleanTutorial = (_tuto) => {
    if(_tuto === "DASHBOARD") {
      tutorialState.completeTutorial("_DASHBOARD");
    }
    tutorialState.deleteTutorial(_tuto);
  }

  return  (<Button
     onClick={() => cleanTutorial(tutorial)} 
     type="text"
     icon={<QuestionCircleFilled />} ></Button>)
}

export async function updateTutorials(tutorial) {
  let user = JSON.parse(localStorage.getItem("user"));
  let tutorials_completed = user.tutorials_completed;
  if (!isTutorialCompleted(tutorial)) {
    tutorials_completed.push(tutorial);
  }
  if(tutorial !== "_DASHBOARD"){
    const resp = await axios.patch("update-tutorials-completed", {
      tutorials_completed
    }, {
      headers: {
        ...authHeaders()
      }
    });
  }
  user.tutorials_completed = tutorials_completed;
  localStorage.setItem("user", JSON.stringify(user));
  return user;
}

export default OverlayTutorial;
