import React from "react";
import { Modal } from "antd";
import CreateFeeForm from "../law-components/Forms/CreateFeeForm";
import { useMediaQuery } from "react-responsive";

export const CreateFeeModal = ({
  visible,
  onCancel,
  onNewFee,
  expedient,
  fee,
  onUpdated,
  withoutExpedient
}) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });

  return (
    <Modal
      open={visible}
      title={fee ? "Editar Honorario" : "Nuevo Honorario"}
      footer={false}
      destroyOnClose={true}
      width={isTabletOrMobile ? "auto" : "85vw"}
      centered
      closable
      onCancel={() => onCancel(false)}
    >
      <CreateFeeForm
        withoutExpedient={withoutExpedient}
        onUpdated={onUpdated}
        fee={fee}
        onCancel={onCancel}
        onDone={onNewFee}
        expedient={expedient}
      />
    </Modal>
  );
};
