import { useEffect, useState } from "react"
import { useClearBrowserCache } from "react-clear-browser-cache"
import Echo from "laravel-echo";
import { versionCompare } from "../util/versionCompare";
import { parseJwt } from "../util/jwt.helper";

export default function useVersionUpdater() {
  const data = parseJwt();
  const clearBrowserCacheBoundary = useClearBrowserCache()
  const [ask, setAsk] = useState(false)
  const [currentVersion] = useState(clearBrowserCacheBoundary.latestVersion)

  //Echo
  const status_channel = "metagestion_status";
  const isDev = process.env.REACT_APP_ENV === 'development'
  const wsPort = isDev ? 2096 : 8443
  const wsHost = process.env.REACT_APP_WS_HOST || 'localhost'
  const wsConfig = {
    broadcaster: "pusher",
    key: "123456",
    wsHost: wsHost,
    [isDev ? 'wssPort' : 'wssPort']: wsPort,
    forceTLS: true,
    encrypted: false,
    disableStats: true
  }
  const echo = new Echo(wsConfig);

  //meta.json part
  useEffect(() => {
    const fetchVersion = async () => {
      const resp = await fetch('/meta.json', { cache: "no-store" })
      const data = await resp.json()
      return data;
    }
    fetchVersion()
      .then(({ version }) => {
        if (versionCompare(version, currentVersion) > 0) {
          setAsk(true)
        }
      })
      .catch((e) => console.log(e))
    return () => {
    }
  }, [data])

  //Websocket part
  useEffect(() => {
    const channel = echo
      .channel(status_channel)
      .listen(".frontend-updated", d => {
        if (d.frontend_updated) {
          setAsk(true)
        }
      });
    return () => {
      channel.stopListening(".frontend-updated");
    }
  }, [])

  return [ask, () => clearBrowserCacheBoundary.clearCacheAndReload()]

}