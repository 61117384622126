import axios from "../util/Api";
import { getPercentage } from "./expedient_movement.service";

const ClientService = {
  getExpedients: (clientId) => {
    return axios.get(`/client/get-expedients/${clientId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getClients: () => {
    return axios.get("/client", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }, getClientsBySign: () => {
    return axios.get("/clients/by-sign", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createClient: (client_data, get_progres) => {
    return axios.post("/client", client_data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      onUploadProgress: progress => {
        get_progres(Math.trunc(getPercentage(progress.loaded, progress.total)));
      }
    });
  },
  searchClient: (params = {}) => {

    return axios.get(`/client/search`, {
      params: params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }, clientCheck: (params = {}) => {

    return axios.get(`/client-check`, {
      params: params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  updateClient: (client_data, get_progres) => {
    return axios.post(`/client/update`, client_data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      },
      onUploadProgress: progress => {
        get_progres(Math.trunc(getPercentage(progress.loaded, progress.total)));
      }
    });
  },
  updateClientType: clientType => {
    return axios.patch(`/client-type/${clientType.id}`, clientType, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getClientTypes: () => {
    return axios.get("/client-type", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getClientTypePg: (params) => {
    return axios.get(`/paginate/client-type`, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  createClientType: client_type => {
    return axios.post("/client-type", client_type, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteClientType: clientType => {
    return axios.delete(`/client-type/${clientType}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteClient: client => {
    return axios.delete(`/client/${client}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  deleteClientSign: client_id => {
    return axios.delete(`client/delete-sign/${client_id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  },
  getClient: client => {
    return axios.get(`/client/${client}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    });
  }
};

export default ClientService;
