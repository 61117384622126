import { SendOutlined } from "@ant-design/icons";
import { Button, Form, message, Progress } from "antd";
import React, { useState } from "react";
import DragAndDropper from "../../law-components/ExpedientFiles/DragAndDrop";
import checkStorage from "../../law-components/ExpedientFiles/StorageWarning";
import { getPercentage } from "../../services/expedient_movement.service";
import axios, { authHeader, baseURL } from "../../util/Api";

const RequestFileUploader = ({ reqFile, onUpload, infoText }) => {
  const [fileList, setFileList] = useState([]);
  const [progress, setProgress] = useState();

  const handleSubmit = values => {
    let formData = new FormData();
    formData.append("id", reqFile.id);
    formData.append("studio_id", reqFile.expedient.studio_id);
    fileList.forEach(file => {
      formData.append("files[]", file.originFileObj);
    });

    let filesSize = 0;
    filesSize = fileList.map((file_data) => file_data.size).reduce((a, b) => a + Number.parseInt(b));

    checkStorage({
      user_role_id: 4,
      studio_id: reqFile.expedient.studio_id,
      fileSize: filesSize,
      onChecked: () => {
        uploadFiles(formData, (percentage) => {
          setProgress(percentage);
          if (percentage === 100) {
            setFileList([]);
            setProgress(0);
          }
        })
          .then(() => {
            onUpload && onUpload();
            message.success("¡Documentos enviados!");
          })
          .catch(e => {
            message.error('Ha ocurrido un error');
            console.log(e);
          })
      }
    })
  };

  return (
    <>
      <Form onFinish={handleSubmit}>
        <Form.Item name="files">
          <DragAndDropper
            buttonText="1. Seleccionar archivos"
            infoText="Haga click para activar la cámara y escanear la documentación solicitada."
            onFileSelect={setFileList}
          />
        </Form.Item>
        <Form.Item>
          {!progress ? (
            <Button
              disabled={fileList.length === 0}
              icon={<SendOutlined />}
              style={{ width: "100%" }}
              type="primary"
              htmlType="submit"
            >
              2. ENVIAR ARCHIVOS
            </Button>
          ) : (
            <>
              <span>
                {progress < 100
                  ? "Sus documentos estan siendo cargados, por favor sea paciente."
                  : "Sus documentos fueron cargados."}
              </span>
              <Progress
                percent={progress}
                status={progress < 100 ? "active" : "success"}
              />
            </>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

async function uploadFiles(files, get_progress) {
  const resp = await axios.post(`${baseURL}/scan/upload`, files, {
    "Content-Type": "multipart/form-data",
    headers: {
      ...authHeader
    },
    onUploadProgress: progress => {
      get_progress(Math.trunc(getPercentage(progress.loaded, progress.total)));
    }
  });
  return resp.data;
}

export default RequestFileUploader;
