import React from "react";
import { Modal, Typography } from "antd";
import CreateClientForm from "../law-components/Forms/CreateClientForm";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as CloseIcon } from "../assets/assets2_0/close-icon.svg";

const CreateClientModal = ({ visible, onCancel, onNewClient, onUpdated, client, title, showLinkButton = false }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 650px)' })

  return (
    <Modal
      closeIcon={<CloseIcon className="clickable-icon" />}
      title={
        <Typography.Text style={{ fontWeight: 800 }}>
          Contactos
        </Typography.Text>
      }
      destroyOnClose
      visible={visible}
      footer={null}
      zIndex={1000}
      onCancel={() => {
        onCancel();
      }}
      className="responsive-modal"
      width={isTabletOrMobile ? "90vw" : "75vw"}
      centered
      style={isTabletOrMobile ? { margin: '125px 0px 0px 0px' } : { margin: "0px" }}
      headStyle={{ backgroundColor: "#3D529E", fontSize: "1.3em", border: "1px solid #3D529E" }}
      bodyStyle={{ border: "1px solid #cbcaca", backgroundColor: "#fafafa", padding: isTabletOrMobile ? "15px" : "25px", borderRadius: "0px 0px 10px 10px" }}
    >
      <CreateClientForm
        onCancel={() => onCancel()}
        title={title} onSaved={(c) =>
          onNewClient(c)}
        client={client}
        onUpdated={onUpdated}
        showLinkButton={showLinkButton}
      />
    </Modal>
  );
};

export default CreateClientModal;

CreateClientModal.defaultProps = {
  onUpdated: () => null,
  title: 'cliente'
}