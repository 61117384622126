import { useState } from "react";
import { createContainer } from "unstated-next";

const useMovementFilters = () => {
  const [filters, setFilters] = useState({
    movements: [],
    date: null,
    type: null,
    filters: null
  });

  let set = (movements, date, type, filters) => {
    const ids = movements.map(m => (m.id === -1 ? m.md5 : m.id));
    setFilters({ movements: ids, date, type, filters });
  };

  return { filters, setFilters, set };
};

const MovementsFiltersContainer = createContainer(useMovementFilters);
export default MovementsFiltersContainer;
