import { Col, DatePicker, Divider, Form, Input, Row } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useExpedientMovementForm } from '../../../../hooks/useExpedientMovementFormState';
import { parseJwt } from '../../../../util/jwt.helper';
import { CustomSwitch } from '../../../CustomTabs/CustomTabs';
import ObservationAutocomplete from '../../../ObservationsAutocomplete';
import Antd4MovementTypeSelect from '../../../PrebuiltFormComponents/Antd4MovementTypeSelect';
import Antd4UserSelect from '../../../PrebuiltFormComponents/Antd4UserSelect';
import CustomSwtichComponent from '../../../PrebuiltFormComponents/CustomSwitchComponent';
import { CaretDownOutlined } from '@ant-design/icons';
import CollapsablePanel from '../../../../util/CollapsablePanel';

const FirstStepForm = ({ expedient, expedient_movement, form }) => {

    const { fields, setFields } = useExpedientMovementForm();
    const [showAdvancedFields, setShowAdvancedFields] = useState(false);

    const user = parseJwt();
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const [tutoStep, setTutoStep] = useState(1);

    let lexMovCond = true;
    if (
        fields &&
        fields.movement_type_id ===
        Number.parseInt(process.env.REACT_APP_MOVEMENT_TYPE_ID_LEX_ESCRITO)
    ) {
        lexMovCond = false;
    }

    const firstStepFields = [
        {
            key: "movement_type_id_col",
            lg: 8,
            md: 24,
            xs: 24,
            _tutoStep: 2,
            cond: lexMovCond,
            item: (
                <Antd4MovementTypeSelect
                    onChange={mt => {
                        form.setFieldsValue({
                            is_judicial: Boolean(mt.is_judicial),
                            taskable: mt.taskable,
                            insta_la_accion: mt.insta_la_accion,
                            important: Boolean(mt.important),
                        });
                        setFields({
                            movement_type_id: mt,
                            is_judicial: Boolean(mt.is_judicial),
                            taskable: Boolean(mt.taskable),
                            insta_la_accion: Boolean(mt.insta_la_accion),
                            important: Boolean(mt.important),
                            days_before_mail_notification: mt.days_before_mail_notification,
                            days_before_popup_notification: mt.days_before_popup_notification
                        })
                    }}
                    expedientType={expedient ? expedient.expedient_type.id : null}
                    currentMovementType={
                        fields.movement_type_id
                    }
                />
            )
        },
        {
            key: "is_judicial_col",
            lg: 8,
            md: 24,
            xs: 24,
            _tutoStep: 3,
            cond: lexMovCond,
            item: (
                <Form.Item label="Modo" name="is_judicial" valuePropName="checked">
                    {/* <Switch
                  onChange={v => setMode(v)}
                  checkedChildren={"Judicial"}
                  unCheckedChildren={"Extrajudicial"}
                /> */}
                    <CustomSwtichComponent
                        uniSwitch={!isTabletOrMobile}
                        fieldName={"is_judicial"}
                        unCheckedChildren={"Extrajudicial"}
                        onSelect={(v) => setFields({ is_judicial: v })}
                        checkedChildren={"Judicial"}
                        value={fields.is_judicial}
                        form={form} />
                </Form.Item>
            )
        },
        {
            key: "date_col",
            lg: 8,
            md: 24,
            xs: 24,
            _tutoStep: 5,
            cond: true,
            item: (
                <Form.Item label="Fecha" name="date">
                    <DatePicker className="responsive-input" showTime={{ format: "HH:mm A" }} format="L LT" />
                </Form.Item>
            )
        },
        {
            key: "internal_responsibles_col",
            lg: 8,
            md: 24,
            xs: 24,
            _tutoStep: 6,
            cond: true,
            item: (
                <Antd4UserSelect
                    formKey="internal_responsibles"
                    label="Responsable internos"
                    mode="multiple"
                    users={
                        expedient_movement && expedient_movement.responsible
                            ? expedient_movement.responsible
                                .filter(r => !Boolean(r.pivot.is_corresponsible))
                                .flatMap(r => r.id)
                            : [user.user.id]
                    }
                />
            )
        },
        {
            key: "co_responsibles_col",
            lg: 8,
            md: 24,
            xs: 24,
            _tutoStep: 7,
            cond: true,
            item: (
                <Antd4UserSelect
                    mode="multiple"
                    label="Co-responsables"
                    formKey="co_responsibles"
                    users={
                        expedient_movement && expedient_movement.responsible
                            ? expedient_movement.responsible
                                .filter(r => Boolean(r.pivot.is_corresponsible))
                                .flatMap(r => r.id)
                            : []
                    }
                />
            )
        },
        {
            key: "taskable_col",
            lg: 8,
            md: 24,
            xs: 24,
            _tutoStep: 8,
            cond: true,
            item: (
                <Form.Item label="Agendar" name="taskable" valuePropName="checked">
                    <CustomSwtichComponent onSelect={(v) => setFields({ taskable: v })} uniSwitch={!isTabletOrMobile} fieldName={"taskable"} value={fields.taskable} form={form} />
                </Form.Item>
            )
        },
        {
            key: "realizado_col",
            lg: 8,
            md: 24,
            xs: 24,
            _tutoStep: 10,
            cond: lexMovCond,
            item: (
                <Form.Item label="Estado" name="realizado" valuePropName="checked">
                    <CustomSwitch
                        mode="toggle"
                        input={true}
                        width={'100%'}
                        borderRadius={isTabletOrMobile ? '15px' : '7.5px'}
                        padding={isTabletOrMobile ? '4ch 3ch' : '2ch 1.5ch'}
                        options={[{ color: "#24335D", name: "HECHO" }, { name: "PENDIENTE", color: "#FF5879" }]} defaultValue={fields.realizado ? 0 : 1}
                        onChange={v => {
                            form.setFieldsValue({ realizado: v.name === 'HECHO' })
                        }} />
                </Form.Item>
            )
        },
        {
            key: "public_access_col",
            lg: 8,
            md: 24,
            xs: 24,
            cond: true,
            item: (
                <Form.Item
                    label="Acceso externo"
                    name="public_access"
                    valuePropName="checked"
                >
                    <CustomSwtichComponent
                        uniSwitch
                        fieldName={"public_access"}
                        onSelect={(v) => setFields({ public_access: v })}
                        value={fields.public_access}
                        form={form} />
                </Form.Item>
            )
        },
        {
            key: "observations_col",
            lg: 24,
            md: 24,
            xs: 24,
            _tutoStep: 11,
            cond: true,
            item: (
                <Form.Item rules={[
                    { required: true, message: "Debe escribir una observación" }
                ]} label="Observaciones" name="observations">
                    <ObservationAutocomplete
                        initialValue={
                            fields.observations
                        }
                        onChange={(value) => {
                            form.setFieldsValue({ observations: value })
                        }}
                    />
                </Form.Item>
            )
        }, {
            key: "public_access_observation_col",
            lg: 24,
            md: 24,
            xs: 24,
            cond: fields.public_access,
            item: (
                <Form.Item
                    name="public_access_observation"
                    key="public_access_observation"
                    label="Observaciones (Link externo)"
                >
                    <Input.TextArea className="responsive-input" placeholder="Observaciones" />
                </Form.Item>
            )
        }
    ];

    const firstStepAdvancedFields = [
        {
            key: "foja_number_col",
            lg: 6,
            md: 24,
            xs: 24,
            _tutoStep: 4,
            cond: fields.is_judicial,
            item: (
                <Form.Item label="Numero de foja" name="foja_number">
                    <Input className="responsive-input" autoComplete="cc-csc" />
                </Form.Item>
            )
        }, {
            key: "insta_la_accion_col",
            lg: 6,
            md: 24,
            xs: 24,
            _tutoStep: 9,
            cond: lexMovCond,
            item: (
                <Form.Item
                    label="Insta la acción"
                    name="insta_la_accion"
                    valuePropName="checked"
                >
                    <CustomSwtichComponent
                        uniSwitch={!isTabletOrMobile}
                        fieldName={"insta_la_accion"}
                        onSelect={(v) => setFields({ insta_la_accion: v })}
                        value={fields.insta_la_accion}
                        form={form} />
                </Form.Item>
            )
        }, {
            key: "is_public_col",
            lg: 6,
            md: 24,
            xs: 24,
            _tutoStep: 12,
            cond: lexMovCond,
            item: (
                <Form.Item label="Es Público" name="is_public" valuePropName="checked">
                    <CustomSwtichComponent
                        uniSwitch
                        fieldName={"is_public"}
                        onSelect={(v) => setFields({ is_public: v })}
                        value={fields.is_public}
                        form={form} />
                </Form.Item>
            )
        },
        {
            key: "important_col",
            lg: 6,
            md: 24,
            xs: 24,
            _tutoStep: 13,
            cond: lexMovCond,
            item: (
                <Form.Item
                    label="Marcar como importante"
                    name="important"
                    valuePropName="checked"
                >
                    <CustomSwtichComponent
                        uniSwitch
                        fieldName={"important"}
                        onSelect={(v) => setFields({ important: v })}
                        value={fields.important}
                        form={form} />
                </Form.Item>
            )
        }
    ]

    const updateMainForm = (values) => {
        Object.assign(values, {
            expedient_id: expedient.id,
            date: moment(values.date).format("YYYY-MM-DD HH:mm:ss"),
            responsibles: values.internal_responsibles
                .map(resp => ({
                    responsible_id: resp,
                    is_corresponsible: false
                }))
                .concat(
                    values.co_responsibles.map(resp => ({
                        responsible_id: resp,
                        is_corresponsible: true
                    }))
                )
        });
        setFields(values);
    }

    return (
        <Form
            form={form}
            title='first-step-form'
            layout="vertical"
            onFinish={updateMainForm}
            initialValues={{
                is_judicial: fields.is_judicial,
                taskable: Boolean(fields.taskable),
                realizado: fields.realizado,
                insta_la_accion: Boolean(fields.insta_la_accion),
                public_access: fields.public_access,
                is_public: fields.is_public,
                important: fields.important,
                observations: fields.observations,
                foja_number: fields.foja_number,
                date: moment(fields.date),
                public_access_observation: fields.public_access_observation,
            }}
        >
            <Row gutter={10} style={{ flexDirection: "inherit" }}>
                {firstStepFields
                    .map(f =>
                        f.cond ? f : { item: !lexMovCond ? null : <div>&nbsp;</div> }
                    )
                    .filter(field => {
                        if (!lexMovCond) {
                            return field.item !== null;
                        }
                        return true;
                    })
                    .map(field => (
                        <Col key={field.key} xs={field.xs} lg={field.lg} md={field.md}>
                            <div>
                                {field.item}
                            </div>
                        </Col>
                    ))}

            </Row>
            <Divider
                type="horizontal"
                orientation="left"
                style={{ borderTopColor: '#cbcaca' }}>
                <div
                    className="show-advanced-options"
                    onClick={() => {
                        setShowAdvancedFields(!showAdvancedFields)
                    }}
                >
                    <CaretDownOutlined className={showAdvancedFields ? "open-advance" : "closed-advance"} /> Opciones avanzadas
                </div>
            </Divider>
            <CollapsablePanel type="block" vertical={true} open={showAdvancedFields}>
                <Row gutter={10} style={{ flexDirection: "inherit" }}>
                    {firstStepAdvancedFields
                        .map(f =>
                            f.cond ? f : { item: !lexMovCond ? null : <div>&nbsp;</div> }
                        )
                        .filter(field => {
                            if (!lexMovCond) {
                                return field.item !== null;
                            }
                            return true;
                        })
                        .map(field => (
                            <Col key={field.key} xs={field.xs} lg={field.lg} md={field.md}>
                                <div>
                                    {field.item}
                                </div>
                            </Col>
                        ))}
                </Row>
            </CollapsablePanel>
        </Form>
    )
}

export default FirstStepForm;