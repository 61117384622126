import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Upload } from 'antd';
import React, { useState } from 'react';
import { getBase64 } from '../ImageUploader';
import { ReactComponent as DeleteIcon } from "../../../assets/assets2_0/delete.svg";

const { Dragger } = Upload;

const UploadSign = ({ onFileSelect, file, image, onDelete }) => {

    const [fileList, setFileList] = useState(file ? file : []);
    const [previewImage, setPreviewImage] = useState(image);
    const [savedSign, setSavedSign] = useState(image ? true : false);

    const isJpgOrPng = (fileType) => fileType === 'image/jpeg' || fileType === 'image/png';

    const _onChange = ({ file, fileList }) => {
        if (file.status === "removed") {
            setPreviewImage(null)
            setFileList([]);
            onFileSelect && onFileSelect([]);
        } else {
            if (!isJpgOrPng(file.type)) {
                message.error('Solo puedes subir archivos de tipo JPG/PNG!');
            } else {
                setFileList([file]);
                onFileSelect && onFileSelect([file]);
            }
        }
    };

    return (
        <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: "flex-start", alignItems: "center" }}>
            <Upload
                accept='jpg,png'
                key="file"
                name="file"
                fileList={fileList}
                onChange={_onChange}
                action={null}
                beforeUpload={(file, fileList) => {
                    if(!isJpgOrPng(file.type)) return false;
                    getBase64(file, setPreviewImage);
                    return false;
                }}
            >
                <div style={{ display: "flex", flexDirection: "column", gap: "3px", justifyContent: "space-evenly" }}>
                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        {previewImage ? <img alt="example" style={{ width: '200px', borderRadius: "15px" }} src={previewImage} /> : <></>}

                    </div>
                    <Button classname={"responsive-input"} style={{ height: "45px" }} icon={<UploadOutlined />}>Adjuntar firma</Button>
                </div>
            </Upload>
            {savedSign && previewImage ?

                <Popconfirm
                    title="¿Seguro desea eliminar esta firma?"
                    onConfirm={async () => {
                        const response = await onDelete();
                        console.log(response)
                        if (response.status === 200) {
                            message.success("Firma eliminada")
                            setSavedSign(false);
                            setPreviewImage(null);
                            setFileList([]);
                            onFileSelect && onFileSelect([]);
                        } else {
                            message.error("No se pudo eliminar la firma")
                        }
                    }}
                >

                    <DeleteIcon
                        width={"22"}
                        height={"22"}
                        style={{ cursor: "pointer" }}
                        color="#FF5879"
                    />
                </Popconfirm> : <></>}

        </div>



    )
}

export default UploadSign;