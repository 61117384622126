import React, { useState } from "react";
import { Typography, Tooltip } from "antd";
import { onCopy } from "../../routes/RtfPage/RtfInfo";

const ExpandCollpaseText = ({ text, style, rows, onEndEditing, copyable = false, copyFromText = false, expandable = true }) => {
  const [isExpanded, expand] = useState(false);
  const [ellipsis, setEllipsis] = useState(false);
  const [counter, setCounter] = useState(0);

  const renderParagraph = () => (
    <>
      <Typography.Paragraph
        onClick={(e) => {
          if (copyFromText) {
            onCopy(text)
          }
        }}
        copyable={copyable}
        editable={
          onEndEditing
            ? {
              onChange: v => {
                onEndEditing(v);
              }
            }
            : null
        }
        style={{ cursor: copyFromText ? "pointer" : "auto", Justify: "inter-word", marginBottom: "0px", ...style }}
        ellipsis={{
          rows,
          expandable: expandable,
          onExpand: (e) => {
            e.stopPropagation();
            expandOrCollapse(true);
            setEllipsis(!ellipsis);
          },
          onEllipsis: () => setEllipsis(!ellipsis)
        }}
      >
        {text}
      </Typography.Paragraph>
      {isExpanded && (
        <span
          style={{ color: "#038fde", cursor: "pointer" }}
          onClick={() => {
            expandOrCollapse(false);
          }}
        >
          {" "}
          contraer
        </span>
      )}
    </>
  );

  const expandOrCollapse = value => {
    let _counter = !isExpanded ? counter + 0 : counter + 1;
    setCounter(_counter);
    expand(value);
  };

  return (
    <div key={counter}>
      {text && text.length < 25 ? renderParagraph()
        :
        <Tooltip trigger="hover" title={ellipsis ? text : ""}>
          {renderParagraph()}
        </Tooltip>}
    </div>
  );
};

export default ExpandCollpaseText;

ExpandCollpaseText.defaultProps = {
  text: "",
  style: {},
  rows: 3
};
