import React from "react";
import { TurnoSelectorProvider } from "./TurnoSelectorContext";
import TDateSelect from "./TDateSelect";
import THourSelect from "./THourSelect";
import './turno-selector.less'
import TDataInput from "./TDataInput";
import TResume from "./TSave";
import TurnoSave from "./TSave";

export default function TurnoSelector({ onSaved, show, onUpdated, appointment }) {
  return <TurnoSelectorProvider appointment={appointment} >
    <div className="turno-selector-wrapper">
      <div className="turno-selector-container">
        <TDataInput />
        <TDateSelect />
        <THourSelect appointment={appointment} />
      </div>
      <div className="turno-selector-footer">
        <TurnoSave onSaved={onSaved} onUpdated={onUpdated} />
      </div>
    </div>
  </TurnoSelectorProvider>
}