import React, { useState, useEffect } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from "antd";
import ClientService from "../../services/client.service";
const { Option } = Select;

export const ClientTypeSelect = ({
  getFieldDecorator,
  clientType,
  multi,
  onChange,
  title,
  required
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    ClientService.getClientTypes()
      .then(data => {
        setData(data.data);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Form.Item key="client_type_id" label={`Tipo${multi ? 's' : ''} de ${title}${multi ? 's' : ''}`}>
      {getFieldDecorator("client_type_id", {
        rules: [
          {
            required: required,
            message: "Debe indicar el tipo de " + title
          }
        ],
        initialValue: clientType
      })(
        <Select
          className="responsive-input"
          optionFilterProp="children"
          showSearch
          onChange={v => onChange(data[data.findIndex(m => m.id === v)])} loading={loading} mode={multi ? "multiple" : "default"}>
          {data.map(client_type => (
            <Option key={client_type.id} value={client_type.id}>
              {client_type.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
};

ClientTypeSelect.defaultProps = {
  multi: false,
  onChange: (v) => null,
  title: 'cliente',
  required: true
};
