import React from "react"
import { message } from "antd";
import axios from "axios";
import { toast } from "react-toastify";

const route3 = "http://sd-1701654-h00007.ferozo.net/backend"; //DEV SERVER
const route4 = "http://localhost:8000"; //LOCAL DEV ROUTE
const route5 = "https://metagestion.com.ar/backend"; //PROD SERVER
export const apiRoute = process.env.REACT_APP_API_ROUTE;
export const baseURL = `${process.env.REACT_APP_API_ROUTE}/api`;
export const wsUrl = baseURL === `${route5}/api` ? "metagestion.com.ar" : "localhost";
export const authHeader = {
  Authorization: `Bearer ${localStorage.getItem("token")}`
};

if (localStorage.getItem("token")) {
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("token");
}

const instance = axios.create({
  baseURL,
  headers: authHeader
});

instance.interceptors.response.use(
  function (success) {
    return success;
  },
  function (error) {
    if (!error.response) {
      toast.error("Error de conexión", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    } else {
      if (error.response.data.action === "logout") {
        localStorage.clear();
        window.location.href = "/signin";
      } else if (error.response.data.type === 'usage_limit_reached') {
        message.warning({
          content: "Has superado el límite de uso de esta característica.",
          marginTop: '80vh',
          duration: 5
        })
      }
    }
    console.dir(error);
    return Promise.reject(error);
  }
);

export const authHeaders = () => {
  return { Authorization: `Bearer ${localStorage.getItem("token")}` };
};

export default instance;
