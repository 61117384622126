import React, { useState, useEffect } from "react";
import {
  DollarOutlined,
  DownloadOutlined,
  FolderOutlined,
  InboxOutlined,
  ReloadOutlined,
  SwapOutlined
} from "@ant-design/icons";
import {
  Row,
  Col,
  Button,
  Input,
  Typography,
  Table,
  Popconfirm,
  message,
  Tooltip,
  Select
} from "antd";
import ExpedientService from "../../services/expedient.service";
import { baseURL } from "../../util/Api";
import { bytesFormatter } from "../../util/bytesFormatter";
import Uploader from "./Uploader";
import moment from "moment";
import { downloadFileFunc } from "../ExpedientMovements";
import ExpedientDetailContainer from "../../unstatedStores/ExpedientDetailState";
import FilesMassActions from "./FilesMassActions";
import { ReactComponent as EyeIcon } from "../../assets/assets2_0/eye.svg";
import { ReactComponent as DeleteIcon } from "../../assets/assets2_0/delete.svg";
import PreviewFileModal from "../../modals/PreviewFileModal";
import { parseJwt } from "../../util/jwt.helper";
import { BsRobot } from "react-icons/bs";

let defaultFiles = [];

const ExpedientFileList = ({
  expedient, //Puede ser un expediente o un movimiento de expediente
  newFileUpdated,
  service,
  showActions,
  showUploader,
  size,
  onAllFilesDeleted,
  donwloadFileRoute = `${baseURL}/file/download/`,
  plublicAccess,
  allowDelete = true,
  showFilters = true
}) => {


  const tokenInfo = parseJwt();
  const user = tokenInfo ? tokenInfo.user : null;
  const studio = user ? user.studio : null;
  const mev_active = studio && Boolean(studio.mev_scraping);//Extraigo la info de esta manera porque este componente se renderiza en una vista donde el usuario no está logeado y por tanto TokenInfo es null
  const [loading, showLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState(0);
  const [deleting, setDeleting] = useState(false);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [onlyMev, setOnlyMev] = useState(false);

  const [hoverStates, setHoverStates] = useState({});
  const [deleteHoverStates, setDeleteHoverStates] = useState({});
  const [previewFile, setPreviewFile] = useState(false);
  const [fileToPreview, setFileToPreview] = useState(undefined);

  const expedientState = ExpedientDetailContainer.useContainer();
  const { scan } = expedientState;
  useEffect(() => {
    expedient && loadFiles();
  }, [expedient, newFileUpdated, scan.files, onlyMev]);

  const loadFiles = () => {
    showLoading(true);
    service.getFiles(expedient.id, { only_mev: onlyMev }).then(files => {
      setFiles(files.data);
      defaultFiles = files.data;
      showLoading(false);
    });
  };

  const filterFiles = text => {
    if (text !== "") {
      setFiles(
        defaultFiles.filter(file =>
          file.name.toLowerCase().includes(text.toLowerCase())
        )
      );
    } else {
      setFiles(defaultFiles);
    }
  };

  const handleMouseEnter = id => {
    setHoverStates(prev => ({ ...prev, [id]: true }));
  };

  const handleMouseLeave = id => {
    setHoverStates(prev => ({ ...prev, [id]: false }));
  };

  const handleMouseEnterDelete = id => {
    setDeleteHoverStates(prev => ({ ...prev, [id]: true }));
  };

  const handleMouseLeaveDelete = id => {
    setDeleteHoverStates(prev => ({ ...prev, [id]: false }));
  };

  const isEditable = record => {
    if (!record.is_editable) return false;
    if (plublicAccess) return false;
    if (!allowDelete) return false;
    return {
      onChange: async newName => {
        if (newName !== record.name) {
          const renamedFile = await renameFile(record.id, newName, service);
          setFiles(
            files.map(f => ({
              ...f,
              name: record.id === f.id ? renamedFile.name : f.name
            }))
          );
          message.success("Archivo renombrado");
        }
      }
    };
  };

  const fileColumns = [
    {
      width: "45%",
      key: "name",
      dataIndex: "name",
      title: "Nombre",
      render: (name, record) => (
        <Tooltip trigger="hover" title={name}>
          <Typography.Text
            style={{ whiteSpace: "normal" }}
            ellipsis
            editable={isEditable(record)}
          >
            {name}
          </Typography.Text>
        </Tooltip>
      )
    },
    {
      key: "mime",
      dataIndex: "mimetype",
      title: "Formato"
    },
    {
      key: "size",
      dataIndex: "size",
      title: "Tamaño",
      render: size => bytesFormatter(size)
    },
    {
      key: "created_at",
      dataIndex: "created_at",
      title: "Cargado",
      render: created_at => moment(created_at).format("DD/MM/YYYY"),
      sorter: (a, b) => moment(a.created_at) - moment(b.created_at)
    },
    {
      key: "fileable_type",
      dataIndex: "fileable_type",
      title: "Origen",
      align: "center",
      render: (fileable_type, record) => {

        if (record.is_mev) {
          return (
            <Tooltip title="Movimiento MEV">
              <BsRobot />
            </Tooltip>
          )
        }
        return fileOrigin(fileable_type, record);
      }
    },
    {
      key: "actions",
      dataIndex: "id",
      title: "Acciones",
      align: "center",
      render: (id, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px"
          }}
        >
          <Tooltip trigger="hover" title="Previsualizar">
            <EyeIcon
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: hoverStates[id] ? "#0185F8" : "#002A4E",
                padding: 0,
                border: "none",
                background: "none",
                fontSize: "17px"
              }}
              onMouseEnter={() => handleMouseEnter(id)}
              onMouseLeave={() => handleMouseLeave(id)}
              onClick={() => {
                setFileToPreview(record);
                setPreviewFile(true);
              }}
            />
          </Tooltip>
          <DownloadFile file={record} donwloadRoute={donwloadFileRoute} />
          {!plublicAccess && allowDelete && record.is_editable && (
            <Tooltip trigger="hover" title="Eliminar archivo">
              <Popconfirm
                title={`¿Seguro desea eliminar el archivo "${record.name}"?`}
                onConfirm={async () => {
                  setDeleting(true);
                  const deletedFile = await deleteFile(id, service);
                  const refreshFiles = files.filter(
                    f => f.id !== deletedFile.id
                  );
                  if (refreshFiles.length === 0) {
                    onAllFilesDeleted && onAllFilesDeleted();
                  }
                  if (
                    record.fileable_type === "App\\Models\\ExpedientFileRequest"
                  ) {
                    expedientState.deleteScanFile(record);
                  }
                  setFiles(refreshFiles);
                  setDeleting(false);
                }}
              >
                <DeleteIcon
                  disabled={deleting}
                  width={"13.313"}
                  style={{ cursor: "pointer" }}
                  color={deleteHoverStates[id] ? "#FF5879" : "#95001D"}
                  onMouseEnter={() => handleMouseEnterDelete(id)}
                  onMouseLeave={() => handleMouseLeaveDelete(id)}
                />
              </Popconfirm>
            </Tooltip>
          )}
        </div>
      )
    }
  ];

  const rowSelection = {
    selectedRowKeys: selectedRowsId,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowsId(selectedRowKeys.map(selectedRowKey => selectedRowKey));
    },
    getCheckboxProps: record => ({
      disabled: !["pdf", "jpg", "jpeg", "png", "doc", "docx", "rtf"].includes(
        record.mimetype.toLowerCase()
      ), // Column configuration not to be checked
      name: record.name
    })
  };

  files.forEach((element, i) => {
    element.key = element.id;
  });

  return (
    <div>
      {fileToPreview && (
        <PreviewFileModal
          showModal={previewFile}
          setShowModal={setPreviewFile}
          file={fileToPreview}
          setFileToPreview={setFileToPreview}
        />
      )}
      <Row>
        <Col
          xxl={showUploader ? 18 : 24}
          xl={showUploader ? 18 : 24}
          lg={showUploader ? 18 : 24}
          md={24}
          sm={24}
          xs={24}
        >
          {showActions && (
            <div style={{ padding: "5px 0px" }}>
              <ActionButtons handleFilter={filterFiles} expedient={expedient} />

              <FilesMassActions
                expedient={expedient}
                reload={() => loadFiles()}
                showActions={selectedRowsId.length > 0}
                selectedFiles={selectedRowsId}
                onSaved={file => {
                  setFiles([file, ...files]);
                }}
                cleanSelection={() => setSelectedRowsId([])}
              />

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                <Tooltip title="Recargar archivos">
                  <Button
                    type="primary"
                    icon={<ReloadOutlined />}
                    onClick={() => loadFiles()}
                  />
                </Tooltip>


                {(mev_active && showFilters) && <Select
                  className="responsive-input"
                  defaultValue={"only_expedient"}
                  onSelect={v => {
                    if (v === "only_mev") {
                      setOnlyMev(true)
                    } else {
                      setOnlyMev(false)
                    }
                  }}
                  style={{
                    width: "180px",
                    textAlign: "center"
                  }}
                  size="small"
                  placeholder="Judicial"
                >
                  <Select.Option key={8} value="only_expedient">
                    Solo expediente
                  </Select.Option>
                  <Select.Option key={9} value="only_mev">
                    Solo Mev
                  </Select.Option>
                </Select>}
              </div>
            </div>
          )}
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection
            }}
            className="gx-table-responsive"
            size={size}
            loading={loading}
            dataSource={files}
            columns={fileColumns}
            pagination={{
              defaultPageSize: 5,
              pageSizeOptions: [5, 10, 20],
              showSizeChanger: true
            }}
          />
        </Col>
        {showUploader && (
          <Col
            xxl={6}
            xl={6}
            lg={6}
            md={24}
            sm={24}
            xs={24}
            style={{ textAlign: "center" }}
          >
            <Uploader
              onFileUpload={file => {
                setFiles([file, ...files]);
              }}
              filelistChange={fileList => setFileList(fileList)}
              expedient={expedient}
            />
            {/* {fileList === 0 && <Empty description="Cargue algunos archivos" />} */}
          </Col>
        )}
      </Row>
    </div>
  );
};

const ActionButtons = ({ handleFilter }) => {
  return (
    <React.Fragment>
      <Row style={{ marginBottom: 20 }}>
        <Col span={24}>
          <Input
            autoComplete="cc-csc"
            placeholder="Filtrar 🔎"
            onKeyUp={e => handleFilter(e.target.value)}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

async function renameFile(file, name, service) {
  const renamedFile = await ExpedientService.renameFile(file, name);
  return renamedFile.data;
}

export async function deleteFile(file, service) {
  const deletedFile = await ExpedientService.deleteFile(file);
  return deletedFile.data;
}

export function DownloadFile({ file, downloadRoute, placement = "top" }) {
  const [loading, setLoading] = useState(false);
  const [isDownloadHovered, setIsDownloadHovered] = useState(false);
  return (
    <Tooltip trigger="hover" title="Descargar archivo" placement={placement}>
      <Button
        loading={loading}
        type="link"
        icon={
          <DownloadOutlined
            style={{
              color: isDownloadHovered ? "#0185F8" : "#002A4E",
              fontSize: "19px"
            }}
          />
        }
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          color: isDownloadHovered ? "#0185F8" : "#002A4E",
          padding: 0,
          border: "none",
          background: "none"
        }}
        onMouseEnter={() => setIsDownloadHovered(true)}
        onMouseLeave={() => setIsDownloadHovered(false)}
        onClick={() => {
          setLoading(true);
          downloadFileFunc(
            downloadRoute
              ? `${downloadRoute}${file.id}`
              : `${baseURL}/file/download/${file.id}`,
            `${file.name}.${file.mimetype}`.replace(".pdf.pdf", ".pdf")
          ).finally(() => setLoading(false));
        }}
      />
    </Tooltip>
  );
}

ExpedientFileList.defaultProps = {
  service: ExpedientService,
  showActions: true,
  showUploader: true,
  plublicAccess: false,
  size: "middle"
};

const fileOrigin = (fileable_type, f) => {


  if (fileable_type === "App\\Models\\Expedient") {
    return (
      <Tooltip title="Expediente">
        <FolderOutlined />
      </Tooltip>
    );
  } else if (fileable_type === "App\\Models\\ExpedientMovement") {
    return (
      <Tooltip title="Movimiento">
        <SwapOutlined />
      </Tooltip>
    );
  } else if (fileable_type === "App\\Models\\ExpedientAccount") {
    return (
      <Tooltip title="Cuenta corriente">
        <DollarOutlined />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={f.fileable.indications && f.fileable.indications}>
        <InboxOutlined />
      </Tooltip>
    );
  }
};

export default ExpedientFileList;
