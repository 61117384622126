import React, { useState, useEffect } from "react";
import { CloseOutlined, FilePdfOutlined, InboxOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Row,
  Col,
  Select,
  Card,
  message,
  DatePicker,
  TimePicker,
  Popconfirm,
  Upload,
  Spin,
} from "antd";
import moment from "moment";
import 'moment/locale/es';

import AppointmentService from "../../services/appointment.service";

const UploadDocumentsPage = ({ form, match }) => {
  const [appointment, setAppointment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const downloadUrl = 'http://localhost:8000/api/file/download';
  const { getFieldDecorator } = form;
  const appointmentId = match.params.appointment;

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async () => {
    setLoading(true);

    await AppointmentService.getAppointmentById(appointmentId)
      .then(res => {
        console.log(res.data);

        setAppointment(...res.data);
      })
      .catch(err => handleBadResponse(err));

    setLoading(false);
  }

  const handleBadResponse = (error) => {
    if (!error.response) {
      message.error('Error de conexión');
    } else {
      message.error('Ha ocurrido un error');
    }

    console.log('Ha ocurrido un error', error);
  }

  const normFile = e => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      console.log('Values', values);

      if (!err) {
        setLoading(true);

        const files = values.dragger || [];
        for (const file of files) {
          const formdata = new FormData;

          formdata.append('description', `Archivo ${appointment && appointment.files.length + 1} de cita N. ${appointmentId}`);
          formdata.append('file', file.originFileObj);

          console.log('FormData', formdata);

          await AppointmentService.uploadAppointmentFile(appointmentId, formdata)
            .then(res => {
              form.resetFields();
              fetchData();
            })
            .catch(err => handleBadResponse(err));
        }

        setLoading(false);
      }
    });
  };

  const onDelete = async (id) => {
    setLoading(true);

    await AppointmentService.deleteFile(id)
      .then(res => {
        fetchData();
      })
      .catch(err => handleBadResponse(err));

    setLoading(false);
  }

  const title = () => {
    return (
      <Row>
        <Col lg={8} md={8} xs={24} className='event-modal-title'>
          <Row type='flex' justify='center' className='no-margin'>{appointment.user.name}</Row>
          <Row type='flex' justify='center' className='event-modal-datetime no-margin'>{appointment.specialty.name}</Row>
        </Col>
        <Col lg={8} md={8} xs={24} className='event-modal-title'>
          <Row type='flex' justify='center' className='no-margin'>{moment(appointment.date).format('dddd, DD [de] MMMM')}</Row>
        </Col>
        <Col lg={8} md={8} xs={24} className='event-modal-title'>
          {moment(appointment.starting_time, 'HH:mm:ss').format('hh:mm a')} - {moment(appointment.ending_time, 'HH:mm:ss').format('hh:mm a')}
        </Col>
      </Row>
    )
  }

  return <>
    <Row type='flex' justify='center' className='align-center full-height'>
      <Card title={appointment && title()} 
      className='full-width full-height no-margin' 
      style={{ maxHeight: '100%', overflow: 'auto'}}>
        <Form layout='vertical' onSubmit={handleSubmit}>
          <Form.Item >
            {getFieldDecorator('dragger', {
              valuePropName: 'fileList',
              getValueFromEvent: normFile,
            })(
              <Upload.Dragger name="files" accept='.jpg,.png,.pdf'>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click aquí o arrastra archivos a este cuadro.</p>
                <p className="upload-files-hint">(pdf, jpg, png)</p>
              </Upload.Dragger>,
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Guardar
            </Button>
          </Form.Item>
        </Form>
        <Row type='flex' justify='start' className='event-modal-title'>
          {`Observación`}
        </Row>
        <Row type='flex' justify='start' className='shifts-header'>
          {appointment && appointment.observation}
        </Row>
      </Card>
    </Row>
  </>;
};

const WrappedUploadDocumentsPage = Form.create({ name: 'upload-documents-page' })(UploadDocumentsPage);

export default WrappedUploadDocumentsPage;
