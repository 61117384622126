import { Checkbox, Col, DatePicker, Form, Input, message, Modal, Row, Select, Tag, Button } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CustomSwitch } from '../law-components/CustomTabs/CustomTabs';
import SelectStudio from '../law-components/Forms/Studio/SelectStudio';
import Antd4TagAutocomplete from '../law-components/PrebuiltFormComponents/Antd4TagAutocomplete';
import Antd4UserSelect from '../law-components/PrebuiltFormComponents/Antd4UserSelect';
import CustomSwtichComponent from '../law-components/PrebuiltFormComponents/CustomSwitchComponent';
import StudioTaskService from '../services/studio_task.service';
import { parseJwt } from '../util/jwt.helper';
import { TaskListIcon } from '../util/taskListIcon';

const CreateStudioTaskModal = ({ task, visible, onCancel, onUpdated, onCreated, schedule, show_custom_title = false }) => {

  return (
    <div>
      <Modal
        title="Tarea de Estudio"
        destroyOnClose
        visible={visible}
        footer={null}
        className="responsive-modal"
        width="75vw"
        centered
        onCancel={onCancel}
      >
        <StudioTaskForm onCancel={onCancel} editing_task={task} onUpdated={onUpdated} onCreated={onCreated} schedule={schedule} show_custom_title={show_custom_title} />
      </Modal>
    </div>
  )
}

export default CreateStudioTaskModal;

const StudioTaskForm = ({ editing_task, onCancel, onUpdated, onCreated, schedule, show_custom_title }) => {

  const [done, setDone] = useState(editing_task ? editing_task.realizado : false);
  const [loading, setLoading] = useState(false);
  const [taskable, setTaskable] = useState(editing_task ? Boolean(editing_task.taskable) : !schedule ? schedule : true);
  const [isPublic, setIsPublic] = useState(editing_task ? Boolean(editing_task.is_public) : true)

  const [form] = useForm();
  const { user } = parseJwt();
  const is_super_admin = user.role_id === 1;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' })

  const submitData = async (values) => {

    {
      form.validateFields()
        .then(values => {
          setLoading(true);

          const set_custom_title = values.custom_title && values.custom_title.trim() !== '';

          Object.assign(values, {
            custom_title: set_custom_title ? values.custom_title : null,
            realizado: done,
            date: moment(values.date).format("YYYY-MM-DD HH:mm:ss"),
            responsibles: values.internal_responsibles
              .map(resp => ({
                responsible_id: resp,
                is_corresponsible: false
              }))
              .concat(
                values.corresponsibles.map(resp => ({
                  responsible_id: resp,
                  is_corresponsible: true
                }))
              )
          });

          delete values["internal_responsibles"];
          delete values["corresponsibles"];

          if (editing_task) {
            Object.assign(values, {
              id: editing_task.id
            });

            StudioTaskService.updateStudioTask(values)
              .then((res) => {
                onUpdated && onUpdated(res.data);
                message.success("Check actualizado exitosamente");
              })
              .catch((e) => {
                message.error("Ha ocurrido un error");
                console.log(e);
              })
              .finally(() => setLoading(false));
          } else {
            StudioTaskService.createStudioTask(values)
              .then((res) => {
                onCreated && onCreated(res.data);
                message.success("Check creado exitosamente");
              })
              .catch((e) => {
                message.error("Ha ocurrido un error");
                console.log(e);
              })
              .finally(() => setLoading(false));
          }
        })
        .catch(error => console.log(error))
    }
  }

  const onPrioritySelect = (v, form) => {
    switch (v) {
      case "1":
        form.setFieldsValue({
          days_before_mail_notification: "14,3,1",
          days_before_popup_notification: "14,3,1"
        });
        break;
      case "2":
        form.setFieldsValue({
          days_before_mail_notification: "7,3,1",
          days_before_popup_notification: "7,3,1"
        });
        break;
      case "3":
        form.setFieldsValue({
          days_before_mail_notification: "2,1",
          days_before_popup_notification: "2,1"
        });
        break;

      default:
        form.setFieldsValue({
          days_before_mail_notification: null,
          days_before_popup_notification: null
        });
        break;
    }
  }

  const fields = [
    {
      key: 'custom_title',
      lg: 24,
      md: 24,
      xs: 24,
      cond: show_custom_title,
      item: (
        <Form.Item key={"custom_title"} label="Título (opcional)" name="custom_title">
          <Input className="responsive-input" />
        </Form.Item>
      )
    },
    {
      key: 'date',
      lg: 8,
      md: 24,
      xs: 24,
      cond: true,
      item: (
        <Form.Item
          key={"date"}
          label="Fecha"
          name="date"
          rules={[
            {
              required: true,
              message: "Debe indicar la fecha"
            }
          ]}
        >
          <DatePicker size="large" className="responsive-input" showTime={{ format: "HH:mm A" }} format="L LT" />
        </Form.Item>
      )
    }, {
      key: 'internal_responsibles',
      lg: 8,
      md: 24,
      xs: 24,
      cond: true,
      item: (
        <Antd4UserSelect
          required={true}
          mode='multiple'
          formKey="internal_responsibles"
          label="Responsable"
          users={
            editing_task
              ? editing_task.responsible
                .filter(r => !Boolean(r.pivot.is_corresponsible))
                .flatMap(r => r.id)
              : [user.id]
          }
        />
      )
    }, {
      key: 'corresponsibles',
      lg: 8,
      md: 24,
      xs: 24,
      cond: true,
      item: (
        <Antd4UserSelect
          mode='multiple'
          formKey="corresponsibles"
          label="Co-responsable"
          users={
            editing_task
              ? editing_task.responsible
                .filter(r => Boolean(r.pivot.is_corresponsible))
                .flatMap(r => r.id)
              : undefined
          }
        />
      )
    }, {
      key: 'priority',
      lg: 8,
      md: 24,
      xs: 24,
      cond: true,
      item: (
        <Form.Item className="responsive-input" key={"priority"} label="Prioridad" name="priority" valuePropName="checked">
          <Select onSelect={(v) => onPrioritySelect(v, form)} defaultValue={editing_task ? editing_task.priority : "3"} style={{ width: "100%" }} >
            <Select.Option label="Alta" value="1">Alta</Select.Option>
            <Select.Option label="Media" value="2">Media</Select.Option>
            <Select.Option label="Baja" value="3">Baja</Select.Option>
          </Select>
        </Form.Item>
      )
    }, {
      key: 'is_public',
      lg: 8,
      md: 24,
      xs: 24,
      cond: true,
      item: (
        <Form.Item key={"is_public"} label="Es Público" name="is_public" valuePropName="checked">
          <CustomSwtichComponent onSelect={(v) => setIsPublic(v)} fieldName={"is_public"} value={isPublic} form={form} />
        </Form.Item>
      )
    }, {
      key: 'realizado',
      lg: 8,
      md: 24,
      xs: 24,
      cond: true,
      item: (
        <Form.Item label="Estado" name="realizado" valuePropName="checked">
          <CustomSwitch
            mode="toggle"
            input={true}
            width={'100%'}
            borderRadius={isTabletOrMobile ? '15px' : '7.5px'}
            padding={isTabletOrMobile ? '4ch 3ch' : '2ch 1.5ch'}
            options={[{ color: "#24335D", name: "HECHO" }, { name: "PENDIENTE", color: "#ff5879" }]} defaultValue={done ? 0 : 1}
            onChange={v => {
              setDone((v.name === 'HECHO'))
            }} />
        </Form.Item>
      )
    }, {
      key: 'taskable',
      lg: 8,
      md: 24,
      xs: 24,
      cond: true,
      item: (
        <Form.Item key={"taskable"} label="Agendar" name="taskable" valuePropName="checked">
          <CustomSwtichComponent onSelect={(v) => setTaskable(v)} fieldName={"taskable"} value={taskable} form={form} />
        </Form.Item>
      )
    }, {
      key: "days_before_popup_notification",
      lg: 8,
      md: 8,
      xs: 24,
      _tutoStep: 14,
      cond: true,
      item: (
        <Form.Item
          name="days_before_popup_notification"
          label="Notificación de popup (mostrar días antes)"
        >
          <Input className="responsive-input" autoComplete="cc-csc" disabled={!Boolean(taskable)} />
        </Form.Item>
      )
    },
    {
      key: "days_before_mail_notification",
      lg: 8,
      md: 8,
      xs: 24,
      _tutoStep: 15,
      cond: true,
      item: (
        <Form.Item
          name="days_before_mail_notification"
          label="Notificación de email (mostrar días antes)"
        >
          <Input className="responsive-input" autoComplete="cc-csc" disabled={!Boolean(taskable)} />
        </Form.Item>
      )
    }, {
      key: 'observations',
      lg: 24,
      md: 24,
      xs: 24,
      cond: true,
      item: (
        <Form.Item key={"observations"} label="Observaciones" name="observations">
          <Input.TextArea rows={2} />
        </Form.Item>
      )
    }, {
      key: 'tags',
      lg: 24,
      md: 24,
      xs: 24,
      cond: true,
      item: (
        <Antd4TagAutocomplete
          //initialValue={editing_task ? editing_task.tags.flatMap(t => t.name) : []}
          label="Tags"
          name="tags"
          filter="studio_tasks"
        />
      )
    }, {
      key: 'studio_id',
      lg: 8,
      md: 24,
      xs: 24,
      cond: is_super_admin,
      item: (
        <Form.Item
          key={"studio_id"}
          label="Estudio"
          name="studio_id"
          rules={[{
            required: true,
            message: "Debe seleccionar un estudio"
          }]}
        >
          <Select >
            {SelectStudio()}
          </Select>
        </Form.Item>
      )
    }
  ]

  return (
    <Form
      form={form}
      title='studio-task'
      layout="vertical"
      onFinish={submitData}
      initialValues={editing_task ? {
        custom_title: editing_task.custom_title ? editing_task.custom_title.title : null,
        studio_id: editing_task.studio_id,
        priority: editing_task.priority,
        realizado: done,
        is_public: editing_task.is_public,
        date: moment(editing_task.date),
        tags: editing_task.tags.flatMap(t => t.name),
        observations: editing_task.observations,
        taskable: taskable,
        days_before_mail_notification: editing_task.days_before_mail_notification,
        days_before_popup_notification: editing_task.days_before_popup_notification


      } : {
        date: moment(),
        is_public: true,
        tags: [],
        taskable: taskable,
        priority: "3",
        days_before_mail_notification: "1",
        days_before_popup_notification: "1"
      }}
    >
      <Row gutter={10} style={{ flexDirection: "inherit" }}>
        {
          fields
            .filter((item) => item.cond)
            .map(field => (
              <Col xs={field.xs} lg={field.lg} md={field.md}>
                <div>
                  {field.item}
                </div>
              </Col>
            ))
        }
        <Col span={24} style={{ display: "inline-flex", justifyContent: "center" }}>
          <Form.Item key="submit">
            <Button
              style={{ margin: '3px 8px' }}
              id="noattach"
              type="primary"
              htmlType="submit"
              className="full-input responsive-input responsive-button"
              loading={loading}
            >
              Guardar
            </Button>

            <Button
              type="danger"
              onClick={() => onCancel()}
              className="ant-btn warn responsive-input responsive-button"
              htmlType="button"
              style={{ margin: '3px 8px' }}
              disabled={loading}
            >
              Cancelar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>)
}

export const CreateStudioTaskButton = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      type={"primary"}
      size={"small"}
      icon={<TaskListIcon />}
    >
      Nueva Check
    </Button>
  );
};