import React, { useState, useEffect } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select } from "antd";
import CountryService from "../../services/geographic.service";
const { Option } = Select;

export const CountrySelect = ({
  getFieldDecorator,
  country,
  onSelect,
  required,
  onFetched
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    CountryService.getCountries()
      .then(data => {
        setData(data.data);
        onFetched && onFetched(data.data);
        setLoading(false);
      })
      .catch(err => console.log(err));
  }, []);

  return getFieldDecorator ? (
    <Form.Item key="country" label="País">
      {getFieldDecorator("country", {
        rules: [
          {
            required,
            message: "Debe indicar el país."
          }
        ],
        initialValue: country ? country.id : null
      })(
        <Select
          className="responsive-input"
          placeholder="País"
          style={{ width: "100%" }}
          loading={loading}
          onSelect={v => {
            const countryObject = data[data.findIndex(c => c.id === v)];
            onSelect( countryObject);
          }}
        >
          {data.map(_country => (
            <Option key={_country.id} value={_country.id}>
              {_country.name}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  ) : (
    <Select
      defaultValue={country ? country.id : null}
      placeholder="País"
      style={{ width: "100%" }}
      loading={loading}
      onSelect={v => {
        const countryObject = data[data.findIndex(c => c.id === v)];
        onSelect( countryObject);
      }}
    >
      {data.map(_country => (
        <Option key={_country.id} value={_country.id}>
          {_country.name}
        </Option>
      ))}
    </Select>
  );
};

CountrySelect.defaultProps = {
  required: true
};
