import React from "react";
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Tooltip } from "antd";

const TagsInput = ({ getFieldDecorator, tags, formKey, label, required }) => {
  return (
    <Form.Item
      key={formKey}
      label={
        <React.Fragment>
          {label}{" "}
          <Tooltip title="Escriba los tags separados por coma (,)">
            <QuestionCircleTwoTone />
          </Tooltip>
        </React.Fragment>
      }
    >
      {getFieldDecorator(formKey, {
        rules: [
          {
            required: required,
            message: "Debe indicar al menos un tag."
          }
        ],
        initialValue: tags || null
      })(<Input.TextArea  autoComplete="cc-csc" placeholder="tag1,tag2,tag3,tagN" value={implodeTags(tags || [])} />)}
    </Form.Item>
  );
};

export default TagsInput;

export function explodeTags(tags) {
  if(!tags) return [];
  if (tags.length < 1) return [];
  if(typeof tags === 'string')
  return tags.split(",");
  else return tags;
}

function implodeTags(tags) {
  if (!tags) return "";
  return tags.join(",");
}

TagsInput.defaultProps = {
  tags: [] || null,
  formKey: "tags",
  label: "Tags"
};
