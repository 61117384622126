import React, { useState } from "react";
import { FilterOutlined } from "@ant-design/icons";
import { Button } from "antd";
import ExpedientFilterModal from "../../../modals/ExpedientFilterModal";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as FilterIcon } from "../../../assets/assets2_0/filter.svg";

export const ExpedientFilter = () => {
  const [shoModal, setShoModal] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <>
      {isTabletOrMobile ? (
        <Button
          icon={<FilterIcon />}
          title="Expedientes"
          type="default"
          className="advance-filter-btn"
          onClick={() => setShoModal(true)}
        >
          Avanzada
        </Button>
      ) : (
        <Button
          icon={<FilterIcon />}
          title="Expedientes"
          type="default"
          className="advance-filter-btn"
          onClick={() => setShoModal(true)}
        >
          Busqueda avanzada
        </Button>
      )}

      <ExpedientFilterModal
        visible={shoModal}
        handleCancel={() => setShoModal(false)}
      />
    </>
  );
};
