const initialState = {
    balance: 0
};

const balanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DEBIT':
            return {
                ...state,
                balance: state.balance - action.payload
            };
        case 'CREDIT':
            return {
                ...state,
                balance: state.balance + action.payload
            };
        default:
            return state;
    }
};

export default balanceReducer;