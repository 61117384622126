import moment from "moment"
import React, { createContext, useState } from "react"

export const turnoDefaultHours = [6, 23]

export const TurnoSelectorContext = createContext({
  appointmentId: undefined,
  customTitle: '',
  date: [],
  availableDays: [],
  availableHours: [],
  busyHours: [],
  hour: undefined,
  user: undefined,
  client: undefined,
  observations: '',
  duration: 30,
  isOverturn: false,
  maxDuration: 30,
  steps: calculateMinuteSteps(),
  loadingHours: false,
  setCustomTitle: (customTitle) => {
    this.customTitle = customTitle
  },
  setBusyHours: () => { },
  setMaxDuration: (maxDuration) => {
    this.maxDuration = maxDuration
  },
  setUser(user) {
    this.user = user
  },
  setClient(client) {
    this.client = client
  },
  setObservations(observations) {
    this.observations = observations
  },
  setDate(date) {
    this.date = date
  },
  setHour(hour) {
    this.hour = hour
  },
  setDuration(d) {
    this.duration = d
    this.steps = calculateMinuteSteps(d, this.hours)
  },
  setAvailableDays(availableDays) {
    this.availableDays = availableDays
  },
  setAvailableHours(availableHours) {
    this.availableHours = availableHours
  },
  setSteps(steps) {
    this.steps = steps
  },
  setIsOverturn: () => { },
  setLoadingHours: (loadingHours) => {
    this.loadingHours = loadingHours
  }
})

export function calculateMinuteSteps(duration = 30, hours = turnoDefaultHours) {
  /* debugger */
  const startHour = hours[0]
  const endHour = hours[hours.length - 1]
  let m = 0
  let steps = []
  let h = startHour
  let loopLimit = 96
  while (true) {
    if (steps.length > loopLimit) break;
    if (m >= 60) {
      m -= 60
      h += 1
    }
    if (h >= endHour) break;
    steps.push(`${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`)
    m += duration
  }
  return steps
}

export const TurnoSelectorProvider = ({ children, appointment }) => {

  //appointment representa un turno que esta siendo editado, sino es null

  const [customTitle, setCustomTitle] = useState(null);
  const appointmentId = appointment ? appointment.id : null;
  const [date, setDate] = useState(appointment ? moment(appointment.date) : moment())
  const [hour, setHour] = useState(appointment ? appointment.starting_time.substr(0, 5) : null)
  const [duration, setDuration] = useState(appointment ? appointment.duration : 30)
  const [maxDuration, setMaxDuration] = useState(30)
  const [steps, setSteps] = useState(appointment ? calculateMinuteSteps(appointment.duration) : calculateMinuteSteps())
  const [user, setUser] = useState(appointment ? appointment.user_id : null)
  const [client, setClient] = useState(appointment ? appointment.client : null)
  const [observations, setObservations] = useState(appointment ? appointment.observation : '')
  const [availableDays, setAvailableDays] = useState([])
  const [availableHours, setAvailableHours] = useState(appointment ? appointment.user && appointment.user.setting ? appointment.user.setting.available_hours : [7, 22] : [7, 22])
  const [busyHours, setBusyHours] = useState([])
  const [isOverturn, setIsOverturn] = useState(appointment ? Boolean(appointment.out_of_shift) : false)
  const [loadingHours, setLoadingHours] = useState(false)

  const handleDurationChange = (d) => {
    setDuration(d)
    let newSteps = calculateMinuteSteps(d, availableHours);

    if (appointment && !newSteps.includes(`${appointment.starting_time.substr(0, 5)}`)) {
      console.log({ newSteps, hour_to_add: `${appointment.starting_time.substr(0, 5)}` })
      newSteps = [`${appointment.starting_time.substr(0, 5)}`, ...newSteps].sort((a, b) => Number(a.split(":")[0]) - Number(b.split(":")[0]))
    }

    setSteps(newSteps)
  }

  const handleAvailableHoursChange = ([start, end]) => {
    const hours = []
    for (let i = start; i <= end; i++) {
      hours.push(i)
    }
    setAvailableHours(hours)
  }

  const handleDateChange = (d) => {
    setDate(d)
    if (appointment) {
      setHour(null)
    }
  }

  return <TurnoSelectorContext.Provider value={{
    loadingHours,
    appointmentId,
    customTitle,
    date,
    hour,
    duration,
    steps,
    user,
    client,
    observations,
    availableDays,
    availableHours,
    busyHours,
    isOverturn,
    maxDuration,
    setCustomTitle,
    setIsOverturn,
    setBusyHours,
    setUser,
    setClient,
    setObservations,
    setSteps,
    setDate: handleDateChange,
    setDuration: handleDurationChange,
    setHour,
    setAvailableDays,
    setAvailableHours: handleAvailableHoursChange,
    setMaxDuration,
    setLoadingHours
  }}>
    {children}
  </TurnoSelectorContext.Provider>
}
