import React, { useEffect, useState } from "react";
import GoogleOAuthService from "../../services/google_oauth.service";
//import { GoogleLogin } from "react-google-login";
import GoogleLogut from "./logout/logout";
import { Spin } from "antd";
import GoogleStateContainer from "../../unstatedStores/GoogleStateStore";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';


const GoogleOAuth = ({ askForContactSync, canSync, history }) => {
  const [loading, setLoading] = useState(false);
  const googleStateContainer = GoogleStateContainer.useContainer();
  const [dataSource, setDataSource] = useState("https:\/\/www.googleapis.com\/auth\/calendar https:\/\/www.googleapis.com\/auth\/contacts https:\/\/www.googleapis.com\/auth\/calendar.events");
  
  const login = useGoogleLogin({
    onSuccess: codeResponse => responseGoogle(codeResponse),
    flow: 'auth-code',
    scope: dataSource
  });

  useEffect(() => {
    setLoading(true);
    GoogleOAuthService.getScopes()
      .then(response => {
        setDataSource(response.data);
      })
      .catch();
    validateAuthorization();
  }, []);

  const validateAuthorization = () => {
    GoogleOAuthService.validateAuthorization()
      .then(response => {
        if (response.status == 200) {
          if (response.data) {
            googleStateContainer.set(response.data);
            canSync(true);
            history.push("/settings#integraciones");
          }
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const responseGoogle = _response => {
    console.log(_response);
    GoogleOAuthService.callback({ code: _response.code })
      .then(response => {
        if (response.status == 200) {
          if (response.data.syncMessage) {
            canSync(true);
            askForContactSync();
          }
          validateAuthorization();
        }
      })
      .catch();
  };

  if (loading) return <Spin />;
  if (
    googleStateContainer.googleState.authorized === false &&
    dataSource !== ""
  ) {
    return (
      <div style={{ margin: "10px 0px 10px 0px" }}>
        <button onClick={() => login()}>
          Sign in with Google 🚀{' '}
        </button>
      </div>
    );
  } else {
    return (
      <div style={{ margin: "10px 0px 10px 0px" }}>
        <GoogleLogut
          onLogOut={() => {
            googleStateContainer.set({
              authorized: false,
              userInfo: {
                ...googleStateContainer.googleState.userInfo,
                picture: null
              }
            });
            canSync(false);
          }}
        />
      </div>
    );
  }
};
export default GoogleOAuth;
