import React, { useState, useEffect } from "react";
import {
  DeleteOutlined,
  InfoCircleOutlined,
  EditOutlined,
  QrcodeOutlined
} from "@ant-design/icons";
import { Icon as LegacyIcon } from "@ant-design/compatible";
import {
  Badge,
  List,
  Typography,
  Popover,
  Button,
  Modal,
  message,
  Tooltip
} from "antd";
import ExpedientResponsibles from "../ExpedientResponsibles";
import { ChangeExpedientStatusPopOver } from "../ChangeExpedientStatus";
import moment from "moment";
import WhatsAppQuickMessage from "../WhatsAppQuickMessage";
import ExpandCollpaseText from "../ExpandCollapseText";
import ViewClient from "../../routes/ClientPage/ViewClient";
import ExpedientService from "../../services/expedient.service";
import { withRouter } from "react-router-dom";
import Authorization from "../../components/Authorization";
import CreateClientModal from "../../modals/CreateClientModal";
import RequestExpedientFiles from "../RequestExpedientFiles";
import { ExpiresAtInfo } from "./ExpiresAtInfo";
import { onCopy } from "../../routes/RtfPage/RtfInfo";
import "../../../src/law-components/ExpedientInfo/index.css";
import {
  CopyablePublicAccessCode,
  TogglePublicAccess
} from "./TogglePublicAccess";
import { ReactComponent as DeleteIcon } from "../../assets/assets2_0/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/assets2_0/edit.svg";
import { connect } from "react-redux";
import AccountBalance from "../ExpedientAccount/components/AccountBalance";

const { Item } = List;

const ExpedientInfo = ({
  currentLang,
  expedient,
  showObservations,
  ...props
}) => {
  const [_expedient, setExpedient] = useState(expedient);
  const [expedientClient, setExpedienteClient] = useState(expedient.client);
  const [showEditClient, setShowEditClient] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isStatusHovered, setIsStatusHovered] = useState(false);
  const [isEditClientHovered, setIsEditClientHovered] = useState(false);

  useEffect(() => {
    setExpedient(expedient);
  }, [expedient]);

  const generateFront = async () => {
    try {
      setLoading(true);
      const response = await ExpedientService.generatePrintableFront(
        expedient.id
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = `caratula.pdf`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      message.error("Ha ocurrido un error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        border: "1px solid #CCCCCC",
        borderRadius: "12px"
      }}
    >
      <CreateClientModal
        title={currentLang.text.client}
        visible={showEditClient}
        client={expedientClient}
        onUpdated={c => {
          setExpedienteClient(c);
          setShowEditClient(false);
        }}
        onCancel={() => {
          setShowEditClient(false);
        }}
      />
      <List bordered={false} size="small" split={false}>
        <Item>
          <Typography.Text strong>Nº de Expediente</Typography.Text>
          <Typography.Text
            className="copyable-text"
            onClick={event => onCopy(event.currentTarget.innerHTML)}
          >
            {_expedient.header
              ? `${_expedient.expedient_number}(${_expedient.header.radicacion}-
                ${_expedient.header.codigo_interno})`
              : _expedient.expedient_number}
          </Typography.Text>
        </Item>
        <Item>
          <Typography.Text strong>Nº de Carpeta</Typography.Text>
          <Typography.Text
            className="copyable-text"
            onClick={event => onCopy(event.currentTarget.innerHTML)}
          >
            {_expedient.folder}
          </Typography.Text>
        </Item>
        <Item>
          <Typography.Text strong>Tribunal</Typography.Text>
          <JudicialEstructureInfo expedient={expedient} />
        </Item>
        <Item>
          {showObservations ? (
            <ChangeExpedientStatusPopOver
              expedient={_expedient}
              onDone={exp => {
                setExpedient({ ..._expedient, status: exp.status });
              }}
              child={
                <Typography.Text
                  style={{
                    cursor: "pointer",
                    fontWeight: "600",
                    alignItems: "baseline"
                  }}
                >
                  Estado
                  <Tooltip title="Editar Estado del Expediente">
                    <EditIcon
                      viewBox="0 0 40 10"
                      color={isStatusHovered ? "#0185F8" : "#002A4E"}
                      onMouseEnter={() => setIsStatusHovered(true)}
                      onMouseLeave={() => setIsStatusHovered(false)}
                    />
                  </Tooltip>
                </Typography.Text>
              }
            />
          ) : (
            <Typography.Text strong>Status </Typography.Text>
          )}
          <Badge
            color={_expedient.status ? _expedient.status.color : "#3D529E"}
            status="processing"
            text={_expedient.status.name}
          />
        </Item>
        <Item>
          <Typography.Text strong>Tipo </Typography.Text>
          <Typography.Text
            className="copyable-text"
            onClick={event => onCopy(event.currentTarget.innerHTML)}
          >
            {_expedient.expedient_type.name}
          </Typography.Text>
        </Item>
        <Item>
          <Typography.Text strong>Acceso externo</Typography.Text>
          <TogglePublicAccess
            onChange={(public_code, public_access) =>
              setExpedient({ ..._expedient, public_code, public_access })
            }
            expedientId={expedient.id}
            defaultValue={expedient.public_access}
          />
        </Item>
        {_expedient.public_access && _expedient.public_code && (
          <Item>
            <Typography.Text strong>Codigo externo</Typography.Text>
            <CopyablePublicAccessCode code={_expedient.public_code} />
          </Item>
        )}
        <Item>
          <Typography.Text strong>
            <Popover
              placement="top"
              title={`Información del ${currentLang.text.client}`}
              trigger="hover"
              content={
                <ViewClient
                  currentLang={currentLang}
                  fields={[
                    "email",
                    "name",
                    "phone",
                    "mobile_phone",
                    "extra_phone",
                    "main_address",
                    "secondary_address",
                    "tags",
                    "observations",
                    "identification",
                    "sign",
                    "plate",
                    "job_title",
                    "location",
                    "birthdate"
                  ]}
                  client={expedientClient}
                />
              }
            >
              <InfoCircleOutlined />
            </Popover>{" "}
            {currentLang.text.client}{" "}
          </Typography.Text>
          <div
            style={{
              display: "flex",
              flexFlow: "row",
              flexWrap: "nowrap",
              gap: "0.75ch"
            }}
          >
            {expedientClient ? expedientClient.firstname : ""}{" "}
            {expedientClient ? expedientClient.lastname : ""}
            {expedientClient && (
              <Typography.Text
                className="copyable-text"
                onClick={event => {
                  let data_show = `${
                    expedientClient && expedientClient.firstname !== null
                      ? expedientClient.firstname
                      : ""
                  } ${
                    expedientClient && expedientClient.lastname !== null
                      ? expedientClient.lastname
                      : ""
                  }`;
                  onCopy(data_show);
                }}
              ></Typography.Text>
            )}
            {expedientClient && (
              <Tooltip title="Editar Cliente">
                <EditIcon
                  onClick={e => {
                    e.stopPropagation();
                    setShowEditClient(true);
                  }}
                  style={{
                    cursor: "pointer"
                  }}
                  color={isEditClientHovered ? "#0185F8" : "#002A4E"}
                  onMouseEnter={() => setIsEditClientHovered(true)}
                  onMouseLeave={() => setIsEditClientHovered(false)}
                />
              </Tooltip>
            )}
            {expedientClient &&
              expedientClient.client_country &&
              expedientClient.mobile_phone && (
                <WhatsAppQuickMessage
                  phone={expedientClient}
                  tooltip={"Enviar Whatsapp"}
                />
              )}
          </div>
        </Item>
        <Item>
          <Typography.Text strong>Monto reclamado </Typography.Text>
          <Typography.Text>
            <AccountBalance amount={_expedient.claimed_amount} size="1em" />
          </Typography.Text>
        </Item>
        <Item>
          <Typography.Text strong style={{ textAlign: "center" }}>
            Responsables
          </Typography.Text>
          <ExpedientResponsibles
            exp_responsibles={_expedient.expedient_responsibles}
          />
        </Item>
        <Item>
          <Typography.Text strong>F. de inicio</Typography.Text>
          <Typography.Text
            className="copyable-text"
            onClick={event => onCopy(event.currentTarget.innerHTML)}
          >
            {moment(_expedient.init_date).format("DD/MM/YYYY")}
          </Typography.Text>
        </Item>
        <Item>
          <Typography.Text strong>F. de reclamo</Typography.Text>
          <Typography.Text
            className="copyable-text"
            onClick={event => {
              if (event.currentTarget.innerHTML !== "-") {
                onCopy(event.currentTarget.innerHTML);
              }
            }}
          >
            {_expedient.claim_date
              ? moment(_expedient.claim_date).format("DD/MM/YYYY")
              : "-"}
          </Typography.Text>
        </Item>
        <Item>
          <Typography.Text strong>F. de ingreso</Typography.Text>
          <Typography.Text
            className="copyable-text"
            onClick={event => {
              if (event.currentTarget.innerHTML !== "-") {
                onCopy(event.currentTarget.innerHTML);
              }
            }}
          >
            {_expedient.demand_begin_date
              ? moment(_expedient.demand_begin_date).format("DD/MM/YYYY")
              : "-"}
          </Typography.Text>
        </Item>
        <Item>
          <Typography.Text strong>F. de prescripción</Typography.Text>
          <Typography.Text
            className="copyable-text"
            onClick={event => {
              if (event.currentTarget.innerHTML !== "-") {
                onCopy(event.currentTarget.innerHTML);
              }
            }}
          >
            {_expedient.prescription_date
              ? moment(_expedient.prescription_date).format("DD/MM/YYYY")
              : "-"}
          </Typography.Text>
        </Item>
        <Item>
          <ExpiresAtInfo expedient={_expedient} />
        </Item>
        {showObservations && (
          <Item>
            <Typography.Text strong>
              Observaciones:{" "}
              <ExpandCollpaseText
                copyable={true}
                onEndEditing={text => {
                  updateObservation({ ..._expedient, observation: text })
                    .then(data => {
                      setExpedient({ ...expedient, observation: text });
                    })
                    .catch(() => {});
                }}
                style={{ fontWeight: "normal" }}
                rows={3}
                text={_expedient.observation}
              />
            </Typography.Text>
          </Item>
        )}
        <Item
          style={{
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1ch 0.75ch"
          }}
        >
          {showObservations && <RequestExpedientFiles expedient={expedient} />}
          <Button
            icon={<QrcodeOutlined />}
            type="primary"
            onClick={() => generateFront()}
            loading={loading}
          >
            Generar carátula
          </Button>
          <DeleteOrRestoreExpedient
            expedient={expedient}
            onChange={(d, type) => {
              type === "deleted" && props.history.push("/expedient");
            }}
          />
        </Item>
      </List>
    </div>
  );
};

export const DeleteOrRestoreExpedient = ({ expedient, onChange, size }) => {
  const { confirm } = Modal;

  const showConfirm = (action = "delete") => {
    confirm({
      title: `¿Esta seguro de ${
        action === "delete" ? "eliminar" : "restaurar"
      } este expediente?`,
      content:
        action === "delete" ? "Si lo desea puede restaurarlo mas adelante" : "",
      okText: "Si",
      cancelText: "No",
      okButtonProps: { type: action === "delete" ? "danger" : "primary" },
      onOk() {
        action === "delete"
          ? softDeleteExpedient(expedient.id).then(d => onChange(d, "deleted"))
          : restoreExpedient(expedient.id).then(d => onChange(d, "restored"));
      },
      onCancel() {
        console.log("Cancel");
      }
    });
  };

  return expedient.deleted_at ? (
    <Authorization
      permission="expedient.delete"
      onRender={() => (
        <Tooltip title="Restaurar expediente">
          <Button
            size={size || "default"}
            type="primary"
            icon={<LegacyIcon type="restore" />}
            onClick={() => showConfirm("restore")}
          >
            Restaurar
          </Button>
        </Tooltip>
      )}
    />
  ) : (
    <Authorization
      permission="expedient.delete"
      onRender={() => (
        <Tooltip title="Eliminar expediente">
          <DeleteIcon
            style={{ cursor: "pointer" }}
            onClick={() => showConfirm()}
            color="#FF5879"
          />
        </Tooltip>
      )}
    />
  );
};

export async function updateObservation(expedient) {
  const updated = await ExpedientService.updateExpedient(expedient);
  message.info("El expediente ha sido actualizado");
  return updated.data;
}

export async function softDeleteExpedient(expedient) {
  const deleted = await ExpedientService.softDeleteExpedient(expedient);
  message.info("El expediente ha sido eliminado");
  return deleted.data;
}

export async function restoreExpedient(expedient) {
  const deleted = await ExpedientService.restoreExpedient(expedient);
  message.info("El expediente ha sido restaurado");
  return deleted.data;
}

export const JudicialEstructureInfo = ({ expedient }) => {
  return (
    <Popover
      trigger="hover"
      content={
        <List>
          <List.Item key="1">
            {expedient.jurisdiction ? expedient.jurisdiction.name : "-"}
          </List.Item>
          <List.Item key="2">
            {expedient.fuero ? expedient.fuero.name : "-"}
          </List.Item>
          <List.Item key="3">
            {expedient.court ? expedient.court.name : "-"}
          </List.Item>
          <List.Item key="4">
            {expedient.secretary ? expedient.secretary.name : "-"}
          </List.Item>
        </List>
      }
    >
      <Typography.Text
        style={{
          color: "#3D529E",
          cursor: "pointer",
          textAlign: "end",
          fontWeight: "500"
        }}
        onClick={event => onCopy(event.currentTarget.innerHTML)}
      >
        {expedient.court ? expedient.court.name : "No asignado"}
      </Typography.Text>
    </Popover>
  );
};

const mapStateToProps = ({ lang }) => {
  const { currentLang } = lang;
  return { currentLang };
};

export default connect(mapStateToProps, {})(ExpedientInfo);

ExpedientInfo.defaultProps = {
  showObservations: true
};
