import React, { Component, useState, useEffect } from "react";
import { Layout, Popover, Affix, Modal, Badge, Typography, Tooltip } from "antd";
import { connect } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "../languageData";
import SearchBox from "components/SearchBox";
import UserInfo from "components/UserInfo";
import {
  switchLanguage,
  toggleCollapsedSideNav
} from "../../../appRedux/actions/Setting";
import HorizontalNav from "../HorizontalNav";
import { Link } from "react-router-dom";
import CreateProfile from "../../../law-components/Forms/CreateProfile";
import { parseJwt } from "../../../util/jwt.helper";
import { ExpedientFilter } from "./ExpedientFilter";
import InMailService from "../../../services/inmail.service";
import Authorization from "../../../components/Authorization";
import AdminStudioSelect from "./AdminStudioSelect";
import Notifications, {
  NotificationList
} from "../../../law-components/Notifications";
import NotificationsService from "../../../services/notifications.service";
import MailCounterContainer from "../../../unstatedStores/MailNotificacion";
import TicketModal from "../../../law-components/Ticket";
import { GoogleQuickLogin } from "../../../components/UserInfo";
import { history } from "../../../appRedux/store";
import { ReactComponent as EmailWhiteIcon } from "../../../assets/assets2_0/email_white.svg";
import { ReactComponent as MetajuridicoLogo } from "../../../assets/images/header-logo.svg";
import { ReactComponent as DefensoriaLogo } from "../../../assets/images/defensoria_logo_navbar.svg";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import QuickActionsMenu from "../../../law-components/QuickActionsMenu";
const { Header } = Layout;

class BelowHeader extends Component {
  state = {
    searchText: "",
    showProfile: false,
    showModalExpedient: false,
    notifications: [],
    isDefensoriaDesign: this.props.isDefensoriaDesign
  };

  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={e => this.props.switchLanguage(language)}
          >
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  updateSearchChatUser = evt => {
    this.setState({
      searchText: evt.target.value
    });
  };

  render() {
    const { navCollapsed, authUser } = this.props;
    const { showProfile, isDefensoriaDesign } = this.state;
    const user = authUser;
    const isWindowedEditor = history.location.pathname.includes(
      "windowed/rtf-editor"
    );
    const custom_nav_bar_color = isDefensoriaDesign
      ? { backgroundColor: "#B22A4D" }
      : {};

    return (
      !isWindowedEditor && (
        <>
          <Modal
            title={
              <Typography.Text style={{ fontWeigth: 700, color: "#FFFFFF" }}>
                {" "}
                Editar perfil
              </Typography.Text>
            }
            visible={showProfile}
            closeIcon={<CloseOutlined style={{ color: "#35B6BC" }} />}
            width="60%"
            onCancel={() => this.setState({ showProfile: false })}
            footer={null}
          >
            <CreateProfile user={user} />
          </Modal>
          <Header className="meta-header" style={{ ...custom_nav_bar_color, zIndex: 51 }}>
            <div className="logo-container">
              <div
                onClick={() => history.push("/home")}
                className="logo-content"
              >
                {isDefensoriaDesign ? (
                  <DefensoriaLogo
                    style={{ marginTop: "5px" }}
                    width={"200px"}
                    height={"90px"}
                  />
                ) : (
                  <MetajuridicoLogo
                    width={"190px"}
                    style={
                      isDefensoriaDesign
                        ? { marginTop: "40px" }
                        : { marginTop: "0px" }
                    }
                  />
                )}
              </div>
              <div className="logo-separator" />
              <CurrentDate />
            </div>
            <div className="header-searchbox">
              <div style={{ display: "flex", flexDirection: "row", gap: "5px", width: "50%" }}>
                <QuickActionsMenu />
                <SearchBox
                  inputStyle={{ borderRadius: "2px 10px 10px 2px" }}
                  clearText={() => this.setState({ searchText: "" })}
                  placeholder="Buscar"
                  onChange={this.updateSearchChatUser.bind(this)}
                  value={this.state.searchText}
                />
              </div>
              <ExpedientFilter />
              <Authorization
                onRender={() => <AdminStudioSelect user={user} />}
                permission="master_admin.changestudio"
              />
            </div>
            <div className="header-icon-container">
              <NotificationsComponent user={user} />

              {process.env.REACT_APP_SHOW_TICKETS === "1" &&
                user.role_id !== 1 && <TicketModal />}

              <UnreadedInMails />
            </div>
            <div className="user-info">
              <GoogleQuickLogin />
              <UserInfo
                user={user}
                onProfileClick={() => this.setState({ showProfile: true })}
              />
            </div>
            <Nav />
          </Header>
        </>
      )
    );
  }
}

export const NotificationsComponent = ({ user }) => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    NotificationsService.getPushNotifications()
      .then(notf => {
        setNotifications(notf.data);
      })
      .catch(e => console.log(e));
  }, []);

  return (
    <>
      <Tooltip trigger="hover" title="Notificaciones">
        <Badge
          color={"#35B6BC"}
          style={{ border: "none" }}
          count={notifications.filter(n => n.readed === "no").length}
        >
          <Notifications
            user={user}
            onNotification={n => {
              setNotifications([n, ...notifications]);
            }}
            children={
              <NotificationList
                onAllReaded={() => {
                  setNotifications(
                    notifications.map(n =>
                      n.readed === "no" ? { ...n, readed: "yes" } : n
                    )
                  );
                }}
                onReaded={(id, readed) => {
                  setNotifications(
                    notifications.map(n => (n.id === id ? { ...n, readed } : n))
                  );
                }}
                notifications={notifications}
              />
            }
          />
        </Badge>
      </Tooltip>
    </>
  );
};

export const CurrentDate = () => {
  const isLowResolution = useMediaQuery({ query: "(max-width: 1368px)" });
  const day = moment().format("DD");
  const month = isLowResolution
    ? moment().format("MMM")
    : moment().format("MMMM");
  const year = moment().format("YYYY");
  return (
    <Typography.Text style={{ color: "#fff" }}>
      {day} de {month} del {year}
    </Typography.Text>
  );
};

const Nav = () => {
  return (
    <div className="nav">
      <HorizontalNav />
    </div>
  );
};

export const UnreadedInMails = () => {
  const mailCounterState = MailCounterContainer.useContainer();
  useEffect(() => {
    InMailService.countUnreaded()
      .then(c => {
        mailCounterState.set(c.data);
      })
      .catch(e => console.log(e));
  }, []);
  return (
    <>
      <Tooltip trigger="hover" title="Mensajes">
        <Badge color={"#35B6BC"} showZero={false} count={mailCounterState.count}>
          <Link to="/mailbox">
            <EmailWhiteIcon style={{ width: "21px" }} />
          </Link>
        </Badge>
      </Tooltip>
    </>
  );
};

const mapStateToProps = ({ settings, auth, designMode }) => {
  const { locale } = settings;
  const { authUser } = auth;
  const { isDefensoriaDesign } = designMode;
  return { locale, authUser, isDefensoriaDesign };
};
export default connect(mapStateToProps, {
  toggleCollapsedSideNav,
  switchLanguage
})(BelowHeader);
