import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { Button, Col, Modal, Popconfirm, Popover, Row, Select, Tooltip, Typography } from 'antd';
import { CloseCircleOutlined, DisconnectOutlined, LinkOutlined, SearchOutlined } from '@ant-design/icons';
import { LawTable } from '../../../law-components/Table';
import { ReactComponent as CandadoGris } from "../../../assets/assets2_0/candado_gris.svg";
import ExpedientService from '../../../services/expedient.service';
import { Link } from 'react-router-dom';
import ExpedientInfo from '../../../law-components/ExpedientInfo';
import { UserAvatar } from '../../../law-components/LawCalendar/SmallMovementCalendar';

const { Option } = Select;

const SelectExpedientToLinkModal = ({ action, record: optionalSettlement, icon = true, cond, showUnlink, unLink }) => {
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 960px)" });
    /* const settlementsTabContext = useContext(SettlementsTabContext); */
    const [formType, setFormType] = useState("all");
    const [open, setOpen] = useState(false);
    const [reload, setReload] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const VinculateButton = ({ action, cond, record }) => {
        return cond && (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row"
                }}
            >
                <Button onClick={() => {
                    action(record)
                }} >
                    Vincular
                </Button>
            </div>
        );
    };

    const columns = [
        {
            title: "Caratula",
            dataIndex: "front",
            key: "front",
            width: "40%",
            render: (front, { is_public }) => {
                return (
                    <Typography.Text
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "1ch"
                        }}
                    >
                        {front}
                        {is_public === 0 && <CandadoGris height="25" />}
                    </Typography.Text>
                );
            }
        },
        {
            title: "N Expediente",
            dataIndex: "expedient_number",
            key: "expedient_number",
            render: (expedient_number, expedient) => (
                <Link to={`/expedient/details#${expedient.uuid}`}>
                    <Popover
                        trigger="hover"
                        placement="rightBottom"
                        content={
                            <ExpedientInfo showObservations={false} expedient={expedient} />
                        }
                    >
                        <Typography.Text style={{ fontSize: ".7rem", color: "#038fde" }}>
                            {expedient.expedient_number}
                        </Typography.Text>
                    </Popover>
                </Link>
            )
        },
        {
            title: "Resp.",
            dataIndex: "responsible",
            key: "responsible",
            render: (element, { expedient_responsibles }) => {
                return expedient_responsibles.map(resp => (
                    <div style={{ width: "63px", height: "39px" }}>
                        <Tooltip
                            trigger="hover"
                            title={`${resp.firstname} ${resp.lastname}`}
                        >
                            <span>
                                <UserAvatar
                                    responsible={resp}
                                    color={resp.profile ? resp.profile.color : "#24335D"}
                                />
                            </span>
                        </Tooltip>
                    </div>
                ));
            }
        },
    ];

    const extraActions = [
        {
            key: "vincular",
            component: <VinculateButton />,
            cond: (expedient) => true,
            action: (expedient) => {
                setOpen(false)
                action(optionalSettlement, expedient)
            }
        }
    ]

    const fetchParams = {
        page: null,
        filter: {
            columns: ["front", "folder", "expedient_number", "responsible", "court", "init_date", "demand_begin_date", "claimed_amount", "status", "actions"],
            filterActive: true,
            "expedient-status": 3,
            "expedient-status-switch": true,
            showall: false,
            sort_order: "ascend",
            showTrashed: 0,
            perPage: 25
        },
        perPage: 25,

    };

    const getExpedients = async (params) => {
        //Fix LawTable component handling params
        if (params.page && params.page.current) {
            params.page = params.page.current;
        }
        const fixed_params = {
            page: params.page || 1,
            filter: {
                ...params.filter,
                search: params.search || ""
            }
        }
        return ExpedientService.getExpedientPg(fixed_params.page, fixed_params.filter, 0, fixed_params.perPage)
    };

    return (cond && <div>
        <Modal
            destroyOnClose
            width={'80%'}
            title={"Seleccionar expediente"}
            footer={null}
            open={open}
            closable
            onCancel={() => setOpen(false)}
        >
            <Row gutter={['0ch', '0.1ch']} align="middle">
                <Col span={24}>
                    {/* <ExpedientList
                        showTrash={false}
                        print={false}
                        showAllPages={true}
                        filterActive={false}
                        archived={false}
                    /> */}
                    <LawTable
                        extraActions={extraActions}
                        fullWidthSearchbar={true}
                        fetchParams={fetchParams}
                        reload={reload}
                        searchPlaceholder='Buscar expediente (Caratula y Numero de expediente)...'
                        columns={columns}
                        fetch={getExpedients}
                        onSearch={getExpedients}
                    />
                </Col>
            </Row>

        </Modal>
        {icon ?
            <Tooltip title="Vincular expediente" trigger="hover">
                <LinkOutlined
                    style={{ color: isHovered ? "#0185F8" : "#002A4E", fontSize: "22px" }}
                    onClick={() => setOpen(true)}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                />
            </Tooltip> :
            showUnlink ?
                <Tooltip trigger="hover" title="Desvincular expediente">
                    <Popconfirm
                        title="Seguro desea desvincular el expediente?"
                        onConfirm={() => unLink()}
                    >

                        <Button style={{ width: "50px", padding: "11px" }} danger type="primary" className='responsive-button responsive-input'>
                            <CloseCircleOutlined style={{ fontSize: "20px" }} />
                        </Button>
                    </Popconfirm>
                </Tooltip>
                : <Tooltip trigger="hover" title="Buscar expediente">
                    <Button style={{ width: "50px" }} type="primary" className='responsive-button responsive-input' onClick={() => setOpen(true)} >
                        <SearchOutlined style={{ fontSize: "17px" }} />
                    </Button>
                </Tooltip>
        }
    </div>)
}

export default SelectExpedientToLinkModal;