import { useEffect, useState } from "react";
import StudioService from "../services/studio.service";
import { parseJwt } from "../util/jwt.helper";

export function useTrialDays() {

  const [trialChecked, setTrialChecked] = useState(false);
  const [trialData, setTrialData] = useState(undefined);

  useEffect(() => {
    if (!trialChecked) {
      setTrialChecked(true);
      StudioService.isTrialActive()
        .then((res) => {
          setTrialData(res.data.data)
          setTimeout(() => setTrialChecked(false), 1800000)
        })
        .catch((e) => console.log(e))
    }

  }, [trialChecked]);

  return trialData;
}
