import React, { useState } from "react";
import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Row, Col, message, Input, Form, Button } from "antd";
import { withRouter } from "react-router-dom";
import ImageUploader from "../ImageUploader";
import { baseURL } from "../../../util/Api";
import ProfileService from "../../../services/profile.service";
import { avatarStore } from "../../../rxjsStore/avatarStore";
import ChangeMyPassword from "./ChangeMyPassword";
import ToggleTwoFa from "./ToggleTwoFa";
import ToggleMonthlyReport from "./ToggleMonthlyReport";
import { useMediaQuery } from "react-responsive";

const CreateProfile = props => {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' });
  const { form, onDone, user } = props;
  const [loading, setLoading] = useState(false);
  console.log(user.profile)
  const [profile, setProfile] = useState(user.profile);
  const [color, setColor] = useState(user.profile.color || "#000");

  console.log(profile)

  const editUsername = values => {
    const { username } = values;
    setLoading(true);
    ProfileService.updateUsername({ username, user: user.id })
      .then(profile => {
        setProfile(_p => ({ ..._p, username: profile.data.username }));
        message.success("actualizado");
      })
      .catch(e => message.error("No se pudo actualizar"))
      .finally(() => setLoading(false));
  };

  const fields = [
    {
      key: "username",
      lg: 12,
      md: 12,
      xs: 24,
      label: "",
      cond: true,
      item: (
        <Form
          onFinish={editUsername}
          layout="vertical"
          initialValues={{
            username: profile ? profile.username : ""
          }}
        >
          <Form.Item
            label="Nombre de usuario"
            name="username"
            rules={[
              {
                pattern: /^[a-zA-Z0-9_]*$/,
                message: "Solo puede contener numeros, letras y guión bajo (_)"
              },
              { required: true, message: "Debes indicar un nombre de usuario" }
            ]}
          >
            <Input autoComplete="cc-csc"
              className="reponsive-input"
              onSearch={{
                onChange: username => {
                  editUsername(username.toLowerCase().replace(/\./g, "_"));
                }
              }}
              style={{ fontSize: 20 }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              style={{ width: "auto", minHeight: "45px", borderRadius: "15px", border: "0px" }}
              className="reponsive-input responsive-button"
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              <CheckCircleOutlined />Actualizar nombre de usuario
            </Button>
          </Form.Item>
        </Form>
      )
    },
    {
      key: "color",
      lg: 12,
      md: 12,
      xs: 24,
      label: "Color",
      cond: isTabletOrMobile,
      item: (
        <Input autoComplete="cc-csc"
          className="reponsive-input"
          style={{ display: "flex", minHeight: "45px", alignItems: "center", borderRadius: "15px" }}
          suffix={
            <SyncOutlined
              onClick={() => updateColor(color, user.id)}
              style={{ color: "blue", fontWeight: "bold", cursor: "pointer" }}
            />
          }
          type="color"
          defaultValue={color}
          onChange={c => setColor(c.target.value)}
        />
      )
    }, {
      key: "two_fa",
      lg: 12,
      md: 12,
      xs: 24,
      label: "",
      cond: isTabletOrMobile,
      item: <ToggleTwoFa user={user} />
    }, {
      key: "profile_photo",
      lg: 6,
      md: 6,
      xs: 12,
      label: "Imagen de perfil",
      cond: true,
      item: (
        <ImageUploader
          updated={image64 => avatarStore.updateAvatar(image64)}
          currentImage={profile.photo}
          user={user}
          name="avatar"
          action={`${baseURL}/profile/photo`}
        />
      )
    },
    {
      key: "sign",
      lg: 6,
      md: 6,
      xs: 12,
      label: "Firma",
      cond: true,
      item: (
        <ImageUploader
          currentImage={profile.sign}
          user={user}
          name="sign"
          action={`${baseURL}/profile/sign`}
        />
      )
    },
    {
      key: "dni",
      lg: 12,
      md: 12,
      xs: 24,
      label: "N° de identificación",
      cond: true,
      item: (
        <Input
          disabled
          lassName="reponsive-input"
          value={profile.dni}
          readOnly
          style={{ width: "100%", minHeight: "45px" }}
        />
      )
    },
    {
      key: "change_password",
      lg: 12,
      md: 12,
      xs: 24,
      label: "Nueva contraseña",
      cond: true,
      item: <ChangeMyPassword />
    },
    {
      key: "color",
      lg: 24,
      md: 24,
      xs: 24,
      label: "Color",
      cond: !isTabletOrMobile,
      item: (
        <Input autoComplete="cc-csc"
          className="reponsive-input"
          style={{ display: "flex", minHeight: "45px", alignItems: "center", borderRadius: "15px", width: "50%" }}
          suffix={
            <SyncOutlined
              onClick={() => updateColor(color, user.id)}
              style={{ color: "blue", fontWeight: "bold", cursor: "pointer" }}
            />
          }
          type="color"
          defaultValue={color}
          onChange={c => setColor(c.target.value)}
        />
      )
    },
    {
      key: "two_fa",
      lg: 12,
      md: 12,
      xs: 24,
      label: "",
      cond: !isTabletOrMobile,
      item: <ToggleTwoFa user={user} />
    }, {
      key: "monthly_report",
      lg: 12,
      md: 12,
      xs: 24,
      label: "",
      cond: true,
      item: <ToggleMonthlyReport user={user} />
    }
  ];

  return (
    <Row type="flex" justify="space-between" gutter={[25, 10]}>
      {fields.filter((item) => item.cond).map(field => (
        <Col lg={field.lg} xs={field.xs}>
          {field.label}
          <br />
          {field.item}
        </Col>
      ))}
    </Row>
  );
};

function updateColor(color, user) {
  ProfileService.updateColor(color, user)
    .then(p => message.success("Color actualizado"))
    .catch(e => message.error("No se ha podido actualizar el color"));
}

export default withRouter(CreateProfile);
